export const allowAnnoymousAccess = () :boolean => {
    const href = window.location.href
    return href.indexOf("/forgot-password")>-1 ||  href.indexOf("/verify-user")>-1 ||  
        href.indexOf("/reset-password")>-1 ||  href.indexOf("/register")>-1 
        ||  href.indexOf("/login")>-1 
        ||  href.indexOf("/reporting/system/automated-report")>-1 
}
export const isPublicPage = ()=>{
  const href = window.location.href
  return href.indexOf("/reporting/system/automated-report")>-1
}
export const groupBy = (items:any , key :string) => items.reduce(
    (result:any, item:any) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );