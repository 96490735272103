import { useEffect, useState } from "react";
import TextBox from "./textBox";
import './css/tagBuilder.scss';
import React from "react";
import './css/helpPopout.scss';
import { FormValidationField } from "../../extensions/formValidation";
import { Editor, EditorTextChangeEvent } from "primereact/editor";

export interface TagBuilderTag {
    name: string,
    value: string
}
export interface TagBuilderProps {
    tags: TagBuilderTag[],
    triggerKey?: string,
    onChange?: (e: any) => void,
    id: string,
    label: string,
    value?: string,
    editor?:boolean
    helpText?: string | undefined,
    helpIconChildren?: React.ReactNode,
    validationField?: FormValidationField
}

export function TagBuilder(props: TagBuilderProps) {
    const triggerKey = props.triggerKey ?? '{';
    const [tags, setTags] = useState(props.tags);

    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState(props.value);
    const [inputLabel, seInputLabel] = useState<string>(props.label);
    const [inputId, setInputId] = useState<string>(props.id);
    const [helpText, setHelpText] = useState(props.helpText);
    const [messageContent,setMessageContent] = useState(props.value)
    // const [caretPos,setCaretPos] = useState(0)
    
     
    useEffect(() => {
        setTags(props.tags)
    }, [props.tags]);

    useEffect(() => {
        setInputValue(props.id)
    }, [props.id]);

    useEffect(() => {
        setInputValue(props.label)
    }, [props.label]);

    useEffect(() => {
        setInputValue(props.value)
        if(props.editor) setMessageContent(props.value)
    }, [props.value]);

    const handleKeyPress = (e: any) => {
        const key = e.key;
        
        // if (key && (key === triggerKey || key === "Shift")) {
        if (key && (key === triggerKey)) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handleValueChanged = (e: any) => {
        setInputValue(e.target.value);

        if (props.onChange) {
            props.onChange(e);
        }
    }
    const handleEditorUpdated = (e: EditorTextChangeEvent) => {
        
        if (props.onChange) {
            props.onChange(e);
        }



        // if((tags?.length ?? 0) > 0 && window && window.getSelection())
        //     setCaretPos(window.getSelection()?.focusOffset??0)
        setMessageContent(e.htmlValue??'')

        


    }
    const handleTagSelection = (e: any, tag: string) => {
        if(props.editor){
            insertTag(e,tag)
            return
        }
        let cursorPosition = (inputValue?.length ?? 1) - 1;
        const inputBox = e.target.parentElement.parentElement.querySelector('input[type=text]');
        if (inputBox) {
            cursorPosition = inputBox.selectionStart;
        }
        let valueSplitPre = inputValue?.substring(0, cursorPosition - 1);
        let valueSplitPost = inputValue?.substring(cursorPosition, inputValue.length);

        setShow(false);
        setInputValue(`${valueSplitPre}${tag}${valueSplitPost}`);
    }

    const insertTag = (e:any,tag:string) => {
        // const cleanText = messageContent?.replace(/<\/?[^>]+(>|$)/g, "");
        // let valueSplitPre = cleanText?.substring(0, caretPos);
        // let valueSplitPost = cleanText?.substring(caretPos+1, cleanText.length + tag.length);
        // setMessageContent(`${valueSplitPre}${tag}${valueSplitPost}`)
        setMessageContent(messageContent?.replace("~",tag))
      }

    return <>
        <div className={"tag-builder" + (show ? ' visible' : '')}>

            {!props.editor && <TextBox validationField={ props.validationField ?? undefined } id={inputId} label={inputLabel} value={inputValue} onKeyPress={handleKeyPress} onChange={handleValueChanged} icon={props.helpIconChildren ? 'none' : undefined} helpIconChildren={props.helpIconChildren} iconType="help" />}
            {props.editor && <Editor id="ar_editor" value={messageContent} onTextChange={handleEditorUpdated} onKeyDown={(tags?.length ?? 0) > 0 ? handleKeyPress : undefined} style={{ height: '320px' }} />}
            <ul className="tag-builder--list" style={{ display: (show ? 'block' : 'none') }}>
                {React.Children.toArray(
                    tags.map(tag => {
                        return <li onClick={(e) => { handleTagSelection(e, tag.value) }} title={`Click to insert '${tag.name}'`}>{tag.name}</li>
                    })
                )}
            </ul>
            {helpText && <p style={{ display: (!show ? 'block' : 'none') }} className="tag-builder--help-text">{helpText}</p>}
        </div>
    </>
}