import { useEffect, useState } from "react"
import { DocumentUploadType, DocumentUploadTypes } from "../interfaces/system/documentUploadTypes";
import { fetchDocumentLibraryList } from "../services/documentLibraryService";
import { HelpDocsList } from "../components/help/help-docs-list";
import Feedback, { FeedbackType } from '../components/core/feedback'
import { Grid } from "@mui/material";
import Panel from "../components/core/panel";
import { updatePageTitle } from "../services/utils";
import Loading from "../components/core/loading";

export function HelpContainer() {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [helpDocumentList, setHelpDocumentList] = useState<DocumentUploadType[]>();
    const [feedbackStatus, setFeedbackStatus] = useState<FeedbackType>();

    useEffect(() => {
        getHelpDocs();
    }, []);

    const getHelpDocs = async () => {
        updatePageTitle("Help Documents");
        setFeedbackStatus(FeedbackType.Loading);

        const response = await fetchDocumentLibraryList(DocumentUploadTypes.Help);
        if (response && response.success) {
            setHelpDocumentList(response.result);
        }

        setFeedbackStatus(FeedbackType.hide);
    }

    return <>
        <Grid container justifyContent="center" spacing={0} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel title="Help Options">
                    <Feedback type={feedbackStatus ?? FeedbackType.hide} message={errorMessage}></Feedback>
                    {!helpDocumentList && <>
                        <Loading loading={true} useBackdrop={false} />
                    </>}

                    {helpDocumentList && (helpDocumentList?.length ?? 0) > 0 && <>
                        <HelpDocsList documents={helpDocumentList} />
                    </>}

                </Panel>
            </Grid>
        </Grid>
    </>
}