import React, { useEffect, useState } from "react";
import Loader from "../components/core/loading";
import FormSubmission from "../components/form/form-submission";
import useForm from "../hooks/useForm"
import { Grid } from '@mui/material';
import { useParams } from "react-router-dom"
import { FeedbackType } from '../components/core/feedback'
import { serviceCanBeSubmitted, ServiceOverviewType } from "../interfaces/system/serviceType";
import { fetchFormFromCache, fetchSubmissionOverview, saveFormSubmission } from "../services/form-service";
import { FormType, FormSubmissionType, FormActionTypeEnum, FormFieldType, FormTypeEnum } from "../interfaces/system/formType";
import FormSubmissionSummary from "../components/home/form-submission-summary";
import Panel from "../components/core/panel";
import { AppToastContext } from "../extensions/context/appHook";
import { useNavigate } from "react-router-dom";
import { fetchAmbulanceMetrics, fetchEmergencyMetrics } from "../services/demandAndCapacityService";
import { DatasetTypeEnum } from "../interfaces/system/dataTypes";
import { createNewDemandAndCapacityMetric, DemandAndCapacityMetrics } from "../interfaces/reporting/demandAndCapacity";
import Page from "../components/core/page";
import Loading from "../components/core/loading";
import { userHasApprovedPermissionForService } from "../services/storageService";
import FeedBackText from "../components/core/feedbackText";
import ActionButton from "../components/core/actionButton";


const FormContainer: React.FC = () => {

    const [loading, setLoading] = useState(true);
    const [serviceOverview, setServiceOverview] = useState<ServiceOverviewType>()
    const [form, setForm] = useState<FormType>()
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [hasForm, setHasForm] = useState(true)
    const [formIdToLoad, setFormIdToLoad] = useState(0)
    const [demandAndCapacityMetrics, setDemandAndCapacityMetrics] = useState<DemandAndCapacityMetrics>()
    const [formNotFound, setFormNotFound] = useState(false);
    const [serviceCanSubmit, setServiceCanSubmit] = useState(true);
    const [enableSubmission, setEnableSubmission] = useState(true);
    const [accessDenied,setAccessDenied] = useState(false)

    let { serviceId, formIdParam } = useParams();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    
    const {
        feedbackStatus, setFeedbackStatus,
    }
        = useForm({ initialObjectValues: {} })

    useEffect(() => {
        loadService()
       
    }
        , [])

    useEffect(() => {
        if (serviceOverview)
            presentForm();
        else
            ensureHasAccess()
    }
        , [serviceOverview])

    useEffect(() => {
        if (serviceOverview && form)
            populateAutomatedFields()
    }
        , [serviceOverview, form])

    useEffect(() => {
        if (serviceOverview && formIdToLoad)
            presentForm();
    }
        , [formIdToLoad])


    useEffect(() => {
        setLoading(!hasForm)
    }
        , [form])

    const populateAutomatedFields = async () => {



        const automatedFields = form?.fields.filter(f => f.automationDatasetMetricTypeId);
        if (!automatedFields || automatedFields?.length === 0) return

        if (!serviceOverview?.service?.code) return

        const hasEmergencyMetrics = automatedFields.findIndex(f => f.automationDatasetTypeId === DatasetTypeEnum.Emergency as number) > -1
        const hasAmbulanceMetrics = automatedFields.findIndex(f => f.automationDatasetTypeId === DatasetTypeEnum.Ambulance) > -1

        const demandAndCapacityMetrics = createNewDemandAndCapacityMetric()
        if (hasEmergencyMetrics) {
            let emergencyMetrics = await fetchEmergencyMetrics(serviceOverview?.service?.code)

            demandAndCapacityMetrics.emergency = emergencyMetrics?.result?.find(f => f.siteCode === serviceOverview?.service?.code)
        }

        if (hasAmbulanceMetrics) {
            const ambulanceMetrics = await fetchAmbulanceMetrics(serviceOverview?.service?.code)
            demandAndCapacityMetrics.ambulance = ambulanceMetrics?.result?.find(f => f.siteCode === serviceOverview?.service?.code)
        }
        setDemandAndCapacityMetrics(demandAndCapacityMetrics)
    }

    const presentForm = async () => {
        if (!serviceOverview?.form?.id) {
            
            setHasForm(false)
            return
        }

        
        // handle form override in URL param
        if (formIdParam && parseInt(formIdParam)) {
            let formIdQueryString = parseInt(formIdParam);
            setFormIdToLoad(formIdQueryString);
        }
        

        const formId = formIdToLoad > 0 ? formIdToLoad : serviceOverview?.form?.id
        setLoading(true)
        const response = await fetchFormFromCache(formId, true);

        if (response && response.success && response.result) {
            const f = response.result
            if(serviceOverview.service.opelAutomation){
                const nonOpelFields:FormFieldType[] = []

            f.fields.forEach(f=>{
                const i = f.options.findIndex(i=> i.opelWeighting??0>0)
                if(i<0) nonOpelFields.push(f)
            })

            f.fields = nonOpelFields
            }
            setForm({ ...f })
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(response.error)

        if (formIdParam && parseInt(formIdParam) && formIdToLoad) {
            setFormNotFound(true);
        }
    }
    const ensureHasAccess = () =>{
        var hasPermission = userHasApprovedPermissionForService(300, parseInt(serviceId || '0'))
        
        if(!hasPermission){
            setAccessDenied(true)
            return
        }
    }
    const loadService = async () => {
        setLoading(true)
        const response = await fetchSubmissionOverview(parseInt(serviceId || ''));
        
        setLoading(false)
        
        if (response && response.success && response.result) {

            if(!serviceCanBeSubmitted(response.result)){
                setServiceOverview(undefined)
                setServiceCanSubmit(false)
            }
            setServiceOverview(response.result)
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(response.error)
    }
    const toastHandler = React.useContext(AppToastContext)
    const navigate = useNavigate();

    const formSubmittedSuccessfully = () => {

        switch (form?.actionType?.id || 0) {
            case FormActionTypeEnum.DisplayMessage:
                setShowSuccessMessage(true);
                break;
            case FormActionTypeEnum.RedirectToForm:
                toastHandler.successMessage("Successfuly submitted Escalation. Please complete the follow on form.")
                if (serviceId && parseInt(serviceId) > 0 && (form?.formActionNumeric ?? 0) > 0) {
                    setTimeout(() => {
                        window.location.replace(`/form-submission/${serviceId}/${form?.formActionNumeric}/`);
                    }, 1000)
                }
                else{
                    navigate('/', { replace: true })
                }
                break;
            default:
                navigate('/', { replace: true })
                toastHandler.successMessage("Successfuly submitted form.")
                break;
        }
    }
    const handleCancelFormSubmission = () => {
        navigate('/', { replace: true })
    }
    const handleSaveFormSubmission = async (formSubmission: FormSubmissionType, valid: boolean) => {

        if (!valid) {
            setErrorMessage('Please complete highlighted fields')
            setFeedbackStatus(FeedbackType.Error)
            return
        }

        setEnableSubmission(false)

        setFeedbackStatus(FeedbackType.Loading)
        formSubmission.serviceId = parseInt(serviceId || '0')
        formSubmission.appendExistingOpelFields =  form?.formType.id=== FormTypeEnum.Weighted &&
                                         serviceOverview?.service.opelAutomation
        const response = await saveFormSubmission(formSubmission);

        if (response && response.success && response.result) {
            formSubmittedSuccessfully()
            setFeedbackStatus(FeedbackType.hide)
        }
        else{
            setEnableSubmission(true)
            setErrorMessage('Error submitting Form - ' + response.error)
            setFeedbackStatus(FeedbackType.Error)
        }
        
    }

    if (loading && hasForm)
        return <Grid container justifyContent="center" style={{ marginTop: '5vw', marginBottom: '6vw' }}><Loader loading={true} useBackdrop={false} /></Grid>

    if (!hasForm)
        return <Grid container justifyContent="center">
            <Grid item xs={7}>
                <Panel title={serviceOverview?.service?.name} ><p>This service does not have an associated form. </p></Panel>
            </Grid>
        </Grid>


    if (!serviceCanSubmit)
        return <Grid container justifyContent="center">
            <Grid item xs={7}>
                <Panel title={serviceOverview?.service?.name} ><p>This service does not support form submissions. </p></Panel>
            </Grid>
        </Grid>

    if (!serviceOverview && !loading && !accessDenied){
        return <Grid container justifyContent="center">
            <Grid item xs={7}>
                <Panel title="Service Permissions" ><p>This service does not exist or you do not have persmissions to submit. </p></Panel>
            </Grid>
        </Grid>
    }
    if (accessDenied){
        return <Grid container justifyContent="center">
            <Grid item xs={7}>
                <Panel title="Service Permissions" >
                <FeedBackText show={true}  severity="error" message="You do not have persmissions to submit escalation levels for this service. " />
                <br></br>
                <ActionButton severity="success" href="/home" label="Home"/>
                </Panel>
            </Grid>
        </Grid>
    }

    if (formIdParam && formIdToLoad && formNotFound) {
        return <Grid container justifyContent="center">
            <Grid item xs={7}>
                <Page title={serviceOverview?.service?.name ?? ''} errorMessage={errorMessage} feedbackStatus={feedbackStatus}><p>Requested form was not found.</p></Page>
            </Grid>
        </Grid>
    }

    if (showSuccessMessage)
        return <><p>The form was successfully submitted</p>
            <p>{form?.formActionText}</p>
        </>

    return <>
        <Page title={serviceOverview?.service?.name ?? ''} errorMessage={errorMessage} feedbackStatus={feedbackStatus}>
            <Loading loading={false}></Loading>

            <Grid container style={{marginTop:-50}}>
                <Grid item xs={8}>
                </Grid>

                <Grid item xs={4}>
                
                    <FormSubmissionSummary showLastUpdated={true} showFooter={false} gaugeWidth="150px" padding={5} className="form-submission-summary-align-right" serviceStatus={serviceOverview?.service} />
                </Grid>
                <Grid item xs={12}>
                    {hasForm && <FormSubmission form={form} opelAutomation={serviceOverview?.service.opelAutomation} demandAndCapacityMetrics={demandAndCapacityMetrics} disabled={!enableSubmission} onSave={handleSaveFormSubmission} onCancel={handleCancelFormSubmission} />}
                </Grid>
            </Grid>
        </Page>
    </>
}

export default FormContainer;