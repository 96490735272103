import { useEffect, useState } from "react";
import { DocumentUploadServiceType, DocumentUploadType, DocumentUploadTypes } from "../../../interfaces/system/documentUploadTypes"
import DebugVariable from "../../core/debugVariable";
import { Grid } from "@mui/material";
import TextBox from "../../core/textBox";
import { DocumentUploadValidator } from "../../../validators/doc-upload-validator";
import TickBox from "../../core/tickBox";
import Dropdown from "../../core/dropdown";
import OKCancelButtons from "../../core/OKCancelButtons";
import ActionButton from "../../core/actionButton";
import ServiceSelector, { SelectedServiceType } from "../../core/service-selector";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { DownloadDocumentLibraryFile } from "../../../services/http";

export interface DocumentLibraryEditorProps {
    areaHierarchy: AreaHierarchyType,
    selectedItem: DocumentUploadType,
    onSave?: (item: DocumentUploadType, valid: boolean) => void,
    onCancel?: () => void
}
export function DocumentLibraryEditor(props: DocumentLibraryEditorProps) {
    const [selectedItem, setSelectedItem] = useState<DocumentUploadType>(props.selectedItem);
    const [modelValidator, setModelValidator] = useState(new DocumentUploadValidator());
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);


    useEffect(() => {
        setSelectedItem(props.selectedItem);
        setIsValid(modelValidator.validate(props.selectedItem));
    }, [props.selectedItem]);


    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedItem) return;
        if (e.target.files) {
            let updatedItem = { ...selectedItem, file: e.target.files[0] };
            setSelectedItem(updatedItem);
            setDirty(true);
            setIsValid(modelValidator.validate(selectedItem));
        }
    };
    const handleServiceSelection = (items: SelectedServiceType[]) => {
        if (!selectedItem) return;
        let _selections: DocumentUploadServiceType[] = [];
        for (var i = 0; i < items.length; i++) {
            _selections.push({ id: 0, serviceId: items[i].id, deleted: false, documentUploadId: selectedItem.id, sortOrder: 99 });
        }
        let updatedModel: DocumentUploadType = { ...selectedItem, services: _selections };
        setSelectedItem(updatedModel);
        setDirty(true);
    }


    const handleCancelClicked = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }
    const handleSave = async () => {
        //console.dir(selectedItem);
        if (props.onSave) {
            setIsValid(modelValidator.validate(selectedItem));
            props.onSave(selectedItem, isValid);
        }
    }

    const handleDownloadFile = async () => {
        DownloadDocumentLibraryFile(selectedItem.uploadKey + '', selectedItem.documentTypeId, `${selectedItem.filename}.${selectedItem.fileExtension}`);
    }

    return <>
        <DebugVariable variable={selectedItem} hide={true} />
        <p>{selectedItem.id > 0 ? 'Edit' : 'Add new'} document upload:</p>
        <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
            <Grid item xs={2}>
                <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} disabled={selectedItem.id <= 0} />
            </Grid>
            <Grid item xs={8}></Grid>

            <Grid item xs={12} lg={8}>
                <TextBox id="name" label="Name" value={selectedItem.name} validationField={modelValidator.name} onChange={handleFieldUpdated} />
            </Grid>
            
            <Grid item xs={12} lg={4}>
                <Dropdown id="documentTypeId" label="Document Type" filter={false} options={[{ id: DocumentUploadTypes.Help as number, code: '', name: 'Help' }, { id: DocumentUploadTypes.Service as number, code: '', name: 'Service' }]} includeBlankOption={true} validationField={modelValidator.documentTypeId} value={selectedItem?.documentTypeId ?? 0} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={12}>
                <TextBox id="description" label="Description" rows={6} value={selectedItem.description} onChange={handleFieldUpdated} maxLength={512} />
            </Grid>

            <Grid item xs={12}>
                <input type="file" name="files" onChange={handleFileChange} />
                {selectedItem.filename && <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <p>Current file: <strong>{selectedItem.filename}.{selectedItem.fileExtension} ({((selectedItem.fileSize / 1024) / 1024).toString().substr(0, 4)} MB)</strong></p>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <ActionButton onClick={handleDownloadFile} label="Download file" severity="info" size="small" icon="pi pi-paperclip"></ActionButton>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
            {selectedItem.documentTypeId === DocumentUploadTypes.Service && <>
                <Grid item xs={12}>
                    <p>Select services (leave blank for all services):</p>
                    <ServiceSelector areaHierarchy={props.areaHierarchy} onChange={handleServiceSelection} selections={selectedItem.services ? selectedItem.services?.map(s => { return { id: s.serviceId } }) : undefined}></ServiceSelector>
                </Grid>

            </>}
            <Grid item xs={12}>
                {dirty && isValid && <OKCancelButtons onOK={handleSave} onCancel={handleCancelClicked} />}

                {(!dirty || !isValid) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                    <div style={{ float: 'left', marginRight: '5px' }}>
                        <ActionButton onClick={handleCancelClicked} label="Cancel" severity="secondary"></ActionButton>
                    </div>
                </div>}
            </Grid>
        </Grid>
    </>
}