import React from "react";
import { DomainEntityType } from "../../interfaces/domain/iDomainEntity";
import { Grid } from "@mui/material";
import Loader from "../../components/core/loading";
import Panel from "../core/panel";

interface ServiceFeatureListProps {
    serviceFeatures?:DomainEntityType[]
  }
export default function ServiceFeatureList(props: ServiceFeatureListProps) {
    const [features,setFeatures]  = React.useState(props.serviceFeatures)
    const [loading,setLoading]  = React.useState(true)

    React.useEffect(() => {
        if(props.serviceFeatures)
            setLoading(false)
        setFeatures(props.serviceFeatures)
       }
       ,[props.serviceFeatures])

    if(loading)
        return <Grid container justifyContent="center" style={{marginTop:'5vw',marginBottom:'6vw'}}><Loader loading={true} useBackdrop={false}/></Grid>

    
        return <>
            <Panel title="Features" >
            <Grid container spacing={2}>
            {features?.map((f:DomainEntityType) => (

                <Grid xs={4}>
                    <li>{f.name}</li>
                </Grid>

                ))}
            </Grid>
        </Panel>
        </>
}