import { FormValidation, FormValidationField } from '../extensions/formValidation';
import { SystemMessageType } from '../interfaces/system/systemMessageType';
import { ValidatorType } from './ValidatorType'

export class SystemMessageValidator implements ValidatorType {

    validator = new FormValidation([])
    subject = new FormValidationField('', '')
    activeFrom = new FormValidationField('', '')
    activeTo = new FormValidationField('', '')
    messageContent = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: SystemMessageType) => {
        this.validator = new FormValidation([])
        this.subject = this.validator.createTextField(objectValues.subject)
        this.messageContent = this.validator.createTextField(objectValues.messageContent)
        this.activeFrom = this.validator.createTextField(objectValues?.activeFrom ?objectValues?.activeFrom?.toString() :'')
        this.activeTo = this.validator.createTextField(objectValues?.activeTo ?objectValues?.activeTo?.toString() :'')
    }

    validate = (validatorObjectValues: SystemMessageType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
