import React, { useState, useEffect } from "react";
import {AddressType} from '../../interfaces/system/iAddress';
import {Grid} from '@mui/material';
import Panel from "../core/panel";
import LabelText from "../core/labelText";
import Loader from "../core/loading";

export interface AddressSummaryProps {
    address?: AddressType
    label?: string
    disabled?:boolean | false
    toggle?:boolean|false
    onChange?: (addressDetails: AddressType) => void
  }
  export default function AddressSummary(props: AddressSummaryProps) {
  const [addressObject, setAddressObject] = useState(props.address)
  const [fullAddress, setFullAddress] = useState('')
  const [loading,setLoading]  = React.useState(true)

  useEffect(() => {
    constructFullAddress()
    },[addressObject]);
    useEffect(() => {
        if(props.address) 
                setLoading(false)
        setAddressObject(props.address)
        },[props.address]);

  
  
 const constructFullAddress = () => {

    if(!addressObject) return
  setFullAddress( `${addressObject.addressLine1 ? addressObject.addressLine1 + '\n' : ''}${addressObject.addressLine2 ? addressObject.addressLine2 + '\n' : ''}${addressObject.town ? addressObject.town + '\n':''}${addressObject.county ? addressObject.county  + '\n': ''}${addressObject.postcode ? addressObject.postcode : ''}`
  )
} 
    if(loading)
        return <Grid container justifyContent="center" style={{marginTop:'5vw',marginBottom:'6vw'}}><Loader loading={true} useBackdrop={false}/></Grid>


      return <Panel title="Contact">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <LabelText label="Contact Name" text={addressObject?.contactName}/>
            </Grid>
            <Grid item xs={6}>
                <LabelText label="Telephone" text={addressObject?.contactTelephone}/>
            </Grid>
            <Grid item xs={6}>
                <LabelText label="Mobile" text={addressObject?.contactMobile}/>
            </Grid>
            <Grid item xs={12}>
                <LabelText label="Address" text={fullAddress}/>
            </Grid>
            
            <Grid item xs={12}>
                <LabelText type="url" label="Website" text={addressObject?.website}/>
            </Grid>
            <Grid item xs={12}>
                <LabelText type="email" label="Email" text={addressObject?.email}/>
            </Grid>
        </Grid>
        </Panel>

  }