import {FormValidation, FormValidationField} from '../extensions/formValidation';
import {ValidatorType} from './ValidatorType'
import { UserType } from '../interfaces/user/iUser';

export class UserRegistrationValidator implements ValidatorType
{
   formValidator = new FormValidation([])
    fullName= new FormValidationField('','')
    jobRole= new FormValidationField('','')
    applicationUse= new FormValidationField('','')
    serviceName= new FormValidationField('','')
    serviceCategory = new FormValidationField('','')
    area = new FormValidationField('','')

     initialiseValidationFields = (objectValues: UserType) => {
        this.formValidator = new FormValidation([])
         this.fullName = this.formValidator.createTextField(objectValues.fullName)
        this.jobRole= this.formValidator.createTextField(objectValues.jobRole)
        this.applicationUse= this.formValidator.createTextField(objectValues.applicationUse)
        this.serviceName= this.formValidator.createTextField(objectValues.serviceName)
        this.serviceCategory= this.formValidator.createDropdownField(objectValues.serviceCategoryId)
        this.area= this.formValidator.createDropdownField(objectValues.areaId)
    }
    
 validate =  (validatorObjectValues: UserType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid
 }
}

export default UserRegistrationValidator

