import { useEffect, useState } from "react";
import { FormType } from "../../../interfaces/system/formType"
import { ActionCardRecipientType, ActionCardType, createEmptyActionCardRecipient } from "../../../interfaces/system/actionCardType";
import { ActionCardRecipientValidator } from "../../../validators/action-card-validator";
import SortableList from "../../core/sortableList";
import { Grid } from "@mui/material";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import Dropdown from "../../core/dropdown";
import ActionButton from "../../core/actionButton";
import DebugVariable from "../../core/debugVariable";

export interface ActionCardRecipientEditorProps {
    item: ActionCardType,
    form?: FormType,
    onUpdate: (recipients: ActionCardRecipientType[], valid: boolean) => void
}
export function ActionCardRecipientEditor(props: ActionCardRecipientEditorProps) {
    const [selectedActionCard, setSelectedActionCard] = useState(props.item);
    const [selectedItem, setSelectedItem] = useState<ActionCardRecipientType>();
    const [form, setForm] = useState(props.form);
    const [modelValidator, setModelValidator] = useState(new ActionCardRecipientValidator());
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        setSelectedActionCard(props.item);
    }, [props.item]);

    useEffect(() => {
        setDirty(false);
        if (selectedItem) {
            setIsValid(modelValidator.validate(selectedItem));
        }
    }, [selectedItem]);


    const handleListSort = (l: any) => { }

    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));

        triggerUpdate(updatedItem);
    }

    const handleAddNewRecipient = (e: any) => {

        if (selectedActionCard.recipients) {
            const currentRecipients: ActionCardRecipientType[] = selectedActionCard.recipients;
            const newRecipient: ActionCardRecipientType = createEmptyActionCardRecipient(selectedActionCard.id);
            newRecipient.id = 0 - (currentRecipients.length)
            currentRecipients.push(newRecipient);
            setSelectedActionCard({ ...selectedActionCard, recipients: currentRecipients });
            setSelectedItem(newRecipient);
        }
    }

    const triggerUpdate = (recipient: ActionCardRecipientType) => {
        if (!selectedItem || !recipient) return;
        let newList: ActionCardRecipientType[] = [];
        selectedActionCard.recipients?.forEach(r => {
            newList.push(r.id === recipient.id ? recipient : r);
        });
        
        let updatedItem = { ...selectedActionCard, recipients: newList };
        setSelectedActionCard(updatedItem);
        
        props.onUpdate(updatedItem.recipients, isValid);
    }

    if (!form) {
        return <>Awaiting service selection</>
    }


    return <>
        <Grid container justifyContent="left" spacing={1} style={{ padding: "1rem 0.5rem" }}>
            <Grid item xs={12}>
                <p><strong>Recipient Groups:</strong></p>
                {(selectedActionCard.recipients?.length ?? 0) <= 0 && <p>Action card has no recipients, click the 'New' button below to start.</p>}
            </Grid>
            <Grid item xs={6} sm={4} lg={3}>
                <SortableList data={selectedActionCard.recipients} onItemSelection={(s) => { setSelectedItem(s); }} disableSort={true} onReOrder={handleListSort} />
                <ActionButton onClick={handleAddNewRecipient} label="New"/>
            </Grid>
            <Grid item xs={6} sm={8} lg={9}>
                <DebugVariable hide={true} variable={selectedItem} title="Selected recipient" />
                {selectedItem &&
                    <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
                        <Grid item xs={2}>
                            <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} hidden={selectedItem.id <= 0} />
                        </Grid>
                        <Grid item xs={2}>
                            <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={() => { }} disabled={selectedItem.id <= 0} />
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={6}>
                            <TextBox id="name" label="Name" value={selectedItem.name} validationField={modelValidator.name} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={6}>
                            <Dropdown id="distributionListId" label="Distribution List" optionName="distribution-list" includeBlankOption={true} validationField={modelValidator.distributionListId} value={selectedItem?.distributionListId ?? 0} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={12}>
                            <TextBox id="description" label="Description" rows={3} value={selectedItem.description} validationField={modelValidator.description} onChange={handleFieldUpdated} maxLength={1024} />
                        </Grid>

                    </Grid>
                }
            </Grid>
        </Grid>
    </>
}