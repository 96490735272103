import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { AmbulanceMetric, EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";
import DateLabelWithElapsedIndicator from "../../core/date-label-with-elapsed-indicator";
require('./demand-and-capacity.scss')

interface DataQualityType{
    name:string
    lastUpdatedOn:Date
}
export interface DemandAndCapacityPanelProps{
    emergencyMetric?:EmergencyMetric
    ambulanceMetric?:AmbulanceMetric
}
export default function DemandAndCapacityDataQuality(props: DemandAndCapacityPanelProps) {

    // const [emergencyMetric, setEmergencyMetric] = React.useState(props.emergencyMetric);
    // const [ambulanceMetric, setAmbulanceMetric] = React.useState(props.ambulanceMetric);
    const [dq, setDq] = React.useState<DataQualityType[]>();

    React.useEffect(() => {
        const l:DataQualityType[] = []
        // if(props.emergencyMetric){
        //     setEmergencyMetric(props.emergencyMetric)
        // }
        // if(props.ambulanceMetric){
        //     setAmbulanceMetric(props.ambulanceMetric)
            
        // }
        if(props.emergencyMetric)
            l.push({name:"Emergency", lastUpdatedOn:props?.emergencyMetric?.lastUpdatedOn})

            
        if(props.ambulanceMetric)
            l.push({name:"Ambulance", lastUpdatedOn:props?.ambulanceMetric?.lastUpdatedOn})

        setDq(l)
      }
      ,[props.emergencyMetric,props.ambulanceMetric])
      
   

    const lastupdatedBodyTemplate = (rowData:any) => {
        
        return <div style={{display:'flex'}}>
                <DateLabelWithElapsedIndicator date={rowData?.lastUpdatedOn} redMax ={8} greenMax={5} />
            </div>;
    }
    return  <DataTable value={dq}  
    sortMode="single" sortField="area.name" sortOrder={1} scrollable scrollHeight="40vw"
    responsiveLayout="scroll">
    <Column field="name" header="Data Last Updated"   ></Column>
    <Column field="lastUpdatedOn" header="" body={lastupdatedBodyTemplate}  ></Column>
</DataTable>
}