import { useState } from "react"
import SimpleDialog from "./simpleDialog"
import './css/helpPopout.scss';

export interface HelpPopoutProps {
    children: React.ReactNode,
    popoutTitleText: string
    className?:string
}
export function HelpPopout(props: HelpPopoutProps) {
    const [show, setShow] = useState(false)
    const [titleText, setTitleText] = useState(props.popoutTitleText)


    return <>
        <i className={"help-popout-icon pi pi-question" + (show ? ' selected':'') + (props.className ? ` ${props.className}` : '')} title="Click to view more information" onClick={() => { setShow(true) }} ></i> &nbsp;

        <SimpleDialog show={show} fullScreen={false} noCaption="" title={titleText} onClose={() => { setShow(false) }}>
            {props.children}
        </SimpleDialog>
    </>
}