import { EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";
import { Chart } from 'primereact/chart';
import { useEffect, useState } from "react";
import GroupBox from "../../core/groupBox";
import { HelpPopout } from "../../core/help-popout";


export interface DemandAndCapacityPatientArrivalsChartProps{
    emergencyMetric:EmergencyMetric
}
export default function DemandAndCapacityPatientArrivalsChart(props: DemandAndCapacityPatientArrivalsChartProps) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const  [emergencyMetric, setEmergencyMetric] = useState(props.emergencyMetric);

    useEffect(() => {
        setEmergencyMetric(props.emergencyMetric)
      }
      ,[props.emergencyMetric])
    useEffect(() => {
        
        if(!emergencyMetric || !emergencyMetric.arivalsByHour) return 
        
        const actual :any[]=[]
        const expected :any[]=[]

        // const currentHour = (new Date()).getHours()
        
        for(var i = 0; i<=23;i++){
            const arrival = emergencyMetric.arivalsByHour.find(f=> f.hour === i)
            
            if(!arrival){
                actual.push(undefined)
                expected.push(0)    
                continue
            }

            actual.push(arrival.actual)
            expected.push(arrival.expected)
        }


        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
                     '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00',
                     '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
                     '21:00', '22:00', '23:00'],
            datasets: [
                {
                    type: 'line',
                    label: 'Expected',
                    borderColor: documentStyle.getPropertyValue('--red-300'),
                    borderWidth: 2,
                    pointStyle:false,
                    // fill: false,
                    tension: 0.4,
                    data: expected,
                    options:{spanGaps:true}
                },
                {
                    type: 'bar',
                    label: 'Actual',
                    backgroundColor: documentStyle.getPropertyValue('--blue-300'),
                    data: actual,
                    borderColor: 'white',
                    borderWidth: 2,
                } 
            ]
        };
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 0.99,
            plugins: {
                legend: {
                    display:false,
                    labels: {
                        color: textColor
                    }
                },
                labels: {
                    render: function (args:any) {alert('')
                              if (args.value != 0)
                                  return args.value;
                          },
                    fontSize: 10
  
                      }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                  }],
                  yAxes: [{
                    stacked: true
                  }],
                x: {
                    spanGaps:true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        display:false,
                    }
                },
                y: {
                    spanGaps:true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    
    return <GroupBox title="Patient Arrivals by Hour" variant="dashboard-panel">
        <div style={{float:'right'}}>
                <HelpPopout popoutTitleText={"Patient Arrivals by Hour"}>
                    <p>Patient Arrivals by hour</p>
                </HelpPopout>
        </div>
        <span className="small-label-text-secondary" style={{marginLeft:10}}>Total Attendees: {props.emergencyMetric.totalAttendees.toString()}</span>
        <div style={{clear:'both',height:400}}>
        <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    </GroupBox>
    

}