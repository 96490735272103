import { Grid } from "@mui/material";
import React from "react";
import Loader from "../../../components/core/loading";
import { formatDateTime } from "../../../extensions/formatter";
import { FormSubmissionType } from "../../../interfaces/reporting/form-submission";
import { FormResultTypeEnum } from "../../../interfaces/system/formType";
import { ServiceStatusType } from "../../../interfaces/system/serviceType";
import { setStylesForFieldsFromSubmission } from "../../../services/reporting/form-service";
import DataTable from "../../core/dataTable";
import LabelText from "../../core/labelText";


interface FormSubmissionReportProps {
    service?:ServiceStatusType
    formSubmission?:FormSubmissionType
    formResultType:FormResultTypeEnum
    includeSummary?:boolean
  }

  
const FormSubmissionReport:  React.FC<FormSubmissionReportProps> = (props) => {
    // const [service,setService]  = React.useState(props.service)
    const [formSubmission,setFormSubmission]  = React.useState(props.formSubmission)
    const [loading,setLoading]  = React.useState(true)

     React.useEffect(() => {

      let formSubmission = props.formSubmission
      if(!formSubmission) return
      setLoading(false)
      handleFormSubmissionSet(formSubmission)

     }
     ,[props.formSubmission])
 
      const handleFormSubmissionSet =async (formSubmission:FormSubmissionType)=>{
        
          formSubmission = await setStylesForFieldsFromSubmission(formSubmission,props.formResultType)
          setFormSubmission(formSubmission)
      }

     if(loading)
        return <Grid container justifyContent="center" style={{marginTop:'5vw',marginBottom:'6vw'}}><Loader loading={true} useBackdrop={false}/></Grid>


        var footer = !props.includeSummary ? undefined :<><Grid item xs={8}  >
        <LabelText id="fs-created" label="Submitted" text={formatDateTime(formSubmission?.createdOn)} />
      </Grid>
      <Grid item xs={8} >
        <LabelText  id="fs-comments" label="Comments" text={formSubmission?.comments} />
      </Grid></>


        return <>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={8} className="escalation small">
            <DataTable dataKey="id" loading={false} columns={[{header:"Escalation Breakdown", field:"formField.name"},
                {header:"Level", field:"formFieldOption.reportingLabel", style:"style"}]}  value={formSubmission?.fields}   paginator={false} filter={false} allowExport={true}
                footer={footer}
                />
            </Grid>
             
        </Grid>
        </>
}


export default FormSubmissionReport;

