import GaugeChart from "react-gauge-chart";
import { formatMinutesToHHMM } from "../../../extensions/formatter";
import GroupBox from "../../core/groupBox";
import { HelpPopout } from "../../core/help-popout";

export interface DemandAndCapacityGaugeRangeProps {
    min: number
    max: number
}
export interface DemandAndCapacityGaugeProps {
    title: string
    value: number
    format?: 'number' | 'time' | 'percentage'
    help?: string
    MaxValue: number
    minValue: number
    ranges?: DemandAndCapacityGaugeRangeProps[]
    step?: number
    steps?: number[]
    id: string
    onClick?: () => void
    inverted?: boolean
}
export default function DemandAndCapacityGauge(props: DemandAndCapacityGaugeProps) {

    
    const handleClick = () => {
        if (props.onClick)
            props.onClick()
    }

    let ranges: number[] = []
    if (!props.ranges && props.steps) {
        props.steps.forEach(f => {
            ranges.push(f)
        })
    } else if (!props.ranges) {
        for (let i = 0; i < 3; i++) {

            ranges.push(props.step || 1 * i)

        }
    }
    else {
        props?.ranges?.forEach(f => {
            ranges.push((f.max - f.min) / props.MaxValue)
        })
    }

    const value = (!props.format || props.format === "number") ? props.value :
        props.format === "time" ? formatMinutesToHHMM(props.value) : `${Math.ceil(props.value*100)}%`
    return <GroupBox title={props.title} variant='dashboard-panel--gauge'>
        <div style={{position:'relative',marginTop:'-15px',float:'right'}}>
            <HelpPopout popoutTitleText={props.title}>
                <p>{props.help}</p>
            </HelpPopout>
        </div>
        {/* <h3 style={{color:'#000', textAlign:"center",fontWeight:"normal",fontSize:'1rem',margin:0}}>{props.title}</h3> */}
        <div className="chart-container" style={{ width: '100%', color: '#000', cursor: (props.onClick ? 'pointer' : '')  }} onClick={handleClick}>
            <GaugeChart id={props.id}
                arcsLength={ranges}
                arcWidth={0.15}
                arcPadding={0.01}
                colors={!props.inverted ? ['#A6C567', 'rgb(252, 187, 105)', 'rgb(225, 144, 148)'] : ['rgb(225, 144, 148)', 'rgb(252, 187, 105)', '#A6C567']}
                cornerRadius={0.2}
                fontSize={'1rem'}
                nrOfLevels={2}
                animate={true}
                style={{marginTop:'30px'}}
                formatTextValue={(value) => ''}
                // formatTextValue={(value) => value}
                percent={props.value<=props.MaxValue? props.value / props.MaxValue : 1} />
            <div style={{ textAlign: "center", width: '100%',marginTop:'-15px' }}>
                <span className="value-text" >{value}</span>
            </div>
        </div>
    </GroupBox>
}