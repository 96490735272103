import { useEffect, useState } from "react";
import { CapacityGridFieldOptionNumericValue, CapacityGridFieldTypes, CapacityGridSubmissionColumnType, CapacityGridSubmissionDataType, CapacityGridType, } from "../../interfaces/system/capacityGridType"
import { ServiceType } from "../../interfaces/system/serviceType"
import { saveCapacityGridSubmission } from "../../services/capacity-grid-service";
import DebugVariable from "../core/debugVariable";
import CapacityGridSubmissionEditorField from "./capacity-grid-submission-editor-field";
import Loader from "../../components/core/loading";
import ActionButton from "../core/actionButton";
import { parse } from "path";
import CapacityGridResultRangeDisplay from "../core/capacityGridResultRangeDisplay";
import { formatDateTime } from "../../extensions/formatter";

interface ICapacityGridSubmissionEditorRowProps {
    Division: ServiceType,
    CapacityGrid: CapacityGridType,
    RowItem: CapacityGridSubmissionDataType,
    OnUpdated?: (rowData: CapacityGridSubmissionDataType) => void,
    OnSave: (rowData: CapacityGridSubmissionDataType, division: ServiceType) => void,
    OnError?: (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => void,
    EnableEditRowMode: boolean,
    RowShowLoading?: boolean
}
export default function CapacityGridSubmissionEditorRow(props: ICapacityGridSubmissionEditorRowProps) {

    const [loading, setLoading] = useState<boolean>(props.RowShowLoading ?? false);
    const [editorMode, setEditorMode] = useState<'show' | 'edit'>(props.EnableEditRowMode ? 'edit' : 'show');
    const [editRowModeEnabled, setEditRowModeEnabled] = useState<boolean>(props.EnableEditRowMode);
    const [division, setDivision] = useState<ServiceType>(props.Division);
    const [capacityGrid, setCapacityGrid] = useState<CapacityGridType>(props.CapacityGrid);
    const [rowItem, setRowItem] = useState<CapacityGridSubmissionDataType>(props.RowItem);

    // hooks
    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setEditorMode(props.EnableEditRowMode ? 'edit' : 'show');
        setEditRowModeEnabled(props.EnableEditRowMode);
    }, [props.EnableEditRowMode])

    useEffect(() => {
        setLoading(props.RowShowLoading ?? false)
    }, [props.RowShowLoading])

    // component methods
    const init = async () => {
        setLoading(false);
        updateCalculatedCells();
    }

    const setToolTipText = (event: React.MouseEvent<HTMLDivElement>, text: string) => {
        const box: HTMLDivElement = event.currentTarget;
        const table = box.closest("table");
        if (table) {
            const tooltipRow = table.querySelector(".tooltip-text");
            if (tooltipRow) {
                tooltipRow.textContent = text;
            }
        }
    }

    const toggleEditMode = () => {
        setEditorMode(editorMode === 'edit' ? 'show' : 'edit');
    }

    // event handlers
    const handleSubmissionUpdated = (data: CapacityGridSubmissionColumnType) => {
        let updatedValues: CapacityGridSubmissionColumnType[] = rowItem?.columns.map(col => {
            if (col.capacityGridColumnId === data.capacityGridColumnId) {
                return data;
            }
            return col;
        }) ?? [];

        if (rowItem) {
            let rowData = { ...rowItem };
            rowData.columns = updatedValues;
            setRowItem(rowData)
            if (rowItem && props.OnUpdated) {
                props.OnUpdated(rowData)
            }
        }
        updateCalculatedCells();
    }

    const updateCalculatedCells = () => {
        
        let calculatedCells = rowItem.columns.filter(col => {
            return col.fieldType.id === CapacityGridFieldTypes.Calculated
        });
        if (calculatedCells) {
            calculatedCells.forEach(cell => {
                let columnData = capacityGrid.columns.find(col => { return col.id === cell.capacityGridColumnId });
                if (!columnData) {
                    return;
                }
                let formula = columnData?.calculatedFieldFormula;
                if (!formula) {
                    return;
                }
                let regexExtractFields = /\{(.*?)\}/g;
                let formulaFieldRefs = Array.from(formula.matchAll(regexExtractFields), (m) => m[1]);
                if (formulaFieldRefs) {
                    formulaFieldRefs.forEach(f => {
                        let refCell = capacityGrid.columns.find(col => { return col.name === f });
                        let val = rowItem.columns.find(c => c.capacityGridColumnId === refCell?.id)?.value;
                        
                        if (!val || val === '') {
                            val = '0';
                        }
                        formula = formula?.replace(`{${f}}`, val ?? '')
                    });
                    // eslint-disable-next-line no-eval
                    let result = eval(formula)
                    if(result.toString().indexOf('NaN')>-1 || 
                    result.toString().indexOf('Infinity')>-1)
                        result = 0
                    cell.value = result + '';
                }
            });
        }

    }

    // event handlers
    const handleRowError = (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => {
        if (props.OnError) {
            props.OnError(errorMessage, rowData);
        }
    }

    const handleRowSaveClick = async () => {
        setLoading(true);
        if (rowItem) {
            const response = await saveCapacityGridSubmission(rowItem)
            setLoading(false);
            if (response && response.success && response.result) {
                setRowItem({...rowItem, createdOn:response.result.createdOn,
                    createdBy:response.result.createdBy
                })
                toggleEditMode();
                return;
            }
            handleRowError(response.error ?? "Unknown error", rowItem);
        }
    }

    return <><tr key={`cap-grid-edit-division-${division.id}`}>
        <td style={{position:'sticky',left:0}} className="service-name"><div>{division.name}</div></td>

        {capacityGrid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col, index) => {
            let submissionData = rowItem.columns.find(data => data.capacityGridColumnId === col.id);
            let colValue = submissionData?.value ?? '\u00A0';

            if (col.fieldType.id === CapacityGridFieldTypes.Text && (submissionData?.columnOptionId ?? 0) > 0) {
                // for some reason a .find() wont work here so loop over all drop down options to get correct drop down value
                for (var i = 0; i < col.textFieldOptions.length; i++) {
                    const dropdownOption = col.textFieldOptions[i];
                    if (dropdownOption.id + '' === submissionData!.columnOptionId + '') {
                        colValue = dropdownOption.value;
                        break;
                    }
                }
            }

            if (editorMode === 'edit') {
                return <td key={`${col.id}_${division.id}_{${index}_edit`} hidden={loading} onMouseOver={(e) => { setToolTipText(e, `${col.name}${(colValue ? (`: ${colValue}`) : "")}`) }}>
                    <CapacityGridSubmissionEditorField onUpdated={handleSubmissionUpdated} ColumnData={col} SubmissionData={submissionData!}></CapacityGridSubmissionEditorField>
                    <div hidden={true} className="edit-help-text">
                        {col.description}
                    </div>
                </td>
            }

            return <td key={`${col.id}_${division.id}_{${index}_edit`} onMouseOver={(e) => { setToolTipText(e, `${col.name}: ${colValue}`) }}>
                <DebugVariable hide={true} variable={colValue} title="Row item"></DebugVariable>
                <CapacityGridResultRangeDisplay rowConfig={col.numericFieldOptions} cellValue={colValue}></CapacityGridResultRangeDisplay>
            </td>

        })}
        <td onMouseOver={(e) => { setToolTipText(e, `Submitted on ${rowItem.createdOn !== undefined ? formatDateTime(rowItem.createdOn) : ''} by ${rowItem?.createdBy?.fullName}`) }}><span>{rowItem.createdOn !== undefined ? formatDateTime(rowItem.createdOn) : ''}</span>
        </td>
        <td className="edit-button" hidden={loading}>
            {editorMode === 'edit' && !editRowModeEnabled && <><ActionButton size="small" label="Save" severity="success" onClick={handleRowSaveClick}>&nbsp;</ActionButton><ActionButton size="small" label="Cancel" severity="secondary" onClick={toggleEditMode}></ActionButton></>}
            {editorMode !== 'edit' && !editRowModeEnabled && <ActionButton size="small" label="Edit" severity="success" onClick={toggleEditMode}></ActionButton>}
        </td>
        <td colSpan={200} hidden={loading === false}>
            <Loader loading={loading} useBackdrop={false} />
        </td>
    </tr>

    </>
}