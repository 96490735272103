import Page from "../../../components/core/page";
import { useState, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { FeedbackType } from "../../../components/core/feedback";
import ServiceComplianceReport from "../../../components/reports/service-compliance-report";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { fetchFormCompliance } from "../../../services/reporting/form-service";
import { FormComplianceReportType } from "../../../interfaces/reporting/form-submission";
import { CapacityGridDailyReport } from "../../../components/reports/capacity-grid/capacity-grid-daily-report";
import { CapacityGridHistoricReport } from "../../../components/reports/capacity-grid/capacity-grid-historic-report";
import { useParams } from "react-router-dom";
import { CapacityGridSummaryReport } from "../../../components/reports/capacity-grid/capacity-grid-summary-report";
import { fetchCapacityGridSummaryReport } from "../../../services/reporting/capacity-grid-service";
import { CapacityGridSummaryReportFilterOptions, CapacityGridSummaryReportResultType } from "../../../interfaces/system/capacityGridType";



interface CapacityGridReportContainerProps {
    mode: 'compliance' | 'daily' | 'historic' | 'summary'
}
export default function CapacityGridReportContainer(props: CapacityGridReportContainerProps) {

    const [title, setTitle] = useState('Capacity Service Compliance Report')
    const [complianceReportData, setComplianceReportData] = useState<FormComplianceReportType[]>()
    const [summaryReportData, setSummaryReportData] = useState<CapacityGridSummaryReportResultType>()
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType())
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage
    }
        = useForm({ initialObjectValues: {} })
    let { serviceId } = useParams() || 0;
    let svcId = parseInt(serviceId ?? '0');

    useEffect(() => {
        present()
    }, [])
    useEffect(() => {
        presentMapper[props.mode]()
    }, [props.mode])


    const present = async () => {
        setFeedbackStatus(FeedbackType.Loading)

        const a = await fetchAllAreaHierarchyWithServices()
        if (a.success && a.result)
            setAreaHierarchy(a.result)
        else {
            setErrorMessage(a.error as string)
        }

        presentMapper[props.mode]()
        setFeedbackStatus(FeedbackType.hide)
    }
    const presentCompliance = async (serviceIds: number[]) => {
        setTitle('Capacity Service Compliance Report')
        if (!serviceIds || serviceIds.length < 1) {
            return
        }

        setFeedbackStatus(FeedbackType.Loading)
        
        const response = await fetchFormCompliance(serviceIds, 20);
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setComplianceReportData(response.result ?? [])
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }

    const presentDailyReport = () => {
        setTitle('Daily Capacity Grid Report')
    }

    const presentHistoricReport = () => {
        setTitle('Capacity Grid Historic Report')
    }

    const presentSummaryReport = async (options?: CapacityGridSummaryReportFilterOptions) => {
        setTitle('Capacity Grid Summary Report');
        if (!options || !options.serviceIds || (options?.serviceIds?.length ?? 0) < 1) {
            return;
        }
        
        if (!options.month || !options.year) {
            setErrorMessage("Please select a date range.")
            setFeedbackStatus(FeedbackType.Error)
            return;
        }

        setFeedbackStatus(FeedbackType.Loading)
        
        const response = await fetchCapacityGridSummaryReport(options);
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success && response.result) {
            setSummaryReportData(response.result)

            return;
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }

    const presentMapper: { [K: string]: Function } = {
        'compliance': presentCompliance,
        'daily': presentDailyReport,
        'historic': presentHistoricReport,
        'summary': presentSummaryReport
    };

    const handleLoading = () => {
        setFeedbackStatus(FeedbackType.Loading);
    }
    const handleLoaded = () => {
        setFeedbackStatus(FeedbackType.hide);
    }
    const handleError = (message: string) => {
        setErrorMessage(message);
        setFeedbackStatus(FeedbackType.Error);
    }

    return <Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>

        {props.mode === 'compliance' &&
            <ServiceComplianceReport areaHierarchy={areaHierarchy}
                data={complianceReportData} onFetchData={presentCompliance}
                criteria={{ showOnlyServicesWithGridAssociated: true }}
                mode='grid' />
        }

        {props.mode === 'historic' &&
            <CapacityGridHistoricReport  areaHierarchy={areaHierarchy}  parentServiceId={svcId} onLoading={handleLoading} onLoaded={handleLoaded} onError={handleError} />
        }

        {props.mode === 'daily' &&
            <CapacityGridDailyReport areaHierarchy={areaHierarchy} onLoading={handleLoading} onLoaded={handleLoaded} onError={handleError} />
        }

        {props.mode === 'summary' &&
            <CapacityGridSummaryReport data={summaryReportData} areaHierarchy={areaHierarchy} criteria={{ showOnlyServicesWithGridAssociated: true, showBedTypeList: true, showMonth:true, showYear:true, showCapGridSummaryReportCheck: true }} onFetchData={presentSummaryReport} />
        }
    </Page>

}