import { Fieldset as FS } from 'primereact/fieldset';

interface FieldSetProps {
    toggleable?: boolean
    title: string;
    children?: React.ReactNode
  }

export default function FieldSet(props:FieldSetProps) {
    return <FS legend={props.title} toggleable={props.toggleable}>
        {props.children}
    </FS>
}