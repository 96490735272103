import { Grid } from "@mui/material";
import React from "react";
import { AmbulanceMetric, EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";
import Panel from "../../core/panel";
import DemandAndCapacityGauge from "./demand-and-capacity-gauge";

export interface DemandAndCapacityOverviewProps{
    emergencyMetric?:EmergencyMetric
    ambulanceMetric?:AmbulanceMetric
    onNavigateToEmergencyDashboard: (siteCode:string) => void
    onNavigateToAmbulanceDashboard: (siteCode:string) => void
}
export default function DemandAndCapacityOverview(props: DemandAndCapacityOverviewProps) {

    const  [emergencyMetric, setEmergencyMetric] = React.useState<EmergencyMetric>();
    const  [ambulanceMetric, setAmbulanceMetric] = React.useState<AmbulanceMetric>();

    React.useEffect(() => {

        setTimeout(() => {
            setEmergencyMetric(props.emergencyMetric)
        }, (99));
        
      }
      ,[props.emergencyMetric])
      React.useEffect(() => {
        setTimeout(() => {
            setAmbulanceMetric(props.ambulanceMetric)    
        }, (99));
        
      }
      ,[props.ambulanceMetric])


      if(!emergencyMetric && !ambulanceMetric)
        return <>The selected provider does not support Live data</>
     
    const turnaroundMins = ambulanceMetric? Math.ceil(ambulanceMetric.meanTurnAroundTimeSeconds / 60) : 0
    


    return <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} md={6}>
                    {ambulanceMetric &&
                    <Panel title="Ambulance Conveyances" >
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={9} md={6}>
                                <DemandAndCapacityGauge id={`amb${ambulanceMetric.siteCode}`} title="Ambulance Conveyances" MaxValue={ambulanceMetric.totalConveyances<28?28:ambulanceMetric.totalConveyances+2} minValue={0} 
                                ranges={[{min:0,max:10},{min:11,max:19},{min:20,max:ambulanceMetric.totalConveyances<28?28:ambulanceMetric.totalConveyances+2}]}
                                value={ambulanceMetric.totalConveyances}
                                help="Current Active Conveyances"
                                onClick={()=>props.onNavigateToAmbulanceDashboard(ambulanceMetric.siteCode)}
                                />
                            </Grid>
                            <Grid item xs={9} md={6}>
                                <DemandAndCapacityGauge id={`amb_1${ambulanceMetric.siteCode}`} title="Mean Turnaround Time" MaxValue={turnaroundMins<28?28:turnaroundMins+2} minValue={0} 
                                ranges={[{min:0,max:14},{min:15,max:23},{min:24,max:turnaroundMins<28?28:turnaroundMins+2}]}
                                value={turnaroundMins}
                                format="time"
                                help="Today's Average Ambulance Turnaround Time"
                                onClick={()=>props.onNavigateToAmbulanceDashboard(ambulanceMetric.siteCode)}
                                />
                            </Grid>
                        </Grid>
                    </Panel>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {emergencyMetric &&
                    <Panel title="Emergency Department" >
                        <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                                <DemandAndCapacityGauge id={`emerg${emergencyMetric.siteCode}`} title="Patients Waiting" MaxValue={60} minValue={0} 
                                    steps={[15,10,75]}
                                    value={emergencyMetric.patientsWaiting}
                                    onClick={()=>props.onNavigateToEmergencyDashboard(emergencyMetric.siteCode)}
                                    help="No. Patients currently waiting in emergency department"
                                    />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DemandAndCapacityGauge id={`emerg2${emergencyMetric.siteCode}`} title="4+ Hour Waits" MaxValue={20} minValue={0} 
                                    ranges={[{min:0,max:2},{min:3,max:5},{min:5,max:20}]}
                                    value={emergencyMetric.patientsWaitingGt4Hours}
                                    onClick={()=>props.onNavigateToEmergencyDashboard(emergencyMetric.siteCode)}
                                    help="Number of patients waiting 4 hours or more"
                                    />
                            </Grid>
                        </Grid>
                    </Panel>
                    }
                </Grid>
                
            </Grid>
}