import React, {useEffect,useState} from "react"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import OKCancelButtons from "../../core/OKCancelButtons";
import Loader from "../../core/loading";
import { Tree } from 'primereact/tree';
import TickBox from "../../core/tickBox";
import { AreaType, createNewAreaHierarchyType, createNewAreaType } from "../../../interfaces/system/areaTypes";
import DomainEntityValidator from "../../../validators/domainEntityValidator";
import Panel from "../../core/panel";
import ActionButton from "../../core/actionButton";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";

interface AreaEditProps {
    areas?:AreaType[]
    onSave: (areaHierarchy: AreaHierarchyType[], areas:AreaType[],valid:boolean) => void 
    onCancel: () => void 
  }

  interface AreaNodeType {
    key:string
    children:AreaNodeType[]
    label:string
    parentKey?:string,
    parent?:AreaNodeType,
    expanded?:boolean
    className?:string
    deleted?:boolean
  }

const AreaEdit:  React.FC<AreaEditProps> = (props) => {
  const [validator] = useState(new DomainEntityValidator())
  const [areas, setAreas] = useState<AreaType[]>()
  const [treeNodes, setTreeNodes] = useState<AreaNodeType[]>([])
  const [selectedArea, setSelectedArea] = useState<AreaType>()
  const [selectedNode, setSelectedNode] = useState<AreaNodeType>()
  
//   const {
//     objectValues, setValues
//   }
//    = useForm({initialObjectValues: selectedArea, validator: validator})

   useEffect(() => {

        window.addEventListener('popstate', function() {
            props.onCancel()
        }, false);
   },[])

useEffect(() => {
        presentNodes()
},[props.areas])
// useEffect(() => {
//     handleAreaUpdated()
// },[objectValues])

    const presentNodes = ()=>{
        if(!props.areas) return
        const nodes:AreaNodeType[]=[]
        props.areas.forEach(f=>{
            nodes.push({key:f.id.toString(), label:f.name, children:[], parentKey:f.parentId?.toString(),deleted:f.deleted})
        })

        //now sort hierarchy
        const nodeHierarchy:AreaNodeType[]=[]
        nodes.forEach(n=>{

            n.className= !n.label|| n.label==='' && !n.deleted ?'error-box': n.deleted ? 'highlight-backgroud' : ''


            if(n.parentKey){
                const parent = nodes.find(f=> f.key === n.parentKey)
                if(parent){
                    parent.children.push(n)
                }
            }
            else{
                n.expanded=true
                nodeHierarchy.push(n)
            }
        })


        setTreeNodes(nodeHierarchy)
        setAreas(props.areas)
    }
   
const handleSave = async () =>{
  let isValid =  areas?.findIndex(f=> !f.name || f.name === '')===-1
    
  const areaHierarchy=convertNodesToAreaHierachy()
    props.onSave(areaHierarchy||[],areas||[],isValid)

}  
const convertNodesToAreaHierachy = () => {
    const areaHierarchy:AreaHierarchyType[]=[]

    if(!treeNodes) return

    treeNodes.forEach(f=>{
        const newArea = populateChildAreasFromHierarchy(f)
        areaHierarchy.push(newArea)
    })
    return areaHierarchy
}
    const populateChildAreasFromHierarchy =  (node:AreaNodeType):AreaHierarchyType =>{
        
            const newArea = createNewAreaHierarchyType()
            const editedArea = areas?.find(ff=> ff.id.toString() === node.key)

            if(editedArea){
                newArea.name = editedArea.name;newArea.deleted = editedArea.deleted;
                newArea.parentId = editedArea.parentId && editedArea.parentId < 0 ? undefined  :editedArea.parentId ;newArea.id = editedArea.id < 0 ? 0 :editedArea.id
            }
            if(node.children){
                const children :AreaHierarchyType[]  = []
                newArea.children = children
                node.children.forEach(f=>{
                    const newArea = populateChildAreasFromHierarchy(f)
                    children.push(newArea)
                })          
            }

            return newArea
    }


      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
   
 
  const onNodeSelect = (node:any ) => {
    if(areas){
        setSelectedArea(areas.find(f=> f.id === parseInt(node.node.key)))
    }
    setSelectedNode(node.node)
}
const handleAddArea = (isChild?:boolean) =>{
    
    const a = areas||[]

    const newArea = createNewAreaType()
    newArea.name = 'new'
    newArea.id = -Date.now()

    const newNode:AreaNodeType = {key:newArea.id.toString(), label:'new', children:[]}

    if(isChild && selectedArea && selectedNode){
        newArea.parentId = selectedArea.id
        newNode.parentKey = selectedNode.key
        selectedNode?.children.push(newNode)
        selectedNode.expanded=true
    }
    
    const tn = treeNodes
    if(!isChild)
        tn.push(newNode)

    a.push(newArea)
    
    setSelectedNode(newNode)
    setSelectedArea(newArea)

    setAreas([...a])
    setTreeNodes([...tn])
    
}

const handleAddChildArea = () =>{
    if(!selectedArea) return
    handleAddArea(true)
}
const handleRemoveArea = () =>{
    if(!selectedArea) return
    const a = areas||[]
    
    const areaIndex = a.findIndex(f=>f.id === selectedArea.id)
    a.splice(areaIndex,1)

    setAreas([...a])
    setSelectedArea(undefined)
    presentNodes()

}
// const handleAreaUpdated = () =>{
//     if(!selectedArea || !objectValues || !selectedNode ) return
//     selectedArea.name = objectValues.name
//     selectedArea.deleted = objectValues.deleted
//     selectedNode.deleted = objectValues.deleted
//     selectedNode.label = objectValues.name
//     selectedNode.className=!objectValues.name|| objectValues.name===''?'error-box':selectedNode.deleted ? 'highlight-backgroud' : ''
//     setTreeNodes([...treeNodes])
//     setAreas([...areas||[]])
// }
const updateAreaName = (e:any)=>{
        if(!selectedArea   || !selectedNode ) return
        selectedArea.name = e.target.value
        selectedNode.label =selectedArea.name
        selectedNode.className=!selectedArea.name|| selectedArea.name===''?'error-box':selectedNode.deleted ? 'highlight-backgroud' : ''
        setSelectedArea(selectedArea)
        setTreeNodes([...treeNodes])
        setAreas([...areas||[]])
}
const updateAreaDeleted = (e:any)=>{
    if(!selectedArea   || !selectedNode ) return
    selectedArea.deleted = e.target.checked
    selectedNode.className=!selectedArea.name|| selectedArea.name===''?'error-box':selectedNode.deleted ? 'highlight-backgroud' : ''
    setSelectedArea(selectedArea)
    setTreeNodes([...treeNodes])
    setAreas([...areas||[]])
}
const handleDragDrop = (event:any) =>{
    const nodes = event.value as AreaNodeType[]
    const dragNodeKey = (event.dragNode.key as number)
    const dropNodeKey = (event.dropNode.key as number)

    const dragArea = areas?.find(f=> f.id == dragNodeKey)
    if(dragArea){
        dragArea.parentId = dropNodeKey
    }

    setTreeNodes(nodes)
}
if(!areas)
        return <Loader useBackdrop={false} loading={true} />
  return <>
        <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
            <Grid item xs={6} style={{marginTop:-30}}>
                <Panel title="Areas" scrollHeight={'55%'}>
                    <Tree value={treeNodes} selectionMode="single"  dragdropScope="regions" selectionKeys={selectedArea?.id.toString()} onSelect={onNodeSelect} onDragDrop={handleDragDrop} />
                </Panel>
                <Grid container spacing={0} >
                                <Grid item xs={6}><ActionButton icon="pi pi-plus-circle" severity="info" label="Add Area" onClick={handleAddArea}/></Grid>
                                <Grid item xs={6}><OKCancelButtons onOK={handleSave} onCancel={handleCancel} showCancel={false}/></Grid>
                </Grid>
            </Grid>
                <Grid item xs={4}>
                    <Grid item xs={12} style={{marginTop:-30}}>
                        <Panel title="Area Details" >
                        <Grid container spacing={0} >
                            <Grid item xs={2}><TickBox id="deleted"  label="Deleted" checked={selectedArea?.deleted} onChange={(e) => {updateAreaDeleted(e)}}/></Grid>
                            <Grid item xs={12}><TextBox id="name"  label="Name" validationField={validator.name} value={selectedArea?.name || ''} onChange={(e) => {updateAreaName(e)}} /></Grid>
                            <Grid item xs={12}>&nbsp;</Grid>
                            <Grid item xs={9}><ActionButton icon="pi pi-plus-circle" severity="info" label="Add Child Area" onClick={handleAddChildArea}/></Grid>
                            <Grid item xs={3}>{(selectedArea && selectedArea.id <0) && <ActionButton variant="icon" icon="pi pi-times" severity="danger" onClick={handleRemoveArea}/>}</Grid>
                            </Grid> 
                        </Panel>
                    </Grid>            
                    <Grid item xs={12}>
                    
                </Grid>     
                </Grid>
                
        </Grid>     
  </>

}

export default AreaEdit;