import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { DomainEntityType } from '../interfaces/domain/iDomainEntity';
import {ValidatorType} from './ValidatorType'

export class DomainEntityValidator implements ValidatorType
{
   formValidator = new FormValidation([])
    name= new FormValidationField('','')

     initialiseValidationFields = (objectValues: DomainEntityType) => {
        this.formValidator = new FormValidation([])
        this.name= this.formValidator.createTextField(objectValues.name)


    }
    
 validate =  (validatorObjectValues: DomainEntityType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid 
 }
}

export default DomainEntityValidator

