import { useEffect, useState } from "react";
import { EmailAuditType } from "../../../interfaces/system/emailAuditTypes";
import { Grid } from "@mui/material";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../../reports/basic-report-filter";
import { formatDateTimeWithDay } from "../../../extensions/formatter";
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import DataTable from "../../core/dataTable";
import SimpleDialog from "../../core/simpleDialog";


export interface EmailAuditCriteriaProps {
    recipient?: string,
    subjectLine?: string,
    fromDate: Date
    toDate: Date
}

export interface EmailAuditListProps {
    list?: EmailAuditType[],
    onSelection?: (id: number) => void,
    onApplyFilter: (criteria: EmailAuditCriteriaProps) => void
    criteria?: BasicReportFilterCriteria
    defaultFilterCriteria?: BasicReportCriteria
    selectedItem?: EmailAuditType
}

export function EmailAuditList(props: EmailAuditListProps) {
    const [data, setData] = useState(props.list);
    const [selectedItem, setSelectedItem] = useState<EmailAuditType | undefined>()

    useEffect(() => {
        setData(props.list)
    }, [props.list]);

    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem]);

    useEffect(() => {
        //setSelectedItem(props.selectedItem)
    }, [selectedItem]);


    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        props.onApplyFilter({
            fromDate: criteria.fromDate ?? new Date(),
            toDate: criteria.toDate ?? new Date(),
            recipient: criteria.recipentValue,
            subjectLine: criteria.subjectLineValue,
        })
    }


    const handleExport = () => {

        const title = 'Email Audit Report'

        const exportData: ExcelExportDataRow[] = []
        const sheets: ExcelExportSheet[] = []

        sheets.push({ title: title, elements: [{}], name: 'Email Audit Report' })

        const headers: ExcelExportHeader[] = [
            { title: "Id", width: 10 },
            { title: "Sent", width: 20 },
            { title: "Recipient" },
            { title: "Subject" },
        ]

        data?.forEach(d => {
            exportData.push({
                cols: [
                    { value: d.id + '' },
                    { value: formatDateTimeWithDay(d.dateSent) },
                    { value: d.recipient },
                    { value: d.subjectLine }
                ]
            })
        })

        exportToExcel({
            defaultHeaderWidth: 20,
            headers: headers,
            data: exportData,
            sheets: sheets
        })
    }

    const handleRowSelection = (id: number) => {
        if (props.onSelection) {
            props.onSelection(id)
        }
    }

    return <>

        <Grid item xs={12}>
            <BasicReportFilter onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}
                onExport={handleExport}

                hideServiceControl={true} />
        </Grid>
        <p>&nbsp;</p>
        <Grid item xs={12} >
            <DataTable dataKey="id" loading={false} columns={[{ header: "Id", field: "id" },
            {
                header: "Sent", field: "dateSent", formatType: 'datetime'
            },
            { header: "Email", field: "recipient",  formatCallBack: (rowData) => {const splits = rowData.recipient.split(','); 
                    return splits.length<2 ? rowData.recipient:`${splits[0]} + ${splits.length-1} others`; }},
                { header: "Subject", field: "subjectLine" }]} 
                paginationRowsPerPage={25} value={data} onSelect={(row) => handleRowSelection(row.value.id)} onEdit={(row) => handleRowSelection(row['id'])}
                editButton={true} paginator={(data?.length ?? 0) > 25} filter={false} allowExport={false} editButtonIcon="pi pi-search"
            />
        </Grid>

        {selectedItem !== undefined && <>

            <SimpleDialog show={true} fullScreen={false} title="More" onClose={() => { setSelectedItem(undefined); }}>

                <table className="condensed">
                    <tr>
                        <th className="text-left" style={{width:'100px'}}>Id</th>
                        <td>{selectedItem.id }</td>
                    </tr>
                    <tr>
                        <th className="text-left">Recipient</th>
                        <td  style={{maxWidth:100,clip:'auto'}}>{selectedItem.recipient }</td>
                    </tr>
                    <tr>
                        <th className="text-left">Sent</th>
                        <td>{formatDateTimeWithDay(selectedItem.dateSent) }</td>
                    </tr>
                    <tr>
                        <th className="text-left">Subject</th>
                        <td>{selectedItem.subjectLine }</td>
                    </tr>
                    <tr>
                        <th className="text-left" style={{verticalAlign:'top'}}>Content</th>
                        <td><div
                            dangerouslySetInnerHTML={{
                                __html: selectedItem.emailContent
                            }}
                        /></td>
                    </tr>
                </table>
            </SimpleDialog>
        </>
        }

    </>

}