import { CapacityGridColumnType, CapacityGridKeyFieldSummaryType, CapacityGridSearchResultType, CapacityGridSubmissionDataType, CapacityGridSubmissionOverviewType, CapacityGridType, NecsExcludedServiceType } from "../interfaces/system/capacityGridType";
import { HttpResponse, HttpGet, HttpPost } from "./http";
import { getLocalSessionItem, setSessionStorageItem } from "./storageService";

export const fetchCapacityGridList = async (): Promise<HttpResponse<CapacityGridSearchResultType[]>> => {

    const response = await HttpGet<CapacityGridSearchResultType[]>(
        `capacitygrid/list`,
    )

    return response
}
export const fetchNecsExcludedServiceList = async (): Promise<HttpResponse<NecsExcludedServiceType[]>> => {

    const response = await HttpGet<NecsExcludedServiceType[]>(
        `service/necs-excluded-service-list`,
    )

    return response
}
// loads all capacity grid submission definitions for current user
export const fetchCapacityGridSubmissionList = async (gridIds?:number[]): Promise<HttpResponse<CapacityGridType[]>> => {
    //const cacheKey = 'capacity-grid-submission-list';

    const response = await HttpPost<CapacityGridType[]>(
        `capacitygrid/submission`, { gridIds: gridIds ?? [] }
    )

    return response
}

export const fetchServiceData = async (serviceId: number): Promise<HttpResponse<CapacityGridSubmissionOverviewType[]>> => {

    const response = await HttpGet<CapacityGridSubmissionOverviewType[]>(
        `capacitygrid/submissiondata?serviceId=${serviceId}`,
    )

    return response
}
export const fetchServiceDataByAreaId = async (areaId: number): Promise<HttpResponse<CapacityGridSubmissionOverviewType[]>> => {

    const response = await HttpGet<CapacityGridSubmissionOverviewType[]>(
        `capacitygrid/submissiondata?areaId=${areaId}`,
    )

    return response
}

export const fetchCapacityGrid = async (id: number, excludeDeleted: boolean): Promise<HttpResponse<CapacityGridType>> => {
    const response = await HttpGet<CapacityGridType>(
        `capacitygrid/${id}?excludeDeleted=${excludeDeleted}`
    )

    return response
}
export const fetchNecsExcludedService = async (id: string): Promise<HttpResponse<NecsExcludedServiceType>> => {
    const response = await HttpGet<NecsExcludedServiceType>(
        `service/necs-excluded-service/${id}`
    )

    return response
}

export const fetchCapacityGridKeyFieldSummaryForServiceId = async (id: number): Promise<HttpResponse<CapacityGridKeyFieldSummaryType[]>> => {
    const response = await HttpGet<CapacityGridKeyFieldSummaryType[]>(
        `capacitygrid/submission-data-key-field-summary?serviceId=${id}`
    )

    return response
}

export const fetchCapacityGridSharedColumns = async (excludeDeleted: boolean = true, useCache: boolean = true): Promise<HttpResponse<CapacityGridColumnType[]>> => {
    const cacheKey = `cap-grid-shared-cols`

    if (useCache) {
        const cachedValue = getLocalSessionItem(cacheKey) as CapacityGridColumnType[]
        if (cachedValue) return { success: true, result: cachedValue }
    }

    const response = await HttpGet<CapacityGridColumnType[]>(
        `capacitygrid/sharedcolumns?excludeDeleted=${excludeDeleted}`
    )

    if (response.success) {
        setSessionStorageItem(cacheKey, response.result)
    }

    return response
}

export const saveCapacityGrid = async (grid: CapacityGridType): Promise<HttpResponse<CapacityGridType>> => {
    const postData = { ...grid }
    postData.validator = undefined;

    if (postData.columns) {
        postData.columns.forEach(f => {
            f.id = f.id < 0 ? 0 : f.id
            f.textFieldOptions.forEach(o => {
                o.id = o.id < 0 ? 0 : o.id
            });
            f.textFieldOptions.forEach(o => {
                o.id = o.id < 0 ? 0 : o.id
            });
        })
    }

    const response = await HttpPost<CapacityGridType>(
        `capacitygrid`, postData
    )

    return response
}
export const saveCapacityGridColumn = async (column: CapacityGridColumnType): Promise<HttpResponse<CapacityGridColumnType>> => {
    const postData = { ...column }


    const response = await HttpPost<CapacityGridColumnType>(
        `capacitygrid/savecolumn`, postData
    )

    return response
}
export const saveNecsExcludedService = async (service: NecsExcludedServiceType): Promise<HttpResponse<NecsExcludedServiceType>> => {


    const response = await HttpPost<NecsExcludedServiceType>(
        `service/necs-excluded-service`, service
    )

    return response
}
export const saveCapacityGridSubmission = async (data: CapacityGridSubmissionDataType): Promise<HttpResponse<CapacityGridSubmissionDataType>> => {
    const postData = { ...data }

    if (postData.columns) {
        let keyFieldDataRetrieved = false;
        postData.columns.forEach(col => {
            col.id = col.id < 0 ? 0 : col.id
            if (!keyFieldDataRetrieved && col.isKeyField) {
                postData.keyFieldValue = (col.columnOptionId && col.columnOptionId > 0) ? (col.columnOptionId + '') : (col.value ?? '');
                keyFieldDataRetrieved = true;
            }
        });
    }

    const response = await HttpPost<CapacityGridSubmissionDataType>(
        `capacitygrid/submit`, postData
    )

    return response
}