import { FormValidation, FormValidationField } from "../extensions/formValidation";
import { FormResultOptionType, FormResultType } from "../interfaces/system/formType";
import { ValidatorType } from "./ValidatorType";

export class FormResultValidator implements ValidatorType{
    validator = new FormValidation([])
    name = new FormValidationField('', '')
    resultType = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: FormResultType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
        this.resultType = this.validator.createDomainTypeField(objectValues.formResultTypeId)
    }

    validate = (validatorObjectValues: FormResultType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
export class FormResultOptionValidator implements ValidatorType{
    validator = new FormValidation([])
    name = new FormValidationField('', '')
    shortName = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: FormResultOptionType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
        this.shortName = this.validator.createTextField(objectValues.shortName)
    }

    validate = (validatorObjectValues: FormResultOptionType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}