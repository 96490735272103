import React, {useEffect} from "react"
import DataTable from '../../core/dataTable'
import { FormSearchResultType } from "../../../interfaces/system/formType"


interface FormListProps {
    list?:FormSearchResultType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

const FormList:  React.FC<FormListProps> = (params) => { 
useEffect(() => {
  setList(params.list)
},[params.list])

const [list,setList] = React.useState(params.list)
   

  const handleRowSelection =(id:number) => {
    
    if(params.onSelection)
        params.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false} columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Type", field:"formType.name" },
  {header:"Active", field:"deleted", formatType:"yesno-inverse"},
  {header:"Display Order", field:"sortOrder"}]}  paginationRowsPerPage={25} value={list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true} filter={true} onNew={params.onNew} allowExport={true}
  />

}

export default FormList;