import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import Panel from "../../components/core/panel";
import { fetchCapacityGrid, fetchCapacityGridList, fetchNecsExcludedService, fetchNecsExcludedServiceList, saveCapacityGrid, saveNecsExcludedService } from "../../services/capacity-grid-service";
import { CapacityGridSearchResultType, CapacityGridType, createEmptyCapacityGrid, createEmptyNecsExcludedServiceType, NecsExcludedServiceType } from "../../interfaces/system/capacityGridType";
import CapacityGridList from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-list";
import CapacityGridEdit from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-editor";
import useForm from "../../hooks/useForm";
import { Grid } from "@mui/material";
import React from "react";
import { AppToastContext } from "../../extensions/context/appHook";
import { updatePageTitle } from "../../services/utils";
import { clearDomainEntities } from "../../services/domainEntitiesService";
import NecsExcludedServiceList from "../../components/admin/capacity-grids/necs-excluded-service/necs-excluded-service-list";
import NecsExcludedServiceEdit from "../../components/admin/capacity-grids/necs-excluded-service/necs-excluded-service-edit";
import NecsExcludedServiceValidator from "../../validators/necs-excluded-service-validator";

interface CapacityGridBuilderProps {
    mode?: 'list' | 'capacity-grid-builder'|'necs-service-list'|'necs-service-edit'
}
export default function CapacityGridBuilderContainer(props: CapacityGridBuilderProps) {

    // component vars
    const itemsPerPage: number = 25;
    const defaultTitle: string = 'Capacity Grid List';
    const toastHandler = React.useContext(AppToastContext)
    let { id, excludedServiceCqcId } = useParams();
    // state vars
    const [mode, setMode] = useState(props.mode)
    const [title, setTitle] = useState(defaultTitle)
    const [resultList, setResultList] = useState<CapacityGridSearchResultType[]>()
    const [selectedRecordId, setSelectedRecordId] = useState<number>(parseInt(id ?? '0'))
    const [selectedRecord, setSelectedRecord] = useState<CapacityGridType>()
    
    const [necsExcludedServiceList, setNecsExcludedServiceList] = useState<NecsExcludedServiceType[]>()
    const [selectedNecsExcludedServiceId, setSelectedNecsExcludedServiceId] = useState<string>(excludedServiceCqcId??'')
    const [selectedNecsExcludedService, setSelectedNecsExcludedService] = useState<NecsExcludedServiceType>()
    
    const [validator,setValidator] = useState<any>()

    const { feedbackStatus, setFeedbackStatus } = useForm({ initialObjectValues: {} })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const navigate = useNavigate();

    // hooks
    useEffect(() => {
        init()
    }, [mode])

    useEffect(() => {
        updatePageTitle(title)
    }, [title])

    useEffect(() => {
        if (selectedRecordId && selectedRecordId > 0) {
            presentEditForm()
        }

    }, [selectedRecordId])
    useEffect(() => {
        if (selectedNecsExcludedServiceId || selectedNecsExcludedServiceId ==='new') {
            presentEditNecsExcludedServiceEdit()
        }

    }, [selectedNecsExcludedServiceId])

    // presentation handlers
    const init = async () => {
        if (mode === 'list') presentList();
        
        if(mode=== 'necs-service-list') presentNecsList()
        
    }
    const presentNecsList = async () => {

        setFeedbackStatus(FeedbackType.Loading)
        setTitle('NECS Excluded Service List')
        const response = await fetchNecsExcludedServiceList();

        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setNecsExcludedServiceList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentList = async () => {

        setFeedbackStatus(FeedbackType.Loading)
        setTitle(defaultTitle)
        const response = await fetchCapacityGridList();

        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setResultList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentEditForm = async () => {
        
        setFeedbackStatus(FeedbackType.Loading)
        setMode('capacity-grid-builder')

        if (selectedRecordId <= 0) {
            setTitle('Create New Capacity Grid')
            setSelectedRecord(createEmptyCapacityGrid())
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        const response = await fetchCapacityGrid(selectedRecordId, false);
        if (response && response.success && response.result) {
            setTitle(`Edit Capacity Grid - ${response.result.name}`)
            setSelectedRecord(response.result)
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        toastHandler.errorMessage(response.error)
        setMode('list')
    }
    const presentEditNecsExcludedServiceEdit = async () => {
        
        setFeedbackStatus(FeedbackType.Loading)
        setMode('necs-service-edit')

        setValidator(new NecsExcludedServiceValidator())
        
        if (selectedNecsExcludedServiceId ==='new') {
            
            setTitle('Create New Necs Excluded Service')
            setSelectedNecsExcludedService(createEmptyNecsExcludedServiceType())
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        const response = await fetchNecsExcludedService(selectedNecsExcludedServiceId);
        if (response && response.success && response.result) {
            setTitle(`Edit Necs Excluded Service - ${response.result.cqcId}`)
            setSelectedNecsExcludedService(response.result)
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        toastHandler.errorMessage(response.error)
        setMode('necs-service-list')
    }

    // event handlers
    const handleNewRecord = () => {
        setSelectedRecordId(0);
        presentEditForm();
    }
    const handleNewNecsExcludedService = () => {
        setSelectedNecsExcludedServiceId('new');
    }
    const handleRecordSelected = async (id: number) => {
        setSelectedRecordId(id)
        navigate(`/admin/capacity-grid/${id}`);
    }

    const handleEditFormSave = async (grid: CapacityGridType, valid: boolean) => {
        setSelectedRecord({ ...grid })
        setErrorMessage('')
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveCapacityGrid(grid)
        if (saveResponse.success) {
            setFeedbackStatus(FeedbackType.hide)
            toastHandler.successMessage(`Successfuly saved form - '${grid.name}'`);
            // reset form so updated values are restored
            clearDomainEntities();
            handleCancelEditRecord();
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleSaveNecsExcludedService = async (service: NecsExcludedServiceType, validator: any) => {
        setSelectedNecsExcludedService({ ...service })
        setErrorMessage('')
        const valid = (validator as NecsExcludedServiceValidator).validate((service as any))
        
        setValidator(validator)
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveNecsExcludedService(service)
        if (saveResponse.success) {
            setFeedbackStatus(FeedbackType.hide)
            toastHandler.successMessage(`Successfuly saved Necs Excuded Service - '${service.cqcId}'`);
            // reset form so updated values are restored
            clearDomainEntities();
            handleCancelNecsExcludedService();
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleCancelEditRecord = async () => {
        setSelectedRecordId(0);
        setSelectedRecord(undefined);
        setMode('list');
        navigate(`/admin/capacity-grid`);
    }
    const handleCancelNecsExcludedService = async () => {
        setSelectedNecsExcludedServiceId('');
        setSelectedNecsExcludedService(undefined);
        setMode('necs-service-list');
    }

    const handleNecsExcludedServiceSelected = async (id: string) => {
        setSelectedNecsExcludedServiceId(id)
        navigate(`/admin/necs-excluded-service/${id}`);
    }
    // dynamic components
    const CapacityGridListElem = () => {
        if (mode !== 'list') return <></>
        return <CapacityGridList onNew={handleNewRecord} onSelection={handleRecordSelected} itemsPerPage={itemsPerPage} resultList={resultList}></CapacityGridList>
    }
    const CapacityGridEditElem = () => {
        if (mode !== 'capacity-grid-builder') return <></>
        return <CapacityGridEdit gridToEdit={selectedRecord} onCancel={handleCancelEditRecord} onSave={handleEditFormSave}></CapacityGridEdit>
    }

    const NecsListElem = () => {
        if (mode !== 'necs-service-list') return <></>
        return <NecsExcludedServiceList onNew={handleNewNecsExcludedService} onSelection={handleNecsExcludedServiceSelected} list={necsExcludedServiceList}></NecsExcludedServiceList>
    }
    const NecsEditElem = () => {
        if (mode !== 'necs-service-edit') return <></>
        return <NecsExcludedServiceEdit isNew={selectedNecsExcludedServiceId==='new'} service={selectedNecsExcludedService} onCancel={handleCancelNecsExcludedService} onSave={handleSaveNecsExcludedService} validator={validator} />
    }


    return <>
        <p>&nbsp;</p>
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} lg={9}>
                <Panel title={title}>
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    <CapacityGridListElem></CapacityGridListElem>
                    <CapacityGridEditElem></CapacityGridEditElem>
                    <NecsListElem />
                    <NecsEditElem />
                </Panel>
            </Grid>
        </Grid>
    </>
}