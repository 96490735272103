import { ActionCardHomepageOverviewType, ActionCardServiceIdCheckResponseType, ActionCardType } from "../interfaces/system/actionCardType"
import { HttpGet, HttpPost, HttpResponse } from "./http"

export const fetchActionCardList = async (): Promise<HttpResponse<ActionCardType[]>> => {

    const response = await HttpGet<ActionCardType[]>(
        `ActionCard/list`
    )

    return response
}
export const fetchActionCard = async (id: number): Promise<HttpResponse<ActionCardType>> => {

    const response = await HttpGet<ActionCardType>(
        `ActionCard/${id}`
    )

    return response
}

export const fetchActionCardsForService = async (serviceId: number): Promise<HttpResponse<ActionCardHomepageOverviewType>> => {

    const response = await HttpGet<ActionCardHomepageOverviewType>(
        `ActionCard/GetActionCardsForService?serviceId=${serviceId}`
    )

    return response
}
export const checkServiceIdHasActionCard = async (serviceId: number, actionCardId: number): Promise<HttpResponse<ActionCardServiceIdCheckResponseType>> => {

    const response = await HttpGet<ActionCardServiceIdCheckResponseType>(
        `ActionCard/ServiceIdExists?serviceId=${serviceId}&actionCardId=${actionCardId}`
    )

    return response
}
export const saveActionCard = async (data: ActionCardType): Promise<HttpResponse<ActionCardType>> => {
    let postData = { ...data }
    postData.recipients?.forEach(r => {
        r.events?.forEach(e => {
            //e.name = 'testhjgjh';
        });
    });

    const response = await HttpPost<ActionCardType>(
        `ActionCard`, postData
    )

    return response
}