import React from "react";
import { SplitButton as SB } from 'primereact/splitbutton';



interface SplitButtonOptionProps {
  label?:string;
  key?:any;
  icon?:string
}
interface SplitButtonProps {
    id?: string;
    size?: "small" | "medium" | "large" | undefined;
    icon?:string;
    label?:string;
    onClick?:(key:any)=>void;
    variant?: 'text' | 'outlined' | 'contained' | 'icon' | 'link'
    severity?:'success'|'secondary'|'help'|'info'|'danger';
    options:SplitButtonOptionProps[]
  }

const SplitButton:  React.FC<SplitButtonProps> = props => {
      const [items,setItems] = React.useState(props.options)

React.useEffect(()=>{
  const i :any[] = []
  props.options.forEach(f=>{
    i.push({ label: f.label,    icon: f.icon,command:()=>handleClick(f.key)})
  })
  setItems(i)
},[props.options])

      const handleClick = (key:any) =>{
        if(props.onClick)
        props.onClick(key)
      }

      let icon =  props.icon
      if(!icon) {
        switch(props.label||'' ){
        case 'Next': icon = 'pi pi-caret-right'; break;
        case 'Save': icon = 'pi pi-check-circle'; break;
        case 'Back': icon = 'pi pi-caret-left'; break;
        case 'New': icon = 'p-button-icon p-c p-button-icon-left pi pi-plus'; break;
        case 'Create': icon = 'p-button-icon p-c p-button-icon-left pi pi-plus'; break;
        case 'Export': icon = 'p-button-icon p-c p-button-icon-left pi pi-upload'; break;
        }
      }
      
      let className  = props.severity? `p-button-${props.severity}`:'p-button'
        


      return <SB label={props.label} model={items} className={(`${className} mr-2 mb-2`)} ></SB>
      
      // <Button icon={icon} label={params.label} className={className}  onClick={handleClick}></Button>
      
 
}

export default SplitButton;