import React, {useEffect} from "react"
import Error from '../core/error'
import { FeedbackType } from '../../components/core/feedback'
import {useParams } from "react-router-dom"

export interface ICustomerNoteListParams {
    type?:FeedbackType
  }

  interface IRouteParams {
    id?: string
  }

const ErrorContainer:  React.FC<ICustomerNoteListParams> = (props) => {
        // const params = useParams<IRouteParams>()
        const [message,setMessage] = React.useState('')
  
        useEffect(() => {
            let message = ''
            let feedbackType = props.type

            // if(params.id)
            //     feedbackType = parseInt(params.id)


            switch(feedbackType){
                case FeedbackType.SaveFail:
                    message = message ? message : 'Save failed'
                    break
                case FeedbackType.MandatoryFields:
                    message = message ? message : 'Mandatory fields required'
                    break
                case FeedbackType.SaveSuccess:
                    message = message ? message : 'Successfully saved'
                    break
                case FeedbackType.SearchFail:
                    message = message ? message : 'Failed to load search results'
                    break
                case FeedbackType.NoAccess:
                    message = message ? message : 'You do not have access to the selected record or feature'
                    break
            }
            setMessage(message)
       },[])
        

       return <>
       <Error message={message} />
            </>
}
    
export default ErrorContainer