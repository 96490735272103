import { CapacityGridSubmissionDataType, CapacityGridSubmissionOverviewType, CapacityGridType } from "../../interfaces/system/capacityGridType";
import { ServiceType } from "../../interfaces/system/serviceType";
import CapacityGridSubmissionEditorTable from "./capacity-grid-submission-editor-table";
import Panel from "../../components/core/panel";
import './capacity-grid.scss';
import { useEffect, useState } from "react";
import { fetchServiceData, fetchServiceDataByAreaId } from "../../services/capacity-grid-service";
import Loading from "../core/loading";
import { HelpPopout } from "../core/help-popout";
import React from "react";
import { ComponentPropsBase } from "../../interfaces/system/componentPropsBase";

interface ICapacityGridSubmissionEditorProps extends ComponentPropsBase {
    ServiceId?: number,
    AreaId?: number,
    CapacityGrids: CapacityGridType[],
    onSaved: (rowData: CapacityGridSubmissionDataType, capacityGrid: CapacityGridType, division: ServiceType) => void,
    onRowError?: (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => void,
    onMessageChange: (message: string) => void
}

export default function CapacityGridSubmissionEditor(props: ICapacityGridSubmissionEditorProps) {
    //console.clear();
    const [serviceId, setServiceId] = useState<number>(props.ServiceId ?? 0);
    const [areaId, setAreaId] = useState<number>(props.AreaId ?? 0);
    const [serviceData, setServiceData] = useState<CapacityGridSubmissionOverviewType[]>();
    const [areaData, setAreaData] = useState<CapacityGridSubmissionOverviewType[]>();


    // hooks
    useEffect(() => {
        if (serviceId > 0) {
            loadServiceData();
        }
        if (areaId > 0) {
            loadAreaData();
        }

    }, [])

    const loadServiceData = async () => {
        if (!serviceId || serviceId <= 0) {
            return false;
        }
        handleLoading();
        const response = await fetchServiceData(serviceId);
        handleLoaded();
        if (response && response.success && response.result) {
            setServiceData(response.result);
            return
        }
        handleError(response.error ?? "Unkown error");
    }
    const loadAreaData = async () => {
        if (!areaId || areaId <= 0) {
            return false;
        }
        handleLoading();
        const response = await fetchServiceDataByAreaId(areaId);
        handleLoaded();
        if (response && response.success && response.result) {
            setAreaData(response.result);
            return
        }
        handleError(response.error ?? "Unkown error");
    }

    const handleMessageChange = (message: string) => {
        props.onMessageChange(message);
    }

    const handleRowSaved = (rowData: CapacityGridSubmissionDataType, capacityGrid: CapacityGridType, division: ServiceType) => {
        props.onSaved(rowData, capacityGrid, division);
    }

    const handleRowError = (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => {
        if (props.onRowError) {
            props.onRowError(errorMessage, rowData);
        }
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    if ((props.CapacityGrids?.length || 0) <= 0) {
        return <>Loading data...</>
        //return <Loading useBackdrop={true} loading={true} />
    }

    const renderEditorPanel = (grid: CapacityGridType, divisionList: CapacityGridSubmissionOverviewType[], parentName: string) => {
        return <>
            <Panel key={`cap-grid-editor--${grid.id}--`} title={grid.name}>
                <div>
                    <p>{grid.name} <HelpPopout popoutTitleText="More Info">
                        <><p>{grid.description}</p></></HelpPopout>
                    </p>
                    {renderEditor(grid, divisionList, parentName)}
                </div>
            </Panel>
            <p>&nbsp;</p>
        </>
    }
    const renderEditor = (grid: CapacityGridType, divisionList: CapacityGridSubmissionOverviewType[], parentName: string) => {
        return <>
            <CapacityGridSubmissionEditorTable ParentServiceName={parentName} CapacityGrid={grid} Divisions={divisionList} onSaved={handleRowSaved} onLoading={handleLoading} onLoaded={handleLoaded} onRowError={handleRowError} onError={handleError} onMessageChange={handleMessageChange}></CapacityGridSubmissionEditorTable>
        </>
    }

    // if we are handling a service ID only display divisions under that parent
    if (serviceData && serviceData.length > 0 && !areaData) {
        return <>
            {React.Children.toArray(props.CapacityGrids.sort((a, b) => a.sortOrder - b.sortOrder).map((grid) => {
                // select all divisions with this grid ID set
                let divisionData = serviceData?.filter(data => data.service.capacityGridId === grid.id);
                // only display grids where we have a division
                if (!divisionData || divisionData.length <= 0) {
                    return <></>
                }
                return <>
                    {renderEditorPanel(grid, divisionData, divisionData[0].parentService.name)}
                </>
            }))}
        </>
    }
    // when dealing with areas we need to extract area -> parent -> divisions
    if (areaData && areaData.length > 0 && !serviceData) {
        return <>
            {React.Children.toArray(props.CapacityGrids.sort((a, b) => a.sortOrder - b.sortOrder).map((grid) => {
                const parentServiceIds = areaData.map(a => a.parentService.id);
                let idsRendered: number[] = [];
                let displayGrid = areaData?.filter(data => data.service.capacityGridId === grid.id)?.length > 0;
                // only display grids where we have a division using it
                if (!displayGrid) {
                    return <></>
                }

                return <>
                    {React.Children.toArray(
                        <Panel title={grid.name}>
                            <div>
                                <p>{grid.name} <HelpPopout popoutTitleText="More Info">
                                    <><p>{grid.description}</p></></HelpPopout>
                                </p>

                            </div>
                            {React.Children.toArray(parentServiceIds.map(sid => {
                                if (idsRendered.includes(sid)) {
                                    return <></>
                                }
                                let divisionsInArea = areaData?.filter(data => data.service.capacityGridId === grid.id && data.parentService.id === sid);
                                // only display grids where we have a division
                                if (!divisionsInArea || divisionsInArea.length <= 0) {
                                    return <></>
                                }
                                idsRendered.push(sid);
                                return <div>
                                    {renderEditor(grid, divisionsInArea, divisionsInArea[0].parentService.name)}
                                </div>
                            }))}
                        </Panel>
                    )}
                </>

            }))}
        </>
    }
    if ((areaData && areaData.length <= 0) || (serviceData && serviceData.length <= 0)) {
        return <><p>No data for selected area/service</p></>
    }

    return <><p>Loading data...</p></>
}