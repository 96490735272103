import React, {useEffect, useState,useContext} from 'react'
import { DomainEntityType} from '../../interfaces/domain/iDomainEntity'
import {  getCachedOptions, getLocalOptions} from '../../services/domainEntitiesService'
import {FormValidationField} from '../../extensions/formValidation'
import {Dropdown as PDropdown} from 'primereact/dropdown';
import MultiSelect from './multiSelect'

export interface IDropdownOptionGroup {
  label?:string|undefined
  options: IDropdownOptions[]
}

export interface IDropdownOptions {
    id?:number
    code:string
    name:string
}
export interface IDropdownParams {
  label: string
  id?: string
  required?: boolean;
  error?: boolean;
  value?: any;
  multiple?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void
  onSelection?: (event: any) => void
  // applicationDomainId?: ApplicationDomainType
  optionName?:'service-list'|'userRoles' | 'approvalStatuses' | 'regions' | 'serviceCategories' | 'trusts' | 'areas' | 'forms' | 'capacity-grid' |  'capacity-grid-bed-availability' | 'capacity-grid-resource-type' | 'capacity-grid-field-type' | 'capacity-grid-column-visibility-type' | 'capacity-grid-column-reporting-type' | 'form-type-list' | 'form-action-type-list' | 'form-field-display-type-list' | 'form-result-list'
    | 'form-footer-list' | 'dataset-list' | 'funding-list' | 'gender-list' | 'bed-type-list' | 'distribution-list' | 'form-result-type-list' | 'system-message-type'|'audit-event-type-list'|'automated-report-type-list'|'opel-list'
    |'form-field-type'
  options?: IDropdownOptions[],
  includeBlankOption?: boolean
  validationField?: FormValidationField
  onAddition?: () => void
  onDomainEntitiesRefreshed?: () => void
  // refreshDomainEntities?: boolean,
  filter?: boolean
  filterPlaceHolder?: string
  parentOptionId?: number
  dynamicPopulation?: boolean
}
 

// const ITEM_HEIGHT = 30;
// const ITEM_PADDING_TOP = 2;
// const MenuProps = {
// PaperProps: {
//   style: {
//     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//   },
// },
// }

const Dropdown:  React.FC<IDropdownParams> = params => {
  const [loading, setLoading] = useState(false);
    // const classes = useStyles() as any;
    const [options, setOptions] = React.useState<IDropdownOptionGroup[]>([])
    const [selectedValue, setSelectedValue] = React.useState(params.value)
    const  [error, setError] = useState(params.error||false)
    const allOptions = getCachedOptions()

    useEffect(() => {
      // if(params.applicationDomainId){
      //   populateDomainEntities()
      // }
      if(params.optionName){
        
        populateOptions()
      }
  },[params.optionName])

  useEffect(() => {
    if(!params.error && params.validationField){
      if(params.validationField.isValid !== undefined)
        setError(!params.validationField.isValid)
    }
  },[params.validationField])

  useEffect(() => {
    
    // if(params.multiple){
    //   const a = getSelectedDisplayValuesFromIds(params.value)
    //   setSelectedValue(a)
    // }
    // else{

      // if(params.value!== selectedValue)
        setSelectedValue(params.value)
    // }
  },[params.value])

  useEffect(() => {
    if(params.parentOptionId)
      populateOptions()
  },[params.parentOptionId])
 

  useEffect(() => {
    if(params.options)
      setOptions([{options:params.options}])
  },[params.options])

     

    const populateOptions = async () => {

      if(params.multiple) return 
      if(params.optionName)

      setLoading(true)
      const optionsLoadedFromContext = (allOptions && allOptions.serviceCategories && allOptions.serviceCategories.length>0)

      let domainEntities = (allOptions as any)[params.optionName||''] as DomainEntityType[]
      if(!optionsLoadedFromContext || !domainEntities){
        domainEntities = await getLocalOptions(params.optionName||'')
        if(params.parentOptionId){
          const optionId = params.parentOptionId||''
          domainEntities = domainEntities.find(f=> f.id === optionId)?.children || []
        }
      }

      if(!domainEntities || domainEntities.length===0 || (params.dynamicPopulation && (!params.parentOptionId || params.parentOptionId===0))){
        setOptions([])
        setLoading(false)
        return
      }
        

      const isServiceList = params.optionName === 'service-list'
      let options: IDropdownOptionGroup[] = []
      options.push({options:[]})
      if(params.includeBlankOption) 
          options[0].options.push({id: undefined, name:'', code:''})

        domainEntities.map(opt=> {
          const name =  isServiceList ? opt.name.split('|')[0] : opt.name
          options[0].options.push({id: opt.id, name:name, code:opt.id.toString()})
        })



      setOptions([...options]);
      setLoading(false)
    }

    
    const handleChange = (e: any) => {
      
        // if(!params.multiple){
          //event.target.value = getSelectedDisplayValues(event.target.value as string[])
          // setSelectedValue(event.target.value)
        // }
        // else{
        //   // //event.target.value = getSelectedDisplayValues(event.target.value as string[])
        //   // const ids = getSelectedDisplayValues(event.target.value as string[])
          
        //   const ids = (event as any[]).map(m=> parseInt(m.code))
        //   setSelectedValue(ids)
        // }
        
    if(params.multiple){
      const ids = (e as any[]).map(m=> parseInt(m.code))
      e.target = {value : ids, id:params.id}
      
      if(params.onSelection)
          params.onSelection(e)
          

      if(params.onChange) params.onChange(e)
      if(params.onChange){
        params.onChange(e)
      }
      
          return
    }

        let inputValue = (e?.target?.value ?? '') !=='' ? e.target.value : undefined


        if((e?.target?.id ?? '') !== '') e.target.id = params.id
         
        if(!inputValue || inputValue === '') e.target  = {value : null, id:e.target.id}
          setSelectedValue(inputValue)

         if(params.onSelection){
             params.onSelection(e)
             if(params.onChange) params.onChange(e)
         }
         else
          validate(e,inputValue)
      }
      const validate = (e: any,inputValue:string) :boolean => {
        
        if(params.validationField){
          params.validationField.fieldValue = inputValue
          setError(!params.validationField.validate())
        }
        
        if(params.onChange){
          e.target.value=inputValue
          e.target.id = params.id
          params.onChange(e)
        }
        
          return params.error||false;
      }
  
  
      // const getSelectedDisplayValues = (selectedValues:string[]) :string[] =>{
      //   if(!options || !selectedValue)
      //     return []

      //     const allSelectedValues = (selectedValues as string[]).join(', ')
      //     const displayValues :string[] = []
      //     options?.forEach(grp=> {
      //       grp.options?.forEach(o => {
      //         if(allSelectedValues.indexOf(o.displayText)>-1){
      //           displayValues.push(o.displayText)
      //         }
      //       })
      //     })
      //     return displayValues
      // }
      // const getSelectedDisplayValuesFromIds = (selectedValues:string[]) :string[] =>{
      //   if(!options || !selectedValue)
      //     return []

      //     const displayValues :string[] = []
      //     options?.forEach(grp=> {
      //       grp.options?.forEach(o => {
      //         selectedValues.forEach(s => {
      //           if(s.toString() === o.id.toString()){
      //             displayValues.push(o.displayText)
      //           }
      //         })
      //       })
      //     })
      //     return displayValues
      // }
      
      // const handleAddClicked = () => {
      //   if(params.onAddition)
      //   params.onAddition()
      // }
     

      if(params.filter){
        return <PDropdown value={selectedValue} options={options[0].options} onChange={(e)=> {}} optionLabel="name" filter showClear filterBy={"name"} placeholder={params.filterPlaceHolder} />
      }

    //   let helperText = ''
    // if(params.validationField && !params.validationField.isValid){
    //   helperText = params.validationField.errorMessage
    // }
    
      if(params.multiple===undefined || !params.multiple) {
        return <span className="p-float-label">
        <PDropdown inputId={params.id} 
        value={(selectedValue ? selectedValue : params.includeBlankOption || (!options || options?.length<1 || options[0]?.options?.length<1) ? undefined : options[0].options[0].id)} 
        onChange={handleChange} 
        filter={params.filter === undefined || params.filter}
        className={(params.error || error )?'p-invalid':''}
        // onBlur={(event:any) => {validate(event)}}
        // onChange={(event:any) => {validate(event)}}
        disabled={params.disabled}
        options={options && options?.length>0 ?options[0].options:[]} 
        optionLabel={'name'}
        optionValue={'id'}
        scrollHeight={"300px"}
        />
        <label htmlFor={params.id}>{params.label}</label>
    </span>
      //   return <FormControl variant="outlined" fullWidth>
      //   {params.label !== '' && <InputLabel style={{marginTop:0,fontWeight:400}} htmlFor={params.id}>{params.label}</InputLabel>}
      //   <Select fullWidth
      //     native
      //     error={error}
      //     margin='dense'
      //     style={{maxHeight:42}}
      //     id={params.id}
      //     disabled={params.disabled}
      //     value={selectedValue}
      //     onChange={handleChange}
      //     label={params.label !== ''? params.label: undefined}
      //     onBlur={(event:any) => {validate(event)}}
      //     inputProps={{
      //       name: `${params.id}`,
      //       id: `${params.id}`,
      //       value: `${selectedValue}`
      //     }}
      //   >
      //     {params.includeBlankOption && <option aria-label="None" key='blank' />}
          
      //     {options.length ===1 && (!options[0].label || options[0].label === '') &&  options.map((grp) => (
      //     grp.options.map((o) => (
      //       <option key={o.id} value={o.id}>{o.displayText}</option>
      //      )) 
      //     ))}


      //   {options.length > 0 && (options[0].label && options[0].label !== '') &&  options.map((grp, groupId) => {
      //     return <optgroup label={grp.label} key={groupId}>
      //       {grp.options.map((o) => {
      //           return <option key={o.id} value={o.id}>{o.displayText}</option>
      //       })}
      //      </optgroup>
      //   })}

       

      //   </Select>
      // </FormControl>
      }
    
      else 
        return <MultiSelect label={params.label} optionName={params.optionName} onChange={handleChange} scrollHeight="300px"/>
        
    }

export default Dropdown