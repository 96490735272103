import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { FormType, FormTypeEnum } from '../interfaces/system/formType';
import {ValidatorType} from './ValidatorType'
export class FormValidator implements ValidatorType
{

   formValidator = new FormValidation([])
    name= new FormValidationField('','')
    formResult= new FormValidationField('','')
   //  reportingCategory= new FormValidationField('','')

     initialiseValidationFields = (objectValues: FormType) => {
        this.formValidator = new FormValidation([])
        this.name= this.formValidator.createTextField(objectValues.name)

        if(objectValues.formType.id === FormTypeEnum.DataInput)
            this.formResult= this.name
         if(objectValues.formType.id === FormTypeEnum.Weighted)
            this.formResult= this.formValidator.createDomainTypeField(objectValues?.result.id)
    }
    
 validate =  (validatorObjectValues: FormType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid
 }
}

export default FormValidator

