import React, {useEffect} from "react";
import { UserAccessRequestType } from "../../../interfaces/user/iUser";
import DataTable from "../../core/dataTable";

 
interface IProps {
    request:UserAccessRequestType|undefined
    loading: boolean
    onSelection:(request:UserAccessRequestType) => void
  }

const PendingAccessRequests:  React.FC<IProps> = (props) => {

    const [loading] = React.useState(props.loading);
    const [pendingAccessRequest, setPendingAccessRequest] = React.useState(props.request)

    useEffect(() => {

   },[])
   useEffect(() => {
    setPendingAccessRequest(props.request)
   },[props.request])
    
   const handleEdit = (rowData:any) => {
        props.onSelection(rowData)
   }
    return <>
    <DataTable loading={loading} value={pendingAccessRequest?.requests} columns={
        [
            {header:'Name', field:'fullName'},
            {header:'Email', field:'email'},
            {header:'No. Requests', field:'totalRequests'},
            {header:'Requested On', field:'requestedOn', formatType:'datetime'}
        ]} dataKey="id" editButton={true} onEdit={handleEdit}/>
    </>;
}

export default PendingAccessRequests;

