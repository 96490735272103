import React, {useEffect} from "react"
import DataTable from '../../core/dataTable'
import { ServiceCategoryType } from "../../../interfaces/system/areaTypes"


interface ServiceCategoryListProps {
    list?:ServiceCategoryType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

export default function ServiceCategoryList(props: ServiceCategoryListProps) {
useEffect(() => {
  setList(props.list)
},[props.list])

const [list,setList] = React.useState(props.list)
   

  const handleRowSelection =(id:number) => {
    
    if(props.onSelection)
        props.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false} columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Provider Type", field:"serviceCategoryName"},
  {header:"Code", field:"code"},
  {header:"Active", field:"deleted", formatType:"yesno-inverse"},
  {header:"Display Order", field:"sortOrder"}]}  paginationRowsPerPage={10} value={list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true} filter={true} onNew={props.onNew} allowExport={true}
  />

}
