// import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card } from 'primereact/card';
import Loader from '../core/loading'
import React, {useEffect} from 'react'
import HelpIcon from     '../../assets/images/icons/question-mark.svg'
import SimpleDialog from './simpleDialog';
interface GroupBoxProps {
    title: string
    subTitle?: string
    children?: React.ReactNode
    actions?:React.ReactNode
    help?:React.ReactNode
    iconName?:string
    height?:string
    loading?:boolean
    hidden?:boolean
    showHelpOnInit?:boolean
    variant?:'dashboard-panel'|'dashboard-panel--gauge'|'rag-block-panel'
  }

export default function GroupBox (props:GroupBoxProps) {
  const [showHelpDialog, setShowHelpDialog] = React.useState(props.showHelpOnInit)
     
      const loader=<Loader useBackdrop={false} loading={props.loading||false}></Loader>

      useEffect(() => {
      },[props.loading]);

      const  showHelp  = () => {
        setShowHelpDialog(true)
      }

      
      if(props.hidden)
        return <></>


      if(props.variant) {
        const cssClassName = props.variant!== 'rag-block-panel' ? `dashboard-panel ${props.variant}` 
                                            : 'rag-block-panel dashboard-panel'

        return <div className={cssClassName}>
                <h3 className="panel-title">{props.title} <span style={{ float: 'right', marginTop: -5, marginRight: -20 }}>
              </span></h3> 
              {props.children}
            </div>
      }

      const title = <div style={{paddingTop:5, paddingLeft:5}} className="p-card-title">{props.title}
      {/* {props.help && <img src={InfoIcon} style={{height:32, width:32,position:'relative',top:9,left:4,cursor:'pointer'}} onClick={showHelp}/>} */}
      {props.help && <img src={HelpIcon} style={{height:32, width:32,float:'right',marginRight:25,cursor:'pointer'}} onClick={showHelp}/>}
      </div>
        
    return <Card key={props.title} header={title} subTitle={props.subTitle} footer={props.actions} style={{paddingLeft:15}}>
      <SimpleDialog show={showHelpDialog||false} subTitle={''}  onClose={() => setShowHelpDialog(false)} defaultValue={''}>
        {props.help}
      </SimpleDialog>
          {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
          </Typography> */}
              {props.loading === undefined || !props.loading ? props.children : loader}
        {/* {props.actions && <CardActions style={{justifyContent:'flex-end', padding:'2px', marginTop:'-60px'}}>
            {params.actions}
        </CardActions>} */}
        
      </Card>

}
