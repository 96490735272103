import { useEffect, useState } from "react"
import { createNewFormResult, FormResultOptionType, FormResultType } from "../../../interfaces/system/formType"
import DebugVariable from "../../core/debugVariable";
import { fetchFormResult, fetchFormResultList, saveFormResult } from "../../../services/form-service";
import { Grid } from "@mui/material";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import BasicTab from "../../core/basicTab";
import { FormResultRangeEditor } from "./form-result-range-editor";
import OKCancelButtons from "../../core/OKCancelButtons";
import ActionButton from "../../core/actionButton";
import { FormResultValidator } from "../../../validators/form-result-validator";
import Dropdown from "../../core/dropdown";

export interface FormResultEditorProps {
    selectedItemId: number,
    onError?: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void,
    onBackClicked?: () => void,
    onSave: (item: FormResultType) => void
}
export function FormResultEditor(props: FormResultEditorProps) {
    const [selectedItemId, setSelectedItemId] = useState(props.selectedItemId);
    const [selectedItem, setSelectedItem] = useState<FormResultType>();
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [modelValidator, setModelValidator] = useState(new FormResultValidator());

    // hooks
    useEffect(() => {
        init();
        window.addEventListener('popstate', function () {
            if (props.onBackClicked) {
                props.onBackClicked()
            }
        }, false);
    }, []);


    const init = async () => {
        if (selectedItemId > 0) {
            handleLoading();
            const response = await fetchFormResult(selectedItemId);
            handleLoaded();
            if (response && response.success && response.result) {
                setSelectedItem(response.result);
                setIsValid(modelValidator.validate(response.result));
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        } else {
            setSelectedItem(createNewFormResult());
        }
    }

    // event handlers
    const handleLoading = async () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = async () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleCancel = async () => {
        if (props.onBackClicked) {
            props.onBackClicked()
        }
    }
    const handleNewItemClicked = async (items: FormResultOptionType[]) => {
        if (selectedItem) {
            setSelectedItem({ ...selectedItem, options: items });
        }

    }
    const handleError = async (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleFieldUpdated = async (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedItem);
        setIsDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleDeletedUpdated = async (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
        setIsDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }

    const handleRangeUpdated = async (item: FormResultOptionType, valid: boolean) => {
        if (selectedItem) {
            let updatedRanges: FormResultOptionType[] = [];
            selectedItem.options.forEach(opt => {
                
                if (opt.id !== item.id) {
                    updatedRanges.push(opt);
                }
            });
            updatedRanges.push(item);
            updatedRanges = updatedRanges.sort((a, b) => a.sortOrder - b.sortOrder);
            setSelectedItem({ ...selectedItem, options: updatedRanges });
            setIsValid(valid);
            setIsDirty(isValid);
        }
    }

    const handleRangesOrdered = async (items: FormResultOptionType[]) => {
        if (selectedItem) {
            setSelectedItem({ ...selectedItem, options: items });
            setIsDirty(true);
        }
    }

    const handleSave = async () => {
        if (selectedItem) {
            handleLoading();



            const response = await saveFormResult(selectedItem);
            handleLoaded();
            if (response && response.success && response.result) {
                if (selectedItemId === 0) {
                    setSelectedItemId(response.result.id);
                }
                setSelectedItem(response.result);
                if (selectedItem) {
                    props.onSave(selectedItem);
                }
                await fetchFormResultList(false);
                //}
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
       
    }

    const EditResultElement = <>
        {selectedItem &&
            <Grid container spacing={2}  style={{ padding: '15px 5px' }}>
                <Grid item xs={2}>
                    <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} hidden={selectedItem.id<1} />
                </Grid>
                <Grid item xs={2}>
                    <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} disabled={selectedItemId <= 0} />
                </Grid>
                <Grid item xs={6} ></Grid>
                <Grid item xs={4} >
                    <Dropdown id="formResultTypeId" validationField={modelValidator.resultType} includeBlankOption={true} label="Result Type" optionName="form-result-type-list" value={selectedItem?.formResultTypeId}  onChange={handleFieldUpdated} />
                </Grid>
                <Grid item xs={12}>
                    <TextBox id="name" label="Name" validationField={modelValidator.name} value={selectedItem.name} onChange={handleFieldUpdated} />
                </Grid>

                <Grid item xs={12}>
                    <TextBox id="description" label="Description" rows={6} value={selectedItem.description} onChange={handleFieldUpdated} maxLength={1024} />
                </Grid>
            </Grid>
        }
    </>
    const CategoryEditElement = <>
        {selectedItem && <FormResultRangeEditor selectedItem={selectedItem} onOrdered={handleRangesOrdered} onNew={handleNewItemClicked} onUpdated={handleRangeUpdated} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} />}
    </>

    return <>
        <Grid container>
            <BasicTab key='tabs' tabs={[
                { label: 'Details', component: <>{EditResultElement}</> },
                { label: 'Ranges', component: <>{CategoryEditElement}</> }
            ]}>
            </BasicTab>

        </Grid>
        <DebugVariable hide={true} variable={selectedItem} title="Record to update" />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {isDirty && isValid && <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />}

                {(!isDirty || !isValid) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                    <div style={{ float: 'left', marginRight: '5px' }}>
                        <ActionButton onClick={handleCancel} label="Back" severity="secondary"></ActionButton>
                    </div>
                </div>}
            </Grid>
        </Grid>
    </>

}
