import { useEffect, useState } from "react"
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter"
import { Grid } from "@mui/material"
import {ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service"
import DataTable from "../../core/dataTable"
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes"
import { UserPermissionReportType } from "../../../interfaces/reporting/system"
import Loading from "../../core/loading"

export interface UserPermissionReportCriteria {
    serviceIds?: number[]
    userIds?: number[]
    userRoleIds?: number[]
}
export interface UserSubscriptionReportProps {
    areaHierarchy: AreaHierarchyType,
    list?: UserPermissionReportType[],
    onApplyFilter: (criteria: UserPermissionReportCriteria) => void
    criteria?: BasicReportFilterCriteria
    defaultFilterCriteria?: BasicReportCriteria
}

export function UserPermissionReport(props: UserSubscriptionReportProps) {
    const [data, setData] = useState(props.list);
    const [loading, setLoading] = useState(false);
    const [isPrintMode, setIsPrintMode] = useState(false)

    useEffect(() => {
        setData(props.list)
    }, [props.list]);

    useEffect(() => {
        if(isPrintMode){
            setLoading(true)
        }else setLoading(false)
    }, [isPrintMode]);
    useEffect(() => {
        if(loading)
            handleExport()
    }, [loading]);

    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        const filter:UserPermissionReportCriteria = {
            serviceIds: criteria.selectedServiceIds?.map(s => s.id)
        };
        if (criteria.selectedUserId !== undefined && (criteria.selectedUserId ?? 0) > 0) {
            const arr:number[] = [criteria.selectedUserId];
            filter.userIds = arr;
        }
            filter.userRoleIds =  criteria.selectedUserRoles
        
        props.onApplyFilter(filter);
    }

    const handleExport = () => {
        const title = 'User Permission Report'
        const sheets: ExcelExportSheet[] = []
        
        sheets.push({name:title, title:title, elements:[{table:document.querySelector("table"), title:title}]})

        exportToExcel({
            defaultHeaderWidth: 20,
            sheets: sheets
        });
        setIsPrintMode(false)
    }

    return <>

        <Grid item xs={12}>
            <Loading loading={loading}></Loading>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria} 
                onExport={()=>{setIsPrintMode(true)}} />
        </Grid>
        <p>&nbsp;</p>
        <Grid item xs={12} >
            <DataTable dataKey="id" loading={false} columns={[{ header: "User", field: "user.fullName"},
            { header: "Email", field: "user.email"},
            { header: "Service", field: "service.name"},
            { header: "User Role", field: "userRole.name"},
            { header: "Requested On", field: "requestedOn", formatType:"datetime"}]}
            paginationRowsPerPage = { isPrintMode? data?.length : 25} value = { data } editButton = { false} paginator = {(data?.length ?? 0) > 25} filter={false} allowExport={false} editButtonIcon="pi pi-search"
            />
        </Grid></>  
}