import React, {useEffect,useState} from "react"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import Dropdown from "../../core/dropdown";
import OKCancelButtons from "../../core/OKCancelButtons";
import Loader from "../../core/loading";
import BasicTab from "../../core/basicTab";
import TickBox from "../../core/tickBox";
import { createNewFormField, FormActionTypeEnum, FormFieldType, FormFieldTypeEnum, FormResultOptionType, FormResultTypeEnum, FormType } from "../../../interfaces/system/formType";
import Panel from "../../core/panel";
import FormValidator from "../../../validators/form-validator";
import FormEditFieldList from "./form-edit-field-list";
import FormEditField from "./form-edit-field";
import FormEditResultRange from "./form-edit-result-range";
import ActionButton from "../../core/actionButton";

interface FormEditProps {
    form?:FormType
    onSave: (form: FormType,valid:boolean) => void 
    onCancel: () => void 
  }

const FormEdit:  React.FC<FormEditProps> = (props) => {
  const [validator] = useState(props.form?.validator)
  const [formActionTypeId, setFormActionTypeId] = useState<FormActionTypeEnum>()
  const [selectedField, setSelectedField] = useState<FormFieldType>()
  const {
    validateForm, objectValues, setObjectValues,setValues,setValidator,
  }
   = useForm({initialObjectValues: props.form, validator: validator})

   useEffect(() => {

        window.addEventListener('popstate', function() {
            props.onCancel()
        }, false);
   },[])

useEffect(() => {
  if(props.form?.id !== objectValues?.id){
    setObjectValues(props.form)
    
    
  } 

  setFormActionTypeId(props.form?.actionType?.id as FormActionTypeEnum)


  if(props.form && !props?.form?.validator){
    props.form.validator= new FormValidator()
  }


  setValidator(props?.form?.validator)
},[props.form])

   
const handleSave = async () =>{
  setValidator(validator)
  let isValid = validateForm(objectValues)
  
  setObjectValues({
    ...objectValues,
    validator: validator
 })


  props.onSave(objectValues, isValid)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()} 

  const handleFieldSelected = (field:FormFieldType) => {
    setSelectedField(field)
  }
  const handleFieldUpdated =(field: FormFieldType) =>{
    const form = objectValues as FormType
    if(!form || !form.fields || !field) return
    const formFieldIndex = form.fields.findIndex(f=>f.id === field.id)
    
    form.fields[formFieldIndex] = field
    
    setObjectValues({...form, fields:[...form.fields]})
    setSelectedField({...field})

  }

  const handleFieldUpdatehandleFieldsUpdated =(fields: FormFieldType[]) =>{
    setObjectValues({...objectValues, fields:[...fields]})
  }

  const handleAddNewField = (fieldTypeId:FormFieldTypeEnum) => {
    const form = objectValues as FormType
    if(!form.fields) return

    const newField = createNewFormField(fieldTypeId)
    newField.fieldNumber = form.fields.length+1
    form.fields.push(newField)
    setObjectValues({...form, fields:[...form.fields]})
      setSelectedField({...newField})  
    
  }
  const handleCompletionActionChanged = (e:any) => {
    setFormActionTypeId(e.target.value as FormActionTypeEnum)
    let a = objectValues.actionType

    if(!a) a = {}
    a.id = e.target.value as FormActionTypeEnum
    if(!a.name) a.name='N/A'

    setObjectValues({...objectValues, actionType:a})
  }
  const handleFormResultOptionsUpdated = (formResultTypeId:FormResultTypeEnum,options:FormResultOptionType[]) =>{
        if(formResultTypeId=== FormResultTypeEnum.Standard ){
          const result = {...objectValues.result}
          result.options = options
          setObjectValues({...objectValues, result:result})
        }
        else{
          const result = {...objectValues.opelResult}
          result.options = options
          setObjectValues({...objectValues, opelResult:result})
        }
  }
  const copyForm = () => {


    const f :FormFieldType[] = objectValues.fields
    if(f && f.length>0){
      f.forEach(f=> {
        f.id =0
        if(f.options)
          f.options.forEach(ff=>{
            ff.id = 0
          })
      })
    }

    setObjectValues({
      ...objectValues,
      id: 0,
      name:objectValues.name + ' **(COPY)**',
      fields:f
   })




  }
 
if(!objectValues)
        return <Loader loading={true} />
  
        const serviceDetail =  <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
                <Grid item xs={2}><TextBox id="id"  label="Id" value={objectValues.id} disabled={true}/></Grid>
                
                <Grid item xs={2}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>{objectValues.id >0 && <ActionButton label="Copy" severity="info" onClick={copyForm}>/</ActionButton> }</Grid>
                <Grid item xs={12}><TextBox id="name" label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
                
                <Grid item xs={12} ><Dropdown id="formType.id" label="Type" optionName="form-type-list" filter={false}   value={objectValues?.formType?.id}    onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={12} ><Dropdown id="result.id" label="EMS Form Result" filter={false}  validationField={validator?.formResult}  optionName="form-result-list" includeBlankOption={true}  value={objectValues?.result?.id}   onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={12} ><Dropdown id="opelResult.id" label="OPEL Form Result" filter={false}  optionName="form-result-list" includeBlankOption={true}  value={objectValues?.opelResult?.id}   onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={12} ><Dropdown id="footer.id" label="Footer" filter={false}  optionName="form-footer-list" includeBlankOption={true}  value={objectValues?.footer?.id}   onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={12} ><Dropdown id="actionType.id" label="Completion Action" filter={false} optionName="form-action-type-list" includeBlankOption={true}  value={objectValues?.actionType?.id}  onChange={handleCompletionActionChanged} /></Grid>
                {(formActionTypeId?.toString() === FormActionTypeEnum.RedirectToForm.valueOf().toString()) &&
                  <Grid item xs={12} ><Dropdown id="formActionNumeric" label="Redirect Form" optionName="forms" includeBlankOption={true}  value={objectValues?.formActionNumeric}  onChange={(e) => {setValues(e)}} /></Grid>
                }
                {(formActionTypeId?.toString() === FormActionTypeEnum.DisplayMessage.valueOf().toString()) &&
                  <Grid item xs={12} ><TextBox id="formActionText" label="Message" rows={3} value={objectValues.formActionText} onChange={(e) => {setValues(e)}} /></Grid>
                }
                
    </Grid>;

    const addressDetail = <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
        <Grid item xs={12}>
        <FormEditField field={selectedField} onUpdate={handleFieldUpdated} />
          </Grid>
      </Grid>

const formResultRangesDetail = <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
                                <Grid item xs={12}>
                                <FormEditResultRange form={objectValues} onUpdate={handleFormResultOptionsUpdated}/>
                                  </Grid>
                                </Grid>

  return <>
  <Grid container justifyContent="center">
    <Grid item xs={7}>
        <BasicTab key='formTabs' tabs={[{label:'Form Details', component: <>{serviceDetail}</> },
                  {label:'Field Details', component: <>{addressDetail}</>},
                  {label:'Form Result Ranges', component: <>{formResultRangesDetail}</>}]}>
              
        </BasicTab>   

        </Grid> 
        <Grid item xs={5}  style={{paddingLeft:15}}>
          <Panel title="Field List" >
            <FormEditFieldList list={objectValues.fields} selectedId={selectedField?.id} onAddNewField={handleAddNewField} onUpdate={handleFieldUpdatehandleFieldsUpdated} onSelect={handleFieldSelected}></FormEditFieldList>
          </Panel>
        </Grid>
        <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
        <Grid item xs={8}>
        <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />
        </Grid>     </Grid>     
                
                        </Grid>
  </>

}

export default FormEdit;