import React, { useState, useEffect } from "react";
import useForm from "../../hooks/useForm"

import Feedback, { FeedbackType } from '../../components/core/feedback'
import { useNavigate, useParams } from "react-router-dom"
import { createNewUser, UserAccessRequestType, PendingUserRegistrationType, UserRoleType, createNewUserRole, UserType } from "../../interfaces/user/iUser"
import { fetchPendingAccessRequests, saveAccessRequestStatus, fetchPendingRegistrations, generateUserVerificationEmail, fetchUserRoleList, fetchUserRole, saveUserRole, searchUsers, fetchUserByIdForEdit, deletePendingAccessRequests } from '../../services/userService'
import PendingAccessRequests from "../../components/admin/user/pendingAccessRequests";
import PendingAccessRequestAction from "../../components/admin/user/pendingAccessRequestAction";
import { PendingUserAccessRequestResponseType } from "../../interfaces/user/userAdminTypes";
import PendingRegistrations from "../../components/admin/user/pendingRegistrations";
import { AppToastContext } from '../../extensions/context/appHook'
import Panel from "../../components/core/panel";
import UserRoleList from "../../components/admin/user/userRoleList";
import UserRoleEdit from "../../components/admin/user/userRoleEdit";
import { Grid } from "@mui/material";
import { UserList } from "../../components/admin/user/user-list";
import { EditUser } from "../../components/admin/user/edit-user";
import ActionButton from "../../components/core/actionButton";
import { fetchSystemAudit } from "../../services/reporting/system-service";
import { SystemAuditType } from "../../interfaces/reporting/system";

interface IProps {
    mode?: 'pending-access' | 'pending-registrations' | 'list' | 'role-list' | 'edit' | 'pending-access-action' | 'role-edit'
}

const UserAdminContainer: React.FC<IProps> = (props) => {
    const TITLE_PENDING_ACCESS_REQUESTS = 'Pending user access requests'
    const [title, setTitle] = useState(TITLE_PENDING_ACCESS_REQUESTS)
    let { userId, userRoleId } = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const {
        feedbackStatus, setFeedbackStatus,
    }
        = useForm({ initialObjectValues: createNewUser() })

    const [selectedUserId, setSelectedUserId] = useState(userId)
    const [selectedUserRoleId, setSelectedUserRoleId] = useState(userRoleId)
    const [selectedUser, setSelectedUser] = useState<UserType>()
    const [selectedUserRole, setSelectedUserRole] = useState<UserRoleType>()
    const [mode, setMode] = useState(props.mode)
    const [userRoleList, setUserRoleList] = useState<UserRoleType[]>()
    const [userList, setUserList] = useState<UserType[]>()
    const [pendingAccessRequest, setPendingAccessRequest] = useState<UserAccessRequestType>()
    const [pendingRegistrations, setPendingRegistrations] = useState<PendingUserRegistrationType>()
    const [selectedAccessRequest, setSelectedAccessRequest] = useState<UserAccessRequestType>()


    useEffect(() => {

        const userIsBasic = false
        if (userIsBasic) {
            setFeedbackStatus(FeedbackType.NoAccess)
        }
    }
        , [])
    useEffect(() => {

        setMode(props.mode)
    }
        , [props.mode])
    useEffect(() => {

        present()
    }
        , [mode])

    useEffect(() => {
        if (!selectedUserRoleId || (selectedUserRole && selectedUserRole.id.toString() === selectedUserRoleId)) return
        presentEditUserRole()
    }
        , [selectedUserRoleId])


    const present = async () => {
        if (mode === 'role-edit') return
        setFeedbackStatus(FeedbackType.Loading)
        presentMapper[mode || 'list']()

    }

    const presentUserEdit = async () => {
        if (mode !== 'edit') return;
        handleLoading();
        if (selectedUserId && parseInt(selectedUserId) >= 0) {
            const response = await fetchUserByIdForEdit(parseInt(selectedUserId));
            if (response && response.success && response.result) {
                setSelectedUser(response.result);
                setTitle('Edit User ' + response.result?.fullName);
            }
        }
        handleLoaded();
    }

    const presentEditUserRole = async () => {
        if (!selectedUserRoleId) {
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setFeedbackStatus(FeedbackType.Loading)

        if (selectedUserRoleId === 'new') {
            setMode('role-edit')
            setTitle('Create New User Role')
            setSelectedUserRole(createNewUserRole())
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        const response = await fetchUserRole(parseInt(selectedUserRoleId));

        if (response && response.success && response.result) {
            setMode('role-edit')
            setTitle('Edit User Role - ' + response.result.name)

            setSelectedUserRole(response.result)
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        toastHandler.errorMessage(response.error)
        setMode('role-list')
    }
    const presentPendingAccessRequests = async () => {
        setTitle( TITLE_PENDING_ACCESS_REQUESTS)
        const response = await fetchPendingAccessRequests();
        setFeedbackStatus(FeedbackType.hide)
        if (!response || response.success) {
            setPendingAccessRequest(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentUserList = async () => {
        setTitle('Users')
        const response = await searchUsers('', true);
        setFeedbackStatus(FeedbackType.hide)
        if (!response || response.success) {
            setUserList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentPendingRegistrations = async () => {
        setTitle('Pending user registrations')
        const response = await fetchPendingRegistrations();
        setFeedbackStatus(FeedbackType.hide)
        if (!response || response.success) {
            setPendingRegistrations(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentRolesList = async () => {
        setTitle('User Roles List')
        const response = await fetchUserRoleList();
        setFeedbackStatus(FeedbackType.hide)
        if (!response || response.success) {
            setUserRoleList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentMapper: { [K: string]: Function } = {
        'pending-access': presentPendingAccessRequests,
        'pending-registrations': presentPendingRegistrations,
        'role-list': presentRolesList,
        'role-edit': () => { },
        'edit': presentUserEdit,
        'list': presentUserList,
    };

    const handleRequestSelection = (request: UserAccessRequestType) => {
        setTitle(`${request.fullName} - Pending user access request`)
        setSelectedAccessRequest(request)
    }
    const handleDeletePendingAccessRequests = async (r: UserAccessRequestType) => {
        setErrorMessage('')

        setFeedbackStatus(FeedbackType.Loading)
        const saveResponse = await deletePendingAccessRequests(r.userId)
        if (saveResponse.success) {
            present()
            setSelectedAccessRequest(undefined)
            return
        }

        setErrorMessage(saveResponse.error)
        setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveAccessRequestApproval = async (response: PendingUserAccessRequestResponseType[]) => {
        setErrorMessage('')
        if (response.length === 0) return

        setFeedbackStatus(FeedbackType.Loading)
        const saveResponse = await saveAccessRequestStatus(response)
        if (saveResponse.success) {
            present()
            setSelectedAccessRequest(undefined)
            return
        }

        setErrorMessage(saveResponse.error)
        setFeedbackStatus(FeedbackType.Error)

    }
    const toastHandler = React.useContext(AppToastContext)
    const HandleSendVerificationMail = async (userId: string) => {

        setFeedbackStatus(FeedbackType.Loading)
        const response = await generateUserVerificationEmail(userId)

        if (response.success) {
            toastHandler.successMessage("Verification email sent to selected user")
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const handleCancelAccessApproval = () => {
        setSelectedAccessRequest(undefined); setTitle(TITLE_PENDING_ACCESS_REQUESTS);
    }
    const handleNewUserRole = () => {
        setSelectedUserRoleId('new')
    }
    const handleUserRoleSelection = async (id: number) => {
        setSelectedUserRoleId(id.toString())
    }

    const handleUserListSelection = (selectedUser: UserType) => {
        setSelectedUserId(selectedUser.id.toString());
        setMode('edit');
        navigate(`/admin/user/${selectedUser.id}`);
    }

    const handleUserSaved = (updatedUser: UserType, message?: string) => {
        //setSelectedUser(updatedUser);
        setFeedbackStatus(FeedbackType.SaveSuccess)
        setErrorMessage(message);
    }

    const handleEditUserBackButtonClick = () => {
        setSelectedUserId('');
        setSelectedUser(undefined);
        setMode('list');
        navigate(`/admin/user`);

    }

    const handleLoading = () => {
        setFeedbackStatus(FeedbackType.Loading);
    }
    const handleLoaded = () => {
        setFeedbackStatus(FeedbackType.hide);
    }
    const handleError = (errorMessage: string) => {
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(errorMessage);
    }

    const handleSaveUserRole = async (userRole: UserRoleType, valid: boolean) => {
        setSelectedUserRole(userRole)
        setErrorMessage('')
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveUserRole(userRole)
        if (saveResponse.success) {
            toastHandler.successMessage("Successfuly saved User Role - " + userRole.name)
            setFeedbackStatus(FeedbackType.hide)
            if (saveResponse.result) {
                const newService = { ...(userRole as UserRoleType) }
                newService.id = saveResponse.result?.id
                setSelectedUserRole(newService)
                setUserRoleList(undefined)
                presentRolesList()
            }
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)

    }
     
    const handleCancelEditUserRole = () => {
        setSelectedUserRoleId(undefined)
        setSelectedUserRole(undefined)
        setMode('role-list')
    }
    const PendingAccessElem = () => {
        if (props.mode !== 'pending-access') return <></>
        if (props.mode === 'pending-access' && !selectedAccessRequest)
            return <PendingAccessRequests request={pendingAccessRequest} loading={feedbackStatus !== FeedbackType.hide} onSelection={handleRequestSelection} />
        return <PendingAccessRequestAction request={selectedAccessRequest} onDeletePendingRequests={handleDeletePendingAccessRequests} onSaveAccessRequestApproval={handleSaveAccessRequestApproval}
            onCancel={handleCancelAccessApproval} />
    }

    const PendingRegistrationsElem = () => {
        if (mode !== 'pending-registrations') return <></>

        return <PendingRegistrations loading={feedbackStatus !== FeedbackType.hide} request={pendingRegistrations} onSendVerificationMail={HandleSendVerificationMail} />
    }
    const UserRolesElem = () => {
        if (mode !== 'role-list') return <></>
        return <UserRoleList onNew={handleNewUserRole} list={userRoleList} onSelection={handleUserRoleSelection} />
    }
    const UserListElem = () => {
        if (mode !== 'list' || !userList) return <></>
        return <UserList userList={userList} onUserSelection={handleUserListSelection} />
    }
    const UserRoleEditElem = () => {
        if (mode !== 'role-edit') return <></>

        return <UserRoleEdit userRole={selectedUserRole} onSave={handleSaveUserRole} onCancel={handleCancelEditUserRole} />

    }

    const UserEditElem = <>
        {(mode !== "edit" || !selectedUser) && <></>}
        {mode === "edit" && selectedUser && <>
            <EditUser User={selectedUser} OnError={handleError} OnLoading={handleLoading} OnLoaded={handleLoaded} OnSaved={handleUserSaved} ShowChangePassword={false} ShowPasswordReset={true} ShowAdditionalOptions={true}
            ShowAudit={true} />
            <ActionButton onClick={handleEditUserBackButtonClick} label="Back" severity="secondary"></ActionButton>
        </>}
    </>


    return <Grid container justifyContent="center" spacing={0} style={{ paddingTop: "2rem" }}>
        <Grid item xs={12} lg={9}>
            <Panel title={title}>
                <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                <PendingAccessElem />
                <PendingRegistrationsElem />
                <UserRolesElem />
                <UserRoleEditElem />
                {UserEditElem}
                <UserListElem />
                <p>&nbsp;</p>
            </Panel>
        </Grid>
    </Grid>
}

export default UserAdminContainer
