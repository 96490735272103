export interface AddressType  {
     contactTelephone?:string
     contactMobile?:string
     contactName?:string
     email?:string
     website?:string
    addressLine1:string
    addressLine2?: string
    town: string
    county: string
    postcode: string
    latitude: number
    longitude: number
    id:number
 }
 export interface LongitudeLatitudeType {
   lat: number
   lon: number
 }

 export const createNewAddress = (): AddressType  => {
    return {addressLine1:'', addressLine2:'', county:'', postcode:'', town:'',id:0,latitude:0, longitude:0}
 }