import React, {useEffect,useState} from "react"
import {OpelMappingType, ServiceType } from "../../../interfaces/system/serviceType"
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";

interface ServiceOpelMappingsEditProps {
    service?:ServiceType
    onChange?: (opelMappings:OpelMappingType[]) => void
  }

  export default function ServiceShrewdMappingsEdit (props:ServiceOpelMappingsEditProps) {
    const [opelMappings,setOpelMappings] = useState(props.service?.opelMappings)
 
   useEffect(() => {
    setOpelMappings(props.service?.opelMappings)
   },[props.service]) 
   
 
   const updateForm = (e: any,index: number) => {
    
    if(!opelMappings) return
    
    const mapping = opelMappings[index] as any
    
    mapping[e.target.id] = e.target.value
    opelMappings[index] = mapping

    setOpelMappings([...opelMappings])

    if(props.onChange)
      props.onChange(opelMappings);
 }
   

if(!opelMappings)
        return <p>No mappings</p>
  
        
        const serviceDetail =  <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          {React.Children.toArray(opelMappings?.map((item: OpelMappingType, index: number) => {
            return <>
              <Grid spacing={2} container  style={{padding:'5px 5px'}}>
                <Grid item xs={3}><TextBox id="parameterId"  label="Opel Paramter Id"  maxLength={6} type="number" value={item.parameterId} onChange={(e)=>{updateForm(e,index)}}/></Grid>
                <Grid item xs={3}><TextBox id="externalParameterId"  label="Shrewd Paramter Id"  maxLength={6} type="number" value={item.externalParameterId}  onChange={(e)=>{updateForm(e,index)}}/></Grid>
              </Grid>
            </>
          }))}
    </Grid>;

    

  return <> 
          <Grid item xs={12}>
                 {serviceDetail}
              </Grid>
  </>

}

