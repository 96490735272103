import React from 'react';
import { DomainEntityType } from '../../interfaces/domain/iDomainEntity';
import { getCachedOptions, getLocalOptions } from '../../services/domainEntitiesService';
import { Checkbox } from 'primereact/checkbox';
import {CircularProgress, Grid} from '@mui/material';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FormValidationField } from '../../extensions/formValidation';
import { Panel,PanelHeaderTemplateOptions } from 'primereact/panel';



export interface SelectListProps {
    optionName:'permission-list'|'matrix'|'forms'|'service-features-list';
    disabled?:boolean
    selectedIds?:number[]
    onChange: (selectedIds: number[]) => void
    fullRow?:boolean
    scrollHeight?:number
    validationField?: FormValidationField;
    title?:string
  }

  export interface SelectOptionType {
    id:number
    name:string
    checked:boolean
}

const SelectList:  React.FC<SelectListProps> = props => {

  const [options, setOptions] = React.useState<SelectOptionType[]>([])
  const allOptions = getCachedOptions()
  const [selectedIds, setSelectedIds] = React.useState(props.selectedIds||[])
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [selectedAll, setSelectedAll] = React.useState(false);

  React.useEffect(() => {
    if(props.optionName){
      populateOptions()
    }
},[props.optionName])
React.useEffect(() => {
    validate()
},[props.validationField])

React.useEffect(() => {
  setSelectedIds(props.selectedIds||[])
},[props.selectedIds])

  const handleChecked = (e:any)=>{
    let selected = [...selectedIds];

        if (e.checked)
          selected.push(parseInt(e.value));
        else
        selected.splice(selected.indexOf(parseInt(e.value)), 1);

        setSelectedIds(selected);

        if(props.onChange)
          props.onChange(selected)
        if(props.validationField){
          props.validationField.fieldValue = selected.toString()
          props.validationField.validate()
          validate()
        }
  }
  const validate=()=>{
    setHasError((props.validationField!== undefined && props.validationField.isValid!== undefined) && (!props.validationField.isValid||false))
  }
  const populateOptions = async () => {
    setLoading(true)
    const optionsLoadedFromContext = (allOptions && allOptions.serviceCategories && allOptions.serviceCategories.length>0)

    let domainEntities = (allOptions as any)[props.optionName||''] as DomainEntityType[]
    if(!optionsLoadedFromContext || !domainEntities){
      domainEntities = await getLocalOptions(props.optionName||'')
    }

    if(!domainEntities || domainEntities.length===0){
      setLoading(false)
      return
    }

    let options:SelectOptionType[] = []
      domainEntities.map(opt=> {
        options.push({id: opt.id, name:opt.name, checked:false})
      })

    setOptions(options);
    setLoading(false)
  }
  const handleSelectedAll = (e:any) =>{
    setSelectedAll(e.checked)
    if(!e.checked) {
      setSelectedIds([])  
      return
    }
    const ids =options.map((m) => m.id)
    setSelectedIds(ids)
  }

  const checkTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className} style={{display:'block'}}>
            <Checkbox id="checkAll" checked={selectedAll} onChange={handleSelectedAll} />
            <span style={{marginLeft:5}} className={titleClassName}>
            {props.title}
            </span>
        </div>
    )
  }


  if(loading)
        return <CircularProgress color="secondary" />

       if(!options) return <></>

      const listElem = <Grid container spacing={1} xs={9} >   
      {options.map((o:SelectOptionType) => (

<Grid xs={(props.fullRow ? 12 :4)} style={{padding:'9px'}} className="field-checkbox">
<Checkbox name="check" id={`check_${o.id}`} value={o.id} onChange={handleChecked} checked={selectedIds.findIndex(f=> o.id === f) > -1} />
<label style={{paddingLeft:5}} htmlFor={`check_${o.id}`}>{o.name}</label>
</Grid>

          ))}</Grid>;

        if(!props.scrollHeight) return <Panel header={props.title} style={{paddingTop:10}}>{listElem}</Panel>
  
        return   <Panel header={props.title} headerTemplate={checkTemplate} style={{paddingTop:10}}>
        <ScrollPanel style={{ width: '100%', height: `${props.scrollHeight}px`  }} className={(hasError ? 'error-box':'')}>
        {listElem}
              </ScrollPanel>
              {hasError &&<span className='error-text' style={{fontSize:'0.75rem'}}>Please select at least one option</span>}
              </Panel>
              
  }
export default SelectList