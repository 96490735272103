import {IDomainEntities, OptionsType, createNewOptionsType} from '../interfaces/domain/iDomainEntity'
import { AreaHierarchyType } from '../interfaces/system/areaTypes';
import { UserType } from '../interfaces/user/iUser';
// import { decipherText } from './cipherService';

interface IStorageItem {
    value:  any
    expiry: number
}

export const isUserAuthenticated = () : boolean => {
    const user = getUser()
    return user && (user.administrator || (user.roles && user.roles.length>0))
}
export const userHasApprovedPermissions = () : boolean => {
    const user = getUser()
    return (user && user.administrator) || (user && user.roles && user.roles.length>0)
}
export const userHasApprovedPermissionForService = (persmissionTypeId:number, serviceId:number) : boolean => {
    const user = getUser()

    if(user.administrator) return true
    const roleId = user.servicePermissions[serviceId]
    if(!roleId) return false

    const role = user.roles.find(f=> f.id=== roleId)
    if(!role || !role.permissions) return false
    return role.permissions.findIndex(f=>f.permissionTypeId === persmissionTypeId )>-1
}
export const getJwt  = () => {
    const token = getLocalStorageItem('smjwt')
    return token||'';
}
export const setJwt = (token: string) => {
    setLocalStorageItemWithLifespan('smjwt', token, 1209600000)
}
export const setUser = (user: UserType) => {
    setLocalStorageItemWithLifespan('user', user, 1209600000)
}
export const getUser  = () :UserType => {
    return getLocalStorageItem('user');
}
export const getUserFulllname  = ()  => {
    const user = getUser()
    return `${user.fullName}`
}
export const removeJwt = () => {
    removeStorageItem('smjwt')
    removeStorageItem('user')
}
 
 
export const userRoleIsAdmin = (): boolean => {
    const user = getUser();
    
    if(!user) return false

    return user.administrator ?? false;
}

export const isImpersonating = (): string|boolean => {
    const user = getUser();
    
    if(!user) return false

    return user.impersonating ? user.fullName : false;
}

export const userRoleIsAdminFromUserType = (user:UserType): boolean => {
    if(!user) return false

    return user.administrator ?? false;
}


export const resetDomainEntities = () => {
    //localStorage.clear();
    sessionStorage.clear();
}

// export const getCurrentUserDetails = ():UserType => {
//     return {id:1, knownAs: 'Andy', userName:'alexaa', userRoleTypeId:1, email:'ggg', surname:'alexamder', firstname:'pp', active:true, managerUserId:undefined, isVerified:true}
// }

export const getDomainEntities   = ()  : IDomainEntities[] => {
    return getLocalStorageItem('domainEntitites')||'';
}
export const setDomainEntities = (entities:IDomainEntities[]) => {
    return setLocalStorageItem('domainEntitites', entities);
}
export const setOptions = (options:OptionsType) => {
    return setSessionStorageItem('options', options);
}
export const getOptions   = ()  : OptionsType => {
    return getLocalSessionItem('options')||createNewOptionsType();
}
export const getServiceFilterFromSession   = ()  : string => {
    return getLocalSessionItem('service-filter')||'';
}
export const saveServiceFilterToSession = (filter:string) => {
    return setSessionStorageItem('service-filter', filter);
}

export const setOptionList = (key:string, options:any[]) => {
    return setSessionStorageItem(key, options);
}
export const getOptionList   = (key:string)  : any[] => {
    return getLocalSessionItem(key)||[];
}

export const getAllAreaHierarchyWithServicesFromSessionStorage   = ()  : AreaHierarchyType => {
    var a= getLocalSessionItem('AllAreaHierarchyWithServices')||null
    return a;
}
export const setAllAreaHierarchyWithServicesFromSessionStorage = (v:AreaHierarchyType) => {
    return setSessionStorageItem('AllAreaHierarchyWithServices', v);
}

const setLocalStorageItem = (key: string, value : any) => {
    return setLocalStorageItemWithLifespan(key, value, 300)
}
export const setSessionStorageItem = (key: string, value : any) => {
    try{
    return setSessionStorageItemWithLifespan(key, value, 300)
    }
    catch{
        console.log("could not save session data")
    }
}

const setLocalStorageItemWithLifespan = (key: string, value : any, lifeSpanMinutes: number) => {
    const storageItem : IStorageItem = {expiry :(new Date()).getTime()+(lifeSpanMinutes*60000), value : value }
    return window.localStorage.setItem(key, JSON.stringify(storageItem));
}

const setSessionStorageItemWithLifespan = (key: string, value : any, lifeSpanMinutes: number) => {
    const storageItem : IStorageItem = {expiry :(new Date()).getTime()+(lifeSpanMinutes*60000), value : value }
    return window.sessionStorage.setItem(key, JSON.stringify(storageItem));
}

const getLocalStorageItem = (key: string) : any => {
    const storageValue =  window.localStorage.getItem(key);
    
    if(storageValue){
        const storageItm : IStorageItem = JSON.parse(storageValue); 
        if(storageItm.expiry< (new Date()).getTime()){
            return null;
        }
        else{
            return storageItm.value;
        }
    }
    return null;
}

export const getLocalSessionItem = (key: string) : any => {
    const storageValue =  window.sessionStorage.getItem(key);
    
    if(storageValue){
        const storageItm : IStorageItem = JSON.parse(storageValue); 
        if(storageItm.expiry< (new Date()).getTime()){
            return null;
        }
        else{
            return storageItm.value;
        }
    }
    return null;
}

export const removeStorageItem = (key: string) => {
    return window.localStorage.removeItem(key);
}
export const removeSessionStorageItem = (key: string) => {
    return window.sessionStorage.removeItem(key);
}