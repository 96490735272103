import { getUser } from "../../services/storageService";
import UserRoleValidator from "../../validators/userRoleValidator";
import { ApplicationDomainType, DomainEntityType } from "../domain/iDomainEntity";
import { SystemMessageType } from "../system/systemMessageType";
 
export enum AuthenticationResultType
{
    Valid = 0,
    UserNotVerified = 1,
    UserAboutYou = 2,
    NoPermissions = 3,
    InvalidCredentials =4
}
export enum UserPermissionRequestApprovalStatus
{
      Pending = 0,
    Approved = 10,
    Declined = 20
}
export interface UserType  {
   email: string
   jobRole: string
   service: string
   fullName: string
   serviceName: string
   applicationUse: string
   serviceCategoryId:number
   areaId:number
   id:number
   userId?:string
   active:boolean
   administrator?:boolean
   isVerified:boolean
   registrationStatus:AuthenticationResultType
   token: string
   servicePermissions:[]
   roles:UserRoleType[]
   defaultServiceFilter?: string
   deleted?: boolean
   includeAutoGeneratedSubmissions?: boolean
   systemMessages?:SystemMessageType[]
   impersonating?:boolean
}
export interface UserRegistrationLoginType  {
    userName:string;
    password: string;
    passwordConfirmation: string;
    token?: string;
 }
 export class UserLoginType  {
   userId?:string;
   username:string;
   password: string;
   passwordConfirmation: string;
     token?: string;
   captchaToken?:string
     constructor(username: string, password: string, token?: string, captchaToken?: string){
      this.username = username;
      this.password = password;
      this.token =  token;
      this.passwordConfirmation = password;
      this.captchaToken = captchaToken;
   }
}

export interface UserSearchCriteriaType  {
   name?:string
   email?:string
   userRoleTypeId?:ApplicationDomainType.UserRole
}
 export interface IJWTToken  {
    token:string;
    expiration: Date;
    user:UserType
 }
 export class JWTToken implements IJWTToken  {
    token:string;
    expiration: Date;
    user:UserType
    constructor(){
        this.token = '';
        this.expiration = new Date();
        this.user = createNewUser()
     }
 }
 export interface UserAssessmentStatusType {
   userId:number
   totalStudents:number
   reviewsDueWithin7Days:number
   actionsOverdue:number
   totalGreenOutcomes:number
   totalRedOutcomes:number
   totalAmberOutcomes:number
 }

 export interface UserSearchResultType  {
   name:string
   id: number
   email:string
   userRoleName:string
}

export interface UserRoleType  {
   name:string
   publicName:string
   description:string
   id: number
   deleted:boolean
   public:boolean
   permissionTypeIds?:number[]
   permissions:UserRolePermissionType[]
   validator?:UserRoleValidator
}

export interface UserRolePermissionType  {
   id: number
   deleted:boolean
   permissionTypeId:number
}

export interface UserRegistrationAboutYou  {
   fullName:string
   token: string
   jobRole:string
   serviceName:string
   serviceCategoryId:number
   areaId:number
   applicationUse:string
   email:string
}
export interface UserAccessRequestType  {
   requests:UserAccessRoleRequestType[]
   permissions:UserAccessRoleRequestType[]
   totalRequests:number
    fullName:string
   jobRole:string
   applicationUse:string
   service:string
   email:string
   approvalStatus:number
   userId:number
}
export interface PendingUserRegistrationType  {
   email:string
   verifiedOn:Date
   registeredOn:Date
   verified:boolean
   registrationStatus:string
   registrationStatusText:string
   requests:PendingUserRegistrationType[]
}
export interface UserAccessRoleRequestType  {
   id:number
   areaId:number
    serviceId?:number
   service: DomainEntityType
   area:DomainEntityType
   user:UserType
   Role:DomainEntityType
   permission:DomainEntityType
   requestedOn:string
   approvalStatus:number
}
export const createNewUserRole = (): UserRoleType =>{
   return {id:0, name:'', description:'', deleted:false,  public:false, permissions:[], publicName:'', validator:new UserRoleValidator()}
 }
 export const createNewUser = (): UserType =>{
   return {id:0, email:'', fullName:'', active:true,  isVerified:false, applicationUse:'', jobRole:'', service:'',areaId:0, serviceCategoryId:0,serviceName:'', registrationStatus:0
   , token:'',  servicePermissions:[],roles:[]}
 }
 export const createNewUserLogin = (): UserRegistrationLoginType =>{
   return {userName:'',password:'', passwordConfirmation:''}
 }
 export const createNewUserSearchCriteria = (): UserSearchCriteriaType =>{
   return {name:undefined, userRoleTypeId:undefined}
 }
 export const createNewBlankUserRegistrationAboutYou = () : UserRegistrationAboutYou =>{
   return createNewUserRegistrationAboutYou('','','',0,0,'','','')
 }
 export const createNewUserRegistrationAboutYouFromUser = (user:UserType, token:string) : UserRegistrationAboutYou =>{
     return createNewUserRegistrationAboutYou(user.fullName,token,user.jobRole,user.serviceCategoryId,
      user.areaId,user.serviceName,user.applicationUse,user.email)
 }
 export const convertNewUserRegistrationAboutYouToUser = (user:UserRegistrationAboutYou) : UserType =>{
   const existingUser = getUser()
     existingUser.fullName = user.fullName
   existingUser.areaId = user.areaId
   existingUser.serviceCategoryId = user.serviceCategoryId
   existingUser.serviceName = user.serviceName
   existingUser.jobRole = user.jobRole
   existingUser.applicationUse = user.applicationUse

   return existingUser
}
 export const createNewUserRegistrationAboutYou = (fullName:string,
   token: string,
   jobRole:string,
   serviceCategoryId:number,
   areaId:number,serviceName:string,
   applicationUse:string,email:string) : UserRegistrationAboutYou =>{
   return {fullName:fullName, token:token, jobRole:jobRole,
   serviceCategoryId:serviceCategoryId, areaId:areaId,
   applicationUse:applicationUse,email:email,serviceName:serviceName}
 }