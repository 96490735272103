import { Grid } from "@mui/material";
import Panel from "../../components/core/panel";
import { useEffect, useState } from "react";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import { DocumentLibraryList } from "../../components/admin/doc-library/doc-library-list";
import { DocumentUploadType, createEmptyDocumentUpload } from "../../interfaces/system/documentUploadTypes";
import { fetchDocumentLibraryList, fetchDocumentUpload, saveDocumentUpload } from "../../services/documentLibraryService";
import { TreeSelectionEvent } from "primereact/tree";
import { DocumentLibraryEditor } from "../../components/admin/doc-library/doc-library-edit";
import { NetworkWifiTwoTone } from "@mui/icons-material";
import ActionButton from "../../components/core/actionButton";
import { fetchAllAreaHierarchyWithServices } from "../../services/systemService";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../interfaces/system/areaTypes";

export function DocumentLibraryContainer() {
    const [feedbackStatus, setFeedbackStatus] = useState(FeedbackType.hide);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [uploadList, setUploadList] = useState<DocumentUploadType[]>();
    const [expandedType, setExpandedType] = useState<number>();
    const [selectedItem, setSelectedItem] = useState<DocumentUploadType>();
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());


    useEffect(() => {
        present();
    }, []);


    const present = async () => {
        setFeedbackStatus(FeedbackType.Loading);

        const a = await fetchAllAreaHierarchyWithServices()
        if (a.success && a.result)
            setAreaHierarchy(a.result)
        else {
            setErrorMessage(a.error as string)
        }

        const response = await fetchDocumentLibraryList();
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setUploadList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }


    const handleSelection = async (itemId: number, event: TreeSelectionEvent) => {
        setFeedbackStatus(FeedbackType.Loading);
        setSelectedItem(undefined);
        const response = await fetchDocumentUpload(itemId);
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setSelectedItem(response.result);
            return;
        }
        setFeedbackStatus(FeedbackType.Error);
        setErrorMessage(response.error ?? 'Unknown error');
    }

    const handleSave = async (item: DocumentUploadType, valid: boolean) => {
        setSelectedItem(item);
        setErrorMessage('');
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)
        
        const saveResponse = await saveDocumentUpload(item, item.file)
        if (saveResponse.success) {
            //toastHandler.successMessage("Successfuly saved form - " + form.name)
            setFeedbackStatus(FeedbackType.hide)
            if (saveResponse.result) {
                const newForm = { ...(item as DocumentUploadType) }
                newForm.id = saveResponse.result?.id;
                setSelectedItem(undefined);
                setUploadList(undefined);
                setExpandedType(newForm.documentTypeId);
                present();
                setErrorMessage('Item saved');
                setFeedbackStatus(FeedbackType.SaveSuccess);
            }
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error');
        setFeedbackStatus(FeedbackType.Error);

    }

    const handleNewItem = async () => {
        setSelectedItem(createEmptyDocumentUpload());
    }
    const handleCancel = async () => {
        setSelectedItem(undefined);
    }

    if (!uploadList) {
        return <></>
    }

    return <>
        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel title="Document Library">
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={6}>
                            <DocumentLibraryList list={uploadList} expandType={expandedType} onSelection={handleSelection} />
                            <div style={{marginTop:'10px'}}></div>
                            <ActionButton onClick={handleNewItem} label="New" severity="success"></ActionButton>
                        </Grid>
                        <Grid item xs={6}>
                            {selectedItem && <DocumentLibraryEditor areaHierarchy={areaHierarchy} selectedItem={selectedItem} onSave={handleSave} onCancel={handleCancel} />}
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>
}