import { DistributionListMemberType, DistributionListMembersDetailsResultType, DistributionListMembersResultType, DistributionListType, UpdateDistributionListMemberResultType, UpdateDistributionListMemberType } from "../interfaces/system/distributionListTypes"
import { HttpGet, HttpPost, HttpResponse } from "./http"
import { getLocalSessionItem, setSessionStorageItem } from "./storageService"

export const createEmptyDistributionList = (): DistributionListType => {
    return { id: 0, name: '', description: '', members: [], deleted: false, sortOrder: 999 };
}
export const createEmptyDistributionListMember = (distributionListId: number = 0): DistributionListMemberType => {
    return { deleted: false, distributionListId: distributionListId, userDisplayName: '', userEmailAddress: '', sortOrder: 999, userId: undefined, id: 0 };
}

export const fetchDistributionLists = async (useCache: boolean = true): Promise<HttpResponse<DistributionListType[]>> => {

    const cacheKey = `distribution-lists`
    useCache=false

    if (useCache) {
        const cachedValue = getLocalSessionItem(cacheKey) as DistributionListType[]
        if (cachedValue) return { success: true, result: cachedValue }
    }

    const response = await HttpGet<DistributionListType[]>(
        `distributionlist/list`,
    )

    if (response.success && useCache) {
        setSessionStorageItem(cacheKey, response.result)
    }

    return response
}
export const fetchDistributionList = async (id: number, excludeDeleted: boolean): Promise<HttpResponse<DistributionListType>> => {
    const response = await HttpGet<DistributionListType>(
        `distributionlist/${id}?excludeDeleted=${excludeDeleted}`
    )

    return response
}

export const saveDistributionList = async (data: DistributionListType): Promise<HttpResponse<DistributionListType>> => {
    let postData = { ...data }
    if ((postData.description?.length ?? 0) > 1024) {
        postData.description = postData.description.substring(0, 1024);
    }

    const response = await HttpPost<DistributionListType>(
        `DistributionList`, postData
    )

    return response
}
export const fetchDistributionListMembers = async (useCache: boolean = true): Promise<HttpResponse<DistributionListMembersResultType[]>> => {

    const cacheKey = `distribution-list-members`

    // if (useCache) {
    //     const cachedValue = getLocalSessionItem(cacheKey) as DistributionListMembersResultType[]
    //     if (cachedValue) return { success: true, result: cachedValue }
    // }

    const response = await HttpGet<DistributionListMembersResultType[]>(
        `distributionlist/list-members`,
    )

    // if (response.success) {
    //     setSessionStorageItem(cacheKey, response.result)
    // }

    return response
}
export const fetchDistributionListMemberDetails = async (userId?: number, emailAddress?: string, deleted?:boolean, useCache: boolean = true): Promise<HttpResponse<DistributionListMembersDetailsResultType>> => {

    const cacheKey = `distribution-list-member-details`

    if (useCache) {
        const cachedValue = getLocalSessionItem(cacheKey) as DistributionListMembersDetailsResultType
        if (cachedValue) return { success: true, result: cachedValue }
    }

    const response = await HttpGet<DistributionListMembersDetailsResultType>(
        `distributionlist/member-details?userId=${(userId ?? 0) > 0 ? userId : ''}&emailAddress=${emailAddress ?? ''}&deleted=${deleted ?? ''}`,
    )

    if (response.success) {
        setSessionStorageItem(cacheKey, response.result)
    }

    return response
}

export const updateDistributionListMember = async (data: UpdateDistributionListMemberType): Promise<HttpResponse<UpdateDistributionListMemberResultType>> => {
    let postData = { ...data }
    //if ((postData.description?.length ?? 0) > 1024) {
    //    postData.description = postData.description.substring(0, 1024);
    //}

    const response = await HttpPost<UpdateDistributionListMemberResultType>(
        `DistributionList/update-member`, postData
    )

    return response
}