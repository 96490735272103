import { Grid } from "@mui/material";
import { useState, useEffect} from "react";
import { formatDateTime } from "../../../extensions/formatter";
import { FormSubmissionByFieldReportType } from "../../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import Panel from "../../core/panel";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import FormEscalationTriggersSubmission from "./form-escalation-triggers-submission";

export interface FormEscalationTriggersCriteriaProps {
  serviceIds:number[]
  serviceCategoryIds?:number[]
  fromDate?:Date
  toDate?:Date,
  selectedEscalationReportingTypeId?:number

}
interface FormEscalationTriggerSummaryReportProps {
  areaHierarchy:AreaHierarchyType
  onFetchData: (criteria:FormEscalationTriggersCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:FormSubmissionByFieldReportType
  defaultFilterCriteria?:BasicReportCriteria
  }

  
export default function FormEscalationTriggerSummaryReport(props:FormEscalationTriggerSummaryReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    setData(props.data)
},[props.data])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    props.onFetchData({serviceIds: criteria.selectedServiceIds.map(m=>m.id), serviceCategoryIds:criteria.selectedServiceCategoryIds, 
      fromDate:criteria.fromDate, toDate:criteria.toDate, 
      selectedEscalationReportingTypeId: criteria.selectedEscalationReportingTypeId})
}


const handleExport = () =>{

    const title= 'Escalation Trigger Summary Report'
    const sheets:ExcelExportSheet[] = [{name:title,title:title,elements:[]}]
    data?.serviceSubmissions?.forEach(f=>{
        sheets[0].elements.push({table:document.querySelector<HTMLTableElement>(`#t_${f.service.id}`)})
    })


    

    exportToExcel({
    defaultHeaderWidth:20,
    headerBGColour:'00026aa6',
    headerFGColour:'00ffffff',
    headerAlignment:'center',
    sheets:sheets
    })
}


        const noData = !data || !data.serviceSubmissions || data.serviceSubmissions.length<1
        
        return  <Grid container spacing={2}>
            <Grid item xs={10}>
                <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}

                validateServiceSelections={true}
                onExport={handleExport}/>
            </Grid>
            {noData && <Grid item xs={12}>No data to display</Grid>}
              <Grid item xs={12} >
              <Panel title="Escalation Trigger Summary" scrollHeight="50%">
              {data?.serviceSubmissions.map((service) => (
                <table id={`t_${service.service.id}`} className="reporting-table" style={{width:'100%',position:'relative',marginBottom:15}}> 
                            <thead>
                                  <tr>
                                    <th colSpan={((data?.forms.find(f=>f.id=== service.service.formId)?.fields?.length)??0)+3} style={{position:'sticky',top:0}}>{service.service.name}</th>
                                  </tr>
                                  <tr>
                                    <th style={{position:'sticky',top:0}}>Submitted</th>
                                  {data?.forms.find(f=>f.id=== service.service.formId)?.fields?.map((f)=> (
                                    <th style={{position:'sticky',top:0}}>{f.name}</th>
                                  ))}
                                  <th style={{borderLeft:'1px dotted black',position:'sticky',top:0}}>Overall</th>
                                    <th style={{width:750,position:'sticky',top:0}}>Comments</th>
                                  </tr>
                            </thead>
                            <tbody>
                            {service?.submissions.filter(f=>f.formId === service.service.formId).map((submission) => (
                                <tr>
                                    <td style={{whiteSpace:'nowrap',position:'sticky',left:0,background:'#fff'}}>{formatDateTime(submission.submittedOn)}</td>
                                    {submission.fields.map((f) => (
                                        <td className={`form-result-${f?.reportingLabel}`}>{f?.reportingLabel}</td>
                                    ))}
                                    
                                    <td style={{borderLeft:'1px dotted black'}} className={`form-result-${submission.resultLabel}`}>{submission.resultLabel}</td>
                                    <td>{submission.comments}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        ))}
                        <br></br>
                    </Panel>
              </Grid>
        </Grid>
}



