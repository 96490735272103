import { AddressType, LongitudeLatitudeType } from "../interfaces/system/iAddress"
import { HttpResponse,HttpExternalGet,HttpGet } from "./http"

export const fetchLngLatFromPostcode = async (postcode:string) : Promise<HttpResponse<LongitudeLatitudeType[]>> => {
    const response = await HttpExternalGet<any>(
      `https://geocode.maps.co/search?q=${postcode}&country=en&api_key=65a118f8831e8800251153nfd16a82f`
    )
    
    return response
  }
  export const fetchAddressByServiceId = async (serviceId:number) : Promise<HttpResponse<AddressType>> => {
    const response = await HttpGet<AddressType>(
      `address/service/${serviceId}`
    )    
    return response
  }