import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { FormValidation, FormValidationField, ValidationType } from "../../extensions/formValidation";
import useForm from "../../hooks/useForm";
import Feedback, { FeedbackType } from "../core/feedback";
import ActionButton from "../core/actionButton";
import Home from "../home/home";
import GroupBox from "../core/groupBox";
import TextBox from "../core/textBox";
import { userForgotPassword } from "../../services/userService";
import { useSearchParams } from "react-router-dom";

const ForgotPassword: React.FC = () => {
    const loggedIn = false;

    const [validLogin, setValidLogin] = useState(false);
    const [formValidation, setFormValidation] = useState(new FormValidation([]));
    const [email, setEmail] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [searchParams] = useSearchParams();
    let forcePasswordReset = (searchParams.get('force') ?? '') === 'true';

    const {
        feedbackStatus, setFeedbackStatus
    }
        = useForm({ initialObjectValues: {} })

    useEffect(() => {
        if ((searchParams.get('force') ?? '') === 'true') {
            //loginFailure('You must change password at first login');
            //forcePasswordReset = true;
        }
    }, []);


    const initialiseValidationFields = () => {
        const formFields = [new FormValidationField(email || '', 'Please enter a valid email address')];

        formFields[0].validationType = ValidationType.Email;
        formValidation.validationFields = formFields;

        setFormValidation({ ...formValidation });
        
        setTimeout(() => {
            setFormValidation({ ...formValidation });    
        }, (750));

        return formValidation;
    }


    const handleForgotPassword = async () => {
        setFeedbackStatus(FeedbackType.Loading)

        const newFormValidation = initialiseValidationFields()
        var isValid = newFormValidation.isValid();

        if (isValid) {
            const token = await userForgotPassword(email || '')

            if (token.success) {
                loginSuccess()
            }
            else {
                loginFailure(token.error || '')
            }
        }
        else { setFeedbackStatus(FeedbackType.hide) }
    }

    const loginSuccess = () => {
        setValidLogin(true)
        setFeedbackStatus(FeedbackType.SaveSuccess)
    }

    const loginFailure = (errorMessage: string) => {
        setValidLogin(false);
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(errorMessage)
    }



    if (loggedIn) {
        return <Home></Home>;
    }
    const cardActions = <div style={{padding:3,width:'100%'}}>
        <div style={{padding:3,width:'100%',textAlign:'center',marginBottom:15}}>
            <ActionButton onClick={handleForgotPassword} severity="success" label="Continue"></ActionButton>
        </div>
        <div style={{padding:3,width:'100%',textAlign:'center'}}>
            <ActionButton label="Back to Login" icon="pi pi-arrow-left" severity="secondary" href="/login"></ActionButton>
        </div>
        
    </div>


    if (!validLogin) {
        return <React.Fragment>
            <br></br><br></br>

            <Grid container justifyContent="center">
                <Grid item xs={10} lg={6}>
                    <Grid item xs={12}>
                        <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    </Grid>
                    <GroupBox title={forcePasswordReset ? 'Set your new password' : 'Forgotten password?'} actions={cardActions} iconName={"lock"}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={12}>
                                <div style={{ textAlign: 'center' }}>
                                    {!forcePasswordReset && <>
                                        Don't worry, we can help you reset it. Enter your email address below to get started.
                                    </>}
                                    {forcePasswordReset && <>
                                        You are required to set a password at first logon. Enter your email address below to get started.
                                    </>}
                                </div>

                            </Grid>
                            <Grid item xs={12} lg={7}><TextBox id="outlined-username" focus={true} label="Email Address" onChange={(e) => { setEmail(e.target.value || '') }} validationField={formValidation.validationFields[0]} onEnterKey={handleForgotPassword} /></Grid>
                        </Grid>
                    </GroupBox>
                </Grid>
            </Grid>


        </React.Fragment>
    }
    else {
        return <Grid container justifyContent="center">

            <Grid item xs={10} lg={6}>
                {/* <GroupBox title="Check your email" actions={<ActionButton>Back to Login</ActionButton>} iconName={"send"}> */}
                <GroupBox title="Check your email" iconName={"send"}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <div style={{ textAlign: 'center' }}> We've sent you a email containing a link that will let you reset your password and enable you to log in to your EMS+ account.</div>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <div style={{ textAlign: 'center' }}> Your login link was sent to <br></br>
                                {email}
                                <br></br><br></br><br></br>
                            </div>
                        </Grid>

                    </Grid>

                </GroupBox>
                <br></br><br></br>

            </Grid>

            <Grid container spacing={3} justifyContent="center">
                <ActionButton label="Back to login" variant="link" href="/login"></ActionButton>
            </Grid>
        </Grid>
    }
}

export default ForgotPassword;
