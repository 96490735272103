import React, {useEffect,useState} from "react"
import {Grid} from '@mui/material';
import {FormFieldType,FormFieldTypeEnum, getIconNameFromFieldType } from "../../../interfaces/system/formType";
import useForm from "../../../hooks/useForm";
import TextBox from "../../core/textBox";
import Panel from "../../core/panel";
import TickBox from "../../core/tickBox";
import Dropdown from "../../core/dropdown";
import FormEditFieldOptions from "./form-edit-field-options";

interface FormEditFieldProps {
    field?:FormFieldType
    onUpdate: (formField: FormFieldType) => void 
  }

const FormEditField:  React.FC<FormEditFieldProps> = (props) => {
  const [validator] = useState(props.field?.validator)
  const {
    objectValues, setValues, setObjectValues
  }
   = useForm({initialObjectValues: props.field, validator: validator})

  
useEffect(() => {
  if((!objectValues) || props.field?.id !== objectValues.id){
    setObjectValues(props.field)
  }
},[props.field])

useEffect(() => {
  if((objectValues) && props.field?.id === objectValues.id)
    props.onUpdate(objectValues as FormFieldType)
},[objectValues])

const FieldMultiChoiceElem = () => {
  
  return <Grid item xs={12}>
    <Panel title="Options">
        <FormEditFieldOptions formField={objectValues} options={objectValues.options} onUpdate={(f)=> setObjectValues({...objectValues,options:f})} />
      </Panel>
  </Grid>
}
const FieldElem = ():JSX.Element => {
  if(!objectValues) return <>none</>

  switch(objectValues.fieldType.id){
    case FormFieldTypeEnum.MultipleChoice:
      case FormFieldTypeEnum.Dropdown:
      return FieldMultiChoiceElem();
    default:
        return <>{objectValues.fieldTypeId}</>
  }
}
const FieldHeaderElem = () => {
  if(!objectValues)   return <></>
  let icon = getIconNameFromFieldType(objectValues.fieldTypeId)

  return <i className={icon} style={{position:'relative', top:-10}}></i> 
}

  if(!objectValues)
    return <>not set</>
  
  return <>
  <FieldHeaderElem />
  <Grid container spacing={2} style={{padding:'5px 5px'}}>
  
  <Grid item xs={3}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
  <Grid item xs={2}><TickBox id="mandatory"  label="Mandatory" checked={objectValues.mandatory} onChange={(e) => {setValues(e)}}/></Grid>
  
  <Grid item xs={7} ><Dropdown id="fieldType.id" label="Type" optionName="form-field-type" filter={false}   value={objectValues?.fieldType?.id}    onChange={(e) => {setValues(e)}} /></Grid>

  <Grid item xs={12}><TextBox id="name"  label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
  <Grid item xs={12}><TextBox id="helpText" rows={3} label="Help"  value={objectValues.helpText} onChange={(e) => {setValues(e)}} /></Grid>
  <Grid item xs={6} ><Dropdown id="displayType.id" label="Display" optionName="form-field-display-type-list" value={objectValues?.displayType?.id}    onChange={(e) => {setValues(e)}} /></Grid>
  <Grid item xs={6}><TextBox id="opelParameterId"  label="Opel Parameter Id" value={objectValues.opelParameterId} type="number" onChange={(e) => {setValues(e)}} /></Grid>
  </Grid>

  <Grid container spacing={2} style={{padding:'5px 5px'}}>
    <Grid item xs={12}>
    
    <Panel title="Automation">
      <Grid container spacing={2} style={{padding:'5px 5px'}}>
          <Grid item xs={4}>
            <Dropdown id="datasetTypeId" label="Dataset" includeBlankOption={true} optionName="dataset-list" value={objectValues.automationDatasetTypeId} onSelection={(e) => {setObjectValues({...objectValues,automationDatasetTypeId:e.target.value})}}/>
          </Grid>
          <Grid item xs={8}>
            <Dropdown id="automationDatasetMetricTypeId" label="Metric" includeBlankOption={true} optionName="dataset-list" dynamicPopulation={true} value={objectValues.automationDatasetMetricTypeId} parentOptionId={objectValues.automationDatasetTypeId}  onChange={(e) => {setValues(e)}}/>
          </Grid>
      </Grid>
    </Panel>
    
    </Grid>
  </Grid>


  <FieldElem />
  


  <Grid container spacing={2} style={{padding:'5px 5px'}}>
    <Grid item xs={9}>
    
    <Panel title="Calculation Help">
      <Grid container spacing={2} style={{padding:'5px 5px'}}>
      <Grid item xs={3}><TickBox id="hasCalculationHelp"  label="Active" checked={objectValues.hasCalculationHelp} onChange={(e) => {setValues(e)}}/></Grid>
      {objectValues.hasCalculationHelp && <>
      <Grid item xs={12}><TextBox id="numeratorLabel"  label="Numerator Label" value={objectValues.numeratorLabel} onChange={(e) => {setValues(e)}} /></Grid>
      <Grid item xs={12}><TextBox id="denominatorLabel"  label="Denominator Label" value={objectValues.denominatorLabel} onChange={(e) => {setValues(e)}} /></Grid>
      <Grid item xs={12}><TextBox id="resultLabel"  label="Result Label" value={objectValues.resultLabel} onChange={(e) => {setValues(e)}} /></Grid>
      <Grid item xs={12}><TextBox id="negativeResultLabel"  label="Negative Result Label" value={objectValues.negativeResultLabel} onChange={(e) => {setValues(e)}} /></Grid>
      <Grid item xs={12}><TextBox id="formula"  label="Formula" value={objectValues.formula} onChange={(e) => {setValues(e)}} />
      <span>Denominator &#123;Den&#125; Numerator &#123;Num&#125;. e.g. &#123;Num&#125;+&#123;Den&#125;</span></Grid>
      </>
      }
      </Grid>
    </Panel>
    
    </Grid>
  </Grid>

  </>

}

export default FormEditField;




