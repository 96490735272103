import React, {useEffect,useState} from "react"
import { ServiceCategoryEnum, ServiceGroupingResultOptionRangeType, ServiceGroupingType, ServiceType } from "../../../interfaces/system/serviceType"
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import TypeAheadTextbox, { InputOptionType } from "../../core/typeAheadTextbox";
import ActionButton from "../../core/actionButton";
import { getCachedOptions, getFormResultOptionsFromResultId, getOptionsForFormResultId } from "../../../services/domainEntitiesService";
// import {DomainEntitiesContext} from '../../../extensions/context/domainEntities'
import { FormResultOptionType, FormResultTypeEnum } from "../../../interfaces/system/formType";
import BasicTab from "../../core/basicTab";
import Dropdown from "../../core/dropdown";
import useForm from "../../../hooks/useForm";
import Panel from "../../core/panel";

interface ServiceGroupingEditProps {
    service?:ServiceType
    onUpdate: (service: ServiceType) => void 
  }

const ServiceGroupingEdit:  React.FC<ServiceGroupingEditProps> = (props) => {
  const [serviceGroupings, setServiceGroupings] = useState(props?.service?.serviceGrouping||[])
  const [formResultId, setFormResultId] = useState(props?.service?.formResultId)
  const [formResultWeightings, setFormResultWeightings] = useState(props?.service?.formResultWeightings)
  const [formResultOptions, setFormResultOptions] = useState<FormResultOptionType[]>([])
  const [showOpel, setShowOpel]= useState(props?.service?.categoryId && props?.service?.categoryId === ServiceCategoryEnum.Opel)
  const options = getCachedOptions()
  const {
    objectValues, setObjectValues
  }
   = useForm({initialObjectValues: props.service})
  
   useEffect(() => {
      getFormResultDetail()
      
      handleUpdate({target:{id:"formResultId",value:formResultId}})
   },[formResultId])

   useEffect(() => {
    setObjectValues(props.service)
    
    
    setShowOpel(props?.service?.categoryId && props?.service?.categoryId.toString() === ServiceCategoryEnum.Opel.valueOf().toString())

   },[props.service]) 

  const handleUpdate = (e:any) => {

    const ov = {...objectValues,[e.target.id]:e.target.value}
    setObjectValues(ov)
    props.onUpdate(ov)
  }
 
   const addNewServiceGrouping = (resultOptions:FormResultOptionType[]) => {
      const groupings  = serviceGroupings
      const newGrouping: ServiceGroupingType = {serviceId:0, weightings: []}

      resultOptions.forEach(f => {
        newGrouping.weightings.push({formResultOptionId: f.formResultOptionId, weighting: 0})
      });

      groupings.push(newGrouping)

      setServiceGroupings([...groupings])
   }
   const handleRemoveService = (serviceIndex:number) => {
    const sg = serviceGroupings
    sg.splice(serviceIndex,1)
    setServiceGroupings([...sg])
    objectValues.serviceGrouping = sg
    setObjectValues({...objectValues})
   }
   const getFormResultDetail = () => {
    if(!formResultId ||showOpel) return
    

    let resultOptions = getFormResultOptionsFromResultId(options.formResultOptions, formResultId, objectValues.formId)

    if(!resultOptions || resultOptions.length<1){
      resultOptions = getOptionsForFormResultId(options.formResultOptions, formResultId)
      
    }
    
    
    resultOptions = resultOptions.filter(f=> f.formResultTypeId.toString() === (showOpel ? FormResultTypeEnum.Opel.valueOf().toString() : 
                                                                                            FormResultTypeEnum.Standard.valueOf().toString()) )
    setFormResultOptions(resultOptions)

    
    

    if(serviceGroupings.length===0)
      addNewServiceGrouping(resultOptions)
    else{
      
      serviceGroupings.forEach(f=>{
        
        if(!f.weightings || f.weightings.length<1){
          resultOptions.forEach(ff => {
            f.weightings.push({formResultOptionId: ff.formResultOptionId, weighting: 0})
          });
        }
          
      })


    }
    const weightings = formResultWeightings||[]

    resultOptions.forEach(f => {
      weightings.push({formResultOptionId: f.formResultOptionId, weightingFrom: f.weightingFrom, weightingTo: f.weightingTo})
    });
    
    setFormResultWeightings(weightings)

   }
   const handleServiceSelected = (selectedValue:InputOptionType, serviceIndex:number) => {
      const sg = serviceGroupings
      sg[serviceIndex].serviceId = selectedValue.id
      setServiceGroupings([...sg])

      const ov = {...objectValues}
      ov.serviceGrouping = sg
      props.onUpdate(ov)

    }
   const handleWeightingUpdated = (weightingValue:number, resultOptionId:number, serviceId:number) => {
    const sg = serviceGroupings
    const serviceIndex = sg.findIndex(f=>f.serviceId === serviceId)
    if(serviceIndex <0) return

    const weightingIndex = sg[serviceIndex].weightings.findIndex(f=> f.formResultOptionId === resultOptionId)
    if(weightingIndex <0) return

    sg[serviceIndex].weightings[weightingIndex].weighting = weightingValue
    
    setServiceGroupings([...sg])
    objectValues.serviceGrouping = sg
    const ov = {...objectValues}
    setObjectValues(ov)
    props.onUpdate(ov)
   }

   const handleOpelWeightingUpdated = (weightingValue:number, serviceId:number) => {
    const sg = serviceGroupings
    const serviceIndex = sg.findIndex(f=>f.serviceId === serviceId)
    if(serviceIndex <0) return

    sg[serviceIndex].opelWeighting = weightingValue
    
    setServiceGroupings([...sg])
    objectValues.serviceGrouping = sg
    const ov = {...objectValues}
    setObjectValues(ov)
    props.onUpdate(ov)
   }

   const handleFormResultWeightingUpdated = (weightingFrom?:number, weightingTo?:number,formResultOptionId?:number) =>{
    let formOptions = formResultWeightings

    if(!formOptions) return
    
    //delete dupes
    const fo:  ServiceGroupingResultOptionRangeType[]= []
    formOptions.forEach(f=>{
      if(fo.findIndex(fi=> fi.formResultOptionId=== f.formResultOptionId)<0) fo.push(f);
    })
    formOptions = fo
    const formOption = formOptions.find(f=> f.formResultOptionId === formResultOptionId)
    if(!formOption) return

    if(weightingFrom!==undefined)
      formOption.weightingFrom = weightingFrom
    else 
      formOption.weightingTo = weightingTo ??formOption.weightingTo
    
      setFormResultWeightings([...formOptions])
      
      objectValues.formResultWeightings = formOptions
      const ov = {...objectValues,formResultWeightings:formOptions}
    
      setObjectValues(ov)
    
      props.onUpdate(ov)

   }

    const serviceDetail = <Grid container spacing={4} justifyContent = "center" style={{padding:'15px 5px'}}>
      <Grid item xs={10} >
      <Panel title={`${showOpel ? 'Opel' : 'Combined Escalation'} Calculation`}>
              <Grid container spacing={2}  style={{padding:'15px 5px'}}>
                <Grid item xs={6} ><Dropdown id="formResultId" label="Form Result" optionName="form-result-list" includeBlankOption={true}  value={objectValues?.formResultId} onSelection={(e) => {setFormResultId(e.target.value as number)}} /></Grid>
                <Grid item xs={4} ></Grid>
                {(!showOpel) && <>
                  <Grid item xs={6} lg={3} ><TextBox id="cutOffAM"  label="Cut Off AM"  maxLength={6} type="time"  value={objectValues?.cutOffAM} onChange={(e:any) => {handleUpdate({target:{id:"cutOffAM",value:e.value}})}} /></Grid>
                  <Grid item xs={6} lg={3}><TextBox id="cutOffPM"  label="Cut Off PM"  maxLength={6} type="time" value={objectValues?.cutOffPM} onChange={(e:any) => {handleUpdate({target:{id:"cutOffPM",value:e.value}})}}/></Grid>
                </>}
              </Grid>
        </Panel>
      </Grid>
       
      {showOpel &&
        <Grid item xs={10} >
          <Panel title={'Distribution Lists'}>
                <Grid item xs={8}>
                  <Dropdown id="opelDistributionListId" label="Opel Distribution List" optionName="distribution-list" includeBlankOption={true}  value={objectValues?.opelDistributionListId} onChange={(e) => {handleUpdate(e)}} />
                </Grid>
          </Panel>
      </Grid> 
      }
      {!showOpel &&
        <Grid item xs={10}>
          <Panel title={`${showOpel ? 'Opel' : 'Combined Escalation'} Weightings`}>
                <Grid item xs={8} >
                {formResultOptions.map((r) => (
                     <Grid container spacing={1}  style={{padding:'5px 5px'}}>
                     <Grid item xs={5}><span>{r.name}</span></Grid>
                     <Grid item xs={3}><TextBox id="d"  label="Weighting From"  maxLength={6} type="number" value={formResultWeightings?.find(f=>f.formResultOptionId=== r.formResultOptionId)?.weightingFrom} onChange={(e) => {handleFormResultWeightingUpdated(parseFloat(e.target.value),undefined, r.formResultOptionId)}} /></Grid>
                     <Grid item xs={3}><TextBox id="d"  label="Weighting To"    maxLength={6} type="number" value={formResultWeightings?.find(f=>f.formResultOptionId=== r.formResultOptionId)?.weightingTo} onChange={(e) => {handleFormResultWeightingUpdated(undefined,parseFloat(e.target.value), r.formResultOptionId)}} /></Grid>
                 </Grid>
                ))}
                </Grid>
        </Panel>
      </Grid> 
      }

    </Grid>

    const groupedServices = <Grid container spacing={1}  style={{padding:'15px 5px'}}>
      {serviceGroupings.map((m, serviceIndex) => (
        <Grid container spacing={2}  style={{padding:'5px 5px'}}>
          {!showOpel && <>
            <Grid item xs={5}><TypeAheadTextbox id="serviceId" optionName="service-list" value={m.serviceId}  label="Service" onChange={(e) => {handleServiceSelected(e,serviceIndex)}} /></Grid>
              
              {m.weightings.map((r,index) => (<>
                  <Grid item xs={1}><TextBox id="id" value={r.weighting}  label={`Level ${index+1}`} maxLength={6} type="number" onChange={(e)=> {handleWeightingUpdated(parseFloat(e.target.value), r.formResultOptionId, m.serviceId??-1)}} disabled={(m.serviceId??-1)<1}/></Grid>
                </>
              ))}
          </>}
          {showOpel && <>
            <Grid item xs={5}><TypeAheadTextbox id="serviceId" optionName="service-list" value={m.serviceId}  label="Service" onChange={(e) => {handleServiceSelected(e,serviceIndex)}} /></Grid>

              <Grid item xs={2}><TextBox id="opelWeighting" value={m.opelWeighting} label={'Contribution %'} maxLength={6} type="number"  onChange={(e)=> {handleOpelWeightingUpdated(parseFloat(e.target.value), m.serviceId??-1)}}  disabled={m.serviceId ===0}/></Grid>
          </>}
          <Grid item xs={2}><ActionButton severity="danger" variant="text" label="Remove" onClick={()=>handleRemoveService(serviceIndex)}/></Grid>
        </Grid>
    ))}
      <Grid item xs={2}><ActionButton label="Add" variant="icon" visible={(formResultId||0)>0} icon="pi pi-plus" onClick={()=>{addNewServiceGrouping(formResultOptions)}}/></Grid>
    </Grid>

 
  return <>
  <Grid container>
  <BasicTab key='serv_' tabs={[{label:'Escalation Details', component: <>{serviceDetail}</> },
                    {label:'Services', component: <>{groupedServices}</>},
                    ]}>
              
              
        </BasicTab>    
  
  </Grid>
  </>

}

export default ServiceGroupingEdit;