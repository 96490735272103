import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FeedbackType } from "../components/core/feedback";
import useForm from "../hooks/useForm";
import { ServiceType } from "../interfaces/system/serviceType";
import { updatePageTitle } from "../services/utils";
import CapacityGridSubmissionEditor from "../components/capacity-grid/capacity-grid-submission-editor";
import { CapacityGridSubmissionDataType, CapacityGridType } from "../interfaces/system/capacityGridType";
import { fetchCapacityGridSubmissionList } from "../services/capacity-grid-service";
import Page from "../components/core/page";
import { Grid } from "@mui/material";
import Panel from "../components/core/panel";

export default function CapacityGridContainer() {

    // component vars
    let { serviceId } = useParams() || 0;
    let areaId = 0;
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('areaId')) {
        areaId = parseInt(queryParams.get('areaId') ?? '');
    }

    // state vars
    const [capacityGrids, setCapacityGrids] = useState<CapacityGridType[]>();
    const { feedbackStatus, setFeedbackStatus } = useForm({ initialObjectValues: {} })
    const [errorMessage, setErrorMessage] = useState<string>();
    const [noService, setNoService] = useState(false);

    // hooks
    useEffect(() => {
        updatePageTitle("Capacity Grid")
        loadCapacityGrids();
        
    }, [])

    const loadCapacityGrids = async () => {
        handleLoading();
        // load all capacity grids this user has access to, this gives us the required structure/columns the grid will need to present/submit
        const response = await fetchCapacityGridSubmissionList();
        handleLoaded();
        // now we have grids for each division now get the last submitted data
        
        if (response && response.success && response.result) {
            setCapacityGrids(response.result);
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setNoService(true)
        // setFeedbackStatus(FeedbackType.Error)
        // setErrorMessage(response.error)
    }

    const handleRowSaved = (rowData: CapacityGridSubmissionDataType, capacityGrid: CapacityGridType, division: ServiceType) => {
        setErrorMessage(`'${capacityGrid.name}' data for '${division.name}' has been updated`);
        setFeedbackStatus(FeedbackType.SaveSuccess)
    }

    const handleMultipleRowsSaved = (message: string) => {
        setErrorMessage(message);
        setFeedbackStatus(FeedbackType.SaveSuccess)
    }

    const handleRowError = (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => {
        //console.error("An error happened!");
        //console.error(errorMessage);
        setErrorMessage(errorMessage || 'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleLoading = () => {
        setFeedbackStatus(FeedbackType.Loading);
        setNoService(false)
    }
    const handleLoaded = () => {
        setFeedbackStatus(FeedbackType.hide);
    }
    const handleError = (message: string) => {
        setErrorMessage(message);
        setFeedbackStatus(FeedbackType.Error);
    }

    // component markup
    return <>
        <Page title="Capacity Grid Input" errorMessage={errorMessage} feedbackStatus={feedbackStatus}>
            {capacityGrids && (capacityGrids?.length ?? 0) > 0 && <>
                <CapacityGridSubmissionEditor onMessageChange={handleMultipleRowsSaved} AreaId={areaId} ServiceId={parseInt(serviceId ?? "0")} CapacityGrids={capacityGrids} onSaved={handleRowSaved} onRowError={handleRowError} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} />
            </>}
            {noService && <>
                <Grid container justifyContent="center">
                    <Grid item xs={7}>
                        <Panel title="Service Permissions" ><p>This service does not exist or you do not have persmissions to submit. </p></Panel>
                    </Grid>
                </Grid>
            </>}

        </Page>
    </>
}

