import { FormSubmissionType } from "./formType"

export interface ActionCardType {
    id: number,
    createdOn: Date,
    serviceId?: number,
    formId?: number,
    name?: string,
    sortOrder: number,
    deleted: boolean
    recipients?: ActionCardRecipientType[]
}
export interface ActionCardRecipientType {
    id: number,
    actionCardId: number,
    distributionListId: number,
    name: string,
    description: string,
    sortOrder: number,
    deleted: boolean
    events?: ActionCardEventType[]
}
export interface ActionCardEventType {
    id: number,
    actionCardRecipientId: number,
    resultTypeId: number,
    formFieldId: number,
    formResultId: number,
    reportingLevelOptionId: number,
    actionText: string,
    sortOrder: number,
    deleted: boolean,
    name: string
}

export interface ActionCardServiceIdCheckResponseType {
    exists: boolean,
    actionCardId: number
}


export interface ActionCardHomepageOverviewType {
    actionCard: ActionCardType,
    submissionData:FormSubmissionType
}
export enum ActionCardEventTypes {
    Escalation = 1,
    FormField = 2
}

export function createEmptyActionCard(): ActionCardType {
    return { id: 0, createdOn: new Date(), serviceId: 0, sortOrder: 0, deleted: false, recipients: [] };
}
export function createEmptyActionCardRecipient(actionCardId: number): ActionCardRecipientType {
    return { id: 0, actionCardId: actionCardId, name: '', description: '', distributionListId: 0, sortOrder: 99, deleted: false, events: [] };
}
export function createEmptyActionCardEvent(recipientId: number, resultTypeId: number): ActionCardEventType {
    return { id: 0, actionCardRecipientId: recipientId, name: '', actionText: '', formFieldId: 0, formResultId: 0, reportingLevelOptionId: 0, resultTypeId: resultTypeId, sortOrder: 0, deleted: false };
}