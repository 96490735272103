import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ActionButton from "../../../core/actionButton";
import Dropdown, { IDropdownOptions } from "../../../core/dropdown";
import { DomainEntityType } from "../../../../interfaces/domain/iDomainEntity";
import { fetchDistributionListList } from "../../../../services/domainEntitiesService";



export interface DistributionListMemberAddToListProps {
    existingDistListIds?: number[],
    onError: (message: string) => void,
    onAddToList?: (list: DomainEntityType) => void,
    onLoading?: () => void,
    onLoaded?: () => void
}
export function DistributionListMemberAddToList(props: DistributionListMemberAddToListProps) {
    // const [listsToAddTo, setListsToAddTo] = useState<DomainEntityType[]>();
    const [availableDistLists, setAvailableDistLists] = useState<DomainEntityType[]>();
    const [existingDistListIds] = useState(props.existingDistListIds ?? []);
    const [selectedListId, setSelectedListId] = useState(0);


    // hooks
    useEffect(() => {
        init();
    }, []);


    const init = async () => {
        handleLoading();
        const distLists = await fetchDistributionListList();
        handleLoaded();
        if (distLists && distLists.length > 0) {
            setAvailableDistLists(distLists);
        } else {
            handleError('Unknown error while loading available distribution list');
        }
    }

    const filterAvailableDistLists = (): IDropdownOptions[] => {
        let lists: IDropdownOptions[] = [];
        if (availableDistLists) {
            availableDistLists.forEach(d => {
                const exists = existingDistListIds.find(x => x === d.id);
                if (!exists) {
                    lists.push({ id: d.id, name: d.name, code: '' });
                }
            });
        }

        return lists;
    }

    const handleListSelected = (e: any) => {
        if (e.target.value && parseInt(e.target.value) > 0) {
            setSelectedListId(parseInt(e.target.value));
            return;
        }
        setSelectedListId(0);
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }
    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleAddUserToList = () => {
        if (selectedListId > 0 && availableDistLists) {
            const selectedItem = availableDistLists.find(dl => dl.id === selectedListId);
            if (selectedItem) {
                const reducedList: DomainEntityType[] = [];
                availableDistLists.forEach(dl => {
                    if (dl.id !== selectedListId) {
                        reducedList.push(dl);
                    }
                });
                setAvailableDistLists(reducedList);
                if (props.onAddToList && selectedItem) {
                    props.onAddToList(selectedItem);
                }
            }
        }
    }

    return <>
        <Grid item xs={6} sm={4} >
            <Dropdown id="capacityGridDistributionList" label="Distribution List" options={filterAvailableDistLists()} includeBlankOption={true} onChange={handleListSelected} />
        </Grid>

        <Grid item xs={6} sm={4} >
            <ActionButton onClick={handleAddUserToList} label="Add" severity="secondary"></ActionButton>
        </Grid>
    </>
}