import React from "react";
//import Alert from '@material-ui/lab/Alert';
import Loading from './loading'
import FeedBackText from './feedbackText'

type Color = 'success' | 'info' | 'warning' | 'error'

export enum FeedbackType {
    hide,SaveSuccess,SearchFail,SaveFail,MandatoryFields, Loading, NoAccess, Error,LoadFail
}

interface FeedbackParams {
    message: string|undefined
    type:FeedbackType
    useBackdrop?:boolean
}


  const Feedback:  React.FC<FeedbackParams> = (params) => {
    if (params.type === FeedbackType.Loading){
        return <Loading loading={true} useBackdrop={params.useBackdrop}></Loading>
    }

    let severity:Color  = 'success'
    let message= params.message

    switch(params.type){
        case FeedbackType.SaveFail:
            severity = 'error'
            message = message ? message : 'Save failed'
            break
        case FeedbackType.MandatoryFields:
            severity = 'error'
            message = message ? message : 'Mandatory fields required'
            break
        case FeedbackType.SaveSuccess:
            severity = 'success'
            message = message ? message : 'Successfully saved'
            break
        case FeedbackType.SearchFail:
            severity = 'error'
            message = message ? message : 'Failed to load search results'
            break
        case FeedbackType.LoadFail:
            severity = 'error'
            message = message ? message : 'Error loading result'
            break
        case FeedbackType.NoAccess:
            severity = 'error'
            message = message ? message : 'You do not have access to the selected record'
            break
        case FeedbackType.Error:
            severity = 'error'
            message = message ? message : 'Error'
            break
        default:
            message=''
    }

    if(message){
        return <FeedBackText show={true} severity={severity} message={message}></FeedBackText>
    }
    return <></>
}

export default Feedback