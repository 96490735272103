import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { UserRoleType } from '../interfaces/user/iUser';

import {ValidatorType} from './ValidatorType'

export class UserRoleValidator implements ValidatorType
{
   formValidator = new FormValidation([])
    name= new FormValidationField('','')
    permissions= new FormValidationField('','')

     initialiseValidationFields = (objectValues: UserRoleType) => {
        this.formValidator = new FormValidation([])
        this.name= this.formValidator.createTextField(objectValues.name)

        if(objectValues.permissions)
            this.permissions = this.formValidator.createMultiSelectField(objectValues.permissions.map((m)=> m.permissionTypeId),'You must select at least one permission')
        else if(objectValues.permissionTypeIds)
        this.permissions = this.formValidator.createMultiSelectField(objectValues.permissionTypeIds,'You must select at least one permission')

    }
    
 validate =  (validatorObjectValues: UserRoleType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid 
 }
}

export default UserRoleValidator

