import React, {useEffect,useState} from "react"
import { ServiceType } from "../../../interfaces/system/serviceType"
import ActionButton from "../../core/actionButton";
import useForm from "../../../hooks/useForm";
import Panel from "../../core/panel";
import SortableList from "../../core/sortableList";

interface ServiceDivisionListProps {
  service?:ServiceType
    selectedId?:number
    onSelect: (service:ServiceType) => void
    onNew: () => void
  }

const ServiceDivisionList:  React.FC<ServiceDivisionListProps> = (props) => {
  
  const [selectedId,setSelectedId] = useState(props.selectedId)
  const [errorIndexes,setErrorIndexes] = useState<number[]>([])
  const {
    objectValues, setObjectValues
  }
   = useForm({initialObjectValues: props.service})

   useEffect(() => {
    setObjectValues(props.service)
    validateDivisions()
    
   },[props.service]) 

   const handleSelected = (f:ServiceType) => {
    setSelectedId(f.id)
    props.onSelect(f)
   }
  const handleReorderComplete = (orderedList: ServiceType[]) => {
    setObjectValues({
      ...objectValues,
      divisions: orderedList
    })
    validateDivisions()
  }
  
  const validateDivisions = ()=>{
    const i:number[] = []
    if(props.service?.divisions){
      props.service.divisions.forEach((f,index)=>{
        if(f?.validator){
            if(!f.validator.validate(f))
              i.push(index)
        }
    })

    }

    setErrorIndexes([...i])
  }
 
    const NewButtonElem = () => {return <ActionButton severity="success" label="New Division" onClick={props.onNew}></ActionButton>}
   
    if(!objectValues?.divisions || objectValues?.divisions.length<=0)
      return <NewButtonElem />

      
return <>
  <Panel title="Division List">
    <SortableList data={objectValues?.divisions || undefined} onItemSelection={handleSelected} onReOrder={handleReorderComplete}
    errorIndexes={errorIndexes}
    />
      
      <br />
      <br />
      <NewButtonElem />
    </Panel>
  </>

}

export default ServiceDivisionList;