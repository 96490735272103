import React,{useState,useEffect} from "react";
import {Grid} from '@mui/material'
import { RadioButton } from 'primereact/radiobutton';

import {FormFieldDisplayTypeEnum, FormFieldOptionType, FormFieldTypeEnum, FormSubmissionFieldType} from "../../interfaces/system/formType"
import DateTimePicker from "../core/dateTimePicker";
import TextBox from "../core/textBox";
import Dropdown from "../core/dropdown";
import questionIcon from '../../assets/images/icons/question-mark.svg'
import energyIcon from '../../assets/images/icons/energy.svg'
import calculatorIcon from '../../assets/images/icons/calculator.svg'
import Panel from "../core/panel";
import ActionButton from "../core/actionButton";
import LabelText from "../core/labelText";
import { DemandAndCapacityMetrics, getDatasetLastUpdated, getMetricTextFromId, getMetricValueFromId } from "../../interfaces/reporting/demandAndCapacity";
import { formatDateTimeFromJson, formatDateTime, formatDateFromJsonToDate } from "../../extensions/formatter";
import SimpleDialog from "../core/simpleDialog";
import { dateDiffFromNowInMinutes } from "../../services/utils";


interface FormSubmissionFieldProps {
    field?:FormSubmissionFieldType
    demandAndCapacityMetrics?:DemandAndCapacityMetrics
    index:number
    fieldNumber:number
    showDynamicField?:boolean
    hasError?:boolean
    readOnly?:boolean
    onUpdated:(index:number, field:FormSubmissionFieldType) => void
  }
  
const FormSubmissionField:  React.FC<FormSubmissionFieldProps> = (props) => {
  useEffect(() => {
    if(props.field){
      setField(props.field)
      
             setShowCalcHelp(props.field?.field?.hasCalculationHelp??false)
          
      
    }
  }
  ,[props.field])
  useEffect(() => {
      if(props.field?.field?.automationDatasetMetricTypeId && props.demandAndCapacityMetrics){
        handleAutomatedField()
      }

  }
  ,[props.field,props.demandAndCapacityMetrics,props.readOnly])

  useEffect(() => {
    setShowDynamicField(props.showDynamicField)
  }
  ,[props.showDynamicField])

  useEffect(() => {
    setError(props.hasError)
  }
  ,[props.hasError])


    const [error,setError]  = useState(props.hasError)
    const [showDynamicField,setShowDynamicField]  = useState(props.showDynamicField)
    const [showHelpText,setShowHelpText]  = useState(false)
    const [showCalcHelp,setShowCalcHelp]  = useState(false)
    const [isAutomated,setIsAutomated]  = useState(false)
    const [automatedFieldLabel,setAutomatedFieldLabel]  = useState('')
    const [automatedFieldValue,setAutomatedFieldValue]  = useState('')
    const [automatedDataLastUpdated,setAutomatedDataLastUpdated]  = useState('')
    const [field,setField]  = useState<FormSubmissionFieldType>()
    const [selectedId, setSelectedId] = useState<number>();
    const [automatedSelectionOverriden, setAutomatedSelectionOverriden] = useState(false);
    const [showAutoCompleteDialog, setShowAutoCompleteDialog] = useState(false);
    const [selectedOption, setSelectedOption]= useState<FormFieldOptionType>();

  const handleListFieldUpdated = (fo?:FormFieldOptionType,override?:boolean) => {
    
    
    if(!field) return

    if(override === undefined) override = automatedSelectionOverriden
    if(isAutomated && !showAutoCompleteDialog && !automatedSelectionOverriden) {
      setShowAutoCompleteDialog(true)
      setSelectedOption(fo)
      return
    }

    setSelectedId(fo?.id ||0)

    field.isValid =true
    field.fieldOptionId = fo?.id||0
    field.score = fo?.weighting||0
    field.opelScore = fo?.opelWeighting||0
    props.onUpdated(props.index, field)
  }
  const handleDropDownFieldUpdated = (e:any) => {
    const f = field?.field?.options.find(f=> f.id === e.target.value)
      handleListFieldUpdated(f)
  }
  const handleTextFieldUpdated = (e:any) => {
    
    if(!field) return
    const value = e.target.value
    field.isValid =value !== undefined && value!== ''
    field.value = value
    props.onUpdated(props.index, field)
  }
  const handleAutomatedField= async () => {
    if(!props.demandAndCapacityMetrics) return 'no data'
    


    if(!field?.field?.automationDatasetTypeId ||
      !field?.field?.automationDatasetMetricTypeId) return ''


    const lastUpdated = await getDatasetLastUpdated(field?.field?.automationDatasetTypeId, 
        props.demandAndCapacityMetrics)

    if(!lastUpdated) return ''

    const lastUpdatedDate =formatDateTimeFromJson(lastUpdated)
    const formattedDate = formatDateFromJsonToDate(lastUpdatedDate)
    if(!formattedDate) return ''
    const dataAged = dateDiffFromNowInMinutes(formattedDate)
    if(!dataAged || dataAged > 90 ) return ''

    const metricLabel = await getMetricTextFromId(field?.field?.automationDatasetTypeId, 
                          field?.field?.automationDatasetMetricTypeId)
    setAutomatedFieldLabel(metricLabel||'not supported')
    const metricValue = await getMetricValueFromId(field?.field?.automationDatasetTypeId, 
      field?.field?.automationDatasetMetricTypeId,
      props.demandAndCapacityMetrics)

      setAutomatedFieldValue(metricValue?metricValue.toString():'none')
      if(!field?.field?.options || !metricValue) return
      const selectedOptionId = field?.field?.options.find(f=> (metricValue as number)>= (f.automationMetricRangeFrom||0) && 
            (metricValue as number)<=(f.automationMetricRangeTo||0))
      if(selectedOptionId){
        setSelectedId(selectedOptionId.id)
      }

      
      setAutomatedDataLastUpdated(formatDateTime(lastUpdated))
      
      if(metricValue && lastUpdated)
        setIsAutomated(true)
      
  }

  const handleCalcHelpUpdated = (e:any) => {
    if(!field) return
    var f = field as any
    if(e)
      f[e.target.id] = e.target.value
    const ff = f as FormSubmissionFieldType
    
    let formula = field.field?.formula
    if(!formula || !ff.denominator || !ff.numerator) return

    
    formula = formula.replaceAll('{den}', ff.denominator.toString())
      .replaceAll('{num}', ff.numerator.toString())

      let  calcResult =       0.0
      try{
        calcResult = eval(formula)
      }
      catch{}

      if(isNaN(calcResult)) calcResult=0
      else calcResult = parseFloat(parseFloat(calcResult.toString()).toFixed(2))

      ff.calculationHelpResult = calcResult
      setField({...ff})

      //now select the option based on result
      if(isNaN(calcResult)) return

      field?.field?.options.forEach(f=>{
        const calcHelpFrom = parseFloat(f.calculationHelpRangeFrom.toString())
        const calcHelpTo = parseFloat(f.calculationHelpRangeTo.toString())
        if(calcHelpFrom>=0 && ff.calculationHelpResult)
          if(calcHelpFrom<= ff.calculationHelpResult && (calcHelpTo>=ff.calculationHelpResult || calcHelpTo> f.calculationHelpRangeTo )){
            handleListFieldUpdated  (f)
            return
          }

        if(calcHelpFrom<0 && ff.calculationHelpResult)
          if((calcHelpFrom<= ff.calculationHelpResult  ||ff.calculationHelpResult< calcHelpFrom) && (calcHelpTo>=ff.calculationHelpResult )){
            handleListFieldUpdated  (f)
            return
          }
      })

  }
  const handleToggleCalcHelp = () =>{
    const show = !showCalcHelp
    if(field?.field?.hasCalculationHelp)
      setShowCalcHelp(show)
  }
  const handleToggleHelpText = () =>{
    const show = !showHelpText
    setShowHelpText(show)
  }

  const handleAutomatedFieldCancelled = () =>
  {
    setShowAutoCompleteDialog(false)
  }
  const handleAutomatedFieldConfirm = () =>
  {
    setShowAutoCompleteDialog(false)
    setAutomatedSelectionOverriden(true)
    handleListFieldUpdated(selectedOption,true)
    setIsAutomated(false)
  }
    const options:any = []

    if(field?.field?.displayType?.id !== FormFieldDisplayTypeEnum.Visible && !showDynamicField)
      return <></>

    if(field?.field?.fieldType.id === FormFieldTypeEnum.Date)
      return <div className={`highlight form-field-container ${(error!==undefined && error && field?.field?.mandatory) ? 'error-box':''}`}>
      <div className="title">{props.fieldNumber}. {field?.field?.name}</div>
          <div style={{width:'40%'}}>
            <DateTimePicker value={field.value}/>
          </div>
                    
              </div>

        if(field?.field?.fieldType.id === FormFieldTypeEnum.Text||
          field?.field?.fieldType.id === FormFieldTypeEnum.Email||
          field?.field?.fieldType.id === FormFieldTypeEnum.Numeric||
          field?.field?.fieldType.id === FormFieldTypeEnum.TextBlock){
            const type = field?.field.fieldType.id === FormFieldTypeEnum.Email ? 'email' :
            field?.field.fieldType.id === FormFieldTypeEnum.Numeric ? 'number':undefined
    const rows = field?.field.fieldType.id === FormFieldTypeEnum.TextBlock ? 4:undefined
        return <div className={`highlight form-field-container ${(error!==undefined && error && field?.field?.mandatory) ? 'error-box':''}`}>
          <div className="title" style={{marginBottom:3}}  >{props.fieldNumber}. {field?.field?.name}
            {field?.field?.helpText && <span className="help-icon" onClick={handleToggleHelpText}><img src={questionIcon}/>
            </span>}
            {showHelpText && <div className="help-text">
                <span dangerouslySetInnerHTML={{
                                    __html: field?.field?.helpText
                                }} />
            </div>}
        </div>
                  <div style={{width:'50%'}}>
                  <TextBox  value={field.value} label={""} type={type} rows={rows} onChange={handleTextFieldUpdated}/>
                  </div>
            </div>
        }

      if(field?.field?.fieldType.id === FormFieldTypeEnum.Dropdown){
        
        field?.field?.options.forEach(f => {
          options.push({id:f.id, code:'',name:f.name })
        });
        return <div className={`highlight form-field-container ${(error!==undefined && error && field?.field?.mandatory) ? 'error-box':''}`}>
        <div className="title" style={{marginBottom:3}}  >{props.fieldNumber}. {field?.field?.name}
            {field?.field?.helpText && <span className="help-icon" onClick={handleToggleHelpText}><img src={questionIcon}/>
            </span>}
            {showHelpText && <div className="help-text">
                <span dangerouslySetInnerHTML={{
                                    __html: field?.field?.helpText
                                }} />
            </div>}
        </div>
        
            <div style={{width:'40%'}}>
              <Dropdown label="" includeBlankOption={true} options={options} value={selectedId} onSelection={handleDropDownFieldUpdated}/>
            </div>
          </div>
      }
      return <div className={`${!isAutomated ? "highlight" : "automated-field"} form-field-container ${(error!==undefined && error && field?.field?.mandatory) ? 'error-box':''}`}><a id={`field_${props.index}`} />
      <SimpleDialog yesno={true} show={showAutoCompleteDialog}   fullScreen={false} noCaption="Cancel" title="Confirm Override" defaultValue={''} onOk={handleAutomatedFieldConfirm} onClose={handleAutomatedFieldCancelled} >
        <p>Are you sure you want to override the automated selection?</p>
      </SimpleDialog>
        {showHelpText && <div className="help-text">
            <span>{field?.field?.helpText}</span>
        </div>}
        
      <div className="title">
        <span>{props.fieldNumber}{field?.field?.displayType?.id=== FormFieldDisplayTypeEnum.Visible?'':'a'}. {field?.field?.name}</span>
          {field?.field?.helpText && <div className="help-icon"  onClick={handleToggleHelpText}><img src={questionIcon}/></div>}
          {field?.field?.hasCalculationHelp && <div className="help-icon"  onClick={handleToggleCalcHelp}><img src={calculatorIcon}/></div>}
          {isAutomated && <div className="help-icon"><img src={energyIcon} title='Automated field'/></div>}
      </div>
      <Grid container>
          <Grid xs={7}>
          {field?.field?.options.map((o:FormFieldOptionType) => (
              <Grid xs={12}  >
                <Grid className="highlight-dashed">
                  <label className="label">
                    <RadioButton value={o.id} id={`field${props.index}`} name={`field${props.index}`} checked={selectedId===o.id} onChange={()=>handleListFieldUpdated(o)} />
                    <span style={{marginLeft:10}}>{o.name}</span>
                  </label>
                </Grid>
              </Grid>
        ))}
      </Grid>
      <Grid xs={5} style={{marginTop:-30,display:isAutomated?'' :'none'}}>
          <Panel title="Automated Field">
            <Grid container spacing={1}>
              <Grid item xs={9} >
                <LabelText label={automatedFieldLabel}  text={automatedFieldValue}  />
              </Grid>
              <Grid item xs={9} >
                <LabelText label="Data Last Updated"  text={automatedDataLastUpdated}  />
              </Grid>
            </Grid>
          </Panel>
        
        </Grid>
        
        <Grid xs={5} style={{marginTop:-30,display:showCalcHelp?'':'none'}}>
          <Panel title="Calculation Help">
                      <Grid container spacing={1} columnSpacing={2}>
              <Grid item xs={9} >
                <label>{field?.field?.numeratorLabel}</label>
              </Grid>
              <Grid item xs={3} >
                <TextBox id="numerator" value="" label="" type="number" onChange={handleCalcHelpUpdated}/>
              </Grid>
              <Grid item xs={9} >
                <label>{field?.field?.denominatorLabel}</label>
              </Grid>
              <Grid item xs={3} >
                <TextBox id="denominator" value="" label="" type="number" onChange={handleCalcHelpUpdated}/>
              </Grid>
              <Grid item xs={9} >
                <label>{field?.field?.resultLabel}</label>
              </Grid>
              <Grid item xs={3} >
                            <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-percentage" />
                                <TextBox value={field?.calculationHelpResult} label="" disabled={true} type="number"/>
                        </span>
              </Grid>
              <Grid item xs={9} >
              </Grid>
              <Grid item xs={3} >
                  <ActionButton severity="info" label="Select" onClick={handleCalcHelpUpdated}/>
              </Grid>
            </Grid>
          </Panel>
        
        </Grid>
      </Grid>
      <hr />
    </div>;
}


export default FormSubmissionField;

