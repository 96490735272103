import React, { useState, useEffect } from "react";
import {AddressType} from '../../interfaces/system/iAddress';
import {Grid, InputLabel} from '@mui/material';
import TextBox from '../core/textBox'

import AddressDetailsFormValidator from '../../validators/address'
import ActionButton from "../core/actionButton";
import { fetchLngLatFromPostcode } from "../../services/addressService";
export interface IAddressDetailsParams {
    address: AddressType
    label?: string
    disabled?:boolean | false
    toggle?:boolean|false
    formValidator?: AddressDetailsFormValidator
    addressToCopy?:AddressType
    onChange?: (addressDetails: AddressType) => void
  }
const AddressEditDetails:  React.FC<IAddressDetailsParams> = (params) => {
  const [addressObject, setAddressObject] = useState<AddressType>(params.address)
  const [fullAddress, setFullAddress] = useState('')
  const [formValidator, setFormValidator] = useState(params.formValidator)

  useEffect(() => {
    constructFullAddress()
},[]);

  useEffect(() => {
    if(params.onChange){
      params.onChange(addressObject);
    }
},[addressObject]);

useEffect(() => {
  if(params.address?.id !== addressObject?.id)
    setAddressObject(params.address)
},[params.address]);



useEffect(() => {
  setFormValidator(params.formValidator)
},[params.formValidator]);
  
  const updateForm = (e: any) => {
    setAddressObject({
       ...addressObject,
       [e.target.id]: e.target.value
    })
 }
 const constructFullAddress = () => {
  setFullAddress( `${addressObject?.addressLine1 ? addressObject.addressLine1 + '\n' : ''}${addressObject?.addressLine2 ? addressObject.addressLine2 + '\n' : ''}${addressObject?.town ? addressObject.town + '\n':''}${addressObject?.county ? addressObject.county  + '\n': ''}${addressObject?.postcode ? addressObject.postcode : ''}`
  )
}
const updateLongitude = (v:number) =>{
  const l = ((addressObject.longitude ||0) as number);
  updateForm({target:{id:'longitude', value:parseFloat((l+v).toString().substring(0,6))}})
}
const updateLatitude = (v:number) =>{
  const l = ((addressObject.latitude ||0) as number);
  updateForm({target:{id:'latitude', value:parseFloat((l+v).toString().substring(0,6))}})
}
const handleSearchPostcode = () =>{
    fetchLngLatFromPostcode(addressObject.postcode).then((r)=>{
    
    if(!r.success || !r.result) return
    
    if(r.result.length===0 && formValidator){
      formValidator.postcode.isValid = false;
      formValidator.postcode.errorMessage = "Invalid Postcode"
      setFormValidator({...formValidator})
      return
    }
    const l = r.result[0]
    setAddressObject({
      ...addressObject,
      longitude: parseFloat(l.lon.toString().substring(0,6)),
      latitude:parseFloat(l.lat.toString().substring(0,6)),
   })

  })
}
const handleImportAddress = ()=>{
   const a = JSON.parse(JSON.stringify(params.addressToCopy))
   a.id = addressObject.id
   setAddressObject({...a})
}
    if(params.disabled){
      return <TextBox rows={6} label={params.label} disabled={true} value={fullAddress}/>

    }
    
    return <Grid container spacing={2}>
            {!params.toggle && <Grid item xs={12} ><InputLabel>{params.label}</InputLabel></Grid>}
            <Grid item xs={6} ><TextBox label="Contact Name" id="contactName" disabled={params.disabled} value={addressObject?.contactName} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ></Grid>
            <Grid item xs={6} ><TextBox label="Contact Landline" id="contactTelephone" maxLength={32} disabled={params.disabled} value={addressObject?.contactTelephone} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ><TextBox label="Contact Mobile" id="contactMobile"  maxLength={32} disabled={params.disabled} value={addressObject?.contactMobile} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ><TextBox label="Email" id="email" validationField={formValidator && formValidator.email}   value={addressObject?.email} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ><TextBox label="Website" id="website" disabled={params.disabled} value={addressObject?.website} onChange={(e) => {updateForm(e)}} /></Grid>

            <Grid item xs={12} ><TextBox label="Address Line 1" id="addressLine1" validationField={formValidator && formValidator.addressLine1} disabled={params.disabled} value={addressObject?.addressLine1} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={12} ><TextBox label="Address Line 2" id="addressLine2" disabled={params.disabled} value={addressObject?.addressLine2}  onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ><TextBox label="Town" id="town" disabled={params.disabled} validationField={formValidator && formValidator.town} value={addressObject?.town}  onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={6} ><TextBox label="County" id="county" disabled={params.disabled} value={addressObject?.county} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={3} ><TextBox label="Postcode" id="postcode" disabled={params.disabled} value={addressObject?.postcode} maxLength={12} validationField={formValidator && formValidator.postcode} onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={3} ><ActionButton label="Geo Code" severity="info" onClick={handleSearchPostcode} /></Grid>
            <Grid item xs={3} ><TextBox label="Longitude" id="longitude" value={addressObject?.longitude} validationField={formValidator && formValidator.longitude} type="number" onChange={(e) => {updateForm(e)}} /></Grid>
            <Grid item xs={1} ></Grid>
            <Grid item xs={2} ><ActionButton severity="info" icon="pi pi-arrow-up" onClick={()=> updateLatitude(0.01)}/></Grid>
            <Grid item xs={6} ></Grid>
            
            <Grid item xs={3} ><TextBox label="Latitude" id="latitude" value={addressObject?.latitude}  onChange={(e) => {updateForm(e)}} validationField={formValidator && formValidator.latitude} type="number"/></Grid>
            <Grid item xs={2} ><ActionButton severity="info" icon="pi pi-arrow-left" onClick={()=>updateLongitude(-0.01)}/></Grid>
            <Grid item xs={1} ><ActionButton severity="info" icon="pi pi-arrow-right" onClick={()=>updateLongitude(0.01)}/></Grid>
            <Grid item xs={10} >{params.addressToCopy && <ActionButton label="Copy from parent" severity="success" onClick={handleImportAddress}/>}</Grid>
            <Grid item xs={1} ><ActionButton severity="info" icon="pi pi-arrow-down"  onClick={()=> updateLatitude(-0.01)}/></Grid>
            
        </Grid>
}

export default AddressEditDetails;
