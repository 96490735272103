import { useEffect, useState } from "react";
import OKCancelButtons from "../../../core/OKCancelButtons";
import { Grid } from "@mui/material";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import ActionButton from "../../../core/actionButton";
import { ComponentPropsBase } from "../../../../interfaces/system/componentPropsBase";
import { SystemMessageValidator } from "../../../../validators/system-message-validator";
import { createEmptySystemMessage, fetchSystemMessage, fetchSystemMessageList, saveSystemMessage } from "../../../../services/system-message-service";
import { SystemMessageSubscriberType, SystemMessageType } from "../../../../interfaces/system/systemMessageType";
import Dropdown from "../../../core/dropdown";
import { Editor } from 'primereact/editor';
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../../services/systemService";
import ServiceSelector, { SelectedServiceType } from "../../../core/service-selector";
import DateTimePicker from "../../../core/dateTimePicker";
import { Message } from 'primereact/message';
import Panel from "../../../core/panel";
import useForm from "../../../../hooks/useForm";


export interface SystemMessageEditorProps extends ComponentPropsBase {
    selectedId: number,
    onSaved: (item: SystemMessageType) => void,
    onBackClicked: () => void
}
export function SystemMessageEditor(props: SystemMessageEditorProps) {
    const [selectedItemId, setSelectedItemId] = useState<number>(props.selectedId);
    const [validator] = useState(new SystemMessageValidator())
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());

    const [messageContent,setMessageContent] = useState<string>()
    const [selectedServices, setSelectedServices] = useState<SelectedServiceType[]>();
    const {
        validateForm, objectValues, setObjectValues,setValues, setValidator
      }
       = useForm({initialObjectValues: createEmptySystemMessage(), validator: validator})

    // hooks
    useEffect(() => {
        init();
        window.addEventListener('popstate', function () {
            props.onBackClicked()
        }, false);
    }, []);

    useEffect(() => {
        setObjectValues({...objectValues, messageContent:messageContent})
    }, [messageContent]);

    const init = async () => {
        loadAreaHierarchy();
        
        if (selectedItemId > 0) {
            handleLoading();
            const response = await fetchSystemMessage(selectedItemId);

            handleLoaded();
            if (response && response.success && response.result) {
                const si = response.result
                setObjectValues({...si});
                setMessageContent(si.messageContent)
                if (si.subscribers.length > 0) {
                    let _selectedServices: SelectedServiceType[] = [];
                    for (var i = 0; i < si.subscribers.length; i++) {
                        _selectedServices.push({ id: si.subscribers[i].serviceId });
                    }
                    setSelectedServices(_selectedServices);
                }
                validateForm(response.result)
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
    }

    const loadAreaHierarchy = async () => {
        handleLoading();
        const a = await fetchAllAreaHierarchyWithServices()
        if (a.success && a.result) {
            setAreaHierarchy(a.result);
        }
        handleLoaded();
    }

    const handleFieldUpdated = (e: any) => {
        setValues(e)
        validateForm(validator)
    }
    const handleEditorUpdated = (e: any) => {
        setMessageContent(e.htmlValue)
    }
     
    const handleServiceSelection = (items: SelectedServiceType[]) => {
        if (!objectValues) return;
        let _selections: SystemMessageSubscriberType[] = [];
        for (var i = 0; i < items.length; i++) {
            _selections.push({ id: items[i].id, serviceId:items[i].id});
        }
        let updatedModel: SystemMessageType = { ...objectValues, subscribers: _selections };
        setObjectValues(updatedModel);
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }
    const handleItemSaved = (item: SystemMessageType) => {
        if (props.onSaved) {
            props.onSaved(item);
        }
    }

    const handleBackClicked = () => {
        if (props.onBackClicked) {
            props.onBackClicked();
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleCancel = async () => {
        if (props.onBackClicked) {
            props.onBackClicked()
        }
    }

    const handleSave = async () => {
        if (objectValues) {


            const valid = validator.validate(objectValues)
            setValidator({...validator})
            if(!valid){
                if(!objectValues.messageContent)
                    handleError("Message content can not be blank")
                else
                    handleError('')
                return
            }

            handleLoading();
            const response = await saveSystemMessage(objectValues);
            handleLoaded();
            if (response && response.success && response.result) {
                if (selectedItemId === 0) {
                    setSelectedItemId(response.result.id);
                }
                setObjectValues(response.result);
                if (props.onSaved) {
                    props.onSaved(objectValues);
                    await fetchSystemMessageList(false);
                }
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
    }

    const EditElement = <>
        {objectValues &&
            <Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
                <Grid item xs={2}>
                    <TextBox id="id" label="Id" value={objectValues.id} disabled={true} hidden={objectValues.id<1}/>
                </Grid>
                <Grid item xs={2}>
                    <TickBox id="deleted" label="Deleted" checked={objectValues.deleted} onChange={handleFieldUpdated} disabled={selectedItemId <= 0} />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={12}>
                    <TextBox id="subject" label="Subject" value={objectValues.subject} validationField={validator.subject} onChange={handleFieldUpdated} />
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker id="activeFrom" label="Active From" validationField={validator.activeFrom} value={objectValues.activeFrom} onChange={handleFieldUpdated} showTimePicker={true} />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <DateTimePicker id="activeTo" label="Active To"  validationField={validator.activeTo} value={objectValues.activeTo} onChange={handleFieldUpdated} showTimePicker={true} />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Dropdown id="deliveryTypeId" label="Type" optionName="system-message-type" value={objectValues.deliveryTypeId} onChange={handleFieldUpdated} />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <Editor value={messageContent}  onTextChange={handleEditorUpdated} style={{ height: '320px' }} />
                </Grid>
            </Grid>
        }
    </>

    const AssociatedServiceListElement = <>
        {objectValues &&
            <Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
                <Grid item xs={12}>
                    <Message severity="warn" text="WARNING: Emails will be generated for all users associated with selected services SO while in UAT service selections should be limited" />
                </Grid>
                <Grid item xs={12}>
                    <ServiceSelector areaHierarchy={areaHierarchy} onChange={handleServiceSelection} selections={selectedServices}></ServiceSelector>
                    <p>&nbsp;</p>
                </Grid>
            </Grid>
        }
    </>

    if (!objectValues) {
        return<></>
    }

    return <>
        <Grid container>
            <Grid item xs={6}>
                {EditElement}
            </Grid>
            <Grid item xs={6}>
                <Panel title="Services">
                    {AssociatedServiceListElement}
                </Panel>
            </Grid>

            <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />

            {/* {(!isDirty || !isValid) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                <div style={{ float: 'left', marginRight: '5px' }}>
                    <ActionButton onClick={handleBackClicked} label="Back" severity="secondary"></ActionButton>
                </div>
            </div>} */}
        </Grid>

    </>
}