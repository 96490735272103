import { Grid } from "@mui/material"
import { useState } from "react"
import { formatStringToDateTime } from "../../extensions/formatter"
import { AreaHierarchyType } from "../../interfaces/system/areaTypes"
import ActionButton from "../core/actionButton"
import DateTimePicker from "../core/dateTimePicker"
import Dropdown, { IDropdownOptions } from "../core/dropdown"
import FeedBackText from "../core/feedbackText"
import FieldSet from "../core/fieldSet"
import MultiSelect from "../core/multiSelect"
import ServiceSelector, { SelectedServiceType } from "../core/service-selector"
import { UserSearch } from "../core/userSearch"
import TextBox from "../core/textBox"
import TickBox from "../core/tickBox"
import { addMonthsToDate } from "../../services/utils"

export interface BasicReportCriteria {
    selectedGridId?: number,
    selectedUserId?: number,
    selectedAuditEventTypeIds?: number[],
    recipentValue?: string,
    subjectLineValue?: string,
    selectedBedTypeId?: number
    selectedEscalationReportingTypeId?: number
    selectedServiceIds: SelectedServiceType[]
    selectedServiceCategoryIds?: number[]
    selectedMonthId?: number
    selectedYear?: number
    fromDate?: Date
    toDate?: Date,
    capGridSummaryReportCheck?: boolean
    groupByOpel?: boolean
    selectedOpelId?: number
    selectedSubscriptionLevels?:number[]
    selectedUserRoles?:number[]
}
export interface BasicReportFilterCriteria {
    showOnlyServicesWithFormAssociated?: boolean
    showOnlyServicesWithGridAssociated?: boolean
    showFromDate?: boolean
    showToDate?: boolean
    showMonth?: boolean
    showYear?: boolean
    showServiceCategory?: boolean
    hideServiceSelectorCategoryFilter?: boolean
    showSystemAudit?: boolean
    showUser?: boolean
    showServices?: boolean
    showBedAvailabilityList?: boolean
    showBedTypeList?: boolean
    showEscalationGrouping?: boolean
    showEmailAudit?: boolean,
    showCapGridSummaryReportCheck?: boolean,
    showDownloadPdfButton?: boolean,
    showGroupByOpel?: boolean,
    showSubscriptionLevels?:true
    showUserRoles?:boolean
}

interface BasicReportFilterProps {
    mode?: string
    areaHierarchy?: AreaHierarchyType
    onApplyFilter: (criteria: BasicReportCriteria) => void
    onExport?: () => void
    onExportPDF?: (criteria: BasicReportCriteria) => void
    criteria?: BasicReportFilterCriteria
    defaultFilterCriteria?: BasicReportCriteria
    hideServiceControl?: boolean
    validateServiceSelections?: boolean,
    singleServiceSelection?: boolean
}
export default function BasicReportFilter(props: BasicReportFilterProps) {

    const [selections, setSelections] = useState<SelectedServiceType[]>([]);
    const [selectedServiceCategories, setSelectedServiceCategories] = useState<number[]>([]);
    const [selectedAuditTypes, setSelectedAuditTypes] = useState(props?.defaultFilterCriteria?.selectedAuditEventTypeIds);
    const [selectedRecipientValue, setSelectedRecipientValue] = useState(props?.defaultFilterCriteria?.recipentValue);
    const [selectedSubjectLineValue, setSelectedSubjectLineValue] = useState(props?.defaultFilterCriteria?.subjectLineValue);
    const [selectedMonthId, setSelectedMonthId] = useState<number>(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedGridId, setSelectedGridId] = useState<number>();
    const [selectedUserId, setSelectedUserId] = useState(props?.defaultFilterCriteria?.selectedUserId);
    const [selectedBedTypeId, setSelectedBedTypeId] = useState<number>();
    const [selectedEscalationReportingTypeId, setSelectedEscalationReportingTypeId] = useState<number>();
    const [fromDate, setFromDate] = useState(props?.defaultFilterCriteria?.fromDate);
    const [toDate, setToDate] = useState(props?.defaultFilterCriteria?.toDate);
    const [groupByOpel, setGroupByOpel] = useState(props?.defaultFilterCriteria?.groupByOpel);
    const [selectedOpelId, setSelectedOpelId] = useState(props?.defaultFilterCriteria?.selectedOpelId);
    const [selectedSubscriptionLevelIds, setSelectedSubscriptionLevelIds] = useState(props?.defaultFilterCriteria?.selectedSubscriptionLevels);
    const [selectedUserRoleIds, setSelectedUserRoleIds] = useState(props?.defaultFilterCriteria?.selectedUserRoles);
    const [capGridSummaryReportCheck, setCapGridSummaryReportCheck] = useState(props?.defaultFilterCriteria?.capGridSummaryReportCheck);
    const [valid, setValid] = useState(true);
    const [singleServiceSelection] = useState(props.singleServiceSelection ?? false);

    const handleSelection = (s: SelectedServiceType[]) => {
        setSelections(s)
    }
    const handleApplyFilter = () => {
        if (props.validateServiceSelections) {
            const valid = selections && selections.length > 0
            setValid(valid)
            if (!valid) return
        }


        let from = fromDate
        let to = toDate
        
        if(selectedMonthId && (!fromDate || !toDate)){
            const now = new Date()
            from = new Date(now.getFullYear(), selectedMonthId-1)
            to = addMonthsToDate(from,1)
        }
        
        props.onApplyFilter({
            selectedServiceIds: selections, fromDate: from,
            toDate: to, selectedServiceCategoryIds: selectedServiceCategories, selectedGridId: selectedGridId, selectedBedTypeId: selectedBedTypeId,
            selectedEscalationReportingTypeId: selectedEscalationReportingTypeId,
            selectedUserRoles:selectedUserRoleIds,
            selectedMonthId: selectedMonthId, selectedYear: selectedYear, selectedUserId: selectedUserId, selectedAuditEventTypeIds: selectedAuditTypes, subjectLineValue: selectedSubjectLineValue, recipentValue: selectedRecipientValue,
            capGridSummaryReportCheck: capGridSummaryReportCheck, groupByOpel:groupByOpel, selectedOpelId:selectedOpelId, selectedSubscriptionLevels: selectedSubscriptionLevelIds
        })
    }
    const handleExport = () => {
        if (props.onExport)
            props.onExport()
    }
    const handleExportPDF = () => {
        if (props.onExportPDF)
            props.onExportPDF({
                selectedServiceIds: selections, fromDate: fromDate,
                toDate: toDate, selectedServiceCategoryIds: selectedServiceCategories, selectedGridId: selectedGridId, selectedBedTypeId: selectedBedTypeId,
                selectedMonthId: selectedMonthId, selectedYear: selectedYear, selectedUserId: selectedUserId, selectedAuditEventTypeIds: selectedAuditTypes, subjectLineValue: selectedSubjectLineValue, recipentValue: selectedRecipientValue,
                capGridSummaryReportCheck: capGridSummaryReportCheck, groupByOpel: groupByOpel, selectedOpelId: selectedOpelId, selectedSubscriptionLevels: selectedSubscriptionLevelIds
            })
    }
    const handleSelectCategories = (e: any) => {
        setSelectedServiceCategories(e.target.value as number[])
    }
    const handleSelectGrouping = (e: any) => {
        setGroupByOpel((e.target.value as number) === 2)
    }
    const handleSelectOpel = (e: any) => {
        setSelectedOpelId(e.target.value as number)
    }
    const handleSelectSubscriptionLevel = (e: any) => {
        setSelectedSubscriptionLevelIds([e.target.value as number])
    }
    const handleSelectUserRole = (e: any) => {
        setSelectedUserRoleIds(e.target.value)
    }
    const handleSelectSystemAudit = (e: any) => {
        if (!e) {
            setSelectedAuditTypes(undefined)
            return
        }

        setSelectedAuditTypes((e as any[])?.map(m => parseInt(m.code)))
    }

    const handleRecipientValueChange = (e: any) => {
        setSelectedRecipientValue(e.target.value);
    }
    const handleSubjectLineValueChange = (e: any) => {
        setSelectedSubjectLineValue(e.target.value);
    }

    const getYearsForSelect = (): IDropdownOptions[] => {
        const startYear = (new Date().getFullYear()) - 5;
        const endYear = (new Date().getFullYear());
        const options:IDropdownOptions[] = [];
        for (var i = startYear; i <= endYear; i++) {
            options.push({ id: i, name: i.toString(), code: i.toString() });
        }
        return options;
    }


    const containingColSpan = props.hideServiceControl ? 12 : 4
    const colSpan = props.hideServiceControl ? 3 : 12

    return <FieldSet title="Filter" toggleable={true}>
        <Grid container spacing={2}>
            {!props?.hideServiceControl &&
                <Grid item xs={8}>
                    <ServiceSelector areaHierarchy={props.areaHierarchy} onChange={handleSelection} singleSelection={singleServiceSelection}
                        criteria={{
                            ...props.criteria, showOnlyServiceCategoryId:
                                props.defaultFilterCriteria?.selectedServiceCategoryIds ? props.defaultFilterCriteria?.selectedServiceCategoryIds[0] : undefined,
                                hideCategoryFilter:props.criteria?.hideServiceSelectorCategoryFilter
                        }} ></ServiceSelector>
                </Grid>
            }


            <Grid item xs={containingColSpan}>
                <Grid container spacing={3}>

                    {props?.criteria?.showEmailAudit &&
                        <>
                            <Grid item xs={colSpan}>
                                <TextBox label="Recipient" id="recipentValue" value={selectedRecipientValue ?? ''} onChange={handleRecipientValueChange} />
                            </Grid>
                            <Grid item xs={colSpan}>
                                <TextBox label="Subject" id="subjectLineValue" value={selectedSubjectLineValue ?? ''} onChange={handleSubjectLineValueChange} />
                            </Grid>
                        </>
                    }

                    {props?.criteria?.showSystemAudit &&
                        <Grid item xs={colSpan + (props.criteria.showUser?0:2)}>
                            <MultiSelect label="Audit Type" optionName="audit-event-type-list" onChange={handleSelectSystemAudit} />
                        </Grid>
                    }
                    {props?.criteria?.showUser &&
                        <Grid item xs={colSpan}>
                            <UserSearch onUserSelection={(e) => { setSelectedUserId(e?.id ?? undefined) }} />
                        </Grid>
                    }

                    {props?.criteria?.showSubscriptionLevels &&
                        <Grid item xs={colSpan}>
                            <Dropdown label="Level" includeBlankOption={true} options={[{ id: 1, name: 'Level 1', code: '1' }, { id: 2, name: 'Level 2', code: '2' }, { id: 3, name: 'Level 3', code: '3' }, { id: 4, name: 'Level 4', code: '4' }, { id: -1, name: 'Capacity Grid', code: '-1' }]} onChange={handleSelectSubscriptionLevel} />
                        </Grid>
                    }
                    {props?.criteria?.showUserRoles &&
                        <Grid item xs={colSpan}>
                            <Dropdown label="User Role" includeBlankOption={true} multiple={true} optionName="userRoles" onChange={handleSelectUserRole} />
                        </Grid>
                    }

                    {props?.criteria?.showFromDate &&
                        <Grid item xs={colSpan}>
                            <DateTimePicker value={fromDate} label="From Date" onChange={(e) => { setFromDate(formatStringToDateTime(e.target.value) as Date) }} />
                        </Grid>
                    }
                    {props?.criteria?.showToDate &&
                        <Grid item xs={colSpan}>
                            <DateTimePicker value={toDate} label="To Date" onChange={(e) => setToDate(formatStringToDateTime(e.target.value) as Date)} />
                        </Grid>
                    }
                    {props?.criteria?.showMonth &&
                        <Grid item xs={colSpan}>
                            <Dropdown label="Month" filter={false} value={(new Date().getMonth() + 1)} options={[{ id: 1, name: 'January', code: '1' },
                            { id: 2, name: 'February', code: '2' },
                            { id: 3, name: 'March', code: '3' },
                            { id: 4, name: 'April', code: '4' },
                            { id: 5, name: 'May', code: '5' },
                            { id: 6, name: 'June', code: '6' },
                            { id: 7, name: 'July', code: '7' },
                            { id: 8, name: 'August', code: '8' },
                            { id: 9, name: 'September', code: '9' },
                            { id: 10, name: 'October', code: '10' },
                            { id: 11, name: 'November', code: '11' },
                            { id: 12, name: 'December', code: '12' },]}
                                onChange={(e) => setSelectedMonthId(e.target.value as number)} />
                        </Grid>
                    }
                    {props?.criteria?.showYear &&
                        <Grid item xs={colSpan}>
                            <Dropdown label="Year" value={(new Date().getFullYear())} options={getYearsForSelect()}
                                onChange={(e) => setSelectedYear(e.target.value as number)} />
                        </Grid>
                    }
                    {props?.criteria?.showServiceCategory &&
                        <Grid item xs={12}>
                            <Dropdown label="Service Category" multiple={true} optionName="serviceCategories" onChange={handleSelectCategories} />
                        </Grid>
                    }

                    {props?.criteria?.showGroupByOpel && <>
                        <Grid item xs={12}>
                            <Dropdown label="Grouping" options={[{ id: 1, name: 'Service Category', code: 'service' }, { id: 2, name: 'OPEL', code: 'opel' }]} onChange={handleSelectGrouping} />
                        </Grid>
                        {/* {groupByOpel &&
                            <Grid item xs={12}>
                                <Dropdown label="OPEL" optionName="opel-list" includeBlankOption={true} onChange={handleSelectOpel} />
                            </Grid>
                        } */}
                        </>
                    }

                    {props?.criteria?.showBedAvailabilityList &&
                        <Grid item xs={12}>
                            <Dropdown label="Bed Availability Grid" optionName="capacity-grid-bed-availability" includeBlankOption={true} onChange={(e) => setSelectedGridId(e.target.value as number)} />
                        </Grid>
                    }

                    {props?.criteria?.showBedTypeList && ((props?.criteria?.showCapGridSummaryReportCheck ?? true) && !capGridSummaryReportCheck) &&
                        <Grid item xs={12}>
                            <Dropdown label="Bed Type" optionName="bed-type-list" includeBlankOption={true} onChange={(e) => setSelectedBedTypeId(e.target.value as number)} />
                        </Grid>
                    }
                    {props?.criteria?.showEscalationGrouping && ((props?.criteria?.showCapGridSummaryReportCheck ?? true) && !capGridSummaryReportCheck) &&
                        <Grid item xs={12}>
                            <Dropdown label="Escalation Reporting"  includeBlankOption={true} 
                            options  ={[{code:'All', name:'All', id:0},{code:'AM', name:'AM', id:10},{code:'PM', name:'PM', id:20},{code:'Highest', name:'Highest', id:30}]} filter={false}
                            onChange={(e) => setSelectedEscalationReportingTypeId(e.target.value as number)} />
                        </Grid>
                    }
                    {props?.criteria?.showCapGridSummaryReportCheck &&
                        <Grid item xs={12}>
                            <TickBox label="Use summary fields" checked={capGridSummaryReportCheck} onChange={(e) => { const chk = (e as React.ChangeEvent<HTMLInputElement>); setCapGridSummaryReportCheck(chk.target.checked) }} />
                        </Grid>
                    }
                    {props.hideServiceControl && <Grid item xs={7}>

                    </Grid>}
                    <Grid item xs={!props.hideServiceControl ? 4 : 3} md={!props.hideServiceControl ? 5 : 2}>
                        <ActionButton onClick={handleApplyFilter} label="Apply" severity="success" icon="pi pi-filter" />
                    </Grid>
                    <Grid item xs={!props.hideServiceControl ? 6 : 3} md={!props.hideServiceControl ? 6 : 2}>
                        
                        <ActionButton onClick={handleExport} label="Export" severity="secondary" icon="pi pi-file-export" />
                    </Grid>
                    {props.criteria?.showDownloadPdfButton && <Grid item xs={12}>
                    <Grid item xs={12}>
                        
                            <ActionButton onClick={handleExportPDF} label="Download PDF" severity="secondary" icon="pi pi-file-pdf"  />
                            </Grid>
                        </Grid>}  
                    {!valid && <Grid item xs={12}>
                        <FeedBackText severity="error" show={!valid} message="You must select at least one service" />
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    </FieldSet>


}