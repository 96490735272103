import { Grid } from "@mui/material"
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes"
import { SitRepReportType } from "../../../interfaces/system/formType"
import DebugVariable from "../../core/debugVariable"
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter"
import { useEffect, useState } from "react"
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service"
import React from "react"
import { formatDateTime } from "../../../extensions/formatter"

export interface FormSitRepReportCriteriaProps {
    serviceIds: number[]
    selectedServiceCategoryIds?: number[]
    fromDate: Date
    toDate: Date
    groupByOpel?: boolean
    selectedOpelId?: number
}

interface FormSitRepReportProps {
    areaHierarchy: AreaHierarchyType
    onFetchData: (criteria: FormSitRepReportCriteriaProps) => void
    onExportPDF: (criteria: FormSitRepReportCriteriaProps) => void
    criteria?: BasicReportFilterCriteria
    data?: SitRepReportType[]
    defaultFilterCriteria?: BasicReportCriteria
}

export function FormSitRepReport(props: FormSitRepReportProps) {
    const [data, setData] = useState(props.data);
    const [searchTriggered, setSearchTriggered] = useState(false);

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        props.onFetchData({ serviceIds: criteria.selectedServiceIds.map(m => m.id), selectedServiceCategoryIds: criteria.selectedServiceCategoryIds?.map(m => m), fromDate: criteria.fromDate ?? new Date(), toDate: criteria.toDate ?? new Date(), groupByOpel: criteria.groupByOpel, selectedOpelId: criteria.selectedOpelId })
        setSearchTriggered(true);
    }

    const handleExportPDF = (criteria: BasicReportCriteria) => {
        props.onExportPDF({ serviceIds: criteria.selectedServiceIds.map(m => m.id), selectedServiceCategoryIds: criteria.selectedServiceCategoryIds?.map(m => m), fromDate: criteria.fromDate ?? new Date(), toDate: criteria.toDate ?? new Date(), groupByOpel: criteria.groupByOpel, selectedOpelId: criteria.selectedOpelId })
    }

    const handleExport = () => {
        const title = 'Situation Report';
        const reportTables = document.querySelectorAll<HTMLTableElement>("table[data-export-sheet-name]");
        if ((reportTables?.length ?? 0) > 0) {
            let sheets: ExcelExportSheet[] = [];
            reportTables.forEach((table, index) => {
                sheets.push({
                    title: table.dataset.exportSheetTitle ?? `Form Data ${index}`,
                    name: table.dataset.exportSheetName ?? `Form Data ${index}`,
                    elements: [
                        { table: table },
                    ]
                });
            });
            exportToExcel({
                fileName: title,
                defaultHeaderWidth: 20,
                sheets: sheets,
                headers: [{width:50,alignment:'left' },{width:20 },{width:40,alignment:'left' }]
            });
        }
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}
                validateServiceSelections={true}
                onExport={handleExport} onExportPDF={handleExportPDF} />

        </Grid>
        <Grid item xs={12}>
            {searchTriggered && (data?.length ?? 0) <= 0 &&
                <p className="alert alert-warning">Search criteria returned no data, try expanding your search criteria.</p>
            }

            {props.data && (props.data?.length ?? 0) > 0 && <>
                <table className={"table condensed table-highlight bordered"} data-export-sheet-name={"SitRep"} data-export-sheet-title={"Situation Report"}>
                    <thead>
                        <tr>
                            <th className="bold text-left">Service</th>
                            <th className="bold text-left">Escalation Level</th>
                            <th className="bold text-left">Comments</th>
                            <th className="bold text-left">Submitted</th>
                            <th className="bold text-left">Submitted By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(props.data.map(result => {
                            
                            return <>
                                <tr>
                                    <td colSpan={5} className='header' style={{ fontWeight: 'bold', color:'#2b73d1', fontSize:'18px'}}>{result.grouping.name}</td>
                                </tr>
                                {React.Children.toArray(result.results.map(serviceResult => {
                                    return <>
                                        <tr>
                                            <td>{serviceResult.service.name}</td>
                                            
                                            {!serviceResult.resultExpired && <td className={`text-center form-result-${serviceResult.escalationLevel}`}>{serviceResult.escalationLevel}</td>}
                                            {serviceResult.resultExpired && <td className={`text-center form-result-expired`}>{serviceResult.escalationLevel}</td>}
                                            <td>{serviceResult.comments}</td>
                                            <td>{formatDateTime(serviceResult.dateSubmitted)}</td>
                                            <td>{serviceResult.submittedBy.name}</td>
                                        </tr>
                                    </>
                                }))}
                                <tr>
                                    <td colSpan={5}>&nbsp;</td>
                                </tr>
                            </>
                        }))}
                    </tbody>
                </table>
            </>}


        </Grid>

    </Grid>
}