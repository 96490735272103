import { Grid } from "@mui/material";
import { Panel } from "primereact/panel";
import { useEffect, useState } from "react";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import { useNavigate, useParams } from "react-router-dom";
import { updatePageTitle } from "../../services/utils";
import { createEmptyDistributionList  } from "../../services/distribution-list-service";
import { DistributionListType } from "../../interfaces/system/distributionListTypes";
import { DistributionListList } from "../../components/admin/system/distribution-list-editor/distribution-list-list";
import { DistributionListEditor } from "../../components/admin/system/distribution-list-editor/distribution-list-editor";

export interface DistributionListContainerProps {
    mode?: 'list' | 'edit'
}
export function DistributionListContainer(props: DistributionListContainerProps) {

    // component vars
    let { id } = useParams();
    const defaultTitle: string = 'Distribution Lists';

    // state vars
    const [mode, setMode] = useState(props.mode ?? 'list');
    const [selectedRecordId, setSelectedRecordId] = useState(parseInt(id ?? '0'))

    const [feedbackStatus, setFeedbackStatus] = useState<FeedbackType>(FeedbackType.hide)
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const navigate = useNavigate();

    // hooks
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        
        const userIsBasic = false
        if (userIsBasic) {
            setFeedbackStatus(FeedbackType.NoAccess)
        }
        if (selectedRecordId > 0) {
            setMode('edit');
            setPageTitle('Edit');
        } else {
            setMode('list');
            setPageTitle(defaultTitle);
        }
    }

    const setPageTitle = (title: string) => {
        setTimeout(() => { updatePageTitle(title); }, 100);
    }

    const handleLoading = () => {
        setFeedbackStatus(FeedbackType.Loading);
    }
    const handleLoaded = () => {
        setFeedbackStatus(FeedbackType.hide);
    }
    const handleError = (message: string) => {
        setErrorMessage(message);
        setFeedbackStatus(FeedbackType.Error);
    }

    const handleRowClicked = (item: DistributionListType) => {
        setSelectedRecordId(item.id);
        navigate(`/admin/distribution-lists/${item.id}`);
        setMode('edit');
    }
    const handleRowSaved = (item: DistributionListType) => {
        setErrorMessage(`${item.name} saved!`);
        setFeedbackStatus(FeedbackType.SaveSuccess);
    }
    const handleNewItemClicked = () => {
        handleRowClicked(createEmptyDistributionList());
    }
    const handleEditorBackClicked = () => {
        setSelectedRecordId(0);
        navigate(`/admin/distribution-lists`);
        setMode('list');
    }

    return <>
        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel header={defaultTitle}>
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    {mode === 'list' && <><DistributionListList onRowClicked={handleRowClicked} onNew={handleNewItemClicked} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} /></>}
                    {mode === 'edit' && selectedRecordId >= 0 && <><DistributionListEditor selectedId={selectedRecordId} onBackClicked={handleEditorBackClicked} onSaved={handleRowSaved} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} /></>}
                </Panel>
            </Grid>
        </Grid>
    </>
}