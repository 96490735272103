import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { CapacityGridFieldOptionText } from "../../../../interfaces/system/capacityGridType";
import ActionButton from "../../../core/actionButton";
import SortableList from "../../../core/sortableList";
import { CapacityGridColumnEditorDropdownEdit } from "./capacity-grid-editor-dropdown-edit";

interface CapacityGridEditorDropdownListProps {
  columnId: number,
  resultList?: CapacityGridFieldOptionText[],
  onUpdate: (data: CapacityGridFieldOptionText[]) => void
}

export default function CapacityGridEditorDropdownList(props: CapacityGridEditorDropdownListProps) {

  // state vars
  const [resultList, setResultList] = useState(props.resultList)
  const [selectedItem, setSelectedItem] = useState<CapacityGridFieldOptionText>()
  const [showAddButton, setShowAddButton] = useState(true)

  // hook setups
  useEffect(() => {
    init();
  }, [props.resultList])


  // component events
  const init = async () => {
    setResultList(props.resultList)
  }

  const handleItemSelection = (selectedItem: CapacityGridFieldOptionText) => {
    setSelectedItem({ ...selectedItem });
  }

  const handleNewItemButtonClick = () => {
    if (resultList) {
      let newItem = { id: 0, deleted: false, helpText: '', value: '', sortOrder: 99, columnId: props.columnId };
      setSelectedItem(newItem);
    }
  }

  const handleItemUpdated = (item: CapacityGridFieldOptionText) => {
    if (item.id <= 0) {
      resultList?.push(item);
      if (resultList) {
        setResultList([...resultList]);
      }
      setSelectedItem(undefined);
      if (resultList) {
        props.onUpdate(resultList);
      }
      return;
    }
    let items = resultList?.map(option => {
      if (option.id === item.id) {
        return item;
      }
      return option;
    });
    setResultList(items);
    if (items) {
      props.onUpdate(items);
    }
    setShowAddButton(true);
  }

  const handleReOrder = (items: CapacityGridFieldOptionText[]) => {
    setResultList(items);
    if (items) {
      props.onUpdate(items);
    }
  }

  const handleItemRemoved = (itemRemoved: CapacityGridFieldOptionText, newList: CapacityGridFieldOptionText[]) => {
    if (resultList) {
      let newData = [...newList, { ...itemRemoved,deleted : true}];
      handleReOrder(newData)
    }
  }


  return <>
    <Grid container spacing={4} justifyContent="left">
      <Grid item xs={12}>

        <SortableList data={resultList || undefined} onItemSelection={handleItemSelection} onRemove={handleItemRemoved} onReOrder={handleReOrder} showRemove={true}></SortableList>
        <div hidden={!showAddButton}>
          <ActionButton onClick={handleNewItemButtonClick} label={"Add New Option"} severity="secondary" size="small"></ActionButton>
        </div>
      </Grid>

      <Grid item xs={7}>
        {selectedItem && <CapacityGridColumnEditorDropdownEdit item={selectedItem} onUpdate={handleItemUpdated}></CapacityGridColumnEditorDropdownEdit>}

      </Grid>
    </Grid>

  </>
}