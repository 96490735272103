import { createMuiTheme } from "@mui/material";
import { grey, purple } from "@mui/material/colors";

 

export const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2b73d1',
        contrastText:grey[50]
      },
      secondary: {
        main: purple[400],
      }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')
    }
  });

  

  