import { FormValidation, FormValidationField } from '../extensions/formValidation';
import { CapacityGridType } from '../interfaces/system/capacityGridType';
import { ValidatorType } from './ValidatorType'

export class CapacityGridValidator implements ValidatorType {

    gridValidator = new FormValidation([])
    name = new FormValidationField('', '')
    //description = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: CapacityGridType) => {
        this.gridValidator = new FormValidation([])
        this.name = this.gridValidator.createTextField(objectValues.name)
    }

    validate = (validatorObjectValues: CapacityGridType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.gridValidator.isValid()

        return isValid
    }
}