import React from "react";
import { formatMinutesToHHMM } from "../../../extensions/formatter";
import SimpleDialog from "../../core/simpleDialog";
// import amberButtonIcon from '../../../assets/images/icons/led-amber-black.svg'
// import redButtonIcon from '../../../assets/images/icons/led-red-black.svg'
// import greenButtonIcon from '../../../assets/images/icons/led-green-black.svg'
import { Grid } from "@mui/material";
import GroupBox from "../../core/groupBox";
require('./demand-and-capacity.scss')

export interface DemandAndCapacityPanelRangeValuesProps {
    min: number
    max: number
}
export interface DemandAndCapacityPanelRangeProps {
    red: DemandAndCapacityPanelRangeValuesProps
    amber: DemandAndCapacityPanelRangeValuesProps
    green: DemandAndCapacityPanelRangeValuesProps
}

export interface DemandAndCapacitySecondaryPanelProps {
    value: number
    targetValue?: number
    supportingText?: string
}

export interface DemandAndCapacityPanelProps {
    title: string
    value: number
    format?: 'number' | 'time' | 'percentage'
    help?: string
    id: string
    range: DemandAndCapacityPanelRangeProps
    onClick?: () => void
    targetValue?: number
    secondaryPanel?: DemandAndCapacitySecondaryPanelProps
    variant?: 'small' | undefined
    supportingText?: string
}
export default function DemandAndCapacityPanel(props: DemandAndCapacityPanelProps) {

    const [showHelpDialog, setShowHelpDialog] = React.useState(false);

    const handleClick = () => {
        if (showHelpDialog) return
        if (props.onClick)
            props.onClick()
    }

    const handleShowHelpText = (e: any) => {
        e.stopPropagation();
        setShowHelpDialog(true)
    }

    const value = (!props.format || props.format === "number") ? props.value :
        props.format === "time" ? formatMinutesToHHMM(props.value) : `${Math.ceil(props.value*100)}%`

    const targetValue = (!props.format || props.format === "number") ? props.targetValue :
        props.format === "time" ? formatMinutesToHHMM(props.targetValue || 0) : `${Math.ceil(props.targetValue || 0)}%`


    let valueClassName = "amber"
    if (props.value >= props.range.green.min && props.value < props.range.green.max) {
        valueClassName = "green"
    }
    if (props.value >= props.range.red.min) {
        valueClassName = "red"
    }


    return <div onClick={handleClick} style={{ cursor: (props.onClick ? 'pointer':'')}}>
        <SimpleDialog show={showHelpDialog} fullScreen={false} noCaption="Cancel" title={props.title} defaultValue={''} onClose={() => setShowHelpDialog(false)}>
            <p>{props.help}</p>
        </SimpleDialog>

        {!props.variant && <>
            <Grid container>
                <Grid item xs={12}>
                    <GroupBox title={props.title} variant='rag-block-panel'>
                        <div className={`rag-block rag-block-${valueClassName}`}>
                            <div onClick={handleShowHelpText} style={{ float: 'right', position: 'relative', color: '#fff', cursor:'pointer' }}><i style={{ fontSize: 22 }} className="pi pi-question-circle"></i></div>
                            <div className="value-text">{value}</div>
                            <div className="small-label-text">{props.supportingText && props.supportingText !== '' ? props.supportingText : '\u00A0'}</div>
                            <div className="label-text">Target: {targetValue && targetValue !== '' ? targetValue : '\u00A0'}</div>
                        </div>
                        {props.secondaryPanel && <>
                            <DemandAndCapacityPanel title="secondary" id={`sec_${props.id}`} range={props.range} value={props.secondaryPanel.value}
                                format={props.format} variant="small"
                                supportingText={props.secondaryPanel.supportingText}
                            />
                        </>}
                    </GroupBox>

                </Grid>

            </Grid>

            

        </>
        }
        {props.variant && <Grid container>
            <Grid item xs={4}>
                <div className={`rag-block rag-block-secondary rag-block-${valueClassName}`}>
                    <p>&nbsp;</p>
                </div>
            </Grid>
            <Grid item xs={8} className={valueClassName} style={{ marginTop: -10, textAlign:'center' }}>
                <br></br>
                <div className="small-value-text">{value}</div>
                {props.supportingText && <div className="small-label-text-secondary">{props.supportingText}</div>}
                {props.targetValue !== undefined && <div className="small-label-text-secondary">Target: {targetValue}</div>}
            </Grid>
        </Grid>
        }
    </div>
}