import { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm"
import { useParams } from "react-router-dom"
import { fetchEmergencyMetrics, fetchAmbulanceMetrics } from "../../../services/demandAndCapacityService";
import { FeedbackType } from '../../core/feedback'
import { AmbulanceMetric, EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";
import DemandAndCapacityOverview from "./demand-and-capacity-overview";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Loader from "../../core/loading";
import DemandAndCapacityEmergencyDashboard from "./demand-and-capacity-emergency";
import DemandAndCapacityDataQuality from "./demand-and-capacity-data-quality";
import Page from "../../core/page";
import DemandAndCapacityAmbulancDashboard from "./demand-and-capacity-ambulance";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";

interface DemandAndCapacityReportProps {
    mode?: 'emergency' | 'ambulance'

}

export default function DemandAndCapacityReport(props: DemandAndCapacityReportProps) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [emergencyMetrics, setEmergencyMetrics] = useState<EmergencyMetric[]>();
    const [ambulanceMetrics, setAmbulanceMetrics] = useState<AmbulanceMetric[]>();
    const [emergencyMetricsLoaded, setEmergencyMetricsLoaded] = useState(false);
    const [ambulanceMetricsLoaded, setAmbulanceMetricsLoaded] = useState(false);


    const [siteCodes, setSiteCodes] = useState<string[]>([]);
    const [tabs, setTabs] = useState<PagedTab[]>([]);
    

    let { siteCode } = useParams();
    const {
        setFeedbackStatus,
    }   
        = useForm({ initialObjectValues: {} })

    useEffect(() => {
        if (!props.mode || props.mode === "emergency")
            loadEmergencyMetrics()
        if (!props.mode || props.mode === "ambulance")
            loadAmbulanceMetrics()
    }
        , [])


        useEffect(() => {
                presentTabs()
        }
            , [siteCodes])

            useEffect(() => {
                if(!ambulanceMetricsLoaded || !emergencyMetricsLoaded) return
                if(emergencyMetrics && emergencyMetrics.length>0){
                    const sc = emergencyMetrics.map(m => m.siteCode).filter((x, i, a) => a.indexOf(x) === i)
                    setSiteCodes(sc)
                }
                else if(ambulanceMetrics){
                    const sc = ambulanceMetrics.map(m => m.siteCode).filter((x, i, a) => a.indexOf(x) === i)
                    setSiteCodes(sc)
                }
            }
                , [ambulanceMetricsLoaded,emergencyMetricsLoaded])

        

    const loadEmergencyMetrics = async () => {
        if (!siteCode) return

        setLoading(true)
        const response = await fetchEmergencyMetrics(siteCode);
        setEmergencyMetricsLoaded(true)
        setLoading(false)
        if (response && response.success && response.result) {
            setEmergencyMetrics(response.result)
            
            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setFeedbackStatus(FeedbackType.Error)
    }

    const loadAmbulanceMetrics = async () => {
        if (!siteCode) return

        setLoading(true)
        const response = await fetchAmbulanceMetrics(siteCode);
        setAmbulanceMetricsLoaded(true)
        setLoading(false)
        if (response && response.success && response.result) {
            setAmbulanceMetrics(response.result)

            setFeedbackStatus(FeedbackType.hide)
            return
        }
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleNavigateToAmbulanceDashboard = (sc: string) => {
        navigate(`/demandandcapacity/ambulance/${sc}`)

    }
    const handleNavigateToEmergencyDashboard = (sc: string) => {
        navigate(`/demandandcapacity/emergency/${sc}`)
    }


    const presentTabs=()=>{
        const t :PagedTab[] = []
        siteCodes.forEach(sc => {
            const emergencyData = emergencyMetrics ? emergencyMetrics.find(f => f.siteCode === sc) : undefined
            const ambulanceData = ambulanceMetrics ? ambulanceMetrics.find(f => f.siteCode === sc) : undefined
    
            t.push({
                title: emergencyData?.service.name ??  ambulanceData?.service.name ?? '',
                
                children: <DemandAndCapacityOverview
                    emergencyMetric={emergencyData}
                    ambulanceMetric={ambulanceData}
                    onNavigateToAmbulanceDashboard={handleNavigateToAmbulanceDashboard}
                    onNavigateToEmergencyDashboard={handleNavigateToEmergencyDashboard}
                />
            })
        });
        setTabs([...t])
    }


    if (loading)
        return <Grid container justifyContent="center" style={{ marginTop: '5vw', marginBottom: '6vw' }}><Loader loading={true} useBackdrop={false} /></Grid>

    if (!emergencyMetrics && !ambulanceMetrics)
        return <>No service</>


    if (props.mode === "emergency" && siteCode && emergencyMetrics) {
        return <DemandAndCapacityEmergencyDashboard emergencyMetrics={emergencyMetrics} siteCode={siteCode}/>
    }

    if (props.mode === "ambulance" && siteCode) {
        return <DemandAndCapacityAmbulancDashboard ambulanceMetrics={ambulanceMetrics??[]} siteCode={siteCode}/>
    } 

    return <>
        <Page title="Live Data Dashboard">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PagedTabs tabs={tabs} activeIndex={siteCodes.findIndex(f=>f==siteCode)}></PagedTabs>
                </Grid>
 
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8} />

                <Grid item xs={6} md={4}>
                    <DemandAndCapacityDataQuality 
                    emergencyMetric={emergencyMetrics && emergencyMetrics.length>0 ? emergencyMetrics.find(f=>f.siteCode=== siteCode):undefined} 
                    ambulanceMetric={ambulanceMetrics && ambulanceMetrics.length>0 ? ambulanceMetrics.find(f=>f.siteCode=== siteCode):undefined} />
                </Grid>

            </Grid>
        </Page>
    </>

}