import moment from "moment";
import { EmailAuditType } from "../interfaces/system/emailAuditTypes"
import { HttpGet, HttpResponse } from "./http"

export const fetchEmailAuditList = async (id?: number, recipient?: string, subject?: string, rangeFrom?: Date, rangeTo?: Date): Promise<HttpResponse<EmailAuditType[]>> => {
    let urlParams = '?';
    if (id) {
        urlParams += `id=${id}&`;
    }
    if (recipient) {
        urlParams += `recipient=${recipient}&`;
    }
    if (subject) {
        urlParams += `subject=${subject}&`;
    }
    if (rangeFrom) {
        let from = moment(rangeFrom).format('yyyy-MM-DD');
        
        urlParams += `rangeFrom=${from}&`;
    }
    if (rangeTo) {
        let to = moment(rangeTo).format('yyyy-MM-DD');
        urlParams += `rangeTo=${to}`;
    }
    const response = await HttpGet<EmailAuditType[]>(
        `EmailAudit/list${urlParams}`
    )

    return response
}
export const fetchEmailAudit = async (id: number): Promise<HttpResponse<EmailAuditType>> => {
    const response = await HttpGet<EmailAuditType>(
        `EmailAudit/${id}`
    )
    return response
}