export enum DatasetTypeEnum {
  Emergency = 1,
  Ambulance = 2
}
export enum EmergencyMetricEnum 
  {
      AttendeesSinceMidnight = 1,
      AverageMinsFromArrivalToDTA = 2,
      AverageMinsFromDTAToAdmission = 3,
      AverageWaitMinsInDepartment = 4,
      AverageWaitMinsInDepartmentTotal = 5,
      ConversationRate = 6,
      PatientsAdmittedSinceMidnight = 7,
      PatientsSeenWithin4Hours = 8,
      PatientsWaiting = 9,
      PatientsWaiting0_1Hours = 10,
      PatientsWaiting1_2Hours = 11,
      PatientsWaiting2_3Hours = 12,
      PatientsWaiting3_4Hours = 13,
      PatientsWaiting4_8Hours = 14,
      PatientsWaiting8_12Hours = 15,
      PatientsWaiting12PlusHours = 16,
      PercentageOfPatientsSeenWithin4Hours = 17

  }
 
export enum AmbulanceMetrics 
  {
      AverageHandoverTime = 1
  }