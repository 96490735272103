import { createNewDomainEntity, DomainEntityType } from "../domain/iDomainEntity"
import { FormSubmissionType } from "../reporting/form-submission"
import { CapacityGridType } from "./capacityGridType"
import { FormSubmissionFieldType, FormType } from "./formType"

export enum AutomatedReportTypeEnum {
   SitRep = 10,
   CapacityGrid = 20,
   CelSitRep=30
}

export interface AutomatedReportType {
    id:number
    name:string
    body:string
    subject:string
    deleted?:boolean
    includeDataCapture?:boolean
    submissionPerService?:boolean
    reportType?: DomainEntityType
    reportTypeId?:number
    distributionListId?:number
    schedules?:AutomatedReportScheduleType[]
    services?:AutomatedReportServiceType[]
 }
 export interface AutomatedReportServiceType{
   id: number,
   serviceId: number,
}
 export interface AutomatedReportScheduleType {
   id:number
   nextOccurrsOn?:Date|string
   monday:boolean
   tuesday:boolean
   wednesday:boolean
   thursday:boolean
   friday:boolean
   saturday:boolean
   sunday:boolean
}

export interface AutomatedReportDataType {
   dataCaptureSubmission:AutomatedReportDataCaptureType
   automatedReport:AutomatedReportType
   serviceStatus:AutomatedReportDataSubmissionType[]
   formSubmissions:FormSubmissionType[]
   gridSubmission:AutomatedReportDataCapacityGridType
}
export interface AutomatedReportDataCaptureType {
   submissions:[AutomatedReportDataSubmissionType]
   forms:FormType[]
}
export interface AutomatedReportDataCapacityGridType {
   submissions:[AutomatedReportDataGridSubmissionType]
   grids:CapacityGridType[]
}
export interface AutomatedReportDataGridSubmissionType {
   region:DomainEntityType
   serviceCategory:DomainEntityType
   createdOn:Date
   comments:string
   columns:FormSubmissionFieldType[]
   capacityGridId:number
   formResultId: number
   formResultOptionId: number
   id:number
   resultLabel: string
   service: DomainEntityType
}
export interface AutomatedReportDataSubmissionType {
   region:DomainEntityType
   serviceCategory:DomainEntityType
   createdOn:Date
   comments:string
   fields:FormSubmissionFieldType[]
   formId:number
   formResultId: number
   formResultOptionId: number
   id:number
   resultLabel: string
   service: DomainEntityType
}
export interface SystemConfigType {
   id:number
   accessRequestReminderDurationInDays?:number
   adminDistributionListId?:number
   deleteEmailAuditDayCount?:number
   deleteAmbulanceDataDayCount?:number
   deleteEmergencyDataDayCount?:number
   deleteLogsDayCount?:number
   distributionList?:DomainEntityType
}

export interface APIUserType {
   id:number
   key?:string
   userName:string
   name?:string
   deleted?:boolean
   description?:string
   contactName?:string
   contactEmail?:string
   privateKey?:string
   permissions?:APIUserPermissionType[]
}
export interface APIUserPermissionType {
   deleted:boolean
   siteCode:string
}

export const createNewAPIUser = (): APIUserType =>{
   
   return {id:0, name:'',key:'',userName:'',permissions:[{siteCode:'',deleted:false}]}
}
 export const createNewAutomatedReport = (): AutomatedReportType =>{
   
    return {id:0, name:'',body:'',reportType:createNewDomainEntity(),subject:''}
 }
 export const createSystemConfig= (): SystemConfigType =>{
   return {id:0}
}
 export const createNewAutomatedReportSchedule = (): AutomatedReportScheduleType =>{
   return {id:-Date.now(),monday:false,tuesday:false,wednesday:false,
   thursday:false, friday:false,saturday:false,sunday:false}
}