import React from 'react';
import ActionButton from "../core/actionButton";

export interface OKCancelButtonsProps {
    onOK: ()=> void
    onCancel: ()=> void
    cancelLabel?:string
    okLabel?:string
    okDisabled?:boolean
    okHidden?:boolean
    showCancel?:boolean
  }

const OKCancelButtons:  React.FC<OKCancelButtonsProps> = (props) => {
    const [okDisabled, setOKDisabled] = React.useState(props.okDisabled);
    React.useEffect(() => {
        setOKDisabled(props.okDisabled)

     }, [props.okDisabled])

    return <div style={{margin:'10px 10px 10px 0', width:'100%'}}>
                <div style={{float:'left',marginRight:'5px'}}>
                    {(props.showCancel === undefined || props.showCancel) && <ActionButton  onClick={props.onCancel} label={props.cancelLabel||"Back"} severity="secondary"></ActionButton>}
                </div>
                <div style={{float:'right'}}>
                    {!props.okHidden &&  <ActionButton disabled={okDisabled} onClick={props.onOK} label={props.okLabel||"Save"} severity="success"></ActionButton>}
                </div>
            </div>
}

export default OKCancelButtons