import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { ServiceCategoryEnum, ServiceType } from '../interfaces/system/serviceType';
import AddressDetailsFormValidator from './address';
import {ValidatorType} from './ValidatorType'
export class ServiceValidator implements ValidatorType
{
   addressValidator = new AddressDetailsFormValidator()

   formValidator = new FormValidation([])
   name= new FormValidationField('','')
   area= new FormValidationField('','')
   category= new FormValidationField('','')
   tabIndex = 0
   addressIsValid = true
   // divisionValidators:FormValidation[]= []


     initialiseValidationFields = (objectValues: ServiceType) => {
        this.formValidator = new FormValidation([])
        this.name= this.formValidator.createTextField(objectValues.name)
        this.area= this.formValidator.createDomainTypeField(objectValues.areaId)
        this.category= this.formValidator.createDomainTypeField(objectValues.categoryId)


        this.tabIndex =0
    }
    
    

 validate =  (validatorObjectValues: ServiceType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 
     
     this.addressIsValid = validatorObjectValues.categoryId.toString() === ServiceCategoryEnum.Opel.valueOf().toString() ||
      this.addressValidator.validate(validatorObjectValues.address)

      
      if(isValid && !this.addressIsValid)
         this.tabIndex = 1

    return isValid && this.addressIsValid
 }
}

export default ServiceValidator

