import { FilterMatchMode } from "primereact/api";
import { useState } from "react";
import { UserType } from "../../../interfaces/user/iUser"
import DataTable from "../../core/dataTable";

export interface UserListProps {
    userList: UserType[],
    onUserSelection?: (selectedUser: UserType) => void,
    onError?: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void,
}
export function UserList(props: UserListProps) {
    const itemsPerPage = 25;
    const [userList, setUserList] = useState(props.userList);


    const handleRowSelection = (selectedUser: any) => {
        const user = selectedUser ? (selectedUser.value ? selectedUser.value : selectedUser) as UserType : undefined;
        //console.log(user);
        if (props.onUserSelection && user) {
            props.onUserSelection(user);
        }
    }
    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    return <>

        <DataTable dataKey="id" loading={false}
        filters ={{deleted:{ value: null, matchMode: FilterMatchMode.EQUALS  },administrator:{ value: null, matchMode: FilterMatchMode.EQUALS  }}}
            paginationRowsPerPage={itemsPerPage} value={userList} onSelect={(row) => handleRowSelection(row)} onEdit={(row) => handleRowSelection(row)}
            editButton={true} paginator={(userList?.length ?? 0) > itemsPerPage} filter={true} allowExport={true}
            columns={[
                { header: "Id", field: "id" },
                { header: "Name", field: "fullName" },
                { header: "Email", field: "email" },
                { header: "Active", field: "deleted", formatType: "yesno-inverse",filter:true },
                { header: "Administrator", field: "administrator", formatType: "yesno",filter:true }]}
        />
    </>
}