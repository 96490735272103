import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { CapacityGridFieldOptionText } from "../../../../interfaces/system/capacityGridType"
import SimpleDialog from "../../../core/simpleDialog";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";

export interface CapacityGridColumnEditorDropdownEditProps {
  item: CapacityGridFieldOptionText,
  onUpdate: (item: CapacityGridFieldOptionText) => void
}
export function CapacityGridColumnEditorDropdownEdit(props: CapacityGridColumnEditorDropdownEditProps) {

  // state vars
  const [selectedItem, setSelectedItem] = useState(props.item);
  const [showEditDialog, setShowEditDialog] = useState(true)

  // hook setups
  useEffect(() => {
    setShowEditDialog(true);
  }, [])

  useEffect(() => {
    setSelectedItem(props.item);
    setShowEditDialog(true);
  }, [props.item])


  const handleActiveUpdated = (e: any) => {
    if (!selectedItem) return;
    setSelectedItem({ ...selectedItem, deleted: !e.target.checked })
  }
  const handleFieldUpdated = (e: any) => {
    if (!selectedItem) return;

    if ((e.target.id as string).indexOf('.') > -1) {
      const fieldSplits = (e.target.id as string).split('.');
      let o = (selectedItem as any)[fieldSplits[0]] ?? { id: 0, name: '' };
      o[fieldSplits[1]] = e.target.value === '' ? undefined : isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value)
      setSelectedItem({ ...selectedItem, [fieldSplits[0]]: o })
    } else {
      setSelectedItem({ ...selectedItem, [e.target.id]: e.target.value || '' })
    }
  }

  const handleCancel = () => {
    setShowEditDialog(false)
  }

  const handleOk = () => {
    setShowEditDialog(false);
    if (selectedItem) {
      props.onUpdate(selectedItem);
    }
  }

  if (!selectedItem) {
    return <></>
  }

  return <>
    <SimpleDialog show={showEditDialog} title="Edit dropdown option" onOk={handleOk} onClose={handleCancel} yesno={true} yesCaption="Save" noCaption="Cancel">
      <Grid container spacing={2} justifyContent="left">
        <Grid item xs={12}>
          <TickBox id="deleted" label="Active" checked={!selectedItem.deleted} onChange={handleActiveUpdated} />
        </Grid>

        <Grid item xs={12}>
          <TextBox id="value" label="Value" value={selectedItem.value} onChange={handleFieldUpdated} />
        </Grid>
      </Grid>
    </SimpleDialog>
  </>
}