import { Grid } from "@mui/material";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import { FormEscalationTrendCriteriaProps } from "./form-escalation-trend";
import { ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { useState, useEffect} from "react";
import { ServiceStatusCategoryReportType, ServiceStatusReportType } from "../../../interfaces/reporting/form-submission";
import { formatDateTime } from "../../../extensions/formatter";
import { dateDiffFromNowInHours } from "../../../services/utils";
import Panel from "../../core/panel";

interface FormServiceStatusReportProps {
    areaHierarchy:AreaHierarchyType
    onFetchData: (criteria:FormEscalationTrendCriteriaProps)=> void
    criteria?:BasicReportFilterCriteria
    data?:ServiceStatusReportType
    defaultFilterCriteria?:BasicReportCriteria
  }

  export default function FormServiceStatusReport(props:FormServiceStatusReportProps) {
    const  [data, setData] = useState(props.data); 

    useEffect(() => {
      setData(props.data)
  },[props.data])
    
const handleExport = () =>{

  const title= 'Service Status Report'
  const headers: ExcelExportHeader[] =[{title:"", width:60}]
  
 const sheets:ExcelExportSheet[] = []

    data?.categories?.forEach(f => {

      const serviceContainer = document.querySelector(`#service-category-${f.id}`)

      if(!serviceContainer) return
      sheets.push({name:f.name, title:title, elements:[{table:serviceContainer.querySelector("table"), title:title}]})
  })


  exportToExcel({
  defaultHeaderWidth:40,
  headers:headers,
  sheets:sheets
  })
}

const handleApplyFilter = (criteria:BasicReportCriteria)=> {

  props.onFetchData({serviceIds: criteria.selectedServiceIds.map(m=>m.id), serviceCategoryIds:criteria?.selectedServiceCategoryIds??[], 
    fromDate:criteria.fromDate, toDate:criteria.toDate})
}

   const ServiceStatusElem = (s:ServiceStatusCategoryReportType)=> {
    return <Grid item xs={10} >
      <div id={`service-category-${s.id}`}>
      <table className="reporting-table underlined" style={{padding:5, width:'100%'}}>
        <thead>
            <tr>
                <th style={{width:'35vw'}}>Service</th>
                <th>Escalation Level</th>
                <th>Submitted On</th>
                <th>Submitted By</th>
            </tr>
        </thead>
        <tbody>
            
                {s?.services.map((f) => {
                  const hoursSinceUpdate = dateDiffFromNowInHours(f.submittedOn)
                  const reportingLabel = hoursSinceUpdate <=4 ? f.reportingLabel : '0'

                  return (<tr>
                    <td>
                      <div style={{marginTop:-3}}>
                      <div style={{marginTop:-4,float:'left'}}><img src={`/assets/icons/map_hospital_marker_${reportingLabel}a.png`} alt='' /></div>
                      <div style={{marginTop:12,float:'left'}}> {f.name}</div></div>
                    </td>
                    <td className={`form-result-${reportingLabel}`}>{f.reportingLabel}</td>
                    <td className="">{formatDateTime(f.submittedOn)}</td>
                    <td className="">{f.submittedBy.fullName}</td>
                  </tr>)
                }
                )}
            
        </tbody>
    </table>
    </div>
  </Grid>
   }


   const noData = !data || !data.categories || data.categories.length<1
   

 

    const tabs: PagedTab[] = [];
    data?.categories?.forEach(s => {
          tabs.push({
              title: s.name,
              children:ServiceStatusElem(s)
          })
      });

      let serviceCount =0;
       data?.categories?.forEach(s => serviceCount+= s.services.length)
      
      return  <Grid container spacing={2}>
          <Grid item xs={10}>
              <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
              defaultFilterCriteria={props.defaultFilterCriteria}
              validateServiceSelections={true}
              onExport={handleExport}/>
          </Grid>
          {noData && <Grid item xs={12}>No data to display</Grid>}
            {serviceCount>25 && <Grid item xs={12} >
              <PagedTabs tabs={tabs}></PagedTabs>                
            </Grid>
            }
            {serviceCount<=25 && <Grid item xs={12} >
              {tabs?.map((f) => {
                return <Panel title={f.title}><div>{f.children}</div></Panel>
              })
            }
            </Grid>
            }
      </Grid>
        
        
}


