import { useEffect, useState } from "react";
import { DistributionListMembersResultType } from "../../../../interfaces/system/distributionListTypes";
import { fetchDistributionListMembers } from "../../../../services/distribution-list-service";
import DataTable from "../../../core/dataTable";

export interface DistributionListMemberListProps {
    itemsPerPage?: number,
    onRowClicked?: (item: DistributionListMembersResultType) => void,
    onError: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void
}
export function DistributionListMemberList(props: DistributionListMemberListProps) {
    const [listItems, setListItems] = useState<DistributionListMembersResultType[]>();
    const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ?? 25);
    const [loading, setLoading] = useState(true);

    // hooks
    useEffect(() => {
        //console.clear();
        init();
    }, []);

    const init = async () => {
        handleLoading();
        const response = await fetchDistributionListMembers();
        handleLoaded();
        if (response && response.success && response.result) {
            setListItems(response.result);
        } else {
            handleError(response.error ?? 'Unknown error');
        }
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleItemSelection = (item: DistributionListMembersResultType) => {
        if (props.onRowClicked) {
            props.onRowClicked(item);
        }
    }

    const handleLoading = () => {
        setLoading(true);
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        setLoading(false);
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    return <>
        <DataTable loading={loading}
            dataKey="id"
            paginationRowsPerPage={itemsPerPage}
            onSelect={(row) => handleItemSelection(row.value)}
            onEdit={(row) => handleItemSelection(row)}
            editButton={true}
            paginator={(listItems?.length || 0) > itemsPerPage}
            filter={true}
            allowExport={true}
            value={listItems}
            columns={[
                { header: "Display Name", field: "memberDetails.userDisplayName" },
                { header: "Email Address", field: "memberDetails.userEmailAddress" },
                { header: "EMS User", field: "na", formatCallBack: (rowData: DistributionListMembersResultType) => { return (rowData.memberDetails.userId ?? 0) > 0? true : false; }, formatType: "yesno" }
            ]} />
    </>
}