import { useState } from "react"
import { CapacityGridColumnType } from "../../../../interfaces/system/capacityGridType"
import { fetchCapacityGridSharedColumns, saveCapacityGridColumn } from "../../../../services/capacity-grid-service"
import DebugVariable from "../../../core/debugVariable"
import CapacityGridColumnEditor from "../capacity-grid-builder/capacity-grid-column-editor"

export interface CapacityGridSharedColumnEditorProps {
  item: CapacityGridColumnType,
  calculatedFieldNames: string[],
  onError: (errorMessage: string) => void,
  onCancel: () => void,
  onSaved: (item: CapacityGridColumnType) => void
}
export function CapacityGridSharedColumnEditor(props: CapacityGridSharedColumnEditorProps) {
  const [selectedItem, setSelectedItem] = useState<CapacityGridColumnType>(props.item)


  const handleError = (errorMessage: string) => {
    props.onError(errorMessage);
  }

  const handleEditorCancel = () => {
    props.onCancel();
  }

  const handleEditorSaved = async (item: CapacityGridColumnType, isValid: boolean) => {
    if (!isValid) {
      handleError('Please review fields highlighted');
      return;
    }
    const response = await saveCapacityGridColumn(item);
    if (response && response.success) {
      // force refresh of data
      await fetchCapacityGridSharedColumns(false, false);
      props.onSaved(item);
      return;
    }
    handleError(response?.error ?? 'Unknown error');
  }

  return <>
    <CapacityGridColumnEditor column={selectedItem} onUpdate={handleEditorSaved} allowEditSharedColumns={true} calculatedFieldNames={props.calculatedFieldNames} showSaveButton={true} onCancel={handleEditorCancel}></CapacityGridColumnEditor>
  </>
}