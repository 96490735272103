import { CapacityGridColumnType, CapacityGridType } from "../../../../interfaces/system/capacityGridType"
import OKCancelButtons from "../../../core/OKCancelButtons";
import { Grid } from "@mui/material";
import BasicTab from "../../../core/basicTab";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import useForm from "../../../../hooks/useForm";
import { useEffect, useState } from "react";
import Loader from "../../../core/loading";
import { CapacityGridValidator } from "../../../../validators/capacity-grid-validator";
import Dropdown from "../../../core/dropdown";
import CapacityGridColumnList from "./capacity-grid-column-list";
import DebugVariable from "../../../core/debugVariable";
import { createNewDomainEntity } from "../../../../interfaces/domain/iDomainEntity";
import ActionButton from "../../../core/actionButton";


interface CapacityGridEditProps {
    gridToEdit?: CapacityGridType
    onSave: (capacityGrid: CapacityGridType, valid: boolean) => void
    onCancel: () => void
}

export default function CapacityGridEdit(props: CapacityGridEditProps) {

    // state vars
    const [validator] = useState(props.gridToEdit?.validator)
    const { validateForm, objectValues, setObjectValues, setValues, setValidator } = useForm({ initialObjectValues: props.gridToEdit, validator: validator })
    const [dirty, setDirty] = useState(false);

    // component events
    useEffect(() => {
        window.addEventListener('popstate', function () {
            props.onCancel()
        }, false);
    }, [])

    useEffect(() => {
        if (props.gridToEdit?.id !== objectValues?.id) {
            setObjectValues(props.gridToEdit)
        }
        if (props.gridToEdit && !props?.gridToEdit?.validator) {
            props.gridToEdit.validator = new CapacityGridValidator()
        }
        setValidator(props?.gridToEdit?.validator)
    }, [props.gridToEdit])

    // event handlers
    const handleCancel = () => { if (props.onCancel) props.onCancel() }

    const handleSave = async () => {
        setValidator(validator)
        let isValid = validateForm(objectValues)

        setObjectValues({
            ...objectValues,
            validator: validator
        })
        props.onSave(objectValues, isValid)
    }

    const handleFieldUpdated = (e: any) => {
        if (!objectValues) return;
        let updatedGrid = { ...objectValues, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setObjectValues(updatedGrid);
        setDirty(true);
    }
    const handleDeletedUpdated = (e: any) => {
        if (!objectValues) return;
        let updatedGrid = { ...objectValues, deleted: e.target.checked ? true : false };
        setObjectValues(updatedGrid);
        setDirty(true);
    }
    const handleResourceTypeUpdated = (e: any) => {
        if (!objectValues) return;
        let resType = createNewDomainEntity();
        resType.id = parseInt(e.target.value);
        let updatedGrid: CapacityGridType = { ...objectValues, resourceType: resType };
        setObjectValues(updatedGrid);
        setDirty(true);
    }

    const handleColumnsUpdated = (items: CapacityGridColumnType[]) => {
        setDirty(true);
        setObjectValues({
            ...objectValues,
            columns: items
        })

    }

    // dynamic components

    if (!objectValues) {
        return <Loader loading={true} />
    }

    const CapacityGridColumnListElement = <>
        <DebugVariable hide={true} variable={objectValues.columns}></DebugVariable>
        <CapacityGridColumnList grid={objectValues} onNew={() => { return false; }} onUpdate={handleColumnsUpdated} resultList={objectValues?.columns}></CapacityGridColumnList>
    </>

    const CapacityGridEditElement = <>
        <Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
            <Grid item xs={2}>
                <TextBox id="id" label="Id" value={objectValues.id} disabled={true} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="deleted" label="Deleted" checked={objectValues.deleted} onChange={handleDeletedUpdated} />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={12}>
                <TextBox id="name" label="Name" value={objectValues.name} validationField={validator?.name} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={12}>
                <Dropdown id="resourceType.id" label="Type" optionName="capacity-grid-resource-type" value={objectValues?.resourceType?.id} onChange={handleResourceTypeUpdated} />
            </Grid>
            <Grid item xs={12}>
                <TextBox id="description" label="Description" rows={6} value={objectValues.description} onChange={handleFieldUpdated} />
            </Grid>
        </Grid>
    </>

    return <>
        <Grid container>
            <BasicTab key='tabs' tabs={[{
                label: 'Column Editor', component: <>{CapacityGridColumnListElement}</>
            }, {
                label: 'Capacity Grid Details', component: <>{CapacityGridEditElement}</>
            }]}>

            </BasicTab>

        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {dirty && <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />}

                {!dirty && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                    <div style={{ float: 'left', marginRight: '5px' }}>
                        <ActionButton onClick={props.onCancel} label="Back" severity="secondary"></ActionButton>
                    </div>
                </div>}
            </Grid>
        </Grid>
    </>
}