import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { setUser, userRoleIsAdmin } from "../../../services/storageService";
import { saveUserProfile } from "../../../services/userService";
import { EditUserProfileValidator } from "../../../validators/edit-user-profile-validator";
import ActionButton from "../../core/actionButton";
import Dropdown from "../../core/dropdown";
import TextBox from "../../core/textBox";
import { UserEditorProps } from "./edit-user";
import TickBox from "../../core/tickBox";
import Panel from "../../core/panel";

export interface EditUserProfileProps extends UserEditorProps {
    ShowAdditionalOptions?: boolean
}

export function EditUserProfile(props: EditUserProfileProps) {
    const [userToEdit, setUserToEdit] = useState(props.User);
    const [showAdditionalOptions, setShowAdditionalOptions] = useState(props.ShowAdditionalOptions ?? false);

    const [validator, setValidator] = useState(new EditUserProfileValidator())
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setUserToEdit(props.User);
        setValidator(new EditUserProfileValidator());
        setIsValid(true);
    }, [props.User])

    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }
    const handleImpersonate = () => {
        window.open(`/impersonate/${userToEdit.userId}`, "_blank");
    }
    const handleSave = async() => {
        handleLoading();
        const response = await saveUserProfile(userToEdit);
        handleLoaded();
        if (!response || !response.success || !response.result) {
            handleError(response?.error ?? 'An unknown error was encountered');
            return;
        }
        setIsValid(true);
        // update user stored in session
        setUser({ ...userToEdit, jobRole: response.result.jobRole, fullName: response.result.fullName, serviceName: response.result.serviceName, areaId: response.result.areaId, deleted:response.result.deleted, administrator:response.result.administrator });
        if (props.OnSaved) {
            props.OnSaved(response.result);
        }
    }

    const handleFieldChanged = (e: any) => {
        if (!userToEdit) return;
        type ObjectKey = keyof typeof userToEdit;
        const propertyEdited = e.target.id as ObjectKey;
        const currentValue = userToEdit[propertyEdited] as string;
        // only allow changes when the values actually change
        if (currentValue !== (e.target.value as string)) {
            setUserToEdit({ ...userToEdit, [e.target.id]: e.target.value || '' })

        }
        let valid = validator.validate(userToEdit);
        setIsValid(valid);
        setValidator(validator);
    }

    const handleCheckboxUpdated = (e: any) => {
        if (!userToEdit) return;
        let updatedGrid = { ...userToEdit, [e.target.id]: e.target.checked ? true : false };
        setUserToEdit(updatedGrid);
        let valid = validator.validate(userToEdit);
        setIsValid(valid);
        setValidator(validator);
    }

    return <>
        <Panel title="Edit User Details">

            <Grid container spacing={2} justifyContent="left">
                {showAdditionalOptions && <>
                    <Grid item xs={2}>
                        <TickBox id="deleted" label="Deleted" checked={userToEdit.deleted} onChange={handleCheckboxUpdated} />
                    </Grid>
                    <Grid item xs={10}>
                        <TickBox id="administrator" label="Super User" checked={userToEdit.administrator} onChange={handleCheckboxUpdated} />
                    </Grid>
                </>}
                <Grid item xs={6}>
                    <TextBox id="email" label="Email" value={userToEdit.email} validationField={validator.email}  onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <TextBox id="fullName" label="Full name" validationField={validator.fullName} value={userToEdit.fullName} onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <TextBox id="jobRole" label="Job Role" validationField={validator.jobrole} value={userToEdit.jobRole} onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <TextBox id="applicationUse" label="Application Use" validationField={validator.applicationUse} value={userToEdit.applicationUse} onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <TextBox id="serviceName" label="Service Name" validationField={validator.serviceName} value={userToEdit.serviceName} onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <Dropdown id="areaId" label="Area" optionName="regions" validationField={validator.areaId} value={userToEdit.areaId} onChange={handleFieldChanged} />
                </Grid>
                <Grid item xs={6}></Grid>


                        <Grid item xs={6}>
                            {userRoleIsAdmin() && <div style={{ float: 'left' }}>
                                <ActionButton onClick={handleImpersonate} label="Impersonate User" severity="help" disabled={!isValid}></ActionButton>
                            </div>}
                            <div style={{ float: 'right' }}>
                                <ActionButton onClick={handleSave} label="Save" severity={isValid ? 'success' : 'secondary'} disabled={!isValid}></ActionButton>
                            </div>
                        </Grid>
                        <Grid item xs={6}> 
                            
                        </Grid>
            </Grid>
        </Panel>
    </>
}