import { useEffect, useState } from "react";
import { ComponentPropsBase } from "../../../interfaces/system/componentPropsBase";
import { FormResultOptionType, FormResultType } from "../../../interfaces/system/formType";
import { Grid } from "@mui/material";
import { FormResultRangeList } from "./form-result-range-list";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import { PresetColorPicker } from "../../core/preset-color-picker";
import { FormResultOptionValidator } from "../../../validators/form-result-validator";

export interface FormResultRangeEditorProps extends ComponentPropsBase {
    selectedItem: FormResultType,
    onUpdated: (item: FormResultOptionType, valid: boolean) => void,
    onOrdered: (items: FormResultOptionType[]) => void,
    onNew: (items: FormResultOptionType[]) => void
}
export function FormResultRangeEditor(props: FormResultRangeEditorProps) {
    const [selectedItem, setSelectedItem] = useState<FormResultType>(props.selectedItem);
    const [itemToEdit, setItemToEdit] = useState<FormResultOptionType>();
    const [isValid, setIsValid] = useState(false);
    const [modelValidator, setModelValidator] = useState(new FormResultOptionValidator());

    useEffect(() => {
        setSelectedItem(props.selectedItem);
        setModelValidator(new FormResultOptionValidator());
    }, [props.selectedItem]);


    const handleItemClicked = (item: FormResultOptionType) => {
        setIsValid(modelValidator.validate(item));
        setItemToEdit(item);
    }
    const handleOrdered = (items: FormResultOptionType[]) => {
        if (props.onOrdered) {
            props.onOrdered(items);
        }
    }
    const handleNewItemAdded = (items: FormResultOptionType[]) => {
        if (props.onNew) {
            props.onNew(items);
        }
        
        const newItem = items.slice(-1)[0];
        newItem.id = -items.length
        setItemToEdit(newItem);
        setIsValid(modelValidator.validate(newItem));
    }

    const handlePropUpdated = (prop: string, val: any) => {
        const i = itemToEdit
        if (!i) return;

               
        (i as any)[prop] = val

        if (prop.indexOf('.') > -1) {
            const fieldSplits = (prop as string).split('.');
            let xx: any = i;
            let o = xx[fieldSplits[0]]
            if (!o) {
                o = { id: 0, name: '' }
            }
            o[fieldSplits[1]] = val === '' ? undefined : val;
            setItemToEdit({ ...i, [fieldSplits[0]]: o })
        } else {
            setItemToEdit({ ...i, [prop]: val === '' ? undefined : val });
        }
        setIsValid(modelValidator.validate(i));
        
        if (props.onUpdated) {
            props.onUpdated(i, isValid);
        }
        
    }
    const handleFieldUpdated = (e: any) => {
        handlePropUpdated(e.target.id, e.target.value);
    }
    const handleDeletedUpdated = (e: any) => {
        handlePropUpdated('deleted', e.target.checked ? true : false);
    }
    const handleColorSelection = (prop: string, color: string) => {
        if (color && selectedItem) {
            handlePropUpdated('style.' + prop, color);
        }
    }

    return <>
        <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
            <Grid item xs={12}>
                {(selectedItem?.id??0) <=0 && <>
                        <p>You must save the form result before a range can be added</p>
                </>
                }
            </Grid>
            <Grid item xs={4}>
                <FormResultRangeList itemToEdit={selectedItem} onNew={handleNewItemAdded} onSelection={handleItemClicked} onOrder={handleOrdered} />

            </Grid>
            <Grid item xs={8}>
                
                {itemToEdit &&
                    <Grid container spacing={2} justifyContent="left">
                        {itemToEdit.id > 0 && <>
                            <Grid item xs={2}>
                                <TextBox id="id" label="Id" value={itemToEdit.id} disabled={true} />
                            </Grid>
                            <Grid item xs={2}>
                                <TickBox id="deleted" label="Deleted" checked={itemToEdit.deleted} onChange={handleDeletedUpdated} />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            
                        </>
                        }
                        

                        <Grid item xs={12}>
                            <div style={{ backgroundColor: itemToEdit.style?.background, color: itemToEdit.style?.fontColour, fontSize: itemToEdit.style?.fontSize, fontWeight: itemToEdit.style?.fontWeight, fontFamily: itemToEdit.style?.fontName, padding: '.5rem', marginTop: '5px' }} className={itemToEdit.style?.className}>
                                <p>Example: {itemToEdit.name}</p>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <TextBox id="name" label="Name" value={itemToEdit.name} validationField={modelValidator.name} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextBox id="shortName" label="Short Name" value={itemToEdit.shortName} validationField={modelValidator.shortName} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextBox id="style.className" label="Class Name" value={itemToEdit.style?.className} onChange={handleFieldUpdated} />
                            <p style={{ marginTop: '0' }}><small><strong>Optional</strong>: use the format 'form-result-LEVEL' e.g. <em>form-result-1</em> or <em>form-result-green</em></small></p>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={4}>
                                <TextBox id="weightingFrom" label="Default Range From" type="number" value={itemToEdit.weightingFrom} onChange={handleFieldUpdated}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox id="weightingTo" label="Default Range To" value={itemToEdit.weightingTo}  type="number" onChange={handleFieldUpdated}/>
                            </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={4}>
                            <TextBox id="style.fontName" label="Font Name" value={(itemToEdit.style?.fontName ?? '') === '' ? 'arial' : itemToEdit.style?.fontName} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox id="style.fontSize" label="Font Size" value={(itemToEdit.style?.fontSize ?? '') === '' ? '14px' : itemToEdit.style?.fontSize} onChange={handleFieldUpdated} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox id="style.fontWeight" label="Font Weight" value={(itemToEdit.style?.fontWeight ?? '') === '' ? 'bold' : itemToEdit.style?.fontWeight} onChange={handleFieldUpdated} />
                        </Grid>

                        <Grid item xs={12}></Grid>
                        <Grid item xs={4}>
                            <TextBox id="style.fontColour" label="Font Colour" value={itemToEdit.style?.fontColour} onChange={handleFieldUpdated} />
                            <div style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '5px', textAlign: 'center' }}>
                                <PresetColorPicker value={itemToEdit.style?.fontColour} onChange={(color) => { handleColorSelection('fontColour', color) }} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox id="style.background" label="Background Colour" value={itemToEdit.style?.background} onChange={handleFieldUpdated} />
                            <div style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '5px', textAlign: 'center' }}>
                                <PresetColorPicker value={itemToEdit.style?.background} onChange={(color) => { handleColorSelection('background', color) }} />
                            </div>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    </>
}