import { Grid } from "@mui/material";
import { Column } from "primereact/column";
import { useState, useEffect} from "react";
import {  formatDateTimeWithDay } from "../../../extensions/formatter";
import { NecsImportAuditType } from "../../../interfaces/reporting/system";
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import DataTable from "../../core/dataTable";

interface NecsImportAuditReportProps {
  data?:NecsImportAuditType[]
  }

  
export default function NecsImportAuditReport(props:NecsImportAuditReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    
    setData(props.data)

},[props.data])
  
  
const handleExport = () =>{
 
  const title= 'Necs Data Import Audit Report'
   
  const exportData:ExcelExportDataRow[]=[]
  const sheets:ExcelExportSheet[] = []
  
       sheets.push({title:title,elements:[{}],name:'Necs Data Import Audit Report'})
   
       const headers: ExcelExportHeader[] =[{title:"User", width:50},
       {title:"Event", width:50},
       {title:"Date/time", width:20},]

     
       data?.forEach(d => {
        exportData.push({cols:[{value:d.event},
        {value:formatDateTimeWithDay(d.createdOn)}] })
       })

   exportToExcel({
   defaultHeaderWidth:20,
   headers:headers,
   data:exportData,
   sheets:sheets
   })
} 

        return  <Grid container spacing={2}>
          <Grid item xs={12} >
          <DataTable 
            dataKey="id"
            loading={data===undefined}
            paginationRowsPerPage={50}
            paginator={(data?.length || 0) > 50}
            filter={true}
            allowExport={true}
            value={data}
            columns={[
                { header: "Audit Entry", field: "event" },
                { header: "Imported On", field: "createdOn", formatType: "datetime" }
            ]} />
          </Grid>
    </Grid>
  }



