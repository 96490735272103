import { DomainEntityType } from "../domain/iDomainEntity"
import { getLocalOptions } from "../../services/domainEntitiesService"
import { DatasetTypeEnum, EmergencyMetricEnum } from "../system/dataTypes"

 export interface EmergencyMetric  {
    siteCode:string
    lastUpdatedOn:Date
    patientsWaiting:number
    patientsWaitingGt4Hours:number
    attendeesSinceMidnight:number
    totalAttendees:number
    averageAttendeesForTimeOfDay:number
    percentageOfPatientsInDepartmentLt4Hours:number
    currentAverageTimeInDepartment:number
    totalAverageTimeInDepartment:number
    patientsAdmitted:number
    conversionRate:number
    timeFromArrivalToAdmitDecision:number
    timeFromAdmitDecisionToAdmission:number
    currentPatientWaits:EmergencyMetricWait
    totalPatientWaits:EmergencyMetricWait
    arivalsByHour: EmergencyMetricArrivalsByHour[]
    service:DomainEntityType
 }


 export interface EmergencyMetricWait  {
    ltOneHour : number
    oneToTwoHours : number
    twoToThreeHours : number
    threeToFourHours : number
    fourToEightHours : number
    eightToTweleveHours : number
    gtTweleveHours : number
 }

 export interface EmergencyMetricArrivalsByHour  {
    hour : number
    actual : number
    expected : number
 }

 export interface AmbulanceMetric  {
    siteCode:string
    lastUpdatedOn:Date
    service:DomainEntityType
    meanTurnAroundTimeSeconds:number
    onSiteAwaitingHandover:number
    onSiteHandoverComplete:number
    onSiteTotal:number
    totalConveyances:number
    totalArrivals:number
    arrivalsInLast3Hours:number
    enRoute:number
 }
 export interface DemandAndCapacityMetrics  {
   ambulance?:AmbulanceMetric
   emergency?:EmergencyMetric
 }

 export const createNewDemandAndCapacityMetric = () : DemandAndCapacityMetrics =>{
   return {ambulance:undefined, emergency:undefined}
  }

 

   export const getMetricTextFromId = async (datasetTypeId: DatasetTypeEnum, metricTypeId: EmergencyMetricEnum) => {
     const datasetList = await getLocalOptions('dataset-list')
     if(!datasetList || datasetList.length===0) return ''
     const dataset = datasetList.find(f=> f.id === datasetTypeId as number)
     if(!dataset || !dataset.children) return ''
     return dataset.children.find(f=> f.id === metricTypeId as number)?.name
   }
   export const getMetricValueFromId = async (datasetTypeId: DatasetTypeEnum, 
                                             metricTypeId: number,
                                              metrics: DemandAndCapacityMetrics ) => {
     
       if(datasetTypeId === DatasetTypeEnum.Emergency as number)
         return getEmergencyMetricValueFromId(metricTypeId, metrics.emergency)
       if(datasetTypeId === DatasetTypeEnum.Emergency as number)
         return getAmbulanceMetricValueFromId(metricTypeId, metrics.ambulance)

   }
   export const getDatasetLastUpdated = async (datasetTypeId: DatasetTypeEnum, 
       metrics: DemandAndCapacityMetrics ) => {

      if(datasetTypeId === DatasetTypeEnum.Emergency as number)
      return metrics.emergency?.lastUpdatedOn
      if(datasetTypeId === DatasetTypeEnum.Emergency as number)
      return metrics.ambulance?.lastUpdatedOn

}
   export const getEmergencyMetricValueFromId = async (metricTypeId: EmergencyMetricEnum,
      metrics?: EmergencyMetric ) => {
       if(!metrics) return 'not set'
       switch(metricTypeId){
         case EmergencyMetricEnum.AttendeesSinceMidnight:
           return metrics.attendeesSinceMidnight
           case EmergencyMetricEnum.AverageMinsFromArrivalToDTA:
           return metrics.timeFromArrivalToAdmitDecision
           case EmergencyMetricEnum.AverageMinsFromDTAToAdmission:
           return metrics.timeFromAdmitDecisionToAdmission
           case EmergencyMetricEnum.AverageWaitMinsInDepartment:
           return metrics.currentAverageTimeInDepartment
           case EmergencyMetricEnum.ConversationRate:
           return metrics.conversionRate
           case EmergencyMetricEnum.PatientsAdmittedSinceMidnight:
           return metrics.patientsAdmitted
           case EmergencyMetricEnum.PatientsSeenWithin4Hours:
           return metrics.percentageOfPatientsInDepartmentLt4Hours
           case EmergencyMetricEnum.PatientsWaiting:
           return metrics.patientsWaiting
           case EmergencyMetricEnum.PatientsWaiting0_1Hours:
           return metrics.currentPatientWaits.ltOneHour
           case EmergencyMetricEnum.PatientsWaiting1_2Hours:
           return metrics.currentPatientWaits.oneToTwoHours
           case EmergencyMetricEnum.PatientsWaiting2_3Hours:
           return metrics.currentPatientWaits.twoToThreeHours
           case EmergencyMetricEnum.PatientsWaiting3_4Hours:
           return metrics.currentPatientWaits.threeToFourHours
           case EmergencyMetricEnum.PatientsWaiting4_8Hours:
           return metrics.currentPatientWaits.fourToEightHours
           case EmergencyMetricEnum.PatientsWaiting8_12Hours:
           return metrics.currentPatientWaits.eightToTweleveHours
           case EmergencyMetricEnum.PatientsWaiting12PlusHours:
           return metrics.currentPatientWaits.gtTweleveHours
       }
   }
   export const getAmbulanceMetricValueFromId = async (metricTypeId: number,
     metrics?: AmbulanceMetric ) => {
  }