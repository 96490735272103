import  {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../../core/textBox";
import Loader from "../../../core/loading";
import OKCancelButtons from "../../../core/OKCancelButtons";
import {SystemConfigType } from "../../../../interfaces/system/systemTypes"

interface ConfigEditProps {
    config?:SystemConfigType
    onSave: (config: SystemConfigType) => void 
  }

export default function ConfigEdit(props: ConfigEditProps) {
  
  const {
    objectValues, setObjectValues
  }
   = useForm({initialObjectValues: props.config})
 

   useEffect(() => {
    setObjectValues(props.config)
  },[props.config])


  const handleFieldUpdated = (e: any) => {
    if (!objectValues) return;
    let o = { ...objectValues, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
    setObjectValues(o);
}
const handleSave = async () =>{
  
  props.onSave(objectValues)
}
     
   
      
   
if(!objectValues)
        return <Loader loading={true} />
  return <>
        <Grid container spacing={2}  style={{ padding: '15px 5px' }}>
      
        <Grid item xs={6}>
          <Grid container spacing={2} >
          <Grid item xs={12}>
              <TextBox id="accessRequestReminderDurationInDays" label="Access Request Reminder Duration (days)" value={objectValues.accessRequestReminderDurationInDays}  type="number" onChange={handleFieldUpdated} />
          </Grid>
          <Grid item xs={12}>
              <TextBox disabled={true} label="Admin Distribution List" value={objectValues.distributionList.name} />
          </Grid>
          <Grid item xs={12}>
              <TextBox id="deleteAmbulanceDataDayCount" label="Ambulance Data Archive (days)" value={objectValues.deleteAmbulanceDataDayCount}  type="number" onChange={handleFieldUpdated} />
          </Grid>
          <Grid item xs={12}>
              <TextBox id="deleteEmergencyDataDayCount" label="Emergency Data Archive (days)" value={objectValues.deleteEmergencyDataDayCount}  type="number" onChange={handleFieldUpdated} />
          </Grid>
          <Grid item xs={12}>
              <TextBox id="deleteLogsDayCount" label="Emergency Data Archive (days)" value={objectValues.deleteLogsDayCount}  type="number" onChange={handleFieldUpdated} />
          </Grid>
      </Grid>  
    </Grid>
        <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
        <Grid item xs={8}>
          <OKCancelButtons onOK={handleSave} showCancel={false} onCancel={()=>{}} />
        </Grid>     </Grid>     
        </Grid>
                 
  </>

}
