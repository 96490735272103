import React, { useState} from "react";
import { ValidatorType } from "../validators/ValidatorType";
import { FeedbackType } from '../components/core/feedback'

export interface IUseFormParams{
    initialObjectValues: any,
    onSubmit?: () => void,
    validator?: ValidatorType
}
const useForm = (params: IUseFormParams) => { 
    const [hasError, setHasError] = useState(false);
    const [objectValues, setObjectValues] = useState(params.initialObjectValues);
    const [submitted, setSubmitted] = useState(false);
    const [isDirty, setIsDirty]= useState(false);
    const [validator, setValidator] = useState(params.validator);
    const [isValid, setIsValid]= useState(false);
    const [feedbackStatus, setFeedbackStatus] = useState(FeedbackType.hide)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [partialObjectPresent, setPartialObjectPresent] = useState(false)
    const [showPartialConfirmation, setShowPartialConfirmation] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) : any => {
      const { target } = event
      let { id, value } = target

       if(event.target.type === 'checkbox' ){
          
          const newValue ={ ...objectValues, [id]: event.target.checked }
          setObjectValues(newValue)
          return newValue
       }
      const newValue ={ ...objectValues, [id]: Array.isArray(value) ? [...value] : value }
      setObjectValues(newValue)
      
      return newValue
    };
    const setValues = (e: any) => {

      if(e.target?.type === 'checkbox'){
        setObjectValues({ ...objectValues, [e.target.id]: (e.target.checked as boolean) })
        return
      }

      if((e.target?.id) && (e.target.id as string).indexOf('.')>-1){
        const fieldSplits  = (e.target.id as string).split('.')
        let o = objectValues[fieldSplits[0]]
        if(!o){
          o={id:0,name:''}
        }
        o[fieldSplits[1]] = e.target.value === '' ? undefined : isNaN(e.target.value)?e.target.value: parseFloat(e.target.value)
        setObjectValues({ ...objectValues, [fieldSplits[0]]: o })
      }
      else{
        setObjectValues({ ...objectValues, [e.target.id]: e.target.value === '' ? undefined : e.target.value })
      }

        setIsDirty(true)
        return objectValues
    };

    const validateForm = (validationValues: any) : boolean => {
        if(!validator)
            return false;
        const valid = validator.validate(validationValues)
        setIsValid(valid)
        setValidator(validator)
        return valid;
    }
    const handleSubmit = (event: any) => {
        if (event) event.preventDefault();
        setSubmitted(true)
        
        const valid = validateForm(objectValues)

        if(valid && params.onSubmit){
            params.onSubmit();
        }
      };

      // useEffect(() => {
      //     setLoading(false)
      //   },[]);

      return {
         hasError, setHasError, errorMessage,setErrorMessage,
        handleChange,
        handleSubmit,
        submitted,
        validateForm,setValidator,validator,
        // loading, setLoading,
        // saveSuccess, setSaveSuccess,
        // saveFailure, setSaveFailure,
        isValid, setIsValid,
        isDirty, setIsDirty,
        objectValues, setObjectValues,
        partialObjectPresent, showPartialConfirmation, setShowPartialConfirmation, setPartialObjectPresent,
        setValues, feedbackStatus, setFeedbackStatus
      };
  }
  
  export default useForm;
