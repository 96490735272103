import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { NecsExcludedServiceType } from '../interfaces/system/capacityGridType';
import {ValidatorType} from './ValidatorType'

export class NecsExcludedServiceValidator implements ValidatorType
{
   formValidator = new FormValidation([])
    cqcId= new FormValidationField('','')
    address= new FormValidationField('','')

     initialiseValidationFields = (objectValues: NecsExcludedServiceType) => {
        this.formValidator = new FormValidation([])
        this.cqcId= this.formValidator.createTextField(objectValues.cqcId)
        this.address= this.formValidator.createTextField(objectValues.address)


    }
    
 validate =  (validatorObjectValues: NecsExcludedServiceType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid 
 }
}

export default NecsExcludedServiceValidator

