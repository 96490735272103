import React from "react";
import { EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";

export interface DemandAndCapacityCurrentWaitsProps {
    emergencyMetric: EmergencyMetric
}
export default function DemandAndCapacityCurrentWaits(props: DemandAndCapacityCurrentWaitsProps) {

    const [emergencyMetric, setEmergencyMetric] = React.useState(props.emergencyMetric);

    React.useEffect(() => {
        setEmergencyMetric(props.emergencyMetric)
    }
        , [props.emergencyMetric])

    return <>
        <table style={{ padding: 5, width: '100%' }} className="table-rag-status">
            <thead>
                <tr>
                    <th className="empty-header">&nbsp;</th>
                    <th>Today</th>
                    <th>Waiting</th>
                </tr>
            </thead>
            <tbody>
                <tr className="tr-rag-status tr-rag-status--green">
                    <td className="rag-label">0-1 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.ltOneHour}</td>
                    <td>{emergencyMetric?.currentPatientWaits.ltOneHour}</td>
                </tr>
                <tr className="tr-rag-status tr-rag-status--green-2">
                    <td className="rag-label">1-2 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.oneToTwoHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.oneToTwoHours}</td>
                </tr>
                <tr className="tr-rag-status tr-rag-status--amber">
                    <td className="rag-label">2-3 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.twoToThreeHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.twoToThreeHours}</td>
                </tr>
                {/* <tr className="tr-rag-status tr-rag-status--amber-2">
                    <td className="rag-label">2-3 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.threeToFourHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.threeToFourHours}</td>
                </tr> */}
                <tr className="tr-rag-status tr-rag-status--red">
                    <td className="rag-label">3-4 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.threeToFourHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.threeToFourHours}</td>
                </tr>
                <tr className="tr-rag-status tr-rag-status--red-2">
                    <td className="rag-label">4-8 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.fourToEightHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.fourToEightHours}</td>
                </tr>
                <tr className="tr-rag-status tr-rag-status--neutral">
                    <td className="rag-label">8-12 hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.eightToTweleveHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.eightToTweleveHours}</td>
                </tr>
                <tr className="tr-rag-status tr-rag-status--neutral-2">
                    <td className="rag-label">12+ hours</td>
                    <td>{emergencyMetric?.totalPatientWaits.gtTweleveHours}</td>
                    <td>{emergencyMetric?.currentPatientWaits.gtTweleveHours}</td>
                </tr>
            </tbody>
        </table>
        <table style={{ padding: 5, display:'none' }} cellPadding={5} cellSpacing={0}>
            <thead style={{ border: '1px solid #777' }}>
                <th></th>
                <th style={{ border: '1px solid #777' }}>Today</th>
                <th style={{ border: '1px solid #777' }}>Waiting</th>
            </thead>
            <tbody>
                <tr style={{ background: '#38951a', color: '#fff', border: '1px solid #777' }}>
                    <td>0-1 hrs</td>
                    
                </tr>
                <tr style={{ background: '#67b13a', color: '#fff', border: '1px solid #777' }}>
                    <td>1-2 hrs</td>
                    
                </tr>
                <tr style={{ background: '#f29e2d', color: '#fff', border: '1px solid #777' }}>
                    <td>2-3 hrs</td>
                    
                </tr>
                <tr style={{ background: '#e34848', color: '#fff', border: '1px solid #777' }}>
                    <td>3-4 hrs</td>
                    
                </tr>
                <tr style={{ background: '#040404', color: '#fff', border: '1px solid #777' }}>
                    <td>4-8 hrs</td>
                    
                </tr>
                <tr style={{ background: '#040404', color: '#fff', border: '1px solid #777' }}>
                    <td>8-12 hrs</td>
                    
                </tr>
                <tr style={{ background: '#7474ba', color: '#fff', border: '1px solid #777' }}>
                    <td>12+ hrs</td>
                    
                </tr>
            </tbody>
        </table>
    </>
}