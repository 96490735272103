import React from "react";
import { useEffect, useState } from "react";
import Dropdown, { IDropdownOptions } from "./dropdown";

export interface PagedTab {
    title: string,
    children?: React.ReactNode
}
export interface PagedTabProps {
    activeIndex?: number,
    tabs: PagedTab[]
    activeTab?:PagedTab
    onTabChange?:(index: number) => void
}
export function PagedTabs(props: PagedTabProps) {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex ?? 0);
    // const [activeTab, setActiveTab] = useState<PagedTab>(props?.tabs[0]);
    const [tabs, setTabs] = useState(props.tabs);
    const [title, setTitle] = useState('');
    

    useEffect(() => {
        setActiveIndex(props.activeIndex ?? 0);
        if(props.tabs[props.activeIndex??0])
            setTitle(props.tabs[props.activeIndex??0].title)
    }, [props.activeIndex]);
   

    useEffect(() => {
        if (!props.tabs || props.tabs.length < 1) return
        setTabs(props.tabs)
        // setActiveTab(props.tabs[0])
        if(!props.activeIndex)
            setTitle(props.tabs[0].title)
    }, [props.tabs]);

    const handleDropdownSelect = (e: any) => {

        const totalTabsCount = tabs.length;
        let newIndex = parseInt(e.target.value ?? '0');

        if (newIndex < 0 || newIndex > (totalTabsCount) - 1) {
            return;
        }
        handleSetActiveTab(newIndex,tabs[newIndex])
    }
    const handleClickNext = () => {
        const totalTabsCount = tabs.length;
        let newIndex = 0;
        if (activeIndex >= (totalTabsCount) - 1) {
            return;
        }
        newIndex = activeIndex + 1;
        handleSetActiveTab(newIndex,tabs[newIndex])
    }
    const handleSetActiveTab = (newIndex:number,newAciveTab:PagedTab) =>{
        setActiveIndex(newIndex);
        // setActiveTab(newAciveTab)
        onActiveTabChange(newIndex)
        
        setTitle( newAciveTab.title)
    }

    const handleClickPrevious = () => {
        //console.clear();
        let newIndex = activeIndex - 1;
        if (newIndex < 0) {
            return;
        }
        handleSetActiveTab(newIndex,tabs[newIndex])
    }
    const onActiveTabChange = (activeIndex:number) =>{
        if(props.onTabChange)
            props.onTabChange(activeIndex)
    }
    const getDropdownOptions = (): IDropdownOptions[] => {
        const options = tabs.map((tab, index) => {
            return { id: index, name: tab.title, code: ''};
        });
        return options;
    }


    if (!tabs) {
        return <></>
    }

    if ((tabs?.length ?? 0) > 0) {
        const options = getDropdownOptions()
        return <>
            <div className="paged-tabs-controls">
                <div className="paged-tabs-controls-title-area">
                    <h3 className="paged-tabs-controls--title">{title}</h3>
                </div>
                {tabs.length > 1 && <div className="paged-tabs-controls-navigate-area">
                    <div className="paged-tabs-controls-dropdown-area">
                        <Dropdown label="" options={options} value={options[activeIndex].id} onSelection={handleDropdownSelect}></Dropdown>
                    </div>
                    <div className="paged-tabs-controls-button-area">
                        <i className="pi pi-angle-left" style={{ fontSize: '2.5rem', cursor: (activeIndex === 0 ? 'not-allowed' : 'pointer'), opacity: (activeIndex === 0 ? '.5' : undefined) }} onClick={handleClickPrevious} title="Previous"></i>

                        <i className="pi pi-angle-right" style={{ fontSize: '2.5rem', cursor: (activeIndex === (tabs.length - 1) ? 'not-allowed' : 'pointer'), opacity: (activeIndex === (tabs.length - 1) ? '.5' : undefined) }} onClick={handleClickNext} title="Next"></i>
                    </div>
                </div>
                }
                <div style={{ clear: 'both' }} ></div>
            </div>
            <div className="paged-tabs">
                <div className="paged-tabs-inner">
                    {!props.activeTab && React.Children.toArray(tabs?.map((tab, index) => {
                        if (tab === null || tab === undefined) {
                            return <></>
                        }
                        return <>
                            <div className={`paged-tab paged-tab-${activeIndex === index ? 'active' : ''} paged-tab-fade-${activeIndex === index ? 'in' : 'out'}`}>
                                {tab.children}
                            </div>
                        </>
                    }))}
                    {
                        props.activeTab && <div className="paged-tab paged-tab-active paged-tab-fade-in">
                        {props.activeTab.children}
                    </div>
                    }
                </div>
            </div >
        </>
    }
    return <></>
}
