import React, {useEffect,useState} from "react"
import {Grid} from '@mui/material';
import {createNewFormFieldOption, FormFieldOptionType, FormFieldType} from "../../../interfaces/system/formType";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import BasicTab from "../../core/basicTab";
import ActionButton from "../../core/actionButton";
import Dropdown from "../../core/dropdown";

interface FormEditFieldOptionsProps {
    options?:FormFieldOptionType[]
    formField:FormFieldType
    onUpdate: (options: FormFieldOptionType[]) => void 
  }

const FormEditFieldOptions:  React.FC<FormEditFieldOptionsProps> = (props) => {
  const [options, setOptions] = useState(props.options)
  
useEffect(() => {
    setOptions(props.options)
},[props.options])

const handleAddOption = (index:number) => {
  const newOption = createNewFormFieldOption();
  options?.splice(index+1,0,newOption)
  setOptions([...options||[]])
  props.onUpdate(options||[])
}
const handleDeleteOption = (index:number) => {
  if(!options || options?.length===1) return

  const currentOption = options[index]
  if(currentOption.id<0) 
    options?.splice(index,1)
  else
    currentOption.deleted = true

  setOptions([...options])
  props.onUpdate(options||[])
}
 
const updateOption = (index:number,e:any) => {
  if(!options) return
  const currentOption = options[index] as any

  currentOption[e.target.id] = e.target.value
  setOptions([...options])
}
  
  
  const optionElem = <>
   {options?.map((f:FormFieldOptionType, indx:number) => (
    <Grid container style={{paddingTop:5}} spacing={1}>
        <Grid item xs={1}>
            <TickBox id="deleted" label="" checked={options[indx].deleted} onChange={(e) => {updateOption(indx, e)}}/>
        </Grid>
        <Grid item xs={6}>
            <TextBox id="name" disabled={options[indx].deleted} label="Name" value={options[indx].name} onChange={(e) => {updateOption(indx, e)}}/>
        </Grid>
        <Grid item xs={3}>
            <TextBox id="reportingLabel"  disabled={options[indx].deleted} label="Reporting Value" value={options[indx].reportingLabel} onChange={(e) => {updateOption(indx, e)}}/>
        </Grid>
        <Grid item xs={2}>
          <ActionButton variant="icon-only" icon="pi pi-plus-circle" severity="success" onClick={()=>handleAddOption(indx)}/>
          <ActionButton variant="icon-only" icon="pi pi-times-circle" severity="danger" onClick={()=>handleDeleteOption(indx)}/>
        </Grid>
    </Grid>
  ))}
  </>
  const weightingElem = <>
    {options?.map((f:FormFieldOptionType, indx:number) => (
      <>
      <Grid container style={{paddingTop:5}} spacing={1}>
        <Grid item xs={12}>{f.name}</Grid>
      </Grid>    
          

      <Grid container style={{paddingTop:5}} spacing={1}>
          <Grid item xs={3}>
              <TextBox id="weighting"  label="Weighting" value={options[indx].weighting} onChange={(e) => {updateOption(indx, e)}}/>
          </Grid>
          <Grid item xs={3}>
              <TextBox id="opelWeighting"  label="Opel Weighting" value={options[indx].opelWeighting} onChange={(e) => {updateOption(indx, e)}}/>
          </Grid>
          <Grid item xs={3}>
              <TextBox id="calculationHelpRangeFrom" type="number" label="Calc Help Range From" value={options[indx].calculationHelpRangeFrom} onChange={(e) => {updateOption(indx, e)}}/>
          </Grid>
          <Grid item xs={3}>
              <TextBox id="calculationHelpRangeTo" type="number" label="Calc Help Range To" value={options[indx].calculationHelpRangeTo} onChange={(e) => {updateOption(indx, e)}}/>
          </Grid>
      </Grid>
      </>
    ))}
  </>

const automationElem = <>

{options?.map((f:FormFieldOptionType, indx:number) => (
  <>
  <Grid container style={{paddingTop:5}} spacing={1}>
    <Grid item xs={12}>{f.name}</Grid>
  </Grid>    
      

  <Grid container style={{paddingTop:5}} spacing={1}>
      
      <Grid item xs={4}>
          <TextBox id="automationMetricRangeFrom" type="number" label="Metric Range From" value={options[indx].automationMetricRangeFrom} onChange={(e) => {updateOption(indx, e)}}/>
      </Grid>
      <Grid item xs={4}>
          <TextBox id="automationMetricRangeTo"  type="number" label="Metric Range To" value={options[indx].automationMetricRangeTo} onChange={(e) => {updateOption(indx, e)}}/>
      </Grid>
  </Grid>
  </>
))}
</>

  return <>
      <BasicTab key='fieldOptionTabs' tabs={[{label:'Options', component: <>{optionElem}</> },
                  {label:'Weightings', component: <>{weightingElem}</>},
                  {label:'Logic', component: <>Logic</>},
                  {label:'Automation', component: <>{automationElem}</>}]}>
              
        </BasicTab>   

   </>

}

export default FormEditFieldOptions;




