import { FormResultOptionType } from "../system/formType"

export interface OptionsType  {
  serviceCategories:DomainEntityType[]
  regions:DomainEntityType[]
  formResultOptions:FormResultOptionType[]
}
export interface IDomainEntities  {
    domain: ApplicationDomainType
    entityGroups:DomainEntityGroup[]
 }

 export interface DomainEntityGroup{
   entities:DomainEntityType[]
   groupName:string|undefined
 }

export interface DomainEntityType  {
    name:string
    id: number
    displayOrder:number
    active:boolean
    imageFileName?:string
    children:DomainEntityType[]
 }

 export enum ApplicationDomainType{
   Gender = 1,
   Religion,
   Language,
   Ethnicity,
   User,
   UserRole,
   UserManagers,
   FeedbackType
   }

 export const createNewDomainEntity = (): DomainEntityType  => {
   return { 
      id: 0,
      name: '',
      active: true,
      displayOrder:0,
      children:[]
   }
}
export const createNewSimpleDomainEntity = (id:number): DomainEntityType  => {
  return { 
     id: id,
     name: '',
     active: true,
     displayOrder:0,
     children:[]
  }
}

export const createNewOptionsType = (): OptionsType  => {
  return { 
     serviceCategories:[],
     regions:[],
     formResultOptions:[]
  }
}