import React, {useEffect} from "react"
import { APIUserType } from "../../../../interfaces/system/systemTypes"
import DataTable from "../../../core/dataTable"


interface ApiUserListProps {
    list?:APIUserType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

export function ApiUserList(params:ApiUserListProps) { 
useEffect(() => {
  setList(params.list)
},[params.list])

const [list,setList] = React.useState(params.list)
   
const handleNewItemClick = () => {
  if (params.onNew) {
    params.onNew();
  }
}

  const handleRowSelection =(id:number) => {
    
    if(params.onSelection)
        params.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false}  filter={true}
  columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Contact Name", field:"contactName"},
  {header:"No. Permissions", field:"code", formatCallBack: (rowData) => { const r = rowData as APIUserType; return r.permissions ? r.permissions?.length.toString() : ''; }},
  {header:"Active", field:"deleted", formatType:"yesno-inverse"}]}  paginationRowsPerPage={20} value={list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true}   onNew={handleNewItemClick} allowExport={true}
  />

}