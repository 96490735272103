import { useState } from "react";
import { EditUser } from "../components/admin/user/edit-user";
import { FeedbackType } from "../components/core/feedback";
import Loading from "../components/core/loading";
import Page from "../components/core/page";
import { UserType } from "../interfaces/user/iUser";
import { getUser } from "../services/storageService";
import { updatePageTitle } from "../services/utils";

export default function AccountContainer() {
    const [feedbackStatus, setFeedbackStatus] = useState(FeedbackType.hide);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [currentUser, setCurrentUser] = useState(getUser());
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

    updatePageTitle("Account Options");

    const handleError = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setFeedbackStatus(FeedbackType.Error);
    }

    const handleUserSaved = (user: UserType, message?: string) => {
        setErrorMessage(message ?? 'Your details were updated successfuly');
        setFeedbackStatus(FeedbackType.SaveSuccess);
        setCurrentUser(user);
    }

    const handleLoading = () => {
        setShowLoadingOverlay(true);
    }

    const handleLoaded = () => {
        setShowLoadingOverlay(false);
    }

    if (!currentUser) {
        return <p className="p-inline-message p-inline-message-error">You are not logged in and cannot access this page</p>
    }

    return <>
        <Page title="Account options" errorMessage={errorMessage} feedbackStatus={feedbackStatus}>
            <Loading loading={showLoadingOverlay}></Loading>
            <EditUser User={currentUser} OnError={handleError} OnSaved={handleUserSaved} OnLoading={handleLoading} OnLoaded={handleLoaded}></EditUser>
        </Page>

    </>
}