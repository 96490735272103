import { Grid } from "@mui/material";
import React from "react";
import { AmbulanceMetric } from "../../../interfaces/reporting/demandAndCapacity";
import Page from "../../core/page";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import DemandAndCapacityAmbulanceStatus from "./demand-and-capacity-ambulance-status";
import DemandAndCapacityDataQuality from "./demand-and-capacity-data-quality";
import DemandAndCapacityGauge from "./demand-and-capacity-gauge";
 

export interface DemandAndCapacityAmbulancDashboardProps{
    ambulanceMetrics:AmbulanceMetric[]
    siteCode:string
}

interface AmbDashProps{
    ambulanceMetric?:AmbulanceMetric}

export default function DemandAndCapacityAmbulancDashboard(props: DemandAndCapacityAmbulancDashboardProps) {

    const  [ambulanceMetrics, setAmbulanceMetrics] = React.useState<AmbulanceMetric[]>(props.ambulanceMetrics);
    const [siteCodes, setSiteCodes] = React.useState<string[]>(["aa"]);
    
    React.useEffect(() => {
            
            if(props.ambulanceMetrics){
                const sc = [props.siteCode].concat(props.ambulanceMetrics.map(m => m.siteCode)).filter((x, i, a) => a.indexOf(x) === i)
                setTimeout(() => {
                    setSiteCodes(sc)  
                }, (99));    
            }
            setAmbulanceMetrics(props.ambulanceMetrics)
      }
      ,[props.ambulanceMetrics, props.siteCode])

      
      if(!ambulanceMetrics || !siteCodes || siteCodes.length<1 || ambulanceMetrics.length<1) return <>no data</>
        
        const tabs: PagedTab[] = [];
        siteCodes.forEach(sc => {
            const ambulanceData = ambulanceMetrics ? ambulanceMetrics.find(f => f.siteCode === sc) : undefined
    
            tabs.push({
                title: ambulanceData?.service.name ?? '',
                children:<AmbDash ambulanceMetric={ambulanceData} />
            })
        });


        return <Page title="Live Ambulance Data Dashboard"
        breadCrumbs={[{label:'Summary', url:`../${props.siteCode}`},{label:'Ambulance'}]}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PagedTabs tabs={tabs}></PagedTabs>
                </Grid>

            </Grid>
        </Page>
      

      function AmbDash (p:AmbDashProps) {
        const { ambulanceMetric} = p;

        if(!ambulanceMetric) return <>no data</>

        const turnaroundMins = ambulanceMetric? Math.ceil(ambulanceMetric.meanTurnAroundTimeSeconds / 60) : 0
      const maxArrivals = ambulanceMetric? ambulanceMetric.arrivalsInLast3Hours < 20 ? 20 : ambulanceMetric.arrivalsInLast3Hours+10 : 20

    return (<Grid container justifyContent={"center"}>
        
    <Grid item md={10} xs={12} >
        <Grid container spacing={2}>
            <Grid item xs={10} md={4}>
                <DemandAndCapacityAmbulanceStatus ambulanceMetric={ambulanceMetric} />
            </Grid>
            <Grid item xs={10} md={4} >
                <DemandAndCapacityGauge id={`amb${ambulanceMetric.siteCode}`} title="Ambulance Conveyances" MaxValue={ambulanceMetric.totalConveyances<28?28:ambulanceMetric.totalConveyances+2} minValue={0} 
                ranges={[{min:0,max:10},{min:11,max:19},{min:20,max:ambulanceMetric.totalConveyances<28?28:ambulanceMetric.totalConveyances+2}]}
                value={ambulanceMetric.totalConveyances}
                help="Current Active Conveyances"
                />
            </Grid>
             
            <Grid item xs={10} md={4}>
                <DemandAndCapacityGauge id={`amb_1${ambulanceMetric.siteCode}`} title="Mean Turnaround Time" MaxValue={turnaroundMins<28?28:turnaroundMins+2} minValue={0} 
                ranges={[{min:0,max:14},{min:15,max:23},{min:24,max:turnaroundMins<28?28:turnaroundMins+2}]}
                value={turnaroundMins}
                format="time"
                help="Today's Average Ambulance Turnaround Time"
                />
            </Grid>
            <Grid item xs={10} md={4}>
                <DemandAndCapacityGauge id={`amb_1${ambulanceMetric.siteCode}`} title="Arrivals Last 3 Hrs" MaxValue={maxArrivals} minValue={0} 
                ranges={[{min:0,max:5},{min:6,max:15},{min:16,max:maxArrivals}]}
                value={ambulanceMetric.arrivalsInLast3Hours}
                help="The number of patient arrivals over the past three hours.                                "
                />
            </Grid>
            <Grid item xs={12} md={9}>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <DemandAndCapacityDataQuality ambulanceMetric={ambulanceMetric} />
                                </Grid>



            </Grid>
             
    </Grid>
        </Grid>
        )
      }
}