import { Column, ColumnBodyOptions } from "primereact/column";
import { TreeTable, TreeTableExpandedKeysType } from "primereact/treetable";
import { useEffect, useState } from "react";
import { AreaHierarchyChild, AreaHierarchyType, createNewAreaHierarchyType } from "../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../services/systemService";
import './css/userSubscriptionSelector.scss';
import TickBox from "./tickBox";
import { ServiceType } from "../../interfaces/system/serviceType";
import { TreeNode } from "primereact/treenode";
import TextBox from "./textBox";
import ActionButton from "./actionButton";
import { UserType } from "../../interfaces/user/iUser";
import { getUser } from "../../services/storageService";
import Loading from "./loading";

export interface SubscriptionListNode {
    id: number,
    name: string,
    editable: boolean
}

export interface SubscriptionSelectionOption {
    subscriptionResultTypeId: SubscriptionResultType,
    formResultValue: number | null,
    serviceId: number,
    notificationTypeId: NotificationType
}

enum NotificationType {
    Push = 2,
    Email = 1
}
enum SubscriptionResultType {
    Grid = 2,
    Form = 1
}

export interface UserSubscrptionSelectorProps {
    userSelections?: SubscriptionSelectionOption[],
    user: UserType,
    OnError?: (errorMessage: string) => void,
    OnSelectionsUpdated?: (items: SubscriptionSelectionOption[]) => void,
    OnLoading?: () => void,
    OnLoaded?: () => void,
}

export function UserSubscrptionSelector(props: UserSubscrptionSelectorProps) {
    const [subscriptionNodes, setSubscriptionNodes] = useState<TreeNode[]>();
    const [selectedNode, setSelectedNode] = useState<TreeNode>();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [userSelections, setUserSelections] = useState(props.userSelections);
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());
    const [expandedNodes, setExpandedNodes] = useState<TreeTableExpandedKeysType>();
    const [allNodes, setAllNodes] = useState<TreeTableExpandedKeysType>();
    const [filterText, setFilterText] = useState('');
    const [menuPosition, setMenuPosition] = useState([0, 0]);
    const [currentUser] = useState(getUser())

    useEffect(() => {
        getServiceAreas()
    }, [props.userSelections]);

    useEffect(() => {
        
        buildNodeList();
    }, [areaHierarchy]);

    useEffect(() => {
        expandSelectedNodes();
    }, [subscriptionNodes]);
    
    useEffect(() => {
        if(!subscriptionNodes) return
        setExpandedNodes(allNodes)
    }, [filterText]);
 

    const getServiceAreas = async () => {
        handleLoading();
        const response = await fetchAllAreaHierarchyWithServices();
        if (response && response.success && response.result) {
            setAreaHierarchy(response.result);
        } else {
            handleError(response.error ?? 'An unknown error occurred');
        }
        handleLoaded();
    }

    const buildNodeList = () => {
        let subscriptionNodeList: TreeNode[] = [];
        const keys: { [key: string]: boolean } = {};
        areaHierarchy.areas.forEach(area => {
            let n = buildAreaNode(area,keys);
            
            if ((n.children?.length ?? 0) > 0) {
                subscriptionNodeList.push(n);
            }
        });
        setSubscriptionNodes(subscriptionNodeList);
        
        subscriptionNodeList.forEach(f=> {if(f.key) keys[f.key] = true})
        setAllNodes(keys)
    }

    const extractChildItems = (parent: AreaHierarchyChild,keys: { [key: string]: boolean }): TreeNode[] => {
        if (!parent.children) {
            return [];
        }
        let subscriptionNodeSubList: TreeNode[] = [];

        parent.children.forEach(area => {
            let n = buildAreaNode(area,keys);
            // only show areas that have services under them
            if ((n.children?.length ?? 0) > 0) {
                subscriptionNodeSubList.push(n);
            }
        });
        parent.services.forEach(service => {
            // only show services user has access to
            let showService = true
            if(currentUser.id=== props.user.id && currentUser.servicePermissions)
                showService =  (props.user.administrator || props.user.servicePermissions[service.id]) 
                if(showService){
                    let node = buildServiceNode(service);
                    subscriptionNodeSubList.push(node);
                }
        });
        return subscriptionNodeSubList;
    }

    const buildAreaNode = (areaData: AreaHierarchyChild,keys: { [key: string]: boolean }): TreeNode => {
        const key = `area-${areaData.id}`;
        keys[key] = true
        return { key: key, label: areaData.name, children: extractChildItems(areaData as AreaHierarchyChild, keys), data: { name: areaData.name, id: areaData.id, editable: false } };
    }

    const buildServiceNode = (serviceData: ServiceType): TreeNode => {
        const key = `${serviceData.id}`;
        let serviceNode = { key: key, label: serviceData.name, data: { name: serviceData.name, id: serviceData.id, editable: true } };
        return serviceNode;
    }

    const addExpandedAreaKey = (expandedKeys: string[], areaId: string) => {
        if (expandedKeys.find(s => s === `area-${areaId}`) === undefined) {
            expandedKeys.push(`area-${areaId}`);
        }
    }

    const expandSelectedNodes = () => {
        const keys: { [key: string]: boolean } = {};
        if (userSelections && userSelections.length > 0) {
            let expandedKeys: string[] = [];
            for (var i = 0; i < userSelections.length; i++) {
                const selectedService = userSelections[i];
                for (var n = 0; n < areaHierarchy.areas.length; n++) {
                    const area = areaHierarchy.areas[n];
                    if (areaContainsService(area, selectedService.serviceId, expandedKeys)) {
                        addExpandedAreaKey(expandedKeys, area.id.toString());
                        break;
                    }
                }
            }
            if (expandedKeys) {
                expandedKeys.forEach(k => {
                    keys[k] = true;
                });
            };
        }
        setExpandedNodes(keys);
    }

    const areaContainsService = (area: AreaHierarchyChild, serviceId: number, expandedKeys: string[]): boolean => {
        let result = false;
        if (area.services) {
            for (var i = 0; i < area.services.length; i++) {
                const service = area.services[i];
                if (service.id === serviceId) {
                    result = true;
                    addExpandedAreaKey(expandedKeys, area.id.toString());
                    break;
                }
            }
        }
        if (area.children) {
            for (var n = 0; n < area.children.length; n++) {
                const subArea = area.children[n];
                if (areaContainsService(subArea, serviceId, expandedKeys)) {
                    addExpandedAreaKey(expandedKeys, subArea.id.toString());
                    result = true;
                }
            }
        }
        return result;
    }

    //#region events
    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }

    const handleClosePopupMenu = () => {
        setShowEditDialog(false);
        setSelectedNode(undefined);
    }
    // hide menu on window resize
    window.addEventListener('resize', handleClosePopupMenu);

    const handleSubscriptionSelected = (e: any, node: TreeNode, value: number, notificationMethod: NotificationType) => {
        value = value ?? 0;
        const subscriptionResult = e.target.id === 'Capacity' ? SubscriptionResultType.Grid : SubscriptionResultType.Form;
        const checked = e.target.checked;
        // remove
        if (!checked && userSelections) {
            let newSelections: SubscriptionSelectionOption[] = [];
            userSelections.forEach(s => {
                // if this item is not the one unchecked then add to new list of selected items
                if (!(s.serviceId === node.data.id && s.notificationTypeId === notificationMethod && s.subscriptionResultTypeId === subscriptionResult && (s.formResultValue === value || (subscriptionResult === SubscriptionResultType.Grid && s.formResultValue === null)))) {
                    newSelections.push(s);
                }
            });
            setUserSelections(newSelections);
        } else {

            let newSelections = userSelections ?? [];
            newSelections?.push({ formResultValue: value, subscriptionResultTypeId: subscriptionResult, notificationTypeId: notificationMethod, serviceId: node.data.id });
            setUserSelections(newSelections);
        }
    }

    const handleEditButtonClick = (e: any, node: TreeNode) => {
        const target = e.target;
        if (target) {
            setShowEditDialog(true);
            setSelectedNode(node);
            setMenuPosition([(e.pageX + 10) - 200, e.pageY + 10]);
        }
    }

    const handleSelectionsUpdated = () => {
        if (showEditDialog) {
            setShowEditDialog(false);
            if (props.OnSelectionsUpdated && userSelections) {
                props.OnSelectionsUpdated(userSelections);
            }
        }
    }

    //#endregion

    const renderEmailSelectionOptions = (node: TreeNode, options: ColumnBodyOptions) => {
        return renderSelectionOptions(node, options, NotificationType.Email);
    }
    const renderPushSelectionOptions = (node: TreeNode, options: ColumnBodyOptions) => {
        return renderSelectionOptions(node, options, NotificationType.Push);
    }

    const isItemSelected = (node: TreeNode, notificationMethod: NotificationType, submissionType: SubscriptionResultType, itemValue: number): boolean => {
        itemValue = itemValue ?? 0;
        const selections = userSelections?.filter(x => x.notificationTypeId === notificationMethod && x.serviceId === node.data.id);
        const itemSelected = selections?.find(x => x.subscriptionResultTypeId === submissionType && (x.formResultValue === itemValue || (submissionType === SubscriptionResultType.Grid && x.formResultValue == null))) !== undefined;
        return itemSelected;
    }

    const renderEditNotificationArea = (notificationMethod: NotificationType) => {
        if (!selectedNode) {
            return <></>
        }
        const gridSelected = isItemSelected(selectedNode, notificationMethod, SubscriptionResultType.Grid, 0);
        return <>
            <TickBox id="Capacity" label="Capacity" checked={gridSelected} onChange={(e) => { handleSubscriptionSelected(e, selectedNode, 0, notificationMethod) }} /><br />
            {[1, 2, 3, 4].map(level => {
                const selected = isItemSelected(selectedNode, notificationMethod, SubscriptionResultType.Form, level);
                return <TickBox id={level.toString()} label={level.toString()} checked={selected} onChange={(e) => { handleSubscriptionSelected(e, selectedNode, level, notificationMethod) }} />
            })}
        </>
    }

    const renderSelectionOptions = (node: TreeNode, options: ColumnBodyOptions, notificationMethod: NotificationType) => {
        if (!node.data.editable) {
            return renderAreaRow();
        }
        const selections = userSelections?.filter(x => x.notificationTypeId === notificationMethod && x.serviceId === node.data.id);
        let selectedText = '';
        selections?.sort((a, b) => { return (a.formResultValue ?? 0) - (b.formResultValue ?? 0) }).forEach(x => selectedText += `${(x.formResultValue ?? 0) === 0 ? 'Capacity' : x.formResultValue}${x === selections[selections.length - 1] ? '' : ', '}`);
        const icon = notificationMethod === NotificationType.Email ? 'envelope' : 'phone';
        const iconColor = (selections?.length ?? 0) > 0 ? '#52ce52' : '';

        return <><span onClick={(e) => { handleEditButtonClick(e, node); }} title="Click to edit" style={{ cursor: 'pointer', fontWeight: 'bold' }}><i className={`pi pi-${icon} pi-action-btn`} style={{ color: iconColor }}></i> {selectedText}</span></>
    }

    const renderAreaRow = () => {
        return <></>;
    }

    if (!subscriptionNodes || !expandedNodes) {
        return <><Loading loading={true} useBackdrop={false} /></>
    }

    return <>
        <TextBox onChange={(e) => { setFilterText(e.target.value); handleClosePopupMenu(); }} label="Filter areas/services"></TextBox>
        <div className="user-subscriptions-table-container" style={{ overflow: showEditDialog ? 'hidden' : 'auto' }}>
            <TreeTable value={subscriptionNodes} expandedKeys={expandedNodes} onToggle={(e) => setExpandedNodes(e.value)} globalFilter={filterText} filterMode="strict">
                <Column header="Service" expander field="name"></Column>
                <Column header="Email" body={renderEmailSelectionOptions} columnKey="email" headerClassName="subscription-table-small-header"></Column>
                <Column header="Push" body={renderPushSelectionOptions} columnKey="push" headerClassName="subscription-table-small-header"></Column>
            </TreeTable>
            {selectedNode && (subscriptionNodes?.length ?? 0) > 0 &&
                <>
                    <div id="subscriptionmenu" className={`user-subscriptions-table-menu ${showEditDialog ? 'expanded' : ''}`} style={{ display: showEditDialog ? 'block' : 'none', left: menuPosition[0], top: menuPosition[1] }}>
                        <p style={{ textAlign: 'center', margin: '0 0 5px' }}>{selectedNode.data.name}</p>
                        <div className="grid-block">
                            <p><strong><i className="pi pi-envelope"></i> Email:</strong></p>
                            {renderEditNotificationArea(NotificationType.Email)}
                        </div>
                        <div className="grid-block">
                            <p><strong><i className="pi pi-phone"></i> Push:</strong></p>
                            {renderEditNotificationArea(NotificationType.Push)}

                        </div>
                        <div style={{ display: 'block', textAlign: 'right', width: '100%' }}>
                            <ActionButton label="OK" onClick={handleSelectionsUpdated} severity="success"></ActionButton>
                        </div>
                    </div>
                </>
            }
        </div>
    </>
}