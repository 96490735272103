import React, { useState} from "react";
import Home from '../home/home';
import GroupBox from '../core/groupBox';
import TextBox from '../core/textBox';
import Loader from '../core/loading';
import ActionButton from '../core/actionButton';
import { clearUserRedirect, getUserRedirect, impersonate, login} from '../../services/userService';
import FeedBackText from '../core/feedbackText';
import {AuthenticationResultType, IJWTToken, UserLoginType,JWTToken, UserType} from '../../interfaces/user/iUser';
import {FormValidation, FormValidationField} from '../../extensions/formValidation';
import { setJwt } from "../../services/storageService";
import apple_logo from '../../assets/images/logo-apple-app-store.png'
import google_logo from '../../assets/images/logo-google-play.png';
import {INVALID_LOGIN_MESSAGE} from '../../containers/authenticationContainer'
import {useNavigate, useParams } from "react-router-dom"
import { Grid } from "@mui/material";

interface LoginProps {
    onSuccessfulLogin:(user:UserType)=>void
    mode?:'impersonate'
    userId?:string
  }

const Login: React.FC<LoginProps> = (props) => {
    const loggedIn = false;
     const  [loading, setLoading] = useState(false);
     const  [validLogin, setValidLogin] = useState(true);
     const  [formValidation, setFormValidation] = useState(new FormValidation([]));
     const  [errorMessage, setErrorMessage] = useState('');
     const  [email, setEmail] = useState('');
     const  [mode, setMode] = useState(props.mode);
     const  [password, setPassword] = useState('');
    const navigate = useNavigate();
    let { userId } = useParams();
    React.useEffect(() => {
        setMode(props.mode)

    },[props.mode])
    React.useEffect(() => {
        if(mode === "impersonate") 
            handleImpersonate()  
    },[mode])


     const initialiseValidationFields = (e:string, p:string) => {
        
        const formFields = [new FormValidationField(e, 'Please enter a valid email address'),
                            new FormValidationField(p, 'Please enter a password')];

                            // formFields[0].validationType = ValidationType.Email;
                            
        formValidation.validationFields = formFields;

        setFormValidation(formValidation);
        return formValidation;
     }
        
     const handleImpersonate = async () => {
        
        if(!userId)            return

         let loginResponse = AuthenticationResultType.InvalidCredentials
         
            setLoading(true);
             let token = new JWTToken()

            const response = await impersonate(userId)

            if(response.success && response.result && 
                (response.result.user.registrationStatus === AuthenticationResultType.Valid ||
                    response.result.user.registrationStatus === AuthenticationResultType.NoPermissions)){
                token = response.result
                loginSuccess(token)
                return
            }
            loginResponse = response?.result?.user.registrationStatus as AuthenticationResultType
            
            let errorMessage = ''
            switch(loginResponse){
                // case AuthenticationResultType.UserExpired:
                //     errorMessage = "Your EMS+ user account has expired. Please contact your system administrator to reinstate your access."
                //     break
                case AuthenticationResultType.UserNotVerified:
                    errorMessage = "You have not verified your EMS+ user account. Please check your emails for a verification link."
                    break
                case AuthenticationResultType.UserAboutYou:
                    window.location.href = `/verify-user/${response?.result?.user.token}`
                    errorMessage = "You have not completed your EMS+ user account."
                    break
            }
            loginFailure(errorMessage)  
     }

     const handleLogin = async (e:string, p:string) => {
        
        try {
           
         initialiseValidationFields(e,p)
         var isValid = formValidation.isValid()

         let loginResponse = AuthenticationResultType.InvalidCredentials
         
         if(isValid){
            setLoading(true);
             let token = new JWTToken()


            const response = await login(new UserLoginType(e,p))

            if(response.success && response.result && 
                (response.result.user.registrationStatus === AuthenticationResultType.Valid ||
                    response.result.user.registrationStatus === AuthenticationResultType.NoPermissions)){
                token = response.result
                loginSuccess(token)
                return
            }
            loginResponse = response?.result?.user.registrationStatus as AuthenticationResultType
            
            let errorMessage = ''
            switch(loginResponse){
                // case AuthenticationResultType.UserExpired:
                //     errorMessage = "Your EMS+ user account has expired. Please contact your system administrator to reinstate your access."
                //     break
                case AuthenticationResultType.UserNotVerified:
                    errorMessage = "You have not verified your EMS+ user account. Please check your emails for a verification link."
                    break
                case AuthenticationResultType.UserAboutYou:
                    window.location.href = `/verify-user/${response?.result?.user.token}`
                    errorMessage = "You have not completed your EMS+ user account."
                    break
            }
            loginFailure(errorMessage)  
        }
        else{
            loginFailure()
        }
            
    }catch (error) {loginFailure()
         }
     }

     const loginSuccess = (token : IJWTToken) =>{
        setTimeout(() => {
            setJwt(token.token)    
        }, 200); 
         
         setValidLogin(true)
         
         props.onSuccessfulLogin(token.user)
         
         const redirect = getUserRedirect();
         if (redirect) {
             clearUserRedirect();
             navigate(redirect)
             return;
         }
         
        switch(token.user.registrationStatus){
            case 0:
                navigate('/home',{ replace: true })
                // window.location.href='/home';
                break;
            case 1:
                navigate('/verify-user',{ replace: true })
                break;
            case 2:
                navigate(`../registration-about-you/${token.user.token}`,{ replace: true })
                break;
            case 3:
                navigate(`/registration-access-reqirements/${token.user.token}`,{ replace: true })
                break;
        }
        
     }

     const loginFailure = (errorMessage?:string) =>{

        if(!errorMessage || errorMessage === '')
            errorMessage = INVALID_LOGIN_MESSAGE

        setErrorMessage(errorMessage)
        setValidLogin(false);
        setLoading(false);
    }
    
     if(loggedIn){
         return <Home loading={false}></Home>;
     }
     else {
         const cardActions= <Grid container  justifyContent = "center">
                <Grid item xs={10}><ActionButton style={{width:'100%'}} severity="success"  onClick={()=> handleLogin(email, password)} label="Log in"/></Grid>
                <Grid container spacing={0}  justifyContent="center">&nbsp;</Grid>
                <Grid container spacing={0}  justifyContent="center">
                                       <ActionButton label="Forgot password?" variant="link" href="/forgot-password"></ActionButton>
                               </Grid>
         </Grid>

        
        
         return  <React.Fragment>

                 <Loader loading={loading}></Loader>
                 
                 <Grid container spacing={0} justifyContent = "center">
                 <Grid item xs={4} lg={3}>
                    <div>
                            <h2>Welcome to EMS+</h2>
                            <p>EMSPlus is the chosen solution for escalation management and bed availability reporting for both Health & Social Care services to improve patient flow. </p>
                            <p>It is an early warning system for operational pressures across a regional footprint to enable collaboration and informed decision making to effectively manage patient flow, escalation and align capacity to demand.</p>
                    </div>    
                 </Grid>
                 <Grid item xs={5} lg={3} style={{marginTop:15,paddingLeft:7}}>
                 <FeedBackText severity="error" show={!validLogin} message={errorMessage}></FeedBackText>
                 <Grid item xs={12}><br></br></Grid>
                 <GroupBox title="Login to EMS+" actions={cardActions} iconName={"person"} >
                     <Grid container spacing={1} justifyContent = "center" >
                         <Grid item xs={12} lg={12}><TextBox id="outlined-username" focus={true} label="Email Address"   onChange={(e) => {setEmail(e.target.value||'')}} validationField={formValidation.validationFields[0]} onEnterKey={(e) => {setEmail(e); handleLogin(e,password)}} /></Grid>
                         <Grid item xs={12} lg={12}><TextBox id="outlined-password" label="Password" type={'password'} onChange={(e) =>  {setPassword(e.target.value||'')}}  validationField={formValidation.validationFields[1]} onEnterKey={(e) => {setPassword(e); handleLogin(email,e)}}/></Grid>
                     </Grid>

                 </GroupBox>
                 <Grid item xs={12}>&nbsp;</Grid>
                 <GroupBox title="">
                     <Grid container spacing={1} justifyContent = "center" >
                         <p>Don't have an an account? </p>
                     </Grid>
                     <Grid container spacing={1} justifyContent = "center" >
                            <ActionButton label="Register" variant="link" href="/register"></ActionButton>
                     </Grid>

                 </GroupBox>
                 <p>Get the app.</p>
                 <p><a  href="https://apps.apple.com/us/app/emsplus/id1404393311">
                     <img  src={apple_logo} alt="" height="40px"
                     /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <a  href="https://play.google.com/store/apps/details?id=uk.nhs.emsplusmobile">
                         <img  src={google_logo} alt="" height="40px"/></a></p>
                 </Grid>
                </Grid>
             </React.Fragment>;
         }
}


export default Login;
