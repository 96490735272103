export interface NotificationBroadcastType {
    id: number,
    activeFrom:Date,
    activeTo: Date,
    distributionListId: number,
    name: string,
    description: string,
    sortOrder: number,
    deleted: boolean
    includeAutoGeneratedSubmissions:boolean
    subscriptions?: NotificationBroadcastSubscriptionType[]
}
export interface NotificationBroadcastSubscriptionType {
    id: number,
    serviceId: number,
    requestedOn: Date,
    formResultOptionId: number,
    sortOrder: number,
    deleted: boolean
}

export function createEmptyNotificationBroadcast(): NotificationBroadcastType {
    return { activeFrom: new Date(), activeTo:new Date(), deleted:false, description:'', distributionListId:0, id:0, name:'', sortOrder:0, subscriptions:[],
    includeAutoGeneratedSubmissions:false};
}