import { allowAnnoymousAccess } from "../extensions/utils";
import { DocumentUploadTypes } from "../interfaces/system/documentUploadTypes";
import { getJwt, removeJwt } from "./storageService";
export interface IHttpResponse<T> extends Response {
    parsedBody?: T;
}
export class HttpResponse<T>{
    parsedBody?: T;
    result?: T;
    success: boolean = true;
    error?: string
}
const serverUri = process.env.REACT_APP_API_ENDPOINT
//44327

export const HttpGet = <T>(request: RequestInfo): Promise<HttpResponse<T>> => {

    const token = getJwt() || '';
    const anonymous = allowAnnoymousAccess();
    if ((!token || token === '') && !anonymous) {
        window.location.href = '/login'
    }
    const options = {
        method: 'GET',
        headers: {
            Authorization: `bearer ${token}`
        }
    };
    return new Promise((resolve) => {
        let response: IHttpResponse<T>;
        fetch(`${serverUri}/${request}`, options)
            .then(res => {
                response = res;
                if (res.status === 401 && !anonymous) {
                    removeJwt()
                    window.location.href = '/login'
                }
                return res.json();
            })
            .then(body => {
                if (response.ok) {
                    response.parsedBody = body;
                    resolve({ result: body, success: true })
                } else {
                    resolve({ result: body, success: false, error: body["title"] });
                }

            })
            .catch(err => {
                if (response.status === 204) {
                    resolve({ result: undefined, success: false, error: 'Requested Id does not exist' })
                } else
                    if (response.status === 403) {
                        resolve({ success: false, error: 'Unauthorised' });
                    }
                    else
                        resolve({ success: false, error: err });
            });
    });
};

export const HttpExternalGet = <T>(request: RequestInfo): Promise<HttpResponse<T>> => {
    return new Promise((resolve, reject) => {
        let response: IHttpResponse<T>;
        fetch(request)
            .then(res => {
                response = res;
                if (res.status !== 200) {
                    resolve({ success: false, error: 'err' });
                    return
                }
                return res.json();
            })
            .then(body => {
                if (response.ok) {
                    response.parsedBody = body;
                    resolve({ result: body, success: true })
                } else {
                    resolve({ result: body, success: false, error: body["title"] });
                }
            })
            .catch(err => {
                //reject(err);
                resolve({ success: false, error: 'err' });
            });
    });
};

export const HttpPost = <T>(request: RequestInfo, payload: any, file?: File): Promise<HttpResponse<T>> => {
    const token = getJwt() || '';
    if ((!token || token === '') && !allowAnnoymousAccess())
        window.location.href = '/login'

    const formData = new FormData();
    if (file) {
        formData.append("files", file);
    }
    const headers: any = {
        'Authorization': `bearer ${token}`
    };
    if (!file) {
        headers.Accept = 'application/text;';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const options = {
        method: 'POST',
        headers: headers,
        body: !file ? JSON.stringify(payload) : formData
    };
    try {
        const a = new Promise<HttpResponse<T>>((resolve, reject) => {
            let response: IHttpResponse<T>;
            const uri = `${serverUri}/${request}`
            fetch(uri, options)
                .then(res => {
                    response = res;
                    let responseJson: any

                    if (res.status === 404) {
                        resolve({ success: false, error: 'Invalid URI' });
                        return
                    }

                    
                    responseJson = res.json();
                    return responseJson
                })
                .then(body => {
                    if (response && response.ok) {
                        response.parsedBody = body;
                        resolve({ result: body, success: true })
                    } else {
                        if (body.title)
                            resolve({ success: false, error: body.title });

                        resolve({ success: false, error: JSON.stringify(body) });

                    }
                    return response
                })
                .catch(err => {
                    resolve({ success: false, error: `Could not reach ${uri}` });
                    
                    // return response
                });

        });
        return a
    }
    catch {
        return new Promise((reject) => { reject({ success: false, error: "duunno" }) })
    }

};

export const DownloadDocumentLibraryFile = async (uploadKey: string, docType: DocumentUploadTypes, filename: string): Promise<boolean> => {
    const token = getJwt() || '';
    const anonymous = allowAnnoymousAccess();
    if ((!token || token === '') && !anonymous) {
        window.location.href = '/login'
    }
    const options = {
        method: 'GET',
        headers: {
            Authorization: `bearer ${token}`
        }
    };

    const a = new Promise<boolean>((resolve, reject) => {
        fetch(`${serverUri}/storage/DownloadFile?uploadId=${uploadKey}&uploadType=${docType}`, options).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                return true;
            }).catch(err => {
                alert('error ' + err);
                return false;
            });
        });
    });
    return a;

}
export const DownloadFile = async (path:string, params: object, filename: string): Promise<boolean> => {
    const token = getJwt() || '';
    const anonymous = allowAnnoymousAccess();
    if ((!token || token === '') && !anonymous) {
        window.location.href = '/login'
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `bearer ${token}`);
    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(params)
    };

    const a = new Promise<boolean>((resolve, reject) => {
        fetch(`${serverUri}/${path}`, options).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
        resolve(true);
    });
    return a;

}