import React  from "react";
 

interface LabelTextProps {
    id?: string
    label:string;
    text?:string;
    hidden?:boolean;
    type?:'email'|'phone'|'url'
    fontSize?: 'small' | 'medium' | 'large',
    className?:string
  }
const LabelText:  React.FC<LabelTextProps> = props => {

  const labelFontSize = props.fontSize === "large" ? "1.25rem" : props.fontSize === "medium" ? "1.1rem" : "0.8rem"
  const textFontSize = props.fontSize === "large" ? "1.5rem" : props.fontSize === "medium" ? "1.25rem" : "1.3rem"

  let elem = <>{props.text}</>
    if(props.type === "email"){
      elem = <a href={`mailto:${props.text}`}>{props.text}</a>
    }
    if(props.type === "url"){
      elem = <a target="_blank" href={`http://${props.text}`}>{props.text}</a>
    }

    return <div id={props.id} className={props.className ?? undefined }>
        <div style={{fontWeight:'bold', fontSize:labelFontSize}}>{props.label}</div>
        <div style={{fontSize:textFontSize,fontWeight:300}} className="label-text-value">{elem}</div>
      </div>
              
    
}


export default LabelText;