import { Grid } from "@mui/material";
import { useState, useEffect} from "react";
import { formatDateTimeWithDay } from "../../../extensions/formatter";
import { FormSubmissionLevelByDateReportType } from "../../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { ServiceTypeLite } from "../../../interfaces/system/serviceType";
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import { getArgbColourCodeFromReportingName } from "../chartColorPallete";
import FormEscalationLevelTableReport from "./form-escalation-level-table";

export interface FormEscalationLevelCriteriaProps {
  serviceIds:number[]
  fromDate?:Date
  toDate?:Date
  selectedServiceCategoryIds?:number[]
}
interface FormEscalationLevelReportProps {
  areaHierarchy:AreaHierarchyType
  onApplyFilter: (criteria:FormEscalationLevelCriteriaProps)=> void
  onServiceSelected: (criteria?:FormEscalationLevelCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:FormSubmissionLevelByDateReportType
  defaultFilterCriteria?:BasicReportCriteria
  serviceList?:ServiceTypeLite[]
  }

  
export default function FormEscalationLevelReport(props:FormEscalationLevelReportProps) {
  const  [data, setData] = useState(props.data); 
  const [serviceList, setServiceList] = useState<ServiceTypeLite[]>()
  const [criteria, setCriteria] = useState<BasicReportCriteria>()
    
  useEffect(() => {
    
    setData(props.data)

},[props.data])

useEffect(() => {
  setServiceList(props.serviceList)
  
},[props.serviceList])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    setCriteria(criteria)

    props.onApplyFilter({serviceIds: criteria.selectedServiceIds.map(m=>m.id), 
       fromDate:criteria.fromDate, toDate:criteria.toDate, selectedServiceCategoryIds:criteria.selectedServiceCategoryIds})
}


const handleExport = () =>{
    
    const sheets:ExcelExportSheet[] = []
    const serviceName = document.querySelector('.paged-tabs-controls--title')?.textContent??'unknown'


   data?.forms?.forEach((form,formIndex)=>{
    const headers: ExcelExportHeader[] =[{title:"Submitted", width:50}]
    const exportData:ExcelExportDataRow[]=[]

      const submissions = data?.submissions?.filter(f=> f.formId === form.id)

      submissions?.forEach(f=>{
        // headers.push({title: formatDateTimeWithDay(f.createdOn),width:35})
        headers.push({title: formatDateTimeWithDay(f.createdOn),width:35,span:2})
        const row:ExcelExportDataRow = {cols:[]}
        exportData.push(row)
      })
    form?.fields.forEach(field => {
      const row:ExcelExportDataRow = {cols:[]}
       if(field.numeratorLabel) row.span = 3

      row.cols.push({value:field.name})
      submissions?.forEach(submission=>{

        submission.fields?.filter(f=>f.fieldId === field.id).forEach(f => {
          const borderColour = getArgbColourCodeFromReportingName(f.formFieldOption.reportingLabel)

          let calcHelpRows:ExcelExportDataRow[]|undefined
          
          if(field.numeratorLabel){
            calcHelpRows = [{cols:[{value:field.numeratorLabel, header:true},
              {value:field.denominatorLabel??'', header:true}]},
              {cols:[{value:f.numerator??''},
                {value:f.denominator??''}]}
            ]
          }

          row.cols.push({value:f.formFieldOption.name, leftBorderColour:borderColour, rows:calcHelpRows, span:calcHelpRows?undefined:2})  


        })

        
       }) 

       
      exportData.push(row)
    });

  
    
    let row:ExcelExportDataRow = {cols:[{header:true, value:'Overall'}]}
    submissions?.forEach(s=>{
        row.cols.push({value: s.resultLabel??'',span:2, backgroundColour:getArgbColourCodeFromReportingName(s.resultLabel??'')})
    })
    exportData.push(row)

    
    row = {cols:[{header:true, value:'Submitted By'}]}
    submissions?.forEach(s=>{
        row.cols.push({value: s.createdBy.fullName??'',span:2})
    })
    exportData.push(row)

    row = {cols:[{header:true, value:'On Call Manager'}]}
    submissions?.forEach(s=>{
        row.cols.push({value: s.contactName??'',span:2})
    })
    exportData.push(row)

    row = {cols:[{header:true, value:'Contact Number'}]}
    submissions?.forEach(s=>{
        row.cols.push({value: s.contactNumber??'',span:2})
    })
    exportData.push(row)

    row = {cols:[{header:true, value:'Comments'}]}
    submissions?.forEach(s=>{
        row.cols.push({value: s.comments??'',span:2})
    })
    exportData.push(row)

    // data?.submissions?.forEach(f=>{
          sheets.push({title:serviceName +" - Escalation Level Report",elements:[{}],name: `${serviceName} ${(formIndex>0 ? (formIndex).toString() : '')}`,
          headers:headers,
          data:exportData})
    // })

  })
    exportToExcel({
    defaultHeaderWidth:20,
    sheets:sheets
    })
}
    const handleServiceSelected = (index:number) =>{
       if(!serviceList) return
      // props.onServiceSelected(serviceList[index].id)

      
      props.onServiceSelected({...criteria, serviceIds:[serviceList.length >0 ? serviceList[index].id:0]})
    }
    const tabs: PagedTab[] = [];
      serviceList?.forEach(s => {
            tabs.push({
                title: s.name,
                children:<>test</>
            })
        });

        return  <Grid container spacing={2}>
        <Grid item xs={10}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
            defaultFilterCriteria={props.defaultFilterCriteria}
            validateServiceSelections={true}

            onExport={handleExport}/>
        </Grid>
          <Grid item xs={12} >
            <Grid item xs={12}>
              <PagedTabs tabs={tabs} 
                          activeTab={{...{title:'', children:<FormEscalationLevelTableReport data={data} />}}}
                  onTabChange={handleServiceSelected}
                  ></PagedTabs>                
            </Grid>
          </Grid>
    </Grid>
  }



