import React, { useState, useEffect } from "react";
import { CapacityGridSearchResultType } from "../../../../interfaces/system/capacityGridType";
import DataTable from "../../../core/dataTable";

interface CapacityGridListProps {
    resultList?: CapacityGridSearchResultType[],
    onSelection?: (id: number) => void,
    onNew: () => void,
    itemsPerPage?: number
}

export default function CapacityGridList(props: CapacityGridListProps) {

    // component vars
    const itemsPerPage: number = props.itemsPerPage || 25;
    
    // state vars
    const [resultList, setResultList] = useState(props.resultList)
    const [dataLoading, setDataLoading] = useState(true);

    // component events
    const init = async () => {
        updateData();
    }
    const updateData = async () => {
        setResultList(props.resultList)
        setDataLoading(false);
    }

    // hook setups
    useEffect(() => {
        init();
    }, [props.resultList])


    // event handlers
    const handleRowSelection = (id: number) => {
        if (props.onSelection) {
            props.onSelection(id)
        }
    }

    return <DataTable dataKey="id" loading={dataLoading} columns={[{ header: "Id", field: "id" },
    { header: "Name", field: "name" },
    { header: "Type", field: "resourceType.name" },
    { header: "Col. Count", field: "numberOfColumns", },
    { header: "Active", field: "deleted", formatType: "yesno-inverse" }]} paginationRowsPerPage={itemsPerPage} onSelect={(row) => handleRowSelection(row.value.id)} onEdit={(row) => handleRowSelection(row['id'])}
        editButton={true} paginator={(resultList?.length || 0) > itemsPerPage} filter={true} onNew={props.onNew} allowExport={true}
        value={resultList}
    />
}