import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { NotificationBroadcastType } from '../interfaces/system/notificationBroadcastType';
import { ValidatorType } from './ValidatorType'

export class NotificationBroadcastValidator implements ValidatorType {

    validator = new FormValidation([])
    name = new FormValidationField('', '')
    activeFrom = new FormValidationField('', '',ValidationType.Date)
    activeTo = new FormValidationField('', '',ValidationType.Date)
    distributionListId = new FormValidationField('', '',ValidationType.Numeric)

    initialiseValidationFields = (objectValues: NotificationBroadcastType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
        this.activeFrom = this.validator.createDateField(objectValues.activeFrom)
        this.distributionListId = this.validator.createPostiveNumericField(objectValues.distributionListId)
    }

    validate = (validatorObjectValues: NotificationBroadcastType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
//export class DistributionListMemberValidator implements ValidatorType {

//    validator = new FormValidation([])
//    name = new FormValidationField('', '', ValidationType.Required);
//    emailAddress = new FormValidationField('', '', ValidationType.Email);

//    initialiseValidationFields = (objectValues: DistributionListMemberType) => {
//        this.validator = new FormValidation([])
//        this.name = this.validator.createTextField(objectValues.userDisplayName)
//        this.emailAddress = this.validator.createEmailField(objectValues.userEmailAddress)
//    }

//    validate = (validatorObjectValues: DistributionListMemberType): boolean => {
//        this.initialiseValidationFields(validatorObjectValues)
//        const isValid = this.validator.isValid()

//        return isValid
//    }
//}