import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { UserType } from '../interfaces/user/iUser';
import { ValidatorType } from './ValidatorType'

export class EditUserProfileValidator implements ValidatorType {

    validator = new FormValidation([])
    email = new FormValidationField('', '', ValidationType.Email);
    fullName = new FormValidationField('', '', ValidationType.Text);
    jobrole = new FormValidationField('', '', ValidationType.Text);
    applicationUse = new FormValidationField('', '', ValidationType.Text);
    serviceName = new FormValidationField('', '', ValidationType.Text);
    areaId = new FormValidationField(0, '', ValidationType.Numeric)

    initialiseValidationFields = (objectValues: UserType) => {
        this.validator = new FormValidation([])
        this.email = this.validator.createEmailField(objectValues.email)
        this.fullName = this.validator.createTextField(objectValues.fullName)
        this.jobrole = this.validator.createTextField(objectValues.jobRole)
        this.applicationUse = this.validator.createTextField(objectValues.applicationUse)
        this.serviceName = this.validator.createTextField(objectValues.serviceName)
        this.areaId = this.validator.createDropdownField(objectValues.areaId)
    }

    validate = (validatorObjectValues: UserType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()
        return isValid
    }
}