import React, { useState, useEffect} from "react";
import {useParams } from "react-router-dom"
import {fetchAllAreaHierarchyWithServices} from '../services/systemService';
import { AreaHierarchyType,createNewAreaHierarchyType } from "../interfaces/system/areaTypes";
import { SelectedPermissionType } from "../components/core/servicePermissionSelector";
import { saveRegistrationPermissions } from "../services/userService";
import VerifyUser from "../components/user-registration/verifyUser";
import ResetPassword from "../components/user-registration/resetPassword";
import ForgotPassword from "../components/user-registration/forgotPassword";
import UserRegistrationComplete from "../components/user-registration/registrationComplete";
import UserRegistrationAccessRequirements from "../components/user-registration/registrationAccessRequirements";
import UserRegistrationAboutYou from "../components/user-registration/registrationAboutYou";
import RegisterUser from "../components/user-registration/registration"

export const INVALID_TOKEN_ERROR_MESSAGE = "Your password reset link is invalid or expired. Please use the link below to reset your password."
export const NO_VERIFICATION_TOKEN_ERROR_MESSAGE = "In order to login you must complete the registration process. Please check your email account for a verfication link. If you have not received a verification link please contact the EMS+ administator."
export const INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE = "Your verfication link is invalid or expired. Please contact the EMS+ administator."
export const INVALID_LOGIN_MESSAGE = "Unrecognised login details. Please try again."
export const MODE_ACCESS_REQUIREMENTS = 'access-requirements'
export const MODE_REGISTRATION_COMPLETE = 'registration-complete'
  //   interface IRouteParams {
  //   token: string
  // }

  interface IProps {
    mode?:'register'|'verify'|'access-requirements'|'about-you'|'reset'|'registration-complete'|'forgot-password'
    
  }

export interface ITaskReducerAction {
    type: string;
    payload: any;
    e?: any
  }
   

const AuthenticationContainer:  React.FC<IProps> = (props) => {
    const  [loading, setLoading] = useState(true);
    const {token} = useParams()
    const  [mode, setMode] = useState(props.mode);
    const  [restrictAreaId, setRestrictedAreaId] = useState<number>();
    const  [errorMessage, setErrorMessage] = useState('');
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());

    useEffect(() => {
      if(mode ===MODE_ACCESS_REQUIREMENTS){
        getserviceAreas()
      }
      
    },[mode])
    const getserviceAreas = async () =>{
      const a= await fetchAllAreaHierarchyWithServices()
      if(a.success && a.result)
        setAreaHierarchy(a.result)
      else{
        
        setErrorMessage(a.error as string)
      }
      setLoading(false)
   }
    const handlePasswordResetError = (errorMessage:string) => {
    
        
    }
    const handleShowAboutYou = () => {
      setMode('about-you')
    }
    const handleShowAccessRequirements = (user:any) => {
      setRestrictedAreaId(user.areaId)
      setMode(MODE_ACCESS_REQUIREMENTS)
    }
    const handlePermissionsRequest = async (permissions: SelectedPermissionType[]) => {
      if(!token)
        return
        setLoading(true)
        const result = await saveRegistrationPermissions(token,permissions)
        if(result.success)
          setMode(MODE_REGISTRATION_COMPLETE)
        else{
          setErrorMessage('Error saving permissions. Please contact RCMT.')
        }
        setLoading(false)
    }

    // if(token === '')
    //     window.location.href = "/login"
     
    return <>
        {!props.mode && <ForgotPassword />}
        {mode === 'reset' && <ResetPassword token={token||''} onError={handlePasswordResetError}/>}
        {mode === 'verify' && <VerifyUser token={token||''} onError={handlePasswordResetError} onSuccess={handleShowAboutYou}/>}
        {mode === 'register' && <RegisterUser/>}
        {mode === MODE_REGISTRATION_COMPLETE && <UserRegistrationComplete/>}
        {mode === MODE_ACCESS_REQUIREMENTS && <UserRegistrationAccessRequirements onCancel={handleShowAboutYou} loading={loading} errorMessage={errorMessage} onComplete={handlePermissionsRequest} areaHierarchy={areaHierarchy} token={token||''} restrictAreaId={restrictAreaId}/>}
        {mode === 'about-you' && <UserRegistrationAboutYou token={token||''} onSuccess={handleShowAccessRequirements}/>}
        
    </>
}

export default AuthenticationContainer
