import { useEffect, useState } from "react";
import { ComponentPropsBase } from "../../../interfaces/system/componentPropsBase";
import { FormResultOptionType, FormResultType, FormResultTypeEnum } from "../../../interfaces/system/formType";
import SortableList from "../../core/sortableList";
import DebugVariable from "../../core/debugVariable";
import { Grid } from "@mui/material";
import ActionButton from "../../core/actionButton";

export interface FormResultRangeListProps extends ComponentPropsBase {
    itemToEdit: FormResultType,
    onSelection: (item: FormResultOptionType) => void,
    onOrder: (items: FormResultOptionType[]) => void,
    onNew: (items: FormResultOptionType[]) => void
}
export function FormResultRangeList(props: FormResultRangeListProps) {
    const [itemToEdit, setItemToEdit] = useState<FormResultType>(props.itemToEdit);
    const [selectedItem, setSelectedItem] = useState<FormResultOptionType>();

    useEffect(() => {
        setItemToEdit(props.itemToEdit);
    }, [props.itemToEdit]);

    const handleRowClicked = (item: FormResultOptionType) => {
        setSelectedItem(item);
        if (props.onSelection) {
            props.onSelection(item);
        }
    }

    const handleReOrder = (items: FormResultOptionType[]) => {
        setItemToEdit({ ...itemToEdit, options: items })
        props.onOrder(items);
    }

    const handleNewItemAdded = () => {
        if (itemToEdit) {
            let ranges: FormResultOptionType[] = [];
            itemToEdit.options.forEach(opt => {
                ranges.push(opt);
            });
            ranges.push({ deleted: false, formId: 0, formResultId: 0, formResultOptionId: 0, id: -((ranges?.length ?? 1) - 1), name: '', shortName: '', sortOrder: ((ranges?.length ?? 1) + 1), weightingFrom: 0, weightingTo: 0, style: { id: -1, className: '', background: '', fontColour: '', fontName: '', fontSize: '', fontWeight: '' }, formResultTypeId: FormResultTypeEnum.Standard });
            setItemToEdit({ ...itemToEdit, options: ranges });
            if (props.onNew) {
                props.onNew(ranges);
            }
        }

    }

    return <>
        <DebugVariable hide={true} variable={itemToEdit} title="List" />
        <SortableList data={itemToEdit?.options || undefined} onItemSelection={handleRowClicked} onReOrder={handleReOrder}></SortableList>
        {(itemToEdit?.id ?? 0) > 0 && <>
            <Grid container spacing={1} >
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <ActionButton onClick={handleNewItemAdded} label={"Add New"} severity="secondary" size="small"></ActionButton>
                </Grid>
            </Grid>
        </>
        }
    </>
}