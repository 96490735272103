import {  Grid } from "@mui/material";
import React from "react";
import { useState, useEffect} from "react";
import { formatDateTime } from "../../../extensions/formatter";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { FormResultTypeEnum } from "../../../interfaces/system/formType";
import { ServiceTypeLite } from "../../../interfaces/system/serviceType";
import { AutomatedReportDataType, AutomatedReportTypeEnum } from "../../../interfaces/system/systemTypes";
import { ExcelExportDataRow, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { isUserAuthenticated } from "../../../services/storageService";
import ActionButton from "../../core/actionButton";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import Panel from "../../core/panel";
import FormSubmissionReport from "../form/form-submission";

export interface AutomatedReportCriteriaProps {
  serviceIds:number[]
  toDate?:Date
}
interface AutomatedReportProps {
  areaHierarchy:AreaHierarchyType
  data?:AutomatedReportDataType
  serviceList?:ServiceTypeLite[]
  }

  
export default function AutomatedReport(props:AutomatedReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    setData(props.data)
},[props.data])

  
const handleCapacityGridExport = () =>{

  const exportData:ExcelExportDataRow[]=[]
  const sheets:ExcelExportSheet[] = []

  let cgTables = document.querySelectorAll<HTMLTableElement>(".tableCG")

  cgTables.forEach(f=>{
    if(!data?.gridSubmission || !data?.gridSubmission.submissions) return;
      var serviceName = f.querySelector("#thGridName")?.innerHTML??''
    sheets.push({elements:[{table:f}], name:serviceName, title:serviceName})
  })

  exportToExcel({
    defaultHeaderWidth:25,
    data:exportData,
    sheets:sheets,
    fileName:"Situation Report"
    })
}
const handleExport = () =>{

  const isSitRep = data?.automatedReport?.reportType?.id !== AutomatedReportTypeEnum.CapacityGrid

  if(!isSitRep) {
    handleCapacityGridExport()
    return
  }

    const exportData:ExcelExportDataRow[]=[]
    const sheets:ExcelExportSheet[] = []

    let t = document.querySelector<HTMLTableElement>("#tableSS")
    
  sheets.push({name:"Situation Report", title:"Situation Report", elements:[{table:t}]})
  const includeDataCapture = data?.automatedReport?.submissionPerService
  const submissionPerService = data?.automatedReport?.submissionPerService

  if(includeDataCapture){
    t = document.querySelector<HTMLTableElement>("#tableDC")
    sheets.push({name:"Data Capture", title:"Data Capture", elements:[{table:t}]})
  }

  if(submissionPerService){
    var escalationTables = document.querySelectorAll<HTMLTableElement>(".p-datatable-table")
    escalationTables.forEach((tab,indx)=>{
      if(!data.formSubmissions) return;
      var serviceName = data.formSubmissions[indx]?.service?.name
      if(!serviceName) return
  

      
      const createdOn = tab.parentElement?.parentElement?.querySelector("#fs-created")?.querySelectorAll("div")[1]?.innerHTML??''
      const comments = tab.parentElement?.parentElement?.querySelector("#fs-comments")?.querySelectorAll("div")[1]?.innerHTML??''

      sheets.push({name: serviceName, title:serviceName, elements:[{table:tab, headers:[{width:65},{width:15}], 
        rows:[{cols:[{value:''}]},{cols:[{value:"Submitted", header:true},{value:createdOn}]}
        ,{cols:[{value:"Comments", header:true},{value:comments}]}]}],})
    })
  }


    exportToExcel({
    defaultHeaderWidth:40,
    data:exportData,
    sheets:sheets
    })
}

      const tabs: PagedTab[] = [];
        if(data?.formSubmissions)
          data?.formSubmissions.forEach(s => {
              tabs.push({
                  title: s.service?.name??'',
                  children:<FormSubmissionReport includeSummary={true} formSubmission={s} formResultType={FormResultTypeEnum.Standard} />
              })
          });

        return  <Grid container spacing={2}>
          <Grid container spacing={2} style={{marginTop:-45}}>
            <Grid item xs={11}></Grid>
            <Grid item xs={1}><ActionButton onClick={handleExport} label="Export" severity="secondary" icon="pi pi-file-export" /></Grid>
          </Grid>
          <Grid item xs={12}>
            {(data?.serviceStatus && data?.serviceStatus?.length>0) &&  <Grid item xs={12}>
              <Panel title="Situation Report">
                          <table className="condensed bordered table-striped" id="tableSS">
                            <thead>
                              <tr>
                                  <th className="text-left bold">Region</th>
                                  <th className="text-left bold">Service</th>
                                  <th className="text-left bold">Category</th>
                                  <th className="text-left bold">Level</th>
                                  <th className="text-left bold">Submitted</th>
                                  <th className="text-left bold">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                              
                                {React.Children.toArray(data?.serviceStatus?.map(fs => {
                                    return <tr>
                                        <td>{fs?.region?.name}</td>
                                        <td>{fs?.service?.name}</td>
                                        <td>{fs?.serviceCategory?.name}</td>
                                        <td style={{padding:0,margin:0}}><div style={{margin:0}} className={`form-result-${fs?.resultLabel}`}> {fs?.resultLabel}</div></td>
                                        <td>{formatDateTime(fs?.createdOn)}</td>
                                        <td>{fs?.comments}</td>
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                </Panel>
            </Grid>}
            {(data?.dataCaptureSubmission && data?.dataCaptureSubmission.submissions?.length>0) &&  <Grid item xs={12}>
              <Panel title="Data Capture">

              {React.Children.toArray(data?.dataCaptureSubmission?.forms.map(form => {
                      return <table className="condensed bordered table-striped" id="tableDC">
                            <thead>
                              <tr>
                                  <th className="text-left bold">{form.name}</th>
                                    <th className="text-left bold">Submitted</th>
                                    {React.Children.toArray(form?.fields.map(field => {
                                      return <th className="text-left bold">{field.name}</th>
                                    }))}
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(data?.dataCaptureSubmission?.submissions.filter(f=> f.formId === form.id).map(dc => {
                                    return <tr>
                                        <td>{dc?.service?.name}</td>
                                        <td>{formatDateTime(dc?.createdOn)}</td>
                                        {React.Children.toArray(dc?.fields.map(field => {
                                          return <td >{field.value}</td>
                                        }))}
                                    </tr>
                                }))}
                            </tbody>
                        </table>
              }))}
                </Panel>
            </Grid>}
            
            {(data?.gridSubmission && data?.gridSubmission?.submissions.length>0) &&  <Grid item xs={12}>
              <Panel title="Capacity">
                {React.Children.toArray(data?.gridSubmission?.grids?.map(grid => {
                        return <table className="condensed bordered table-striped tableCG">
                              <thead>
                                <tr>
                                    <th id="thGridName" className="text-left bold">{grid.name}</th>
                                      <th className="text-left bold">Submitted</th>
                                      {React.Children.toArray(grid?.columns.map(c => {
                                        return <th className="text-left bold">{c.name}</th>
                                      }))}
                                  </tr>
                              </thead>
                              <tbody>
                                  {React.Children.toArray(data?.gridSubmission?.submissions.filter(f=> f.capacityGridId === grid.id).map(dc => {
                                      return <tr>
                                          <td>{dc?.service?.name}</td>
                                          <td>{formatDateTime(dc?.createdOn)}</td>
                                          {React.Children.toArray(dc?.columns.map(field => {
                                            return <td >{field.value} </td>
                                          }))}
                                      </tr>
                                  }))}
                              </tbody>
                          </table>
                }))}
                </Panel>
            </Grid>}
          </Grid>
          {tabs.length>0 && <Grid item xs={12}>
              <Panel title="Service Escalation Levels">
                <PagedTabs tabs={tabs}></PagedTabs>                
            </Panel>
          </Grid>}
    </Grid>
  }



