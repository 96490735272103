import { Grid } from "@mui/material";
import {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm";
import OKCancelButtons from "../../../core/OKCancelButtons";
import TextBox from "../../../core/textBox";
import Loader from "../../../core/loading";
import TickBox from "../../../core/tickBox";
import { NecsExcludedServiceType } from "../../../../interfaces/system/capacityGridType";
import NecsExcludedServiceValidator from "../../../../validators/necs-excluded-service-validator";

interface NecsExcludedServiceEditProps {
    service?:NecsExcludedServiceType
    validator: NecsExcludedServiceValidator
    isNew?:boolean
    onSave: (service: NecsExcludedServiceType,validator:NecsExcludedServiceValidator) => void 
    onCancel: () => void 
  }

export default function NecsExcludedServiceEdit(props: NecsExcludedServiceEditProps) {
  

  const [validator,setValidator] = useState(props.validator)
  const {
    objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.service, validator: validator})
 

   useEffect(() => {
      setObjectValues(props.service)
      // if(props.service?.cqcId)
      //   setOriginalCqcId(props.service?.cqcId)
      
  },[props.service])

  useEffect(() => {
    setValidator(props.validator)
},[props.validator])
 
const handleSave = async () =>{
   
  props.onSave(objectValues, validator)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
    
if(!objectValues)
        return <Loader loading={true} />
  
  return <>
          <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          <Grid item xs={2}><TextBox id="cqcId"  label="CQC Id" value={objectValues.cqcId} disabled={!props.isNew} validationField={validator?.cqcId}  onChange={(e) => {setValues(e)}}/></Grid>
          
          <Grid item xs={4}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={8}><TextBox id="address" rows={5} label="Address" value={objectValues.address} validationField={validator?.address} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
              <Grid item xs={6}>
                <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />  
              </Grid>
          </Grid>
          
        </Grid>
        
        </>
}
