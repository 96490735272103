import { AmbulanceMetric, EmergencyMetric } from "../interfaces/reporting/demandAndCapacity";
import { HttpResponse, HttpGet } from "./http";

export const fetchEmergencyMetrics = async (siteCode:string) : Promise<HttpResponse<EmergencyMetric[]>> => {
  
  const response = await HttpGet<EmergencyMetric[]>(
    `demandAndcapacity/emergencymetrics/${siteCode}`
  )
  
 
  return response
}

export const fetchAmbulanceMetrics = async (siteCode:string) : Promise<HttpResponse<AmbulanceMetric[]>> => {
  
  const response = await HttpGet<AmbulanceMetric[]>(
    `demandAndcapacity/ambulancemetrics/${siteCode}`
  )
  
 
  return response
}