import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SystemReportContainer from "../../../containers/reporting/system/system-report-container";

export interface UserAuditProps{
    userId?:number
}

export function UserAuditLog(props: UserAuditProps) {
    const [userId, setUserId] = useState(props.userId);

     

    useEffect(() => {
        setUserId(props.userId)
    }, [props.userId])

    return <>
        <Grid container >
            <Grid item xs={12}>
                <SystemReportContainer mode="audit" filterParameter1={userId} />
                <p>&nbsp;</p>
            </Grid>
        </Grid>
        <div style={{ clear: 'both' }}></div>
    </>
}