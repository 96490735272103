import { DomainEntityType } from "../domain/iDomainEntity"
import { FormType, StyleType } from "../system/formType"
import { ServiceTypeLite } from "../system/serviceType"
import { UserType } from "../user/iUser"

export interface FormSubmissionType  {
    id:number
    serviceId:number
    service?:DomainEntityType
    createdOn?:Date
    createdBy?:UserType
    fields:FormSubmissionFieldType[]
    formId:number
    score:number
    formResultOptionId:number
    formResultId?:number
    comments?:string
 }
 export interface FormSubmissionFieldType  {
    id:number
    fieldId:number
    fieldTypeId:number
    fieldOptionId:number
    value:number
    formFieldOption: FormFieldOptionType
    formField:FormFieldType
    style?:StyleType
 }

 export interface OpelFormSubmissionIdsType  {
   services:OpelFormSubmissionIdsServiceType[]
}
export interface OpelFormSubmissionIdsServiceType  {
   service:DomainEntityType
   submissions:OpelFormSubmissionIdsSubmissionType[]
}
export interface OpelFormSubmissionIdsSubmissionType  {
   id:number
   createdOn?:Date
   createdBy?:UserType
}

 export interface FormFieldOptionType  {
    id:number
    name:string
    sortOrder:number
    calculationHelpRangeFrom:number
    calculationHelpRangeTo:number
    formFieldActionType:DomainEntityType
    formFieldActionFieldId:number
    reportingLabel:string
    weighting:number
    opelWeighting:number
    automationMetricRangeFrom:number
    automationMetricRangeTo:number
 }
 export interface FormFieldType  {
    id:number
    name:string
    helpText:string
    opelParameterId?:number
 }


 export interface FormComplianceReportType{
      service:DomainEntityType
        capacityGrid:DomainEntityType
        reportingResultOption:DomainEntityType
        category:DomainEntityType
        lastUpdatedOn:Date
        lapsedDaysSinceLastUpdated:number
 }
export interface FormSubmissionByFieldReportType{
   serviceSubmissions:FormSubmissionByFieldServiceReportType[]
   forms:FormSubmissionByFieldServiceFormReportType[]
}
export interface FormSubmissionByFieldServiceReportType{
   service: ServiceTypeLite
   submissions:FormSubmissionByFieldServiceSubmissionReportType[]
   aggregate:FormSubmissionByFieldAggregateReportType[]
}
export interface FormSubmissionByFieldAggregateReportType{
   fields:FormSubmissionByFieldAggregateFieldReportType[]
}
export interface FormSubmissionByFieldAggregateFieldReportType{
   fieldId:number
   name:string
   selections:FormSubmissionByFieldAggregateFieldCountReportType[]
}
export interface FormSubmissionByFieldAggregateFieldCountReportType{
   reportingLabel?:string
   count:number
}
export interface FormSubmissionByFieldServiceFormReportType{
   id:number
   fields:FormSubmissionByFieldServiceFormField[]
}
export interface FormSubmissionByFieldServiceFormField{
   id:number
   name:string
}
export interface FormSubmissionByFieldServiceSubmissionReportType{
    submittedOn:Date
    formId:number
    fields:FormSubmissionByFieldServiceSubmissionFieldReportType[]
    comments:string
    resultLabel?:string
    opelResultLabel?:string
}
export interface FormSubmissionByFieldServiceSubmissionFieldReportType{
   id:number
   fieldId:number
   name:string
   formFieldOptionId?:number
   resultLabel?:string
   reportingLabel?:string
   formField:FormSubmissionFieldType
   formFieldOption:FormFieldOptionType
   denominator?:string
   numerator?:string
}

export interface OpelFormSubmissionType  {
   services:OpelFormSubmissionServiceType[]
}

export interface OpelFormSubmissionServiceType {
   services:OpelFormSubmissionServiceType[]
   service:DomainEntityType
   submissionId:number
   reportingLabel:string
   opelResultLabel?:string
   score:number
   createdOn:Date
   fields:OpelFormSubmissionFieldType[]
}


export interface OpelFormSubmissionFieldType {
   name:string
   reportingLabel:string
   score:number
   numerator:number
   calculationResult:number
   denominator:number
}

   export interface ServiceStatusReportType
    {
        categories:ServiceStatusCategoryReportType[]
    }
    export interface ServiceStatusCategoryReportType
    {
        id:number
        name:string
        services:ServiceStatusOverviewReportType[]
    }
    export interface ServiceStatusOverviewReportType
    {
      id:number
      name:string
        reportingLabel:string
        submittedOn:Date
        submittedBy:UserType
    }

    export interface FormSubmissionLevelByDateReportType{
      form?:FormType
      forms?:FormType[]
      submissions:FormSubmissionLevelByDateReporSubmissionType[]
      }
      export interface FormSubmissionLevelByDateReporSubmissionType{
         service:DomainEntityType
         id:number
         formId:number
         createdOn:Date
         createdBy:UserType
         fields:FormSubmissionByFieldServiceSubmissionFieldReportType[]
         comments?:string
         contactName?:string
         contactNumber?:string
         resultLabel?:string
      }


 export const createNewFormSubmission = () : FormSubmissionType => {
   return {fields:[], id:0,score:0,formId:0,serviceId:0,formResultOptionId:0}
}
export const createNewFormSubmissionByFieldServiceFormReportType = () : FormSubmissionByFieldServiceFormReportType => {
return { id:0, fields:[]}
}

export interface EscalationTrendReportContainerType
    {
      formSubmissionResultByMonthYearData?:FormSubmissionResultByMonthYearSubmission
    }
    export interface FormSubmissionResultByMonthYearSubmission
    {
      services:ServiceTypeLite[]
      submissions:FormSubmissionResultByMonthYearSubmissionDate[]
      summaries:FormSubmissionResultByMonthYearSubmissionSummary[]
    }
    export interface FormSubmissionResultByMonthYearSubmissionDate{
        year:number
        month:number
        monthName:string
        levelOne:number
        levelTwo:number
        levelThree:number
        levelFour:number
        serviceId:number
    }
    export interface FormSubmissionResultByMonthYearSubmissionSummary{
      createdOn : Date
        am:number
        pm:number
        dayOfWeek:string
        serviceId:number
    }