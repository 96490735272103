// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState, useEffect } from "react";
import { FormValidationField } from '../../extensions/formValidation';
import { HelpPopout } from "./help-popout";
// import { HelpPopout } from "./help-popout";

interface TextBoxProps {
  id?: string
  label?: string;
  placeholder?: string;
  required?: boolean;
  hidden?: boolean;
  type?: 'number' | 'time' | 'date' | 'password' | 'email' | 'tel' | 'date' | 'icon'|'datetime'
  icon?: string,
  iconPosition?: 'left' | 'right',
  iconType?: 'default' | 'help',
  helpIconChildren?: React.ReactNode
  error?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  value?: any
  disabled?: boolean;
  validationField?: FormValidationField;
  helperText?: string;
  onEnterKey?: (value: string) => void
  // onEnterKey?:React.KeyboardEventHandler
  onKeyPress?: React.KeyboardEventHandler
  rows?: number
  maxLength?: number
  focus?: boolean
  ref?: any
  colour?: string
}
const TextBox: React.FC<TextBoxProps> = params => {
  // const  [error, setError] = useState(params.error||false)
  const [value, setValue] = useState(params.value)
  const [disabled, setDisabled] = useState(params.disabled)
  // const [focus, setFocus] = useState(params.focus)
  const [showPassword, setShowPassword] = useState(false)
  const [iconName, setIconName] = useState(params.type==="password" ? 'pi-eye':params.icon)
  const [validationField, setValidationField] = useState(params.validationField)

 
  useEffect(() => {
    if (params.disabled !== undefined)
      setDisabled(params.disabled)
  }, [params.disabled])
  useEffect(() => {
    if (params.validationField !== undefined)
      setValidationField(params.validationField)
  }, [params.validationField])

  const validate = (e: any): string => {

    const inputValue = e.target.value === '' ? null : params.type === "number" ? e.target.value as number : e.target.value

    setValue(inputValue)
    e.target = { value: inputValue, id: e.target.id }

    if (params.onChange) {
      params.onChange(e)
    }
    if (params.validationField) {
      params.validationField.fieldValue = inputValue
      params.validationField.isValid = params.validationField.validate()
      setValidationField(params.validationField)
    }
    return inputValue;
  }

  useEffect(() => {
    if (params.value !== value)
      setValue(params.value??'')
  }, [params.value])

  const handleClickShowPassword = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    if(params.type==='password'){
      setShowPassword(!showPassword)
      setIconName(showPassword?'pi-eye':'pi-eye-slash')
    }
  };

  // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  // };
 
  // let inputProps = {}
  // if (params.type === 'password') {
  //   inputProps = {
  //     inputProps: { maxLength: 50 },
  //     endAdornment: <IconButton
  //       aria-label="toggle password visibility"
  //       onClick={handleClickShowPassword}
  //       onMouseDown={handleMouseDownPassword}
  //       edge="end"
  //     >
  //       {showPassword ? <Visibility /> : <VisibilityOff />}
  //     </IconButton>,
  //   }
  // }

  // if (params.icon) {
  //   inputProps = {
  //       inputProps: { maxLength: params.iconType !== 'help' ? 50 : undefined },
  //       startAdornment: params.iconPosition==='start' && params.iconType !== 'help' ? <InputAdornment position={ 'start' }><i className={params.icon} /></InputAdornment> : null,
  //       endAdornment: params.iconType === 'help' ? <><HelpPopout popoutTitleText="Help text" className="input-help-button">{params.helpIconChildren ?? <></>}</HelpPopout></> : (params.iconPosition === 'end' ? <InputAdornment position={ 'end' }><i className={params.icon} /></InputAdornment> : null),
  //   }
  // }

  // if (params.maxLength) {
  //   inputProps = { maxLength: params.maxLength }
  // }

  // let helperText = params.error ? params.helperText : ''
  // if (params.validationField && !params.validationField.isValid) {
  //   helperText = params.validationField.errorMessage
  // }

if(params.rows){
  return <span className="p-float-label">
      <InputTextarea id={params.id} rows={params.rows}
          value={value??'' }
  onChange={(event: any) => { validate(event) }}
  onBlur={(event: any) => { validate(event) }}
  
  className={(params.error || (validationField && validationField.isValid !== undefined && !validationField.isValid))?'p-invalid':''}
  />
  <label htmlFor={params.id}>{params.label}</label>
</span>
}

if(params.type=== 'time' || params.type=== 'date' || params.type=== 'datetime' ){
  return <span className={`${params.placeholder ? '' : 'p-float-label'} p-input-icon-${params.iconPosition  ? params.iconPosition :'right'}`}>
     <Calendar 
  value={ (value? new Date(Date.parse(value)):null)}
  dateFormat="dd/mm/yy"
  
  style={{width:'100%'}}
  onChange={(event: any) => { validate(event) }}
  onBlur={(event: any) => { validate(event) }}
  className={(params.error || (validationField && validationField.isValid !== undefined && !validationField.isValid))?'p-invalid':''}
   timeOnly={params.type==='time'}
   showTime = {params.type=== 'datetime'}
   hourFormat="24" 
   showIcon
   showButtonBar  />
   <label htmlFor={params.id}>{params.label}</label>
</span>
}

if(params.hidden) return <></>

return <span className={`${params.placeholder ? '' : 'p-float-label'} p-input-icon-${params.iconPosition && params.iconType!=='help' ? params.iconPosition :'right'}`}>
    {iconName && <i className={`pi ${iconName}`} onClick={handleClickShowPassword}/>}
    <InputText  id={params.id} 
    value={value}
    placeholder={params.placeholder} 
    maxLength={params.maxLength}
    onChange={(event: any) => { validate(event) }}
    className={(params.error || (validationField && validationField.isValid !== undefined && !validationField.isValid))?'p-invalid':''}
    onBlur={(event: any) => { validate(event) }}
    disabled={disabled}
    
    type={params.type !== 'password' ? params.type : showPassword ? 'text' : params.type}
    // helperText={helperText}
    // row={params.rows && params.rows > 1 ? true : false}
      onKeyUp={(event: any) => {if (params.onKeyPress) params.onKeyPress(event)}}
      onKeyPress={(event: any) => {if (event.key === 'Enter') {if (params.onEnterKey) { params.onEnterKey(validate(event)) }}}}
     />
     {params.helpIconChildren && <div style={{float:'right',position:'relative',top:-50}}>
            <HelpPopout popoutTitleText="Help text" className="input-help-button">{params.helpIconChildren ?? <></>}</HelpPopout>
          </div>
      }
    <label htmlFor={params.id}>{params.label}</label>
    
</span>

  // return !params.hidden ? <TextField id={params.id} fullWidth ref={params.ref}
  //   label={params.label}
  //   error={params.error || (validationField && validationField.isValid !== undefined && !validationField.isValid)}
  //   // className={params.colour ? classes.root : ''}
  //   disabled={disabled}
  //   size="small"
  //   InputProps={inputProps}
  //   multiline={params.rows && params.rows > 1 ? true : false}
  //   rows={params.rows}
  //   variant="outlined"
  //   type={params.type != 'password' ? params.type : showPassword ? 'text' : params.type}
  //   autoComplete={params.type === 'password' ? "current-password" : ""}
  //   onBlur={(event: any) => { validate(event) }}
  //   value={value}
  //   onChange={(event: any) => { validate(event) }}
  //     className={params.iconType === 'help' ? 'input-with-help' : undefined }
  //   helperText={helperText}

  //   inputRef={(input) => {
  //     if (focus && input) {
  //       setFocus(false)
  //       input.focus()
  //     }
  //   }}
  //   onKeyUp={(event: any) => {
  //     if (params.onKeyPress) params.onKeyPress(event)
  //   }}
  //   onKeyPress={(event: any) => {
  //     if (event.key === 'Enter') {
  //       if (params.onEnterKey) { params.onEnterKey(validate(event)) }
  //     }
  //   }}


  // />
  //   : <></>

}


export default TextBox;