import React, { useState, useEffect} from "react";
import useForm from "../../hooks/useForm"
import {Grid} from '@mui/material'
import Feedback, { FeedbackType } from '../../components/core/feedback'
import {useParams} from "react-router-dom"
import {AppToastContext} from '../../extensions/context/appHook'
import Panel from "../../components/core/panel";
import FormList from "../../components/admin/form-builder/form-list";
import { fetchFormList, fetchForm, saveForm } from "../../services/form-service";
import { createNewForm, FormSearchResultType, FormType } from "../../interfaces/system/formType";
import FormEdit from "../../components/admin/form-builder/form-edit";
import { clearDomainEntities } from "../../services/domainEntitiesService";

interface FormBuilderProps {
  mode?:'list'|'form-builder'|'report-cat-list'|'report-cat-edit'
}
 
const FormBuilderContainer:  React.FC<FormBuilderProps> = (props) => {
  const [title, setTitle] = useState('Form List')
  let { formId } = useParams();
  const  [errorMessage, setErrorMessage] = useState<string|undefined>();
  
    const {
        feedbackStatus, setFeedbackStatus,
      }
       = useForm({initialObjectValues: {}})
      
      const [selectedFormId, setSelectedFormId] = useState(formId)
      const [selectedForm, setSelectedForm] = useState<FormType>()
      const [mode, setMode] = useState(props.mode)
      const [formList, setFormList] = useState<FormSearchResultType[]>()

   useEffect(() => {

    const userIsBasic = false
    if(userIsBasic){
      setFeedbackStatus(FeedbackType.NoAccess)
    }
  }
  ,[])
  useEffect(() => {
    setMode(props.mode)
  }
  ,[props.mode])
  useEffect(() => {
    present()
  }
  ,[mode])

  useEffect(() => {
    if(!selectedFormId || (selectedForm && selectedForm.id.toString() === selectedFormId)) return
    presentEditForm()
  }
  ,[selectedFormId])


  const present = async () =>{
    if(mode==='form-builder') return

    setFeedbackStatus(FeedbackType.Loading)
     presentMapper[mode||'list']()
    
  }
  const presentEditForm = async () =>{
    if(!selectedFormId) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    setFeedbackStatus(FeedbackType.Loading)
    
    if(selectedFormId==='new'){
      setMode('form-builder')
      setTitle('Create New Form')
      setSelectedForm(createNewForm())
      setFeedbackStatus(FeedbackType.hide)
      return 
    }

    const response = await fetchForm(parseInt(selectedFormId));
    
    if(response && response.success && response.result){
      setMode('form-builder')
      setTitle('Edit Form - ' + response.result.name)
      
      setSelectedForm(response.result)
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    
    toastHandler.errorMessage(response.error)
    setMode('list')
  }
   
const presentList = async () =>{
  setTitle('Forms List')
  const response = await fetchFormList();
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setFormList(response.result)
    return
  }
  setErrorMessage(response.error)
  setFeedbackStatus(FeedbackType.Error)
}
  const presentMapper: { [K: string]: Function } = {
    'list': presentList,
    'form-builder': ()=>{},
 };
    
    const toastHandler = React.useContext(AppToastContext)
     
     
    const handleNewForm = () =>{
      setSelectedFormId('new')
    }
    const handleFormSelection =async (id:number) =>{
        setSelectedFormId(id.toString())
    }
    const handleSaveForm = async (form:FormType,valid:boolean) =>{
        setSelectedForm(form)
      setErrorMessage('')
      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveForm(form)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly saved form - " + form.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newForm = {...(form as FormType)}
          newForm.id=saveResponse.result?.id
          setSelectedForm(newForm)
          setFormList(undefined)
          clearDomainEntities()

         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleCancelEditForm = () =>{
      setSelectedFormId(undefined)
      setSelectedForm(undefined)
      setMode('list')
    }
    

        const FormListElem = () => {
          if(mode!=='list') return <></>
            return <FormList onNew={handleNewForm} list={formList} onSelection={handleFormSelection}/>
          }
          const FormEditElem = () => {
            if(mode!=='form-builder') return <></>
      
            return <FormEdit form={selectedForm} onSave={handleSaveForm} onCancel={handleCancelEditForm}/>
            
            }
 

    return <Grid container justifyContent="center" spacing={0}>
        <Grid item xs={12} lg={9}>
    <Panel title={title}>
          <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
          <FormListElem/>
          <FormEditElem />
        </Panel>
        </Grid>
      </Grid>
}

export default FormBuilderContainer
