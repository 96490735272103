import React, {useEffect} from "react"
import DataTable from '../../core/dataTable'
import { UserRoleType } from "../../../interfaces/user/iUser"


interface UserRoleListProps {
    list?:UserRoleType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

const UserRoleList:  React.FC<UserRoleListProps> = (params) => { 
useEffect(() => {
  setList(params.list)
},[params.list])

const [list,setList] = React.useState(params.list)
   

  const handleRowSelection =(id:number) => {
    
    if(params.onSelection)
        params.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false} columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Public Name", field:"publicName"},
  {header:"Active", field:"deleted", formatType:"yesno-inverse"},
  {header:"Public", field:"public", formatType:"yesno"},
  {header:"Display Order", field:"sortOrder"}]}  paginationRowsPerPage={25} value={params.list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true} filter={true} onNew={params.onNew} allowExport={true}
  />

}

export default UserRoleList;