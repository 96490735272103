import { useEffect, useState} from "react";
import { FormSubmissionResultByMonthYearSubmissionDate, FormSubmissionResultByMonthYearSubmissionSummary } from "../../../interfaces/reporting/form-submission";
import Panel from "../../core/panel";
import { Chart } from 'primereact/chart';
import { Grid } from "@mui/material";
import {  chartAmberLight, chartGreen,chartRed, chartBlack } from "../chartColorPallete";
import { addMonthsToDate } from "../../../services/utils";
import { formatDate } from "../../../extensions/formatter";
require('../reporting.scss')

interface FormEscalationTrendSubmissionDaysProps {
  data?:FormSubmissionResultByMonthYearSubmissionDate[]
  summaryData?:FormSubmissionResultByMonthYearSubmissionSummary[]
  id?:number
}

  
export default function FormEscalationTrendSubmissionDays(props:FormEscalationTrendSubmissionDaysProps) {
  const  [data, setData] = useState(props.data); 
  const  [summaryData, setSummaryData] = useState(props.summaryData); 

  useEffect(() => {
    setData(props.data)
    setSummaryData(props.summaryData)
},[props.data, props.summaryData])

const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        let minDate = addMonthsToDate(new Date(), - 12)
        
        const d = data?.filter(f=> new Date(f.year, f.month, 1) >= minDate  )
        
        const chartData = {

            labels: d?.map((m) => `${m.monthName} ${m.year} `),
            datasets: [
                {
                    label: 'Level 1',
                    backgroundColor: chartGreen,
                    borderColor: chartGreen,
                    data: d?.map((m) => m.levelOne / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 2',
                    backgroundColor: chartAmberLight,
                    borderColor: chartAmberLight,
                    data: d?.map((m) => m.levelTwo / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 3',
                    backgroundColor: chartRed,
                    borderColor: chartRed,
                    data: d?.map((m) => m.levelThree / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 4',
                    backgroundColor: chartBlack,
                    borderColor: chartBlack,
                    data: d?.map((m) => m.levelFour / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                }
            ]
        };
        const maxDate = minDate
        minDate = addMonthsToDate(minDate, - 12)

        const dLastYear = data?.filter(f=> new Date(f.year, f.month, 1) >= minDate && new Date(f.year, f.month, 1) <= maxDate )
        const chartDataLastYear = {

            labels: dLastYear?.map((m) => `${m.monthName} ${m.year} `),
            datasets: [
                {
                    label: 'Level 1',
                    backgroundColor: chartGreen,
                    borderColor: chartGreen,
                    data: dLastYear?.map((m) => m.levelOne / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 2',
                    backgroundColor: chartAmberLight,
                    borderColor: chartAmberLight,
                    data: dLastYear?.map((m) => m.levelTwo / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 3',
                    backgroundColor: chartRed,
                    borderColor: chartRed,
                    data: dLastYear?.map((m) => m.levelThree / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                },
                {
                    label: 'Level 4',
                    backgroundColor: chartBlack,
                    borderColor: chartBlack,
                    data: dLastYear?.map((m) => m.levelFour / (m.levelOne+m.levelTwo+m.levelThree+m.levelFour) * 100)
                }
            ]
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.95,
            plugins: { 
                title: {
                    display: true,
                    text: '% Escalations at each level for previous 12 months'
                },
                legend: {position: "right",
                    align: "top",
                        labels: {
                            fontColor: textColor
                        }
                } ,
                custom_canvas_background_color:'rgba(255, 255, 255, 1)'
            },
            scales: {
                x: {
                    ticks: {
                        maxRotation: 60,
                        minRotation: 60,
                        autoSkip: false ,
                        color: textColorSecondary,
                        font: {
                            weight: 400,
                            size: 10,
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    suggestedMax: 4,
                    ticks: {
                        callback: function(value:string) {
                            if (value.length > 4) {
                              return value.substring(0, 4) + '...'; //truncate
                            } else {
                              return value
                            }
                  
                          },
                        stepSize:1,
        
                        color: textColorSecondary,
                        
                    },
                    grid: {
                        color: surfaceBorder,
                    }
                }
            }
        };
const optionsLastYear = {
    maintainAspectRatio: false,
    aspectRatio: 0.95,
    plugins: { 
        title: {
            display: true,
            text: '% Escalations at each level for previous year'
        },
        legend: {position: "right",
            align: "top",
                labels: {
                    fontColor: textColor
                }
        } ,
        custom_canvas_background_color:'rgba(255, 255, 255, 1)'
    },
    scales: {
        x: {
            ticks: {
                maxRotation: 60,
                minRotation: 60,
                autoSkip: false ,
                color: textColorSecondary,
                font: {
                    weight: 400,
                    size: 10,
                },
            },
            grid: {
                display: false,
                drawBorder: false
            }
        },
        y: {
            suggestedMax: 4,
            ticks: {
                callback: function(value:string) {
                    if (value.length > 4) {
                      return value.substring(0, 4) + '...'; //truncate
                    } else {
                      return value
                    }
          
                  },
                stepSize:1,

                color: textColorSecondary,
                
            },
            grid: {
                color: surfaceBorder,
            }
        }
    }
};
        
        return  <div id={`service-${props.id}`} className='service-container'>
                <Grid container>
                    <Grid item xs={6}>
                    <Panel title="Escalation Trigger Summary Chart">
                                <div style={{height:350,width:575}} id={`esc-chart-cur-${props.id}`}>
                                    <Chart type="bar" data={chartData} options={options} />
                                </div>
                                <div style={{height:350,width:575}} id={`esc-chart-prev-${props.id}`}>
                                    <Chart type="bar" data={chartDataLastYear} options={optionsLastYear} />
                                </div>
                    </Panel>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                        <div>
                        <Panel title="Days at Escalation Level">
                            <table className="reporting-table" id={`esc-tab-level-${props.id}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="form-result-1" style={{width:'50px', textAlign:'center'}}>1</th>
                                        <th className="form-result-2" style={{width:'50px', textAlign:'center'}}>2</th>
                                        <th className="form-result-3" style={{width:'50px', textAlign:'center'}}>3</th>
                                        <th className="form-result-4" style={{width:'50px', textAlign:'center'}}>4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                {data?.map((f) => (
                                            <tr>
                                                <td style={{whiteSpace:'nowrap'}}>{f.monthName} {f.year}</td>
                                                <td style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.levelOne}</td>
                                                <td style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.levelTwo}</td>
                                                <td style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.levelThree}</td>
                                                <td style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.levelFour}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Panel>
                        </div>
                        <div style={{marginTop:25}}>
                            <Panel title="Escalation Level Summary">
                                <table className="reporting-table" id={`esc-tab-summary-${props.id}`}>
                                    <thead>
                                        <tr>
                                            <th>Created On</th>
                                            <th  style={{width:'50px', textAlign:'center'}}>Day</th>
                                            <th   style={{width:'50px', textAlign:'center'}}>AM</th>
                                            <th   style={{width:'50px', textAlign:'center'}}>PM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {summaryData?.map((f) => (
                                                <tr>
                                                    <td style={{whiteSpace:'nowrap'}}>{formatDate( f.createdOn)} </td>
                                                    <td style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.dayOfWeek}</td>
                                                    <td className={`form-result-${f.am}`} style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.am}</td>
                                                    <td className={`form-result-${f.pm}`} style={{whiteSpace:'nowrap',textAlign:'center'}}>{f.pm}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </Panel>
                        </div>
                    </Grid>
                </Grid>
               
            </div>
}