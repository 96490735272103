import Page from "../../../components/core/page";
import { useState, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { FeedbackType } from "../../../components/core/feedback";
import SystemAuditReport, { SystemAuditCriteriaProps } from "../../../components/reports/system/system-audit";
import { NecsImportAuditType, SystemAuditType, UserPermissionReportType } from "../../../interfaces/reporting/system";
import { fetchAutomatedReportData, fetchSystemAudit, fetchNecsImportAuditLog, fetchUserSubscriptionReportData, fetchUsePermissionReportData } from "../../../services/reporting/system-service";
import { EmailAuditCriteriaProps, EmailAuditList } from "../../../components/reports/system/email-audit-list";
import { fetchEmailAudit, fetchEmailAuditList } from "../../../services/emailAuditService";
import { EmailAuditType } from "../../../interfaces/system/emailAuditTypes";
import moment from "moment";
import AutomatedReport from "../../../components/reports/system/automated-report";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { useParams } from "react-router-dom";
import { AutomatedReportDataType } from "../../../interfaces/system/systemTypes";
import NecsImportAuditReport from "../../../components/reports/system/necs-import-audit";
import { UserSubscriptionReportType } from "../../../interfaces/system/userSubscriptionReportType";
import { UserSubscriptionReport, UserSubscriptionReportCriteria } from "../../../components/reports/system/user-subscription-report";
import { UserPermissionReport, UserPermissionReportCriteria } from "../../../components/reports/system/user-permission-report";


interface SystemReportContainerProps {
    mode: 'audit' | 'email-audit' | 'automated-report' |'necs-audit' |'user-subscription'|'user-role'
    filterParameter1?:number
}
export default function SystemReportContainer(props: SystemReportContainerProps) {

    const [title, setTitle] = useState('Audit Report')
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());
    const [systemAuditReportData, setSystemAuditReportData] = useState<SystemAuditType[]>()
    const [necsImportAuditReportData, setNecsImportAuditReportData] = useState<NecsImportAuditType[]>()
    const [automatedReportData, setAutomatedReportData] = useState<AutomatedReportDataType>()
    const [emailAuditReportData, setEmailAuditReportData] = useState<EmailAuditType[]>()
    const [userSubscriptionReportData, setUserSubscriptionReportData] = useState<UserSubscriptionReportType[]>()
    const [userPermissionReportData, setUserPermissionReportData] = useState<UserPermissionReportType[]>()
    const [emailAuditSelectedItem, setEmailAuditSelectedItem] = useState<EmailAuditType | undefined>()
    const [filterParameter1, setFilterParameter1] = useState<number>()
    
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage
    }
        = useForm({ initialObjectValues: {} })

    let { identifier, generatedOn } = useParams() || 0;

    useEffect(() => {
        presentTitle()
    }, [])

    useEffect(() => {
        presentTitle()

        if(props.mode === "automated-report") presentAutomatedReport()
    }, [props.mode])

    useEffect(() => {
        setFilterParameter1(props.filterParameter1)
    }, [props.filterParameter1])


    const presentTitle = async () => {
        switch (props.mode) {
            case "audit": setTitle(props.filterParameter1 ? 'User Audit Log': 'Audit Report'); break;
            case "email-audit": setTitle('Email Audit'); break;
            case "necs-audit":  presentNecAuditReport(); break;
            case "automated-report":  setTitle('Automated Report'); break;
            case "user-subscription": await loadAreaHierarchy(); presentUserSubscriptionReport(); break;
            case "user-role": await loadAreaHierarchy(); presentUserRoleReport(); break;
        }
    }

    const presentNecAuditReport = async () =>{
        setFeedbackStatus(FeedbackType.Loading)
        setTitle('Necs Data Import Audit')

        const response = await fetchNecsImportAuditLog()
        setFeedbackStatus(FeedbackType.hide)

        if (response && response.success) {

            setNecsImportAuditReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)

    }


    const presentAutomatedReport = async () =>{
        await loadAreaHierarchy();

        if(!identifier) return

        setFeedbackStatus(FeedbackType.Loading)

        const response = await fetchAutomatedReportData(identifier ??'', parseInt(generatedOn??'0'))

        setFeedbackStatus(FeedbackType.hide)

        if (response && response.success) {
            setAutomatedReportData(response.result)
            setTitle(response.result?.automatedReport?.subject??'');
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)

    }
    const loadAreaHierarchy = async () => {
        
        setFeedbackStatus(FeedbackType.Loading)
        const a = await fetchAllAreaHierarchyWithServices()
        if (a.success && a.result) {
            setAreaHierarchy(a.result);
        }
        setFeedbackStatus(FeedbackType.hide)
    }
    const presentAudit = async (criteria?: SystemAuditCriteriaProps) => {

        if (!criteria)
            return

        setFeedbackStatus(FeedbackType.Loading)

        const response = await fetchSystemAudit(criteria.fromDate, criteria.toDate, criteria.userId, criteria.auditEventTypeIds)
        setFeedbackStatus(FeedbackType.hide)

        if (response && response.success) {

            setSystemAuditReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }

    const presentUserSubscriptionReport = async (criteria?: UserSubscriptionReportCriteria) => {
        setTitle('Subscription Audit Report')
        if (!criteria)
            return
        
        setFeedbackStatus(FeedbackType.Loading)
        const response = await fetchUserSubscriptionReportData(criteria.serviceIds, criteria.userIds, criteria.levels);
        setFeedbackStatus(FeedbackType.hide)

        if (response && response.success) {

            setUserSubscriptionReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentUserRoleReport = async (criteria?: UserPermissionReportCriteria) => {
        setTitle('User Permissions Report')
        if (!criteria)
            return
        
        setFeedbackStatus(FeedbackType.Loading)
        const response = await fetchUsePermissionReportData(criteria.serviceIds, criteria.userIds, criteria.userRoleIds);
        
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {

            setUserPermissionReportData(response.result)
            return
        }
      
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentEmailAudit = async (criteria?: EmailAuditCriteriaProps) => {

        if (!criteria)
            return

        setFeedbackStatus(FeedbackType.Loading)
        const response = await fetchEmailAuditList(undefined, criteria.recipient, criteria.subjectLine, criteria.fromDate, criteria.toDate);
        setFeedbackStatus(FeedbackType.hide)

        if (response && response.success) {

            setEmailAuditReportData(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleEmailAuditPreview = async (id: number) => {
        if (id && id > 0) {
            setFeedbackStatus(FeedbackType.Loading)
            const response = await fetchEmailAudit(id);
            setFeedbackStatus(FeedbackType.hide)

            if (response && response.success) {

                setEmailAuditSelectedItem(response.result)
                return
            }
            setErrorMessage(response?.error ?? 'Unknown error')
        }

        setFeedbackStatus(FeedbackType.Error)
    }

    // const handleOpelSubmissionSelected = async (criteria?: SystemAuditCriteriaProps) => {
    //     await presentAudit(criteria)
    // }


    return <Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>

        {props.mode === "audit" && <SystemAuditReport data={systemAuditReportData}
            criteria={{ showServices: false, showFromDate: true, showToDate: true, showSystemAudit: true, showUser: (((filterParameter1 ?? 0) === 0)),
             }}
            onApplyFilter={presentAudit}
            defaultFilterCriteria={{
                selectedServiceIds: [], fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
                selectedUserId:filterParameter1,
                toDate: new Date(new Date().setHours(23, 23, 0, 0))
            }}
        />
        }

        {props.mode === "email-audit" && <EmailAuditList list={emailAuditReportData} selectedItem={emailAuditSelectedItem} onSelection={(id) => { handleEmailAuditPreview(id); }}
            criteria={{ showServices: false, showFromDate: true, showToDate: true, showEmailAudit: true }}
            onApplyFilter={presentEmailAudit}
            defaultFilterCriteria={{ selectedServiceIds: [], fromDate: new Date(new Date().setHours(0, 0, 0, 0)), toDate: moment().add(1,'days').toDate() }}
        />
        }
        {props.mode === "user-subscription" && <UserSubscriptionReport list={userSubscriptionReportData} areaHierarchy={areaHierarchy }
            criteria={{ showOnlyServicesWithFormAssociated: true, showServiceCategory: false, showServices:true, showUser:true, showSubscriptionLevels:true }}
            onApplyFilter={presentUserSubscriptionReport}
            defaultFilterCriteria={{ selectedServiceIds: [] }}
        />
        }

        {(props.mode === "automated-report") && <AutomatedReport data={automatedReportData}  areaHierarchy={areaHierarchy} 
        />
        }

        {(props.mode === "necs-audit") && <NecsImportAuditReport  data={necsImportAuditReportData} />
        }

        {props.mode === "user-role" && <UserPermissionReport list={userPermissionReportData} areaHierarchy={areaHierarchy }
            criteria={{ showServices:true, showUser:true, showUserRoles:true }}
            onApplyFilter={presentUserRoleReport}
            defaultFilterCriteria={{ selectedServiceIds: [] }}
        />
        }       

    </Page>
}