import { useState } from "react";
import { DocumentUploadType, getUploadFileName } from "../../interfaces/system/documentUploadTypes";
import React from "react";
import { formatDateTime } from "../../extensions/formatter";
import { DownloadDocumentLibraryFile } from "../../services/http";

interface HelpDocsListProps {
    documents: DocumentUploadType[]
}
export function HelpDocsList(props: HelpDocsListProps) {
    const [helpDocumentList, setHelpDocumentList] = useState(props.documents);

    const handleDownloadFile = async (upload: DocumentUploadType) => {
        await DownloadDocumentLibraryFile(upload.uploadKey + '', upload.documentTypeId, getUploadFileName(upload));
    }

    return <>

        <h2>Service User Guides & Templates</h2>

        <table className="table condensed table-striped table-highlight">
            <thead>
                <tr>
                    <th className="text-left bold">Name</th>
                    <th className="text-left bold">Date</th>
                    <th className="text-left bold">Size</th>
                </tr>
            </thead>
            <tbody>
                {React.Children.toArray(helpDocumentList.map((doc) => {
                    return <tr>
                        <td>
                            <i className="pi pi-file"></i> <a href={`/files/${getUploadFileName(doc)}`} onClick={(e) => { e.preventDefault(); handleDownloadFile(doc); return false; }} title="Download file">{doc.name}</a><br />
                            <small>({getUploadFileName(doc)})<br />
                                {doc.description}
                            </small>
                        </td>
                        <td>{formatDateTime(doc.uploadedOn)}</td>
                        <td>{Math.ceil((doc.fileSize) / 1024) + " kb"}</td>
                    </tr>
                }))}
            </tbody>
        </table>

        <h2>Frequently Asked Questions:</h2>
        <p><i className="pi pi-question-circle"></i> <a href="https://csucloudservices.sharepoint.com/:w:/r/teams/rcmt/f/1.%20EMS/Z.%20EMS+%20Website%20Documnets/EMS%20Website%20Documents%20Word%20Versions/Frequently%20Asked%20Questions.docx?d=w836a52fa0fc84ab39ce23516f61ee6ba&csf=1&web=1&e=UigEcO">FAQs</a></p>

        <h2>Training Videos</h2>
        <p><a href="https://www.youtube.com/playlist?list=PL3FSma3O3-vkagBlBd1h8EBkYNFeiTsZT">https://www.youtube.com/playlist?list=PL3FSma3O3-vkagBlBd1h8EBkYNFeiTsZT</a></p>

        <h2>Download the mobile app:</h2>
        <p>
            <a href="https://apps.apple.com/us/app/emsplus/id1404393311" target="_blank" rel="noreferrer">
                <img src="https://emsplus.nhs.uk/assets/images/logo-apple-app-store.png" alt="" height="40px" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=uk.nhs.emsplusmobile" target="_blank" rel="noreferrer">
                <img src="https://emsplus.nhs.uk/assets/images/logo-google-play.png" alt="" height="40px" /></a>
        </p>
    </>

}