import { Grid } from "@mui/material";
import {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm";
import { FundingType } from "../../../../interfaces/system/serviceType";
import DomainEntityFormValidator from "../../../../validators/domain-entity-validator";
import OKCancelButtons from "../../../core/OKCancelButtons";
import TextBox from "../../../core/textBox";
import Loader from "../../../core/loading";
import TickBox from "../../../core/tickBox";

interface FundingTypeEditProps {
    fundingType?:FundingType
    validator: DomainEntityFormValidator
    onSave: (service: FundingType,validator:DomainEntityFormValidator) => void 
    onCancel: () => void 
  }

export default function FundingTypeEdit(props: FundingTypeEditProps) {
  

  const [validator,setValidator] = useState(props.validator)
  const {
    validateForm, objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.fundingType, validator: validator})
 

   useEffect(() => {
      setObjectValues(props.fundingType)
      
  },[props.fundingType])

  useEffect(() => {
    setValidator(props.validator)
},[props.validator])
 
const handleSave = async () =>{
   
  props.onSave(objectValues, validator)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
    
if(!objectValues)
        return <Loader loading={true} />
  
  return <>
          <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          <Grid item xs={2}><TextBox id="id"  label="Id" hidden={objectValues.id<1}  disabled={true} value={objectValues.id} validationField={validator?.id}  onChange={(e) => {setValues(e)}}/></Grid>
          
          <Grid item xs={2}><TextBox id="sortOrder" type="number" label="Display Order" value={objectValues.sortOrder} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={4}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
          <Grid item xs={8}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={8}><TextBox id="description" rows={5} label="Description" value={objectValues.description} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
              <Grid item xs={6}>
                <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />  
              </Grid>
          </Grid>
          
        </Grid>
        
        </>
}
