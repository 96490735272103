import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { fetchUserPermissionsList, saveUserPermissions } from "../../../services/userService";
import ActionButton from "../../core/actionButton";
import ServicePermissionSelector, { PermissionEntityType, SelectedPermissionType, ServiceGroupingType, UserRoleType } from "../../core/servicePermissionSelector";
import { UserEditorProps } from "./edit-user";

export function EditUserAccess(props: UserEditorProps) {
    const [dirty, setDirty] = useState(false);
    const [areaHierarchy, setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType());
    const [userToEdit, setUserToEdit] = useState(props.User);
    const [selectedPermissions, setSelectedPermissions] = useState<SelectedPermissionType[]>();

    useEffect(() => {
        getServiceAreas()
    }, [userToEdit]);

    useEffect(() => {
        setUserToEdit(props.User);
        setDirty(false);
    }, [props.User])

    const getServiceAreas = async () => {
        if (userToEdit && userToEdit.id > 0) {
            handleLoading();
            const response = await fetchAllAreaHierarchyWithServices()

            if (response.success && response.result) {
                setAreaHierarchy(response.result);
                await getSelectedPermissions();
            }
            handleLoaded();
        }
    }

    const getSelectedPermissions = async () => {
        handleLoading();
        let selectedServices: SelectedPermissionType[] = [];
        let selectedAreas: SelectedPermissionType[] = [];
        const response = await fetchUserPermissionsList(userToEdit.id)
        if (response.success && response.result && response.result.length > 0) {
            response.result.forEach(request => {
                const userRoleValue = request.userRoleTypeId === UserRoleType.Pending ? 'Pending' : (request.userRoleTypeId === UserRoleType.Read ? 'Read' : 'Write');

                if ((request.areaId ?? 0) > 0) {
                    selectedAreas.push({ id: (request.serviceId ?? 0), name: '', status: request.status, serviceId: request.serviceId ?? 0, userRoleTypeId: request.userRoleTypeId, type: PermissionEntityType.Service, userRole: userRoleValue, requestedOn: request.requestedOn });
                } else if ((request.serviceId ?? 0) > 0) {
                    selectedServices.push({ id: -(request.serviceId ?? 0), name: '', status: request.status, serviceId: request.serviceId ?? 0, userRoleTypeId: request.userRoleTypeId, type: PermissionEntityType.Service, userRole: userRoleValue, requestedOn: request.requestedOn });
                }
            });
        }
        selectedServices = selectedServices.concat(selectedAreas)

        if (selectedServices) {
            setSelectedPermissions(selectedServices);
        }
        handleLoaded();
    }

    const handlePermissionsChange = (selected: SelectedPermissionType[]) => {
        setDirty(true);
        setSelectedPermissions(selected);
    }

    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }

    const handleSave = async () => {
        if (!selectedPermissions || setSelectedPermissions.length < 1) {
            return;
        }
        handleLoading();
        const response = await saveUserPermissions(userToEdit.id, selectedPermissions);
        handleLoaded();
        if (response && response.success && response.result) {
            if (props.OnSaved) {
                props.OnSaved(props.User, "Selected permissions have been submitted for review");
            }
            setDirty(false);
            return;
        }
        handleError(response?.error ?? 'An unknown error was encountered');
    }

    if (!areaHierarchy || !selectedPermissions) {
        return <></>;
    }

    return <>
        <h3>Edit Access Requirements</h3>
        <Grid container>
            <Grid item xs={12}>
                <ServicePermissionSelector onChange={handlePermissionsChange} selections={selectedPermissions} areaHierarchy={areaHierarchy} grouping={ServiceGroupingType.Region} ></ServicePermissionSelector>
                <p>&nbsp;</p>
            </Grid>
            <div style={{ clear: 'both' }}></div>
            <p>&nbsp;</p>
            {dirty &&
                <>
                    <Grid item xs={12}>
                        <div style={{ float: 'right' }}>
                            <ActionButton onClick={handleSave} label="Save" severity={'success'}></ActionButton>
                        </div>
                        <p>&nbsp;</p>
                    </Grid>
                </>
            }
        </Grid>
        <div style={{ clear: 'both' }}></div>
    </>
}