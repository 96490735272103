interface DebugVariableOptions {
  variable: any,
  title?: string,
  hide?: boolean
}
export default function DebugVariable(props: DebugVariableOptions) {
  return <div style={{ fontSize: "12px", border: "1px solid #ccc", width: "80%", margin: "10px auto", display:((props.hide ?? false) === true ? "none":"block") }}>
    {props.title !== null && <h2 style={{ textAlign: "center", fontWeight: "normal", backgroundColor: "#ccc", color: "#000", padding: "5px", marginTop: "0" }}>{props.title}: </h2>}
    <pre style={{ overflow: "auto", padding: "5px" }}>{JSON.stringify(props.variable, null, 2)}</pre>
  </div>
}