import { useEffect, useState } from "react";
import { CapacityGridColumnType, CapacityGridFieldTypes, CapacityGridSubmissionColumnType } from "../../interfaces/system/capacityGridType"
import DebugVariable from "../core/debugVariable";
import Dropdown, { IDropdownOptions } from "../core/dropdown";
import TextBox from "../core/textBox";

interface ICapacityGridSubmissionEditorFieldProps {
  SubmissionData: CapacityGridSubmissionColumnType,
  ColumnData: CapacityGridColumnType,
  onUpdated: (field: CapacityGridSubmissionColumnType) => void
}
export default function CapacityGridSubmissionEditorField(props: ICapacityGridSubmissionEditorFieldProps) {
  const [submissionData, setSubmissionData] = useState<CapacityGridSubmissionColumnType>(props.SubmissionData);
  const [columnData, setColumnData] = useState<CapacityGridColumnType>(props.ColumnData);
  const [fieldIsDropDown, setFieldIsDropDown] = useState<boolean>(false);
  const [fieldHasRange, setFieldHasRange] = useState<boolean>(false);

  const hideDebug: boolean = true;
  const cellType: CapacityGridFieldTypes = columnData.fieldType.id;

  // hooks
  useEffect(() => {
    setFieldIsDropDown(columnData.fieldType?.name?.toLowerCase() === "text" && ((columnData?.textFieldOptions?.length ?? 0) > 0));
    setFieldHasRange(((columnData?.numericFieldOptions?.length ?? 0) > 0));
  }, [])

  const handleFieldUpdated = (e: any) => {
    if (!submissionData) return;
    //console.clear();

    const value = e.target.value

    if (fieldIsDropDown) {
      submissionData.columnOptionId = value;
      submissionData.value = undefined;
    }
    if (!fieldIsDropDown) {
      submissionData.columnOptionId = undefined;
      submissionData.value = value
    }
    submissionData.id = submissionData.capacityGridSubmissionId = 0;
    props.onUpdated(submissionData)
  }

  switch (cellType) {
    default:
    case CapacityGridFieldTypes.Text:
      if (fieldIsDropDown) {
        let options: IDropdownOptions[] = columnData.textFieldOptions.map(opt => { return { id: opt.id, name: opt.value, code: '' } });

        return <>
          <DebugVariable hide={hideDebug} variable={submissionData} title="Field data" />
          <Dropdown label="" includeBlankOption={true} value={submissionData?.columnOptionId ?? ''} options={options} onSelection={handleFieldUpdated}></Dropdown>
        </>
      }
      return <>
        <DebugVariable hide={hideDebug} variable={submissionData} title="Field data" />
        <TextBox value={submissionData?.value ?? ''} onChange={handleFieldUpdated}></TextBox>
      </>
    case CapacityGridFieldTypes.Numeric:
      return <>
        <DebugVariable hide={hideDebug} variable={submissionData} title="Field data" />
        <TextBox value={submissionData?.value ?? ''} onChange={handleFieldUpdated} type="number"></TextBox>
      </>
    case CapacityGridFieldTypes.Calculated:
      return <>
        <DebugVariable hide={true} variable={columnData.calculatedFieldFormula} title="Formula" />
        <p>{submissionData?.value}</p>
      </>
  }
}