import React, { useState, useEffect} from "react";
import useForm from "../../hooks/useForm"
import { FeedbackType } from '../../components/core/feedback'
import {useParams } from "react-router-dom"
import { createNewUser} from "../../interfaces/user/iUser"
import {AppToastContext} from '../../extensions/context/appHook'
import { saveApiUser, fetchApiUserList, fetchApiUser, } from "../../services/systemService";
import { clearDomainEntities } from "../../services/domainEntitiesService";
import Page from "../../components/core/page";

import Panel from "../../components/core/panel";
import { ApiUserList } from "../../components/admin/system/api-user/api-user-list";
import { APIUserType, createNewAPIUser } from "../../interfaces/system/systemTypes";
import ApiUserEdit from "../../components/admin/system/api-user/api-user-edit";

interface IProps {
  mode?:'list'|'edit'
}
 
export function  APIUserContainer  (props:IProps) {
  const DEFAULT_TITLE = 'API User List'
  let { userId} = useParams();
  
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage,
      }
       = useForm({initialObjectValues: createNewUser()})
      
      const [mode, setMode] = useState(props.mode)
      const [apiUserList, setApiUserList] = useState<APIUserType[]>()
      const [title, setTitle] = useState(DEFAULT_TITLE)
      const [selectedApiUser, setSelectedApiUser] = useState<APIUserType>()
      const [selectedApiUserId, setSelectedApiUserId] = useState(userId)
      const [validator,setValidator] = useState<any>()
 
  useEffect(() => {
    setMode(props.mode)
  }
  ,[props.mode])
  useEffect(() => {
    present()
  }
  ,[mode])

  useEffect(() => {
    if(!selectedApiUserId || (selectedApiUser && selectedApiUser.id.toString() === selectedApiUserId)) return
    presentEdit()
  }
  ,[selectedApiUserId])
  
  const present = async () =>{

    if(mode==='edit' ) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

    setFeedbackStatus(FeedbackType.Loading)
     presentMapper[mode||'list']()
  }
  const presentList = async () =>{
    const backGroundUpdate = mode!=='list'

    if(!backGroundUpdate)
      setTitle(DEFAULT_TITLE)

    if(apiUserList && !backGroundUpdate) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

      const response = await fetchApiUserList();
      setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setApiUserList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
     
  const presentEdit = async () =>{
    if(!selectedApiUserId) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    setFeedbackStatus(FeedbackType.Loading)
    setMode('edit')
    
    if(selectedApiUserId==='new'){
            setTitle('Create New API User')
      setSelectedApiUser(createNewAPIUser())
      setFeedbackStatus(FeedbackType.hide)
      return 
    }

    const response = await fetchApiUser(parseInt(selectedApiUserId));
    
    if(!response || response.success){
      setTitle('Edit API User - ' + response.result?.name)
      
      setSelectedApiUser(response.result)
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    
    toastHandler.errorMessage(response.error)
    // setMode('list')
}
 
  const presentMapper: { [K: string]: Function } = {
    'list': presentList,
    'edit': presentEdit,
     
    
 };
    const handleServiceSelection =async (id:number) =>{
      setSelectedApiUserId(id.toString())
    }
 

    const handleSaveUser = async (user:APIUserType) =>{
      setSelectedApiUser(user)
      setErrorMessage('')
      
      setFeedbackStatus(FeedbackType.Loading)

      const saveResponse = await saveApiUser(user)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly saved API User - " + user.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newService = {...(user as APIUserType)}
          newService.id=saveResponse.result?.id
          setSelectedApiUser(newService)
          setApiUserList(undefined)
          presentList()
          clearDomainEntities()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleNewUser = () =>{
      setSelectedApiUserId('new')
    }
    const handleCancelEdit = () =>{
      setSelectedApiUserId(undefined)
      setSelectedApiUser(undefined)
      setMode('list')
    }
    
    const toastHandler = React.useContext(AppToastContext)
    
    const UserListElem = () => {
      if(mode!=='list') return <></>
        return <><ApiUserList list={apiUserList} onNew={handleNewUser}  onSelection={handleServiceSelection}/></>
    }
    const UserEditElem = () => {
      if(mode!=='edit') return <></>
        return <ApiUserEdit user={selectedApiUser} onSave={handleSaveUser} onCancel={handleCancelEdit}/>
    }
     
    
 
    return <><Page title="" feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
        <Panel title={title}>
              <UserListElem />
              <UserEditElem />
               
               
          </Panel>
          </Page>
      </>
}
