import { Grid } from "@mui/material";
import React from "react";
import { AmbulanceMetric } from "../../../interfaces/reporting/demandAndCapacity";
import LabelText from "../../core/labelText";
import Panel from "../../core/panel";

export interface DemandAndCapacityCurrentWaitsProps{
    ambulanceMetric:AmbulanceMetric
    
}
export default function DemandAndCapacityAmbulanceStatus(props: DemandAndCapacityCurrentWaitsProps) {

    const  [ambulanceMetric, setAmbulanceMetric] = React.useState(props.ambulanceMetric);

    React.useEffect(() => {
        setAmbulanceMetric(props.ambulanceMetric)
      }
      ,[props.ambulanceMetric])
      
    return <Panel title="Status">
        <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                    <LabelText label="En-Route" text={ambulanceMetric?.enRoute.toString()} />
                </Grid>
                <Grid item xs={6}>
                    <LabelText label="On Site" text={ambulanceMetric?.onSiteTotal.toString()} />
                </Grid>
                <Grid item xs={6}>
                    <LabelText label="Awaiting Handover" text={ambulanceMetric?.onSiteAwaitingHandover.toString()} />
                </Grid>
                <Grid item xs={6}>
                    <LabelText label="Handover Complete" text={ambulanceMetric?.onSiteHandoverComplete.toString()} />
                </Grid>
        </Grid>
</Panel>
}