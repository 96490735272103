import React, {useEffect,useState} from "react";
import Panel from "../components/core/panel";
import { userHasApprovedPermissions } from "../services/storageService";
import Home from "../components/home/home";
import { fetchFeaturesForService, fetchServiceStatus } from "../services/systemService";
import { ServiceStatusType } from "../interfaces/system/serviceType";
import { saveServiceFilter } from "../services/userService";
import useForm from "../hooks/useForm";
import { AppToastContext } from "../extensions/context/appHook";
import Feedback, { FeedbackType } from "../components/core/feedback";
import { fetchFormSubmission } from "../services/reporting/form-service";
import { FormSubmissionType,createNewFormSubmission } from "../interfaces/reporting/form-submission";
import {Grid} from "@mui/material";
import { DomainEntityType } from "../interfaces/domain/iDomainEntity";
import { fetchAddressByServiceId } from "../services/addressService";
import { AddressType, createNewAddress } from "../interfaces/system/iAddress";
import { fetchDocumentsByService } from "../services/documentLibraryService";
import { DocumentUploadType } from "../interfaces/system/documentUploadTypes";
import { fetchActionCardsForService } from "../services/actionCardService";
import { ActionCardHomepageOverviewType } from "../interfaces/system/actionCardType";
import { updateCombinedForm } from "../services/form-service";

const HomeContainer:  React.FC = () => {

    const  [noPermissions, setNoPermissions] = useState(false);
    const  [loading, setLoading] = useState(false);
    const  [serviceStatusList, setServiceStatusList] = useState<ServiceStatusType[]>();
    const  [formSubmission, setFormSubmission] = useState<FormSubmissionType>();
    const  [serviceFeatures, setServiceFeatures] = useState<DomainEntityType[]>();
    const  [serviceAddress, setServiceAddress] = useState<AddressType>();
    const [serviceUploads, setServiceUploads] = useState<DocumentUploadType[]>();
    const [serviceActionCard, setServiceActionCard] = useState<ActionCardHomepageOverviewType>();

    const {
        feedbackStatus, setFeedbackStatus, errorMessage}
       = useForm({initialObjectValues: {}})

       const toastHandler = React.useContext(AppToastContext)

    useEffect(() => {
        const hasPermissions = userHasApprovedPermissions()
        setNoPermissions(hasPermissions)

        getServiceStatus()

      },[])

      const handleSaveFilter = async (filter:string) => {
        setFeedbackStatus(FeedbackType.Loading)

        const result = await saveServiceFilter(JSON.stringify(filter))
        setFeedbackStatus(FeedbackType.hide)
        if(result.success){
            toastHandler.successMessage("Successfuly saved your default filter")  
        }
        else
        toastHandler.errorMessage("Failed to save your default filter")  

      }

      const getServiceStatus = async () => {
        setLoading(true)
        const result = await fetchServiceStatus()
        setServiceStatusList(result.result?.services)
        setLoading(false)
      }

      const getFormSubmission = async (serviceId:number) => {
        
        const result = await fetchFormSubmission(serviceId)
        if(result.success && result.result)
            setFormSubmission(result.result)
        else
            setFormSubmission(createNewFormSubmission())
      }

      const getServiceFeatures = async (serviceId:number) => {
        
        const result = await fetchFeaturesForService(serviceId)
        if(result.success && result.result)
            setServiceFeatures(result.result)
        else
            setServiceFeatures([])
      }
      const getServiceAddress = async (serviceId:number) => {
        const result = await fetchAddressByServiceId(serviceId)
        if(result.success && result.result)
            setServiceAddress(result.result)
        else
            setServiceAddress(createNewAddress())
    }

    const handleCombinedUpdated = async (serviceId: number, formResultOptionId:number,auto:boolean) => {
        const result = await updateCombinedForm(serviceId,formResultOptionId,auto)
        
        if(result.success && result?.result){
            toastHandler.successMessage("Successfuly updated CEL")  
            window.location.reload()
        }
        else{
            toastHandler.errorMessage("Failed to update CEL")  
        }
            
    }
    const getServiceUploads = async (serviceId: number) => {
        
        const result = await fetchDocumentsByService(serviceId)
        if(result.success && result.result)
            setServiceUploads(result.result)
    }
    const getServiceActionCard = async (serviceId: number) => {
        
        const response = await fetchActionCardsForService(serviceId)
        if (response.success && response.result)
            setServiceActionCard(response.result)
        
    }

      if(!noPermissions)
        return <Grid container justifyContent="center">
            <Grid item xs={10} lg={6}>
                <Panel title="Access permissions pending"><h4>You do not yet have permission to view the homepage.</h4>
                    <p>If you have just registered, you will be notified by email when an administrator has approved your access request(s). 
                    Please log out and log in again when your access has been approved.</p>
                </Panel>
            </Grid>
        </Grid>


    return <>
        <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
        <Home loading={loading}
            serviceStatusList={serviceStatusList} onSaveServiceFilter={handleSaveFilter}
            formSubmission={formSubmission}
            serviceFeatures={serviceFeatures}
            serviceAddress={serviceAddress}
            serviceUploads={serviceUploads}
            serviceActionCard={serviceActionCard}
            onRequestFormSubmission={getFormSubmission}
            onRequestServiceFeatures={getServiceFeatures}
            onRequestServiceAddress={getServiceAddress}
            onRequestServiceUploads={getServiceUploads}
            onRequestServiceActionCard={getServiceActionCard}
            onCombinedUpdated={handleCombinedUpdated}

            />
    </>
}

export default HomeContainer;