import React, {useState, useEffect} from 'react';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {FormValidationField} from '../../extensions/formValidation';
import {formatDateFromJson, formatDateTimeFromJson} from '../../extensions/formatter'
// import { TextField } from '@mui/material';
import TextBox from './textBox';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker,
//   } from '@material-ui/pickers';

interface IDateTimePickerParams {
    id?: string;
    label?:string;
    required?:boolean;
    password?:boolean;
    error?:boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value:Date|undefined|string;
    disabled?:boolean;
    validationField?: FormValidationField;
    helperText?:string;
    onEnterKey?:React.KeyboardEventHandler
    rows?: number,
    showTimePicker?:boolean
  }

const DateTimePicker:  React.FC<IDateTimePickerParams> = (params) => {
    const [selectedDate, setSelectedDate] = React.useState(params.value);
    const [error, setError] = useState(params.error || false);
    
    useEffect(() => {
        if(!params.error && params.validationField){
          if(params.validationField.isValid !== undefined)
            setError(!params.validationField.isValid)
        }
      },[params.validationField])

      useEffect(() => {
        
        setSelectedDate( params.value)
      },[params.value])


    const validate = (e: any) :boolean => {
      const inputValue = e.target.value

      e.target.id =params.id
      if(params.onChange){
        e.target.value = inputValue as string
        params.onChange(e)
      }
        if(params.validationField){
          params.validationField.fieldValue = inputValue;
          setError(!params.validationField.validate())
        }
          return params.error||false;
      }


    
        let helperText = ''
    if(params.validationField && !params.validationField.isValid){
      helperText = params.validationField.errorMessage
    }
    
    return <TextBox type={params.showTimePicker? 'datetime': 'date'} 
    label={params.label} onChange={validate} value={params.showTimePicker ? formatDateTimeFromJson(selectedDate) :formatDateFromJson(selectedDate)} 
    validationField={params.validationField}
    />
    // return (
    //     <TextField
    //         id={params.id} fullWidth
    //         label={params.label}
    //         type={params.showTimePicker ? 'datetime-local' :'date' }
    //         variant="outlined" 
    //         disabled={params.disabled}
    //         error={ error}
    //         size="small"
    //         helperText={helperText}
    //         // defaultValue={params.showTimePicker ? formatDateTimeFromJson(selectedDate) :formatDateFromJson(selectedDate)}
    //         value={params.showTimePicker ? formatDateTimeFromJson(selectedDate) :formatDateFromJson(selectedDate)}
    //         onChange={(e)=>{setSelectedDate(new Date(Date.parse(e.target.value)))}}
    //         //defaultValue={formatDateFromJson(params.value)}
    //         InputLabelProps={{
    //             shrink: true,
    //         }}
    //         //inputProps={{value:formatDateFromJson(selectedDate)}}
    //         onBlur={(event) => {validate(event)}}
    //   />
    // )
}

export default DateTimePicker