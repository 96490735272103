import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import { ActionCardServiceIdCheckResponseType, ActionCardType, createEmptyActionCard } from "../../interfaces/system/actionCardType";
import { checkServiceIdHasActionCard, fetchActionCard, fetchActionCardList, saveActionCard } from "../../services/actionCardService";
import { ActionCardList } from "../../components/admin/action-card-builder/action-card-list";
import { Grid } from "@mui/material";
import Panel from "../../components/core/panel";
import { ActionCardEditor } from "../../components/admin/action-card-builder/action-card-edit";
import { FormType } from "../../interfaces/system/formType";
import { fetchFormFromCache } from "../../services/form-service";

interface ActionCardBuilderConatinerProps {
    mode?: 'edit' | 'list'
}
export function ActionCardBuilderConatiner(props: ActionCardBuilderConatinerProps) {
    let { id } = useParams() || 0;
    let svcId = parseInt(id ?? '0');
    const navigate = useNavigate();

    const [title, setTitle] = useState('Action Cards');
    const [feedbackStatus, setFeedbackStatus] = useState(FeedbackType.hide);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [itemList, setItemList] = useState<ActionCardType[]>();
    const [selectedItemId, setSelectedItemId] = useState(svcId);
    const [selectedItem, setSelectedItem] = useState<ActionCardType>();
    const [form, setForm] = useState<FormType>();
    const [mode, setMode] = useState(props.mode ?? 'list');

    useEffect(() => {
        present();
    }, [props.mode, selectedItem, mode]);

    
    useEffect(() => {
        //console.clear(); console.log(selectedItemId, "Selected ID changed!");
        if (!selectedItemId || selectedItemId <= 0) return;
        setMode('edit');
        presentEdit();
    }, [selectedItemId]);

    const present = async () => {
        if (props.mode === 'list' || !itemList) {
            refreshList();
        }
    }

    const refreshList = async () => {
        setFeedbackStatus(FeedbackType.Loading);
        setTitle('Action Cards');
        const response = await fetchActionCardList();
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setItemList(response.result)
            return
        }
        setErrorMessage(`Error loading list data: ${response.error}`)
        setFeedbackStatus(FeedbackType.Error)
    }

    const presentEdit = async () => {
        setFeedbackStatus(FeedbackType.Loading);
        if (selectedItemId > 0) {
            const response = await fetchActionCard(selectedItemId);
            if (response && response.success && response.result && response.result.formId) {

                setFeedbackStatus(FeedbackType.Loading);
                const formResponse = await fetchFormFromCache(response.result.formId);
                if (formResponse && formResponse.success) {
                    setForm(formResponse.result);
                    setSelectedItem(response.result);
                    setTitle(`Edit Action Card: ${response.result.name}`);
                    setFeedbackStatus(FeedbackType.hide);
                    return;
                }
                setErrorMessage(`Error loading form data for selected service: ${response.error}`)
                setFeedbackStatus(FeedbackType.Error)
            }
            setErrorMessage(`Error loading action card: ${response.error}`)
            setFeedbackStatus(FeedbackType.Error)
        }
    }

    const checkIfServiceHasActionCard = async (serviceId: number): Promise<ActionCardServiceIdCheckResponseType> => {
        const response = await checkServiceIdHasActionCard(serviceId, selectedItem?.id ?? 0);
        if (response && response.success && response.result) {
            return response.result;
        } else {
            setErrorMessage(`Error checking service ID: ${response.error}`);
            setFeedbackStatus(FeedbackType.Error);
        }

        return { actionCardId: 0, exists: true };
    }

    const handleRowSelection = async (id: number) => {
        setMode('edit');
        navigate(`/admin/action-cards/${id}`);
        setSelectedItemId(id);
        presentEdit();
    }
    const handleCancel = async () => {
        setSelectedItemId(0);
        setMode('list');
        navigate(`/admin/action-cards`);
    }
    const handleNewItem = () => {
        setSelectedItemId(0);
        setMode('edit');
        setSelectedItem(createEmptyActionCard());
    }
    const handleSave = async (item: ActionCardType, valid: boolean) => {
        setSelectedItem(item);
        setErrorMessage('');
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveActionCard(item)
        
        if (saveResponse.success) {
            setFeedbackStatus(FeedbackType.hide)
            setSelectedItem(undefined);
            setMode('list');
            setErrorMessage('Item saved');
            setFeedbackStatus(FeedbackType.SaveSuccess);
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error');
        setFeedbackStatus(FeedbackType.Error);

    }

    return <>
        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel title={title} loading={feedbackStatus === FeedbackType.Loading}>
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                            {mode === 'list' &&
                                <ActionCardList list={itemList ?? []} onSelection={handleRowSelection} onNew={handleNewItem} />
                            }

                            {mode === 'edit' && selectedItem &&
                                <>
                                    <ActionCardEditor item={selectedItem} form={form} onSave={handleSave} onCancel={handleCancel} checkForDuplicateServiceId={checkIfServiceHasActionCard} />
                                </>
                            }
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>

}