import { useEffect, useState } from "react";
import { DistributionListMemberType, DistributionListType } from "../../../../interfaces/system/distributionListTypes";
import { createEmptyDistributionList, createEmptyDistributionListMember, fetchDistributionList, fetchDistributionLists, saveDistributionList } from "../../../../services/distribution-list-service";
import OKCancelButtons from "../../../core/OKCancelButtons";
import { Grid } from "@mui/material";
import BasicTab from "../../../core/basicTab";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import { DistributionListMemberValidator, DistributionListValidator } from "../../../../validators/distribution-list-validator";
import { UserType } from "../../../../interfaces/user/iUser";
import ActionButton from "../../../core/actionButton";
import { UserSearch } from "../../../core/userSearch";
import DataTable from "../../../core/dataTable";
import { clearDomainEntities } from "../../../../services/domainEntitiesService";

export interface DistributionListEditorProps {
    selectedId: number,
    onSaved: (item: DistributionListType) => void,
    onBackClicked: () => void,
    onError: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void
}
export function DistributionListEditor(props: DistributionListEditorProps) {
    const [selectedItemId, setSelectedItemId] = useState<number>(props.selectedId);
    const [selectedItem, setSelectedItem] = useState(createEmptyDistributionList());
    const [modelValidator, setModelValidator] = useState(new DistributionListValidator());
    const [memberValidator, setMemberlValidator] = useState(new DistributionListMemberValidator());
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);

    const [memberToAdd, setMemberToAdd] = useState<DistributionListMemberType>(createEmptyDistributionListMember(selectedItemId));


    // hooks
    useEffect(() => {
        //console.clear();
        init();
        window.addEventListener('popstate', function () {
            props.onBackClicked()
        }, false);
    }, []);

    const init = async () => {
        if (selectedItemId > 0) {
            
            handleLoading();
            const response = await fetchDistributionList(selectedItemId, false);
            handleLoaded();
            if (response && response.success && response.result) {
                setSelectedItem(response.result);
                setIsValid(modelValidator.validate(response.result));
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
    }

    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedGrid = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedGrid);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedGrid = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedGrid);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }
    const handleItemSaved = (item: DistributionListType) => {
        if (props.onSaved) {
            props.onSaved(item);
        }
    }

    const handleRemoveUser = (member: DistributionListMemberType) => {
        
        if (selectedItem) {
            let _members: DistributionListMemberType[] = selectedItem.members.map(m => {
                if (m.id === member.id) {
                    m.deleted = true;
                }

                return m;
            });
            setSelectedItem({ ...selectedItem, members: _members ?? [] });
            setDirty(true);
        }
    }

    const handleBackClicked = () => {
        if (props.onBackClicked) {
            props.onBackClicked();
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleCancel = async () => {
        if (props.onBackClicked) {
            props.onBackClicked()
        }
    }

    const handleSave = async () => {
        if (selectedItem) {
            handleLoading();
            const response = await saveDistributionList(selectedItem);
            handleLoaded();
            if (response && response.success && response.result) {
                if (selectedItemId === 0) {
                    setSelectedItemId(response.result.id);
                }
                setSelectedItem(response.result);
                if (props.onSaved) {
                    props.onSaved(selectedItem);
                    await fetchDistributionLists(false);
                }
                clearDomainEntities()
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
    }

    const handleNewMemberFieldUpdated = (e: any) => {
        let newMember = { ...memberToAdd, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setMemberToAdd(newMember);
        setDirty(true);
    }

    const handleUserSearchSelected = async (user?: UserType) => {
        let exists = selectedItem?.members.find(member => member.userId === user?.id);
        if (!exists && user) {
            setMemberToAdd({ deleted: false, distributionListId: selectedItemId, userDisplayName: user.fullName, userEmailAddress: user.email, sortOrder: 999, userId: user.id, id: 0 });
            setDirty(true);
        }
    }

    const handleAddMember = () => {
        if (selectedItem && selectedItem.members && memberToAdd) {
            const _valid = memberValidator.validate(memberToAdd);
            if (_valid) {
                let _members = selectedItem.members;
                memberToAdd.isEmsUser = (memberToAdd?.userId??0)>0
                _members.push(memberToAdd);
                setSelectedItem({ ...selectedItem, members: _members });
                setMemberToAdd(createEmptyDistributionListMember(selectedItemId));
            }
        }
    }

    const CapacityGridEditElement = <>
        {selectedItem &&
            <Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
                <Grid item xs={2}>
                    <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} hidden={selectedItem.id<=0}/>
                </Grid>
                <Grid item xs={2}>
                    <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} disabled={selectedItemId <= 0} />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={12}>
                    <TextBox id="name" label="Name" value={selectedItem.name} validationField={modelValidator.name} onChange={handleFieldUpdated} />
                </Grid>

                <Grid item xs={12}>
                    <TextBox id="description" label="Description" rows={6} value={selectedItem.description} onChange={handleFieldUpdated} maxLength={1024} />
                </Grid>
            </Grid>
        }
    </>

    
 
    const CapacityGridMembersElement = <>
        {selectedItem && selectedItem.id > 0 && <>
            <Grid container columnSpacing={2} style={{ padding: '15px 5px' }}>
                <Grid item xs={12}>
                    <h3 className="page--title"><span className="pi pi-plus-circle"></span> Add new member to list</h3>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <p><strong>Search for EMS user</strong></p>
                    <UserSearch onUserSelection={handleUserSearchSelected} onError={handleError} />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} style={{ padding: '15px 5px' }}>
                <Grid item xs={6} sm={4}>
                    <TextBox id="userDisplayName" label="Name" validationField={memberValidator.name} value={(memberToAdd && memberToAdd.userDisplayName !== '') ? memberToAdd.userDisplayName : ''} onChange={handleNewMemberFieldUpdated} />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextBox id="userEmailAddress" label="Email" type="email" validationField={memberValidator.emailAddress} value={(memberToAdd && memberToAdd.userEmailAddress !== '') ? memberToAdd?.userEmailAddress : ''} onChange={handleNewMemberFieldUpdated} />
                </Grid>
                {memberToAdd &&
                    <Grid item xs={6} sm={4}>
                        <ActionButton label="Add" onClick={handleAddMember} />
                    </Grid>
                }

                <Grid item xs={12}>
                    <hr style={{ margin: '2rem 0' }} />
                    <h3 className="page--title"><span className="pi pi-user"></span> Current Members</h3>
                    <DataTable  loading={false} dataKey="id" columns={[
                        { header: "Recipient Name", field: "userDisplayName" },
                        { header: "Email Address", field: "userEmailAddress", },
                        { header: "EMS User?", formatType:"yesno" , field: "isEmsUser" }]} paginationRowsPerPage={25}
                        editButton={false} paginator={(selectedItem.members?.length || 0) > 25} filter={true} allowExport={false} deleteButton={true} onDelete={(member:any) => { handleRemoveUser(member) } }
                        value={selectedItem.members}
                        />
                </Grid>

            </Grid>
        </>
        }
        {selectedItem?.id===0 && <Grid container columnSpacing={2} style={{ padding: '15px 5px' }}>
        <Grid item xs={9}>
            <p>The distribution list must be saved before members can be added</p>
            </Grid>
            </Grid>}
    </>

    return <>
        <Grid container>
            <BasicTab key='tabs' tabs={[{
                label: 'Details', component: <>{CapacityGridEditElement}</>
            }, {
                label: 'Members', component: <>{CapacityGridMembersElement}</>
            }]}>

            </BasicTab>
            {dirty && isValid && <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />}

            {(!dirty || !isValid) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                <div style={{ float: 'left', marginRight: '5px' }}>
                    <ActionButton onClick={handleBackClicked} label="Back" severity="secondary"></ActionButton>
                </div>
            </div>}
        </Grid>

    </>
}