import { Icon } from "@mui/material";
import React from "react";
import GroupBox from "./groupBox";


interface ErrorProps {
    message: string
}

  const Error:  React.FC<ErrorProps> = (props) => {
    return <GroupBox title="">
        <h1> <Icon>error_outline</Icon>Ooops!</h1>
        <h2>Something went wrong</h2>
        <h5>Sorry, we encountered an error while processing your request.</h5>
        <p>{props.message}</p>
    </GroupBox>
}

export default Error