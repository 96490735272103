import React, {useEffect} from "react"
import { AutomatedReportType } from "../../../../interfaces/system/systemTypes"
import DataTable from "../../../core/dataTable"


interface AutomatedReportListProps {
    list?:AutomatedReportType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

export default function AutomatedReportList(props: AutomatedReportListProps) {
useEffect(() => {
  setList(props.list)
},[props.list])

const [list,setList] = React.useState(props.list)
   

  const handleRowSelection =(id:number) => {
    
    if(props.onSelection)
        props.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false} columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Subject", field:"subject"},
  {header:"report Type", field:"reportType.name"},
  {header:"Active", field:"deleted", formatType:"yesno-inverse"}]}  paginationRowsPerPage={10} value={list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true} filter={true} onNew={props.onNew} allowExport={true}
  />

}
