import React, { useState, useEffect} from "react";
import {Grid} from '@mui/material';
import UserRegistrationSteps from './registrationSteps';
import GroupBox from '../core/groupBox';
import ActionButton from '../core/actionButton';
import Loader from '../core/loading';
import Error from '../core/error';
import {verifyUserByToken, saveRegisterAboutYou} from '../../services/userService';
import {INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE} from '../../containers/authenticationContainer'
import TextBox from '../core/textBox';
import Feedback, { FeedbackType } from '../../components/core/feedback'
import useForm from "../../hooks/useForm"
import Dropdown from '../core/dropdown'
import { getUser, setUser } from '../../services/storageService'
import {convertNewUserRegistrationAboutYouToUser, createNewUserRegistrationAboutYouFromUser} from '../../interfaces/user/iUser'
import UserRegistrationValidator from "../../validators/userRegistrationValidator";

export interface PropsType {
    token:string
    onError?: (errorMessage:string) => void
    onSuccess?: (user:any) => void
  }

const UserRegistrationAboutYou: React.FC<PropsType> = (props) => {

     const  [loading, setLoading] = useState(false);
     const  [validToken, setValidToken] = useState(false);
     const [user] = useState(createNewUserRegistrationAboutYouFromUser(getUser(), props.token))
     const  [formValidation, setFormValidation] = useState(new UserRegistrationValidator());
     const {
        feedbackStatus, setFeedbackStatus,objectValues, errorMessage,setValues,validateForm, setErrorMessage
      }
      = useForm({initialObjectValues: user, validator: formValidation})

      
      useEffect(() => {
        userVerification()
      },[props.token])
     
       

      const userVerification  = async () => {
        if(!props.token || props.token===''){
            verificationFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)
            return
        }

        setLoading(true)
        const validToken = await verifyUserByToken(props.token)

        if(validToken.success)
            verificationSuccess()
        else
            verificationFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)
      }
      const verificationSuccess = () =>{
        setValidToken(true)
        setLoading(false)
     }
     const verificationFailure = (error:string) =>{
        setLoading(false);
    }

    const handleSave = async () =>{
        setErrorMessage('')
        
        let isValid = validateForm(objectValues)
        setFormValidation(formValidation)
        if(!isValid){
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }
        else
            setFeedbackStatus(FeedbackType.Loading)

        const result =await saveRegisterAboutYou(objectValues)
        if(result.success)
            registrationSuccess()
        else{
            setErrorMessage(result.error ||'')
            setFeedbackStatus(FeedbackType.Error)
        }
      }  
      const registrationSuccess = () => {
        setFeedbackStatus(FeedbackType.hide)

        const newUser = convertNewUserRegistrationAboutYouToUser(objectValues)
        setUser(newUser)
        if(props.onSuccess)
            props.onSuccess(objectValues)
      }
      
    const cardActions= <Grid container spacing={3} justifyContent = "center">
    <Grid container spacing={1}  justifyContent="center"><ActionButton onClick={handleSave} severity="success" label="Next"></ActionButton></Grid>
    <Grid container spacing={0}  justifyContent="center">&nbsp;</Grid>
    <Grid container spacing={0}  justifyContent="center">
                   </Grid>
</Grid>

    if(loading)
        return <Loader loading={loading}></Loader>

     if(!validToken)
     return <Grid container spacing={3} justifyContent="center">
     <Grid item lg={9} xs={12}>
         <Error message={INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE}></Error>
                    </Grid>
        </Grid>
    
    
    return <>
    <Grid container justifyContent="center">
                    
    <Grid item xs={10} lg={6}>
    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>   
    <UserRegistrationSteps step={2}/>
        <GroupBox title="About You" iconName={"thumb_up_alt"} actions={cardActions}>
            <Grid container spacing={3} justifyContent = "center">
                <Grid item xs={12} lg={12}>
                    <p>Please complete the fields below</p>
                </Grid>
                {/* <GroupBox title="Student Details" iconName={"person"}> */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                                    <Grid item xs={12}><TextBox id="fullName" focus={true} label="Fullname" validationField={formValidation.fullName} value={objectValues.fullName} onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><TextBox id="email" label="Email"  disabled={true} value={objectValues.email} onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><TextBox id="jobRole" label="Job Role" validationField={formValidation.jobRole} value={objectValues.jobRole} onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><TextBox id="serviceName" label="Your Service" validationField={formValidation.serviceName} value={objectValues.serviceName} onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><Dropdown id="serviceCategoryId" label="Service Type" optionName="serviceCategories" validationField={formValidation.area} includeBlankOption={true}  value={objectValues.serviceCategoryId}   onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><Dropdown id="areaId" label="Region" optionName="regions" validationField={formValidation.area} includeBlankOption={true}  value={objectValues.areaId}   onChange={(e) => {setValues(e)}} /></Grid>
                        <Grid item xs={12} ><TextBox id="applicationUse" rows={3} label="Intended Use of EMS+" validationField={formValidation.applicationUse} value={objectValues.applicationUse} onChange={(e) => {setValues(e)}} /></Grid>
                        
                    </Grid> 
                </Grid> 
            </Grid> 
        {/* </GroupBox> */}

                
            </Grid>
            
        </GroupBox>
        
    </Grid>

        </Grid>

        </>          
}


export default UserRegistrationAboutYou;
