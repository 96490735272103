import { useState } from "react";
import { CapacityGridFieldOptionNumericValue } from "../../interfaces/system/capacityGridType"


interface CapacityGridResultRangeDisplayOptions {
    rowConfig: CapacityGridFieldOptionNumericValue[],
    cellValue: string,
    displayAsTableCell?: boolean,
    className?: string,
    centerIfNumeric?:boolean
}
export default function CapacityGridResultRangeDisplay(props: CapacityGridResultRangeDisplayOptions) {

    const [cellValue] = useState(props.cellValue);
    const [rowConfig] = useState(props.rowConfig);

    const getRange = () => {
        const numericVal = parseInt(cellValue);
        let range: CapacityGridFieldOptionNumericValue | undefined = undefined;
        if (rowConfig && (rowConfig?.length ?? 0) > 0 && numericVal > 0) {
            for (var i = 0; i < rowConfig.length; i++) {
                const config = rowConfig[i];
                if (numericVal >= config.from && numericVal <= config.to) {
                    range = config
                    break;
                }
            }
        }
        return range;
    }
    const generateBackgroundColor = () => {
        const range = getRange();
        if (range) {
            return range.colour;
        }
        return '';
    }

    if (props.displayAsTableCell) {
        return <td className={`capacity-grid-result-range-display ${props.className ?? ''}`} style={{ backgroundColor: generateBackgroundColor(), textAlign: (props.centerIfNumeric && parseInt(cellValue) >= 0 ? 'center':undefined) }}>
            {cellValue}
        </td>
    }

    return <div className="capacity-grid-result-range-display" style={{ backgroundColor: generateBackgroundColor() }}>
        {cellValue}
    </div>
}