import { Grid } from "@mui/material";
import { useState, useEffect} from "react";
import { formatDateTime } from "../../../extensions/formatter";
import { OpelFormSubmissionIdsSubmissionType, OpelFormSubmissionType } from "../../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { ServiceTypeLite } from "../../../interfaces/system/serviceType";
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import Dropdown from "../../core/dropdown";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import { getOpelArgbColourCodeFromReportingName } from "../chartColorPallete";

export interface OpelSubmissionCriteriaProps {
  serviceIds:number[]
  submissionId?:number
  selectedServiceId?:number
  fromDate:Date
  toDate:Date
}
interface OpelSubmissionReportProps {
  areaHierarchy:AreaHierarchyType
  onApplyFilter: (criteria:OpelSubmissionCriteriaProps)=> void
  onFetchData: (criteria:OpelSubmissionCriteriaProps)=> void
  onServiceSelected: (criteria?:OpelSubmissionCriteriaProps)=> void
  onSubmissionSelected: (criteria?:OpelSubmissionCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:OpelFormSubmissionType
  submissions?:OpelFormSubmissionIdsSubmissionType[]
  defaultFilterCriteria?:BasicReportCriteria
  serviceList?:ServiceTypeLite[]
  }

  
export default function OpelSubmissionReport(props:OpelSubmissionReportProps) {
  const  [selectedTabIndex, setSelectedTabIndex] = useState(0); 
  const  [data, setData] = useState(props.data); 
  const  [submissions, setSubmissions] = useState(props.submissions); 
  const [serviceList, setServiceList] = useState<ServiceTypeLite[]>()
  const [criteria, setCriteria] = useState<BasicReportCriteria>()
  const [isICS, setIsICS]= useState(false)
  let renderedIcs = false

  useEffect(() => {
    renderedIcs= false
    setData(props.data)

    setIsICS(false)
    if(props.data && props.data?.services && props.data?.services?.length>0 && 
      props.data.services[0].services[0]?.services){
      setIsICS(props.data.services[0].services[0].services.length>0)
    }

},[props.data])

useEffect(() => {
  if(props.submissions){
    setSubmissions(props.submissions)
  }
},[props.submissions])


useEffect(() => {
    setServiceList(props.serviceList)
  
},[props.serviceList])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    setCriteria(criteria)

    props.onApplyFilter({serviceIds: criteria.selectedServiceIds.map(m=>m.id), 
       fromDate:criteria.fromDate ?? new Date(), toDate:criteria.toDate??new Date()})
}


const handleExport = () =>{
 
  const title= 'Opel Report'
   
  const exportData:ExcelExportDataRow[]=[]
  const sheets:ExcelExportSheet[] = []
  
  let icsRowSpan = 0
  data?.services?.forEach(ics => {
    (ics?.services?.length> 0 ? ics?.services:data?.services)?.forEach(trust => {
      (trust?.services?.length>0 ? trust?.services : ics?.services)?.filter(f=>f?.fields?.length>0).forEach(service => {
          service?.fields?.filter(f=> isICS || (!isICS && tIndx===0))?.forEach(field => {
            icsRowSpan++
          })
      })
    })
    
  })

  let tIndx =0
  data?.services?.forEach(ics => {
    (ics?.services?.length> 0 ? ics?.services:data?.services)?.forEach(trust => {
      (trust?.services?.length>0 ? trust?.services : ics?.services)?.filter(f=>f?.fields?.length>0).forEach(service => {
          service?.fields?.filter(f=> isICS || (!isICS && tIndx===0))?.forEach(field => {
            

            const row:ExcelExportDataRow = {cols:isICS? [{value:ics.service.name,rowSpan:icsRowSpan},
                {value:`${ics.reportingLabel} \n\n ${formatDateTime(ics.createdOn)}`,rowSpan:icsRowSpan, backgroundColour:getOpelArgbColourCodeFromReportingName(ics.reportingLabel)
                  ,alignment:{ vertical: 'middle', horizontal: 'center', wrapText:true}},
                {value:ics.score?.toString(),rowSpan:icsRowSpan,alignment:{ vertical: 'middle', horizontal: 'center'}}]:[]}

            
              if(isICS){
                const trustRowSpan = service?.fields?.length * ( trust?.services?.filter(f=>f.fields.length>0)?.length)
                row.cols.push({value:trust.service.name,rowSpan:trustRowSpan})
                row.cols.push({value:`${trust.reportingLabel} \n\n ${formatDateTime(trust.createdOn)}`,rowSpan:trustRowSpan, backgroundColour:getOpelArgbColourCodeFromReportingName(trust.reportingLabel),
                  alignment:{ vertical: 'middle', horizontal: 'center', wrapText:true}})
                row.cols.push({value:trust.score?.toString(),rowSpan:trustRowSpan,
                  alignment:{ vertical: 'middle', horizontal: 'center'}})
              }
              else
              {
                const trustRowSpan = service?.fields?.length * ( ics?.services?.filter(f=>f.fields.length>0)?.length)
                row.cols.push({value:ics.service.name,rowSpan:trustRowSpan})
                row.cols.push({value: `${ics.reportingLabel} \n\n ${formatDateTime(ics.createdOn)}` ,rowSpan:trustRowSpan, backgroundColour:getOpelArgbColourCodeFromReportingName(ics.reportingLabel),
                  alignment:{ vertical: 'middle', horizontal: 'center', wrapText:true}})
                row.cols.push({value:ics.score?.toString(),rowSpan:trustRowSpan,
                  alignment:{ vertical: 'middle', horizontal: 'center'}})
              }
              const serviceRowSpan =  service?.fields?.length
              row.cols.push({value:service.service.name,rowSpan:serviceRowSpan})
              row.cols.push({value:`${service.reportingLabel} \n\n ${formatDateTime(service.createdOn)}`,rowSpan:serviceRowSpan, backgroundColour:getOpelArgbColourCodeFromReportingName(service.reportingLabel),
                              alignment:{ vertical: 'middle', horizontal: 'center', wrapText:true}})
              row.cols.push({value:service.score?.toString(),rowSpan:serviceRowSpan,
                alignment:{ vertical: 'middle', horizontal: 'center'}})
            
            row.cols.push({value:field.name})
            row.cols.push({value:field.score?.toString(),alignment:{ vertical: 'middle', horizontal: 'center'}, backgroundColour:getOpelArgbColourCodeFromReportingName(field.reportingLabel)})
            row.cols.push({value:field.denominator?.toString(),alignment:{ vertical: 'middle', horizontal: 'center'}})
            row.cols.push({value:field.numerator?.toString(),alignment:{ vertical: 'middle', horizontal: 'center'}})

            let calcResult = field.calculationResult === 0 ? '' : field.calculationResult?.toString()//"";

            // if(field.denominator && field.numerator && field.numerator!==0)
            //     calcResult = field.calculationResult.toString() //(field.numerator/field.denominator*100).toFixed(2).toString()

            row.cols.push({value:calcResult,alignment:{ vertical: 'middle', horizontal: 'center'}})
            exportData.push(row)
        })
      })
      tIndx++;
    })
    
  })
   
   // data?.submissions?.forEach(f=>{
       sheets.push({title:title,elements:[{}],name:'Opel Report'})
   
       let headers: ExcelExportHeader[] =[
        {title:"ICS", width:30},
      {title:'Level',width:15},
      {title:'Score',width:10},
      {title:'Trust',width:30},
      {title:'Level',width:15},
      {title:'Score',width:10},
      {title:'Service',width:30},
      {title:'Level',width:15},
      {title:'Score',width:10},
      {title:'Parameter',width:40},
      {title:'Score',width:10},
      {title:'Denominator',width:10},
      {title:'Numerator',width:10},
      {title:'Calculation',width:10}
    ]

    if(!isICS)
    {
      headers.splice(0,3)
    }

   exportToExcel({
   defaultHeaderWidth:20,
   headers:headers,
   data:exportData,
   sheets:sheets
   })
}
    const handleServiceSelected = (index:number) =>{
      if(!serviceList) return
      
      props.onServiceSelected({...criteria, selectedServiceId: serviceList[index].id, fromDate:criteria?.fromDate??new Date(), toDate:criteria?.toDate??new Date(),
      submissionId:undefined, serviceIds:serviceList.map(m=>m.id),})
      setSelectedTabIndex(index)
    }
    const handleNewSubmission = (e:any)=>{
      if(!serviceList) return
      if(criteria)
        props.onSubmissionSelected({...criteria, serviceIds:[data?.services[0]?.service?.id??0], fromDate:criteria?.fromDate??new Date(), toDate:criteria?.toDate??new Date(),
          submissionId:e.target.value as number})
    }


    const tabs: PagedTab[] = [];
    let report:JSX.Element = <></>
    if(data){
      serviceList?.forEach(s => {
            tabs.push({
                title: s.name,
                children:<></>
            })
        });

        const icsRowSpan = 999
         report =<Grid container spacing={1}>
          <Grid item xs={5} lg={3} style={{marginBottom:10}}>
              <Dropdown label="Submission" options={submissions?.map(m =>  ({id:m.id,name:formatDateTime(m.createdOn),code:''} ))} 
              onSelection={handleNewSubmission}/>
          </Grid>
          <Grid xs={7} lg={9}></Grid>
         
          <table className="reporting-table light-borders">
            <thead>
              <tr>
                {isICS && <th>ICS</th>}
                {isICS && <th>Level</th>}
                {isICS && <th>Score</th>}
                <th>Trust</th>
                <th>Level</th>
                <th>Score</th>
                <th>Service</th>
                <th>Level</th>
                <th>Score</th>
                <th>Parameter</th>
                <th >Score</th>
                <th>Denominator</th>
                <th>Numerator</th>
                <th>Calculation</th>
              </tr>
            </thead>
            <tbody>
              {data?.services?.map((ics) => (
                <>
                {(ics?.services?.length > 0 ?  ics?.services : data?.services)?.map((trust,tIndx) => (
                  <>
                  {(trust?.services?.length > 0 ?  trust?.services : ics?.services)?.filter(f=>f?.fields?.length>0).map((s,sIndx) => (
                      <>
                      {s?.fields?.filter(f=> isICS || (!isICS && tIndx===0)).map((f,fIndx) => {
                        
                        
                        const a = <tr>
                            {isICS && (fIndx === 0 && tIndx === 0 && !renderedIcs) && <td rowSpan={icsRowSpan}>{ics.service.name}<br></br>{formatDateTime(ics.createdOn)}</td>}
                            {isICS && (fIndx === 0 && tIndx === 0 && !renderedIcs) && <td className={`form-opel-result-${ics.reportingLabel}`} rowSpan={icsRowSpan}>{ics.reportingLabel}</td>}
                            {isICS && (fIndx === 0 && tIndx === 0 && !renderedIcs) && <td style={{textAlign:'center'}} rowSpan={icsRowSpan}>{ics.score}</td>}
                            {(fIndx === 0 &&  sIndx===0)  && <td rowSpan={trust?.services.reduce((partialSum, a) => partialSum + a.fields.length, 0)}>{(isICS? trust.service.name:ics.service.name)}<br></br>{formatDateTime((isICS? trust.createdOn:ics.createdOn))}</td>}
                            {(fIndx === 0  &&  sIndx===0) && <td className={`form-opel-result-${(isICS? trust.reportingLabel:ics.reportingLabel)}`} rowSpan={ trust?.services.reduce((partialSum, a) => partialSum + a.fields.length, 0)}>{(isICS? trust.reportingLabel:ics.reportingLabel)}</td>}
                            {(fIndx === 0  &&  sIndx===0) && <td style={{textAlign:'center'}} rowSpan={trust?.services.reduce((partialSum, a) => partialSum + a.fields.length, 0)}>{(isICS? trust.score:ics.score)}</td>}
                            {(fIndx === 0) && <td rowSpan={s?.fields?.length}>{s.service.name}<br></br>{formatDateTime(s.createdOn)}</td>}
                            {(fIndx === 0) && <td className={`form-opel-result-${s.reportingLabel}`} rowSpan={s?.fields?.length}>{s.reportingLabel}</td>}
                            {(fIndx === 0) && <td style={{textAlign:'center'}} rowSpan={s?.fields?.length}>{s.score}</td>}
                            <td>{f.name}</td>
                            <td style={{textAlign:'center'}}  className={`form-opel-result-${f.reportingLabel}`}>{f.score}</td>
                            <td style={{textAlign:'center'}}>{f.denominator}</td>
                            <td style={{textAlign:'center'}}>{f.numerator}</td>
                            <td style={{textAlign:'center'}}>{`${f.calculationResult??''} ${f.numerator && f.denominator && f.calculationResult!==f.numerator ? '%':''}`}</td>
                        </tr>
                        renderedIcs = true
                        return a
                      }
                      )}
                      </>
                    ))}
                  </>
                  ))}
                </>
              )
              )}
            </tbody>
          </table>
        </Grid>
      }

        return  <Grid container spacing={2}>
        <Grid item xs={10}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
            defaultFilterCriteria={props.defaultFilterCriteria}
            validateServiceSelections={true} 
            onExport={handleExport}/>
        </Grid>
          <Grid item xs={12} >
            <Grid item xs={12}>
              {(data && tabs) && <PagedTabs tabs={tabs}  activeIndex={selectedTabIndex}
                          activeTab={{...{title:'', children:report }}}
                  onTabChange={handleServiceSelected}
                  ></PagedTabs>                
                      }
                       {(!data) && <span>No data to display</span>}
            </Grid>
          </Grid>
    </Grid>
  }



