import { useEffect, useState } from "react";
import { NotificationBroadcastSubscriptionType, NotificationBroadcastType } from "../../../interfaces/system/notificationBroadcastType"
import { Grid } from "@mui/material";
import BasicTab from "../../core/basicTab";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import OKCancelButtons from "../../core/OKCancelButtons";
import { NotificationBroadcastValidator } from "../../../validators/notification-broadcast-validator";
import DateTimePicker from "../../core/dateTimePicker";
import ActionButton from "../../core/actionButton";
import { SubscriptionSelectionOption, SubscriptionSelector } from "../../core/subscriptionSelector";
import Dropdown from "../../core/dropdown";

interface BroadcastEditorProps {
    item: NotificationBroadcastType,
    onSave?: (item: NotificationBroadcastType, valid: boolean) => void,
    onCancel?: () => void
}
export function BroadcastEditor(props: BroadcastEditorProps) {
    const [selectedItem, setSelectedItem] = useState<NotificationBroadcastType>(props.item);
    const [modelValidator, setModelValidator] = useState(new NotificationBroadcastValidator());
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        setSelectedItem(props.item);
        setIsValid(modelValidator.validate(props.item));
    }, [props.item]);


    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleAutoSubmissionUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, includeAutoGeneratedSubmissions: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }
    const handleSubscriptionsUpdated = (items: SubscriptionSelectionOption[]) => {
        if (!selectedItem) return;
        let updatedItem: NotificationBroadcastType = { ...selectedItem, subscriptions: [] };
        if (items) {
            let subs: NotificationBroadcastSubscriptionType[] = [];
            items.forEach(sub => {
                if (sub.formResultOptionId) {
                    let newSub: NotificationBroadcastSubscriptionType = { id: 0, serviceId: sub.serviceId, formResultOptionId: sub.formResultOptionId, deleted: false, requestedOn: new Date(), sortOrder: 0 };
                    subs.push(newSub);
                }
            });
            //updatedItem = { ...selectedItem, subscriptions: subs };
            updatedItem.subscriptions = subs;
        }
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }

    const getPreviousSelections = (): SubscriptionSelectionOption[]|undefined => {
        if (!selectedItem || (selectedItem?.subscriptions?.length ?? 0) <= 0) return undefined;
        let items: SubscriptionSelectionOption[] = [];

        selectedItem.subscriptions?.forEach(sub => {
            items.push({
                formResultOptionId: sub.formResultOptionId,
                notificationTypeId: 1,
                serviceId: sub.serviceId,
                subscriptionResultTypeId: 1
            });
        });

        return items;
    }

    const handleCancelClicked = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }
    const handleSave = async () => {
        
        if (props.onSave) {
            setIsValid(modelValidator.validate(selectedItem));
            props.onSave(selectedItem, isValid);
        }
    }

    const EditElement = <>
        <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
            <Grid item xs={2}>
                <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} hidden={selectedItem.id <= 0} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} disabled={selectedItem.id <= 0} />
            </Grid>
            <Grid item xs={4}>
                <TickBox id="includeAutoGeneratedSubmissions" label="Include Auto Generated Submissions" onChange={handleAutoSubmissionUpdated} checked={selectedItem.includeAutoGeneratedSubmissions}/>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
                <TextBox id="name" label="Name" value={selectedItem.name} validationField={modelValidator.name} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={2}>
                <DateTimePicker id="activeFrom" label="Active From" value={selectedItem.activeFrom} validationField={modelValidator.activeFrom} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={2}>
                <DateTimePicker id="activeTo" label="Active To" value={selectedItem.activeTo} validationField={modelValidator.activeTo} onChange={handleFieldUpdated} />
            </Grid>
            <Grid item xs={2}>
                <Dropdown id="distributionListId" label="Distribution List" optionName="distribution-list" includeBlankOption={true} validationField={modelValidator.distributionListId} value={selectedItem?.distributionListId ?? 0} onChange={handleFieldUpdated} />
            </Grid>

            <Grid item xs={12}>
                <TextBox id="description" label="Description" rows={6} value={selectedItem.description} onChange={handleFieldUpdated} maxLength={1024} />
            </Grid>

        </Grid>
    </>
    const serviceDetails=<>
        <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
            <Grid item xs={12}>
                {selectedItem.id > 0 &&
                        <SubscriptionSelector Mode='Email' userSelections={getPreviousSelections()} ShowCapacityGrid={false} OnSelectionsUpdated={handleSubscriptionsUpdated} />
                }
                {selectedItem.id <= 0 &&
                    <>
                        <p>Service selections will appear once the item is saved.</p>
                    </>}
            </Grid>
        </Grid>
    </>

    return <>
        <Grid container>
            <BasicTab key='tabs' tabs={[{
                label: 'Details', component: <>{EditElement}</>
            },{
                label: 'Services', component: <>{serviceDetails}</>
            }]}>

            </BasicTab>
            <OKCancelButtons onOK={handleSave} onCancel={handleCancelClicked} />

             
        </Grid>
    </>
}