import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { UserType } from "../../../interfaces/user/iUser";
import { updateUserPassword } from "../../../services/userService";
import ActionButton from "../../core/actionButton";
import Panel from "../../core/panel";
import { PasswordComplexityFeedback } from "../../core/password-complexity-feedback";
import TextBox from "../../core/textBox";

export interface EditUserPasswordProps {
    User: UserType,
    OnError?: (errorMessage: string) => void,
    OnSaved?: (user: UserType, message?: string) => void,
    OnLoading?: () => void,
    OnLoaded?: () => void,
    ForceCurrentPassword?: boolean
}

interface ChangeUserPasswordType {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
}

export function EditUserPassword(props: EditUserPasswordProps) {
    const [dirty, setDirty] = useState(false);
    const [userId, setUserId] = useState(props.User.id);
    const [isValid, setIsValid] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(props.ForceCurrentPassword ?? true);
    const [model, setModel] = useState<ChangeUserPasswordType>({ newPassword: '', currentPassword: '', confirmPassword: '' });

    useEffect(() => {
        setUserId(props.User.id);
        setDirty(false);
    }, [props.User])

    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }

    const handleFieldChanged = (e: any) => {
        if (!model) return;
        // only allow changes when the values actually change
        setDirty(true);
        setModel({ ...model, [e.target.id]: e.target.value || '' })
        if (e.target.id === 'currentPassword' && model.newPassword !== '') {
            if (showCurrentPassword && (!model.currentPassword || model.currentPassword === '')) {
                setIsValid(false);
            } else if (showCurrentPassword && model.currentPassword !== '') {
                setIsValid(true);
            }
        }
    }

    const handleOnPasswordValidate = (result: boolean) => {
        setIsValid(result);
    }

    const handleSave = async () => {
        handleLoading();
        const response = await updateUserPassword(userId, model.newPassword, model.currentPassword);
        handleLoaded();
        if (!response || !response.success || !response.result) {
            handleError(response?.error ?? 'An unknown error was encountered');
            return;
        }
        setIsValid(true);
        setDirty(false);
        // update user stored in session
        setModel({ newPassword: '', currentPassword: '', confirmPassword: '' })
        if (props.OnSaved) {
            props.OnSaved(props.User, "User password updated");
        }
    }

    return <>
        <Panel title="Reset Password">
        <Grid container spacing={2} justifyContent="left">
            {showCurrentPassword &&
                <>
                <Grid item xs={12} md={6}>
                        <TextBox id="currentPassword" label="Current Password" value={model?.currentPassword} onChange={handleFieldChanged} type="password" />
                    </Grid>
                    <Grid item xs={6}></Grid>
                </>
            }
            <Grid item xs={6}>
                <TextBox id="newPassword" label="New Password" value={model.newPassword} onChange={handleFieldChanged} type="password" />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                <TextBox id="confirmPassword" label="Confirm Password" value={model.confirmPassword} onChange={handleFieldChanged} type="password" />
            </Grid>
            <Grid item xs={6}></Grid>

            {(dirty && isValid) &&
                <>
                    <Grid item xs={6}>
                        <div style={{ float: 'right' }}>
                            <ActionButton onClick={handleSave} label="Save" severity={isValid ? 'success' : 'secondary'} disabled={!isValid}></ActionButton>
                        </div>
                    </Grid>
                    <Grid item xs={6}> </Grid>
                </>
            }

            <Grid item xs={6}>
                <PasswordComplexityFeedback password={model.newPassword} passwordConfirm={model.confirmPassword} OnValidate={handleOnPasswordValidate}></PasswordComplexityFeedback>
            </Grid>
            <Grid item xs={6}></Grid>



        </Grid>

        </Panel>
    </>
}

