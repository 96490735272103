import { useEffect, useState } from "react";
import { DistributionListType } from "../../../../interfaces/system/distributionListTypes";
import DataTable from "../../../core/dataTable";
import { fetchDistributionLists } from "../../../../services/distribution-list-service";

export interface DistributionListListProps {
    itemsPerPage?: number,
    onRowClicked?: (item: DistributionListType) => void,
    onError: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void,
    onNew?: () => void,
}
export function DistributionListList(props: DistributionListListProps) {
    const [listItems, setListItems] = useState<DistributionListType[]>();
    const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ?? 25);
    const [loading, setLoading] = useState(true);

    // hooks
    useEffect(() => {
        //console.clear();
        init();
    }, []);

    const init = async () => {
        handleLoading();
        const response = await fetchDistributionLists();
        handleLoaded();
        if (response && response.success && response.result) {
            setListItems(response.result);
        } else {
            handleError(response.error ?? 'Unknown error');
        }
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleNewItemClick = () => {
        if (props.onNew) {
            props.onNew();
        }
    }

    const handleItemSelection = (item: DistributionListType) => {
        if (props.onRowClicked) {
            props.onRowClicked(item);
        }
    }

    const handleLoading = () => {
        setLoading(true);
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        setLoading(false);
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    return <>
        <DataTable loading={loading}
            dataKey="id"
            paginationRowsPerPage={itemsPerPage}
            onSelect={(row) => handleItemSelection(row.value)}
            onEdit={(row) => handleItemSelection(row)}
            onNew={handleNewItemClick}
            editButton={true}
            paginator={(listItems?.length || 0) > itemsPerPage}
            filter={true}
            allowExport={true}
            value={listItems}
            columns={[
                { header: "Id", field: "id" },
                { header: "Name", field: "name" },
                { header: "Member Count", formatCallBack: (row: DistributionListType) => { return (row.members?.filter(f=>!f.deleted).length ?? 0) + ''; }, field: 'NONE' },
                { header: "Active", field: "deleted", formatType: "yesno-inverse" }
            ]} />
    </>
}