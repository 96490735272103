import { useEffect, useState} from "react";
import { formatDateTime } from "../../../extensions/formatter";
import { FormSubmissionByFieldServiceReportType,FormSubmissionByFieldServiceFormReportType, createNewFormSubmissionByFieldServiceFormReportType } from "../../../interfaces/reporting/form-submission";
import Panel from "../../core/panel";
import { Chart } from 'primereact/chart';
import { Grid } from "@mui/material";
import {  chartAmberLight, chartGreen,chartRed, chartBlack } from "../chartColorPallete";
import { ServiceCategoryEnum } from "../../../interfaces/system/serviceType";
require('../reporting.scss')

interface FormEscalationTriggersSubmissionProps {
  data?:FormSubmissionByFieldServiceReportType
  forms?:FormSubmissionByFieldServiceFormReportType[]
  id?:string
}

  
export default function FormEscalationTriggersSubmission(props:FormEscalationTriggersSubmissionProps) {
  const  [data, setData] = useState(props.data); 
  const [forms, setForms] = useState(props.forms);

  useEffect(() => {
    setData(props.data)
    setForms(props.forms)
},[props.data,props.forms])


const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const chartData:any[] = []
        data?.aggregate.forEach(agg => {
         chartData.push({
            labels: agg?.fields.map((m,i) => (m.name.length<15 ?  `${i+1}.${m.name}` : `${i+1}.${m.name.substring(0,20)}...`)),
            datasets: [
                {
                    label: 'Level 1',
                    backgroundColor: chartGreen,
                    borderColor: chartGreen,
                    data: agg?.fields.map(m=> m.selections[0].count)
                },
                {
                    label: 'Level 2',
                    backgroundColor: chartAmberLight,
                    borderColor: chartAmberLight,
                    data: agg?.fields.map(m=> m.selections[1].count)
                },
                {
                    label: 'Level 3',
                    backgroundColor: chartRed,
                    borderColor: chartRed,
                    data: agg?.fields.map(m=> m.selections[2].count)
                },
                {
                    label: 'Level 4',
                    backgroundColor: chartBlack,
                    borderColor: chartBlack,
                    data: agg?.fields.map(m=> m.selections[3].count)
                }
            ]
        })})

        // const custom_canvas_background_color = {
        //     id: 'custom_canvas_background_color',
        //     beforeDraw: (chart:any, args:any, options:any) => {
        //       const {
        //         ctx,
        //         chartArea: { top, right, bottom, left, width, height },
        //         scales: { x, y },
        //       } = chart;
        //       ctx.save();
        //       ctx.globalCompositeOperation = 'destination-over';
        //       ctx.fillStyle = '#E5E5E5';
        //       ctx.fillRect(left, top, width, height);
        //       ctx.restore();
        //     },
        //   };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.95,
            plugins: { 
                title: {
                    display: true,
                    text: 'No. times a level of a trigger is selected'
                },
                legend: {position: "right",
                    align: "top",
                        labels: {
                            fontColor: textColor
                        }
                } ,
                custom_canvas_background_color:'rgba(255, 255, 255, 1)'
            },
            scales: {
                x: {
                    ticks: {
                        maxRotation: 60,
                        minRotation: 60,
                        autoSkip: false ,
                        color: textColorSecondary,
                        font: {
                            weight: 400,
                            size: 10,
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    suggestedMax: 4,
                    ticks: {
                        callback: function(value:string) {
                            if (value.length > 4) {
                              return value.substring(0, 4) + '...'; //truncate
                            } else {
                              return value
                            }
                  
                          },
                        stepSize:1,

                        color: textColorSecondary,
                        
                    },
                    grid: {
                        color: surfaceBorder,
                    }
                }
            }
        };

        // setChartOptions(options);

        if(!forms)
            return <></>
        
            let formsForService = forms.filter(f=> data?.submissions.map(m=>m.formId).includes(f.id))

            if(data?.service.categoryId === ServiceCategoryEnum.Combined &&
                data?.submissions?.length<0){
                formsForService = [formsForService[0]]
                let formToCompare :FormSubmissionByFieldServiceFormReportType  = JSON.parse(JSON.stringify( formsForService[0]))
                let formKey = formToCompare.fields.map(m=>m.id).toString()
                const formKeys :any = {}
                formKeys[formKey] = formToCompare

                data?.submissions.forEach(f=>{
                    const submissionKey = f.fields.map(m=>m.id).toString()
                    
                    if(submissionKey !== formKey){

                        formToCompare = forms.find(ff=>ff.id=== f.formId)??formToCompare
                        let newForm :FormSubmissionByFieldServiceFormReportType  = JSON.parse(JSON.stringify( formToCompare))

                        const formExists = formKeys[submissionKey]
                        if(formExists) 
                            newForm = formExists
                        else{
                            newForm.id = new Date().getTime()
                            formsForService.push(newForm)
                            newForm.fields = []

                            f.fields.forEach(ff=>{
                                const fieldIndx  = formToCompare.fields.findIndex(g=>g.id === ff.id)
                                if(fieldIndx>=0)
                                    newForm.fields.push(formToCompare.fields[fieldIndx])
                            })
                        }
                        
                        formToCompare = newForm
                        

                        formKey =submissionKey// formToCompare.fields.map(m=>m.id).toString()
                        formKeys[formKey] =newForm
                        
                    }
                    f.formId = formToCompare.id
                })
            }

        return  <div id={props.id} className='service-container' style={{width:'95%'}}>
                    <Panel title="Escalation Triggers by Date" scrollHeight="50%">
                    {formsForService.map((form) => (
                        <table className="reporting-table trigger-data" style={{width:'150%',position:'relative'}}> 
                            <thead>
                                <tr>
                                    <th style={{position:'sticky',top:0}}>Submitted</th>
                                    {form.fields.map((f) => (
                                        <th style={{position:'sticky',top:0}}>{f.name}</th>
                                    ))}
                                    <th style={{borderLeft:'1px dotted black',position:'sticky',top:0}}>Overall</th>
                                    {data?.service?.categoryId ===10 && <th style={{borderLeft:'1px dotted black',position:'sticky',top:0}}>Opel Score</th>}
                                    <th style={{width:750,position:'sticky',top:0}}>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {data?.submissions.filter(f=>f.formId === form.id).map((f) => (
                                <tr>
                                    <td style={{whiteSpace:'nowrap',position:'sticky',left:0,background:'#fff'}}>{formatDateTime(f.submittedOn)}</td>
                                    {form.fields.map((ff) => (
                                        <td className={`form-result-${f.fields.find(i=>i.id=== ff.id)?.reportingLabel}`}>{f.fields.find(i=>i.id=== ff.id)?.reportingLabel}</td>
                                    ))}
                                    
                                    <td style={{borderLeft:'1px dotted black'}} className={`form-result-${f.resultLabel}`}>{f.resultLabel}</td>
                                    {data?.service?.categoryId ===10 && <td style={{borderLeft:'1px dotted black'}} className={`form-result-${f.opelResultLabel}`}>{f.opelResultLabel}</td>}
                                    <td>{f.comments}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        ))}
                    </Panel>
                    <Panel title={"Escalation Trigger Summary"} scrollHeight="350">
                    {data?.aggregate.map((agg) => (
                        <div style={{width:'50%'}}>
                        <table className="reporting-table trigger-summary" style={{width:'150%',position:'relative'}}> 
                                <thead>
                                    <tr>
                                        <th >Level</th>
                                        {agg.fields.map((f,i) => (
                                            <th>{f.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="form-result-1" style={{whiteSpace:'nowrap',position:'sticky',left:0}}>Level 1</td>
                                        {agg.fields.map((f) => (
                                            <td className="cell-data-numeric">{f.selections[0].count}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="form-result-2" style={{whiteSpace:'nowrap',position:'sticky',left:0}}>Level 2</td>
                                        {agg.fields.map((f) => (
                                            <td className="cell-data-numeric">{f.selections[1].count}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="form-result-3" style={{whiteSpace:'nowrap',position:'sticky',left:0}}>Level 3</td>
                                        {agg.fields.map((f) => (
                                            <td className="cell-data-numeric">{f.selections[2].count}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="form-result-4" style={{whiteSpace:'nowrap',position:'sticky',left:0}}>Level 4</td>
                                        {agg.fields.map((f) => (
                                            <td className="cell-data-numeric">{f.selections[3].count}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        ))}
                    </Panel>
                    <Panel title="Escalation Trigger Summary Chart">
                        <Grid container>
                            <Grid item md={6} xs={12}>
                            {chartData.map((cd) => (
                                <div style={{height:350,width:575}}>
                                    <Chart type="bar" data={cd} options={options} />
                                </div>
                            ))}
                            </Grid>
                        </Grid>
                    </Panel>
            </div>
}