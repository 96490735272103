import React, {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../../core/textBox";
import Loader from "../../../core/loading";
import OKCancelButtons from "../../../core/OKCancelButtons";
import TickBox from "../../../core/tickBox";
import { AutomatedReportScheduleType, AutomatedReportServiceType, AutomatedReportType } from "../../../../interfaces/system/systemTypes"
import BasicTab from "../../../core/basicTab";
import Dropdown from "../../../core/dropdown";
import { TagBuilder } from "../../../core/tagBuilder";
import ServiceSelector, { SelectedServiceType } from "../../../core/service-selector";
import { AreaHierarchyType} from "../../../../interfaces/system/areaTypes";
import AutomatedReportScheduleEdit from "./automated-report-schedule-edit";
import { AutomatedReportValidator } from "../../../../validators/automated-report-validator";

interface AutomatedReportEditProps {
    automatedReport?:AutomatedReportType
    areaHierarchy?:AreaHierarchyType
    validator:AutomatedReportValidator
    onSave: (automatedReport: AutomatedReportType) => void 
    onCancel: () => void 
  }

export default function AutomatedReportEdit(props: AutomatedReportEditProps) {
  const [areaHierarchy, setAreaHierarchy] = useState(props.areaHierarchy);
  const [selectedServices, setSelectedServices] = useState<SelectedServiceType[]>();
  const [messageContent,setMessageContent] = useState<string>('')
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>()
  const [validator,setValidator] = useState(props.validator)
  const {
    objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.automatedReport, validator: validator})
 

   useEffect(() => {
      setAreaHierarchy(props.areaHierarchy)
  },[props.areaHierarchy])
  useEffect(() => {
    setValidator(props.validator)
    setSelectedTabIndex(props.validator.tabIndex)
},[props.validator])

  useEffect(() => {
    setObjectValues(props.automatedReport)
    setMessageContent(props?.automatedReport?.body??messageContent)


      if(props.automatedReport && props.automatedReport.services){
      const s= props.automatedReport.services.map(m=> ({id:m.serviceId}))
      setSelectedServices(s)
    }

},[props.automatedReport])

  useEffect(() => {
    setObjectValues({...objectValues, body:messageContent})
}, [messageContent]);


const handleSave = async () =>{
  
  props.onSave(objectValues)
}
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
   
      const handleServiceSelection = (items: SelectedServiceType[]) => {
        if (!objectValues) return;
        const selections: AutomatedReportServiceType [] = [];
        for (var i = 0; i < items.length; i++) {
          selections.push({ id: items[i].id, serviceId:items[i].id});
        }
        setSelectedServices(selections)
        setObjectValues({...objectValues,services:[...selections]})
      }
      const handleScheduleUpdated = (schedule:AutomatedReportScheduleType[])=> {

        setObjectValues({...objectValues,schedules:[...schedule]})
        
      }
   
if(!objectValues)
        return <Loader loading={true} />
  
  const detailsTab = <>
          <Grid container spacing={2} style={{padding:'15px 5px'}}>
          <Grid item xs={2}><TextBox id="id" disabled={true} label="Id" value={objectValues.id}  onChange={(e) => {setValues(e)}}/></Grid>
          <Grid item xs={4}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
          
          
          <Grid item xs={7}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={5}>
            <Dropdown id="reportTypeId" label="Report Type" optionName="automated-report-type-list" includeBlankOption={true} validationField={validator?.reportType}  value={objectValues?.reportType?.id} onChange={(e) => {setValues(e)}} />
          </Grid>
          <Grid item xs={8}><TextBox id="subject" label="Subject" validationField={validator?.subject} value={objectValues.subject} onChange={(e) => {setValues(e)}} /></Grid>
          
          <Grid item xs={12}>
            <p>{"To insert column names press the '~' key to see a list of available columns."}</p>
            <TagBuilder editor={true} triggerKey={"~"} tags={[{name:'Service List', value:'{servicelist}'},{name:'Date', value:'{date}'},{name:'am/pm', value:'{am/pm}'},{name:'Morning/Afternoon', value:'{mornaft}'},{name:"Excel Link",value:"{excellink}"}]} value={messageContent} id="ddd2" label="no label" onChange={(e) => setMessageContent(e.htmlValue)}/>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
          <Grid item xs={8}>
            <Dropdown id="distributionListId" label="Distribution List" validationField={validator?.distributionList}  optionName="distribution-list" includeBlankOption={true}  value={objectValues?.distributionListId} onChange={(e) => {setValues(e)}} />
          </Grid>
        </Grid>
        
        </>

const criteriaTab = <>
          <Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
                <Grid item xs={8}>
                    <ServiceSelector areaHierarchy={areaHierarchy} onChange={handleServiceSelection} selections={selectedServices}></ServiceSelector>
                    <p>&nbsp;</p>
                </Grid>
                <Grid item xs={4}>
                  <TickBox id="submissionPerService" label="Include tab per Service Submission" checked={objectValues.submissionPerService} onChange={(e) => {setValues(e)}} />
                </Grid>
            </Grid>
        </>

const scheduleTab = <>
<Grid container spacing={2} justifyContent="center" style={{ padding: '15px 5px' }}>
      <Grid item xs={12}>
          <AutomatedReportScheduleEdit automatedReport={objectValues}  onUpdated={handleScheduleUpdated} />
          <p>&nbsp;</p>
      </Grid>
  </Grid>
</>

        
  return <>
  <Grid container justifyContent="center">
  <BasicTab key='serv_' selectedTabIndex={selectedTabIndex} tabs={[{label:'Report Details', component: <>{detailsTab}</> },
                    {label:'Criteria', component: <>{criteriaTab}</>},
                    {label:'Schedule', component: <>{scheduleTab}</>},]}
                    >
              
              
        </BasicTab>    
        <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
        <Grid item xs={8}>
          <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />
        </Grid>     </Grid>     
                
                        </Grid>
  </>

}
