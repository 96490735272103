import moment from "moment"

export const dateDiff = (date1:Date, date2: Date) : number => {
    
    date1 = new Date(date1)
    date2 = new Date(date2)

    let days = (date2.getTime() -new Date(date1).getTime()) / (1000 * 3600 * 24)
    if(date2<date1) days = Math.abs(days)

    return days
}
export const dateDiffFromNow = (date1?:Date) : number => {
    
    if(!date1) return 0

    date1 = new Date(date1)
    const date2 = new Date()

    return dateDiff(date1, date2)
}
export const dateDiffFromNowInHours = (dateToCompare?:Date) : number => {
    
    const date11 = moment(dateToCompare)

    if(!dateToCompare || !date11.isValid()) return 0
     
     const date2 = moment(new Date())
     const date1 = moment(dateToCompare)
    return date2.diff(date1,'hours')
}
export const dateDiffFromNowInMinutes = (dateToCompare?:Date) : number => {
    const date11 = moment(dateToCompare)

    if(!dateToCompare || !date11.isValid()) return 0
     
     const date2 = moment(new Date())
     const date1 = moment(dateToCompare)
    return date2.diff(date1,'minutes')
    // return hours
}
export const addDaysToDate = (date: Date, daysToAdd: number)  : Date => {
    date.setDate(date.getDate() + daysToAdd)
    return date
}
export const addMonthsToDate = (date: Date, monthsToAdd: number)  : Date => {
    let d = new Date(date)
    d = new Date(d.setMonth(d.getMonth()+monthsToAdd));
    return d
}
export const addDaysToTodaysDate = (daysToAdd: number)  : Date => {
    return addDaysToDate(new Date(), daysToAdd)
}
export const getElapsedTextFromDateTime = (date?:Date) =>{
    const mins = dateDiffFromNowInMinutes(date)
    const hours = mins/60
    const label = hours<1 ? 'm' :  hours<24 ? 'h' :  hours < 168 ? 'd' :hours < 744 ? 'w' : hours < 8736 ? 'M' : 'y'
    let elapsed = hours
    switch(label){
        case 'm':
            elapsed = mins <1?1:mins
            break
        case 'd':
            elapsed = elapsed/24
            break
        case 'w':
            elapsed = elapsed/168
            break
        case 'M':
            elapsed = elapsed/744
            break
        case 'y':
            elapsed = elapsed/8742
            break
    }
    return `${parseInt(elapsed.toString())}${label}`
}
export const updatePageTitle = (title:string) => {
    const titlePrefix = `EMS${(title ? ': ':'')}`;
    document.title = `${titlePrefix} ${title}`;
}
export const getCssVariableValue = (variableName: string): string => {
    if (!variableName.startsWith('--')) {
        variableName = '--' + variableName;
    }
    return getComputedStyle(document.documentElement).getPropertyValue(variableName);
}