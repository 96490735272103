import { Identifier } from "typescript";

export interface DocumentUploadType {
    id: number,
    uploadKey?: Identifier,
    documentTypeId: number,
    name: string,
    description: string,
    filename: string,
    fileExtension: string,
    fileSize: number,
    uploadedById: number,
    uploadedOn: Date,
    sortOrder: number,
    deleted: boolean,
    services?: DocumentUploadServiceType[],
    file?: File
}

export interface DocumentUploadServiceType {
    id: number,
    documentUploadId: number,
    serviceId: number,
    sortOrder: number,
    deleted: boolean
}
export enum DocumentUploadTypes {
    Generic = 0,
    Help = 1,
    Service = 2
}
export function createEmptyDocumentUpload(): DocumentUploadType {
    return { id: 0, deleted: false, documentTypeId: 0, name: '', fileExtension:'', filename:'', fileSize:0, sortOrder:99, description:'',uploadedById:0,uploadedOn:new Date()};
}

export function getUploadFileName(upload: DocumentUploadType): string {
    return `${upload.filename}.${upload.fileExtension}`;
}