import React, { useState, useEffect } from "react";
import { CapacityGridFieldOptionNumericValue, CapacityGridSearchResultType } from "../../../../interfaces/system/capacityGridType";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import { Grid } from "@mui/material";
import SimpleDialog from "../../../core/simpleDialog";
import { ColorPicker } from 'primereact/colorpicker';
import { PresetColorPicker } from "../../../core/preset-color-picker";

interface CapacityGridEditorRangeEditProps {
    selectedItem: CapacityGridFieldOptionNumericValue,
    onUpdate: (item: CapacityGridFieldOptionNumericValue) => void
}

export default function CapacityGridEditorRangeEdit(props: CapacityGridEditorRangeEditProps) {

    // state vars
    const [selectedItem, setSelectedItem] = useState<CapacityGridFieldOptionNumericValue | undefined>(props.selectedItem)
    const [showEditDialog, setShowEditDialog] = useState(true)

    // hook setups
    useEffect(() => {
        setShowEditDialog(true);
    }, [])

    useEffect(() => {
        setSelectedItem(props.selectedItem);
        setShowEditDialog(true);
    }, [props.selectedItem])

    const handleActiveUpdated = (e: any) => {
        if (!selectedItem) return;
        setSelectedItem({ ...selectedItem, deleted: !e.target.checked })
    }

    const handleCheckUpdated = (e: any) => {
        if (!selectedItem) return;
        setSelectedItem({ ...selectedItem, [e.target.id]: e.target.checked })
    }

    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        if ((e.target.id as string).indexOf('.') > -1) {
            const fieldSplits = (e.target.id as string).split('.');
            let o = (selectedItem as any)[fieldSplits[0]] ?? { id: 0, name: '' };
            o[fieldSplits[1]] = e.target.value === '' ? undefined : isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value)
            setSelectedItem({ ...selectedItem, [fieldSplits[0]]: o })
        } else {
            setSelectedItem({ ...selectedItem, [e.target.id]: e.target.value || '' })
        }
    }

    const handleColorSelection = (color: string) => {
        if (color && selectedItem) {
            setSelectedItem({ ...selectedItem, colour: color })
        }
    }

    const handleCancel = () => {
        setShowEditDialog(false)
    }

    const handleOk = () => {
        setShowEditDialog(false);
        if (selectedItem) {
            props.onUpdate(selectedItem);
        }
    }

    if (!selectedItem) {
        return <></>
    }

    return <>
        <SimpleDialog show={showEditDialog} title="Edit range" onOk={handleOk} onClose={handleCancel} yesno={true} yesCaption="Save" noCaption="Cancel">
            <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
                <Grid item xs={2}>
                    <TickBox id="deleted" label="Active" checked={!selectedItem.deleted} onChange={handleActiveUpdated} />
                </Grid>
                <Grid item xs={2}>
                    <TickBox id="isTotal" label="Is Total" checked={selectedItem.isTotal} onChange={handleCheckUpdated} />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={6}>
                    <TextBox id="from" label="From" value={selectedItem?.from ?? 0} type="number" onChange={handleFieldUpdated} />
                </Grid>
                <Grid item xs={6}>
                    <TextBox id="to" label="To" value={selectedItem?.to ?? 0} type="number" onChange={handleFieldUpdated} />
                </Grid>
                <Grid item xs={6}>
                    <TextBox id="colour" label="Colour" value={selectedItem.colour} onChange={handleFieldUpdated} />
                    <div style={{ backgroundColor: selectedItem.colour, padding:'.5rem',marginTop:'5px' }}>
                    <p>Example: 1,234</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '5px', textAlign: 'center' }}>
                        <PresetColorPicker value={selectedItem.colour} onChange={handleColorSelection} />
                    </div>
                </Grid>
            </Grid>
        </SimpleDialog>
    </>
}