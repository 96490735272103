
import { FormResultType, FormSearchResultType,FormSubmissionType,FormType } from "../interfaces/system/formType";
import { ServiceOverviewType } from "../interfaces/system/serviceType";
import { fetchServiceList } from "./domainEntitiesService";
import { HttpResponse, HttpGet, HttpPost } from "./http";
import { getLocalSessionItem, setSessionStorageItem } from "./storageService";

export const fetchFormList = async () : Promise<HttpResponse<FormSearchResultType[]>> => {
   
  const response = await HttpGet<FormSearchResultType[]>(
    `form/list`
  )
 
  return response
}
export const fetchForm  = async (id:number) : Promise<HttpResponse<FormType>> => {
  const response = await HttpGet<FormType>(
    `form/${id}`
  )
  
  return response
}
export const fetchFormFromCache  = async (id:number, excludeDeleted?:boolean) : Promise<HttpResponse<FormType>> => {
  const cacheKey = `form${id}`
  
    const cachedValue = getLocalSessionItem(cacheKey) as FormType
    if(cachedValue) return {success:true, result:cachedValue}
  

  const response = await HttpGet<FormType>(
    `form/${id}?excludedeleted=${excludeDeleted!==undefined ? excludeDeleted : false}`
  )
 
    if(response.success)
      setSessionStorageItem(cacheKey, response.result)
  

  return response
}
export const saveForm = async (form:FormType) : Promise<HttpResponse<FormType>> => {
   const s = {...form}
  s.validator=undefined;

  if(form.fields)
    form.fields.forEach(f=>{
      f.id = f.id<0? 0:f.id
      // f.displayType = createNewDomainEntity();f.displayType.id = f.displayTypeId;
      // f.fieldType = createNewDomainEntity();f.fieldType.id = f.fieldTypeId;
      f.options.forEach(o => {
        o.id = o.id<0? 0:o.id
      });
    })

  const response = await HttpPost<FormType>(
    `form`,s
  )
 
  return response
}
export const saveFormSubmission = async (form:FormSubmissionType) : Promise<HttpResponse<FormSubmissionType>> => {
  // const s  = Object.assign({},form);
  
  // const fields = [...s.fields]
  // fields.forEach(f=>f.field = undefined)
  // s.fields = fields

 const response = await HttpPost<FormSubmissionType>(
   `form/submit`,form
 )

 return response
}
export const fetchSubmissionOverview = async (id:number) : Promise<HttpResponse<ServiceOverviewType>> => {
  const response = await HttpGet<ServiceOverviewType>(
    `service/overview/${id}`
  )
 
  return response
}
export const getFormIdForService = async (serviceId:number) =>{
  const response =await fetchServiceList()

  
  if(!response) return undefined

  const service = response.find(f=>f.id.toString() === serviceId.toString())

  return service?.formId
}

export const fetchFormResultList = async (useCache: boolean = true): Promise<HttpResponse<FormResultType[]>> => {

    const cacheKey = `list-form-result`

    if (useCache) {
        const cachedValue = getLocalSessionItem(cacheKey) as FormResultType[]
        if (cachedValue) return { success: true, result: cachedValue }
    }

    const response = await HttpGet<FormResultType[]>(
        `form/${cacheKey}`,
    )

    if (response.success) {
        setSessionStorageItem(cacheKey, response.result)
    }

    return response
}
export const fetchFormResult = async (id: number): Promise<HttpResponse<FormResultType>> => {

    const response = await HttpGet<FormResultType>(
        `form/form-result/${id}`,
    )

    return response
}
export const saveFormResult = async (data: FormResultType): Promise<HttpResponse<FormResultType>> => {
    let postData = { ...data }
    //if ((postData.description?.length ?? 0) > 1024) {
    //    postData.description = postData.description.substring(0, 1024);
    //}

    const response = await HttpPost<FormResultType>(
        `form/save-form-result`, postData
    )

    return response
}
export const updateCombinedForm = async (serviceId:number, formResultOptionId?:number,auto?:boolean) : Promise<HttpResponse<number>> => {
 const response = await HttpPost<number>(
   `form/update-combined-form`,{serviceId:serviceId,formResultOptionId:formResultOptionId,automated:auto}
 )

 return response
}
