import React, {useEffect,useState} from "react"
import {Grid} from '@mui/material';
import Loader from "../../core/loading";
import { createNewFormField, FormFieldType,FormFieldTypeEnum } from "../../../interfaces/system/formType";
import SplitButton from "../../core/split-button";
import FormEditFieldListPreview from "./form-edit-field-list-preview";
import SortableList from "../../core/sortableList";

interface FormEditFieldListProps {
    list?:FormFieldType[]
    selectedId?:number
    onUpdate: (formFields: FormFieldType[]) => void
    onSelect: (field:FormFieldType) => void
    onAddNewField: (fieldTypeId:FormFieldTypeEnum) => void
  }

const FormEditFieldList:  React.FC<FormEditFieldListProps> = (props) => {
  const [list,setList] = useState(props.list||[])
  const [selectedId,setSelectedId] = useState(props.selectedId)
  
useEffect(() => {
  const newList = props.list||[]
  
  newList.map((f,index)=> f.fieldNumber = index+1)

  setList([...newList])
},[props.list])
useEffect(() => {
  setSelectedId(props.selectedId)
},[props.selectedId])

const handleClick = (key:FormFieldTypeEnum) =>{
  addNewField(key as number)
}
const addNewField = (fieldTypeId:FormFieldTypeEnum) => {
  props.onAddNewField(fieldTypeId)

}
const handleFieldSelection = (item:FormFieldType) => {
  if (props.onSelect) {
    props.onSelect(item)
  }
}
const handleFieldRemoved = (itemRemoved: FormFieldType, newList: FormFieldType[]) => {
  
  if (list) {
    const newList = list
    const itemToRemoveId = newList.findIndex(f=>f.id.toString() ===itemRemoved.id.toString())
    
    if(itemToRemoveId>-1){
      newList[itemToRemoveId].deleted=true
      handleReOrder(newList)
    }
  }

}
const handleReOrder = (items: FormFieldType[]) => {
  setList(items)
  props.onUpdate(items);
}

   const fieldOptions = [{key:FormFieldTypeEnum.MultipleChoice, icon:'pi pi-list', label:'Multiple Choice'},
                         {key:FormFieldTypeEnum.Checkbox, icon:'pi-check-square', label:'Checkbox'},
                         {key:FormFieldTypeEnum.Text, icon:'pi pi-stop', label:'Text'},
                         {key:FormFieldTypeEnum.TextBlock, icon:'pi pi-tablet', label:'Text Block'},
                         {key:FormFieldTypeEnum.Dropdown, icon:'pi pi-sort-down', label:'Dropdown'},
                         {key:FormFieldTypeEnum.Email, icon:'pi pi-envelope', label:'Email'},
                         {key:FormFieldTypeEnum.Numeric, icon:'pi pi-calculator', label:'Numeric'},
                         {key:FormFieldTypeEnum.Date, icon:'pi pi-calendar', label:'Date'},]
 
 
if(!list)
        return <Loader loading={true} />
  
         

  return <>
  <Grid container>
  <Grid item xs={5} >
        <SplitButton label="New Field" icon="pi pi-plus" severity="success" options={fieldOptions} onClick={handleClick}></SplitButton>
        </Grid>
    <Grid item xs={12}>
    <SortableList data={list || undefined} onItemSelection={handleFieldSelection} onRemove={handleFieldRemoved} onReOrder={handleReOrder} showRemove={true}></SortableList>
    
    {list.map((f:FormFieldType) => (

        <Grid xs={12} style={{padding:'5px',cursor:'pointer'}} className={selectedId &&  selectedId === f.id ?"highlight highlight-border" : "highlight"}>
          {/* <div onClick={()=>props.onSelect(f)}>
          <FormEditFieldListPreview field={f} onSelect={()=> {}} onDuplicate={()=>{}} onDelete={()=>{}}/>
          </div> */}
        </Grid>

          ))}
          </Grid>
      </Grid>
  </>

}

export default FormEditFieldList;




