import React from "react";
import { Steps } from 'primereact/steps';

export interface PropsType {
    step?:number
  }

const UserRegistrationSteps: React.FC<PropsType> = (props) => {

     const items = [
        {label: 'Registration'},
        {label: 'Verification'},
        {label: 'About you'},
        {label: 'Your access requirements'},
        {label: 'RCMT approval'}
    ];

    return <Steps model={items} activeIndex={props.step}/>
                
             
          
}
export default UserRegistrationSteps;
