import { useEffect, useState } from "react";
import { CapacityGridColumnType } from "../../../../interfaces/system/capacityGridType";
import { fetchCapacityGridSharedColumns } from "../../../../services/capacity-grid-service";
import ActionButton from "../../../core/actionButton";
import Dropdown, { IDropdownOptions } from "../../../core/dropdown";
import LabelText from "../../../core/labelText";
import { Grid } from "@mui/material";

export interface CapacityGridEditorAddSharedColumnProps {
  currentItems: CapacityGridColumnType[],
  onAdd: (item: CapacityGridColumnType) => void
}

export function CapacityGridEditorAddSharedColumn(props: CapacityGridEditorAddSharedColumnProps) {
  const [selectedSharedCol, setSelectedSharedCol] = useState<CapacityGridColumnType>();
  const [sharedColList, setSharedColList] = useState<CapacityGridColumnType[]>();
  const [currentItems, setCurrentItems] = useState<CapacityGridColumnType[]>(props.currentItems);

  // hook setups
  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setCurrentItems(props.currentItems);
  }, [props.currentItems])

  const init = async () => {
    let cols = await fetchCapacityGridSharedColumns();
    if (cols && cols.success && cols.result) {
      let sharedCols = cols.result?.filter(col => {
        let colAlreadyExists = currentItems.find(c => c.id === col.id);
        if (!colAlreadyExists) {
          return col;
        }
        return undefined;
      }).map(col => col)
      setSharedColList(sharedCols);
    }
  }

  const handleAddSharedColumnSelection = (e: any) => {
    let selectedId = parseInt(e.target.value ?? "0");
    if (!sharedColList || (sharedColList?.length ?? 0) <= 0 || selectedId <= 0) {
      return;
    }
    let selected = sharedColList.find(col => col.id === selectedId);
    if (selected) {
      setSelectedSharedCol(selected);
    }
  }

  const handleAddClick = () => {
    if (selectedSharedCol) {
      props.onAdd(selectedSharedCol);
    }
    if (!sharedColList || (sharedColList?.length ?? 0) <= 0) {
      return;
    }
    let selectedIndex = sharedColList.findIndex(col => col.id === selectedSharedCol?.id);
    let newList = sharedColList.splice(selectedIndex, 1);
    setCurrentItems(newList);
    setSelectedSharedCol(undefined);
  }

  if (!sharedColList || (sharedColList?.length ?? 0) <= 0) {
    return <>
      <p className="p-inline-message p-inline-message-warn">No shared columns to add</p>
    </>;
  }

  let dropdownOptions: IDropdownOptions[] = sharedColList?.map(col => {
    return { id: col.id,  value: col.name, name: col.name, code: '' };
  });
  return <>

    <Dropdown options={dropdownOptions} label="Select shared column" onSelection={handleAddSharedColumnSelection} includeBlankOption={true} ></Dropdown>
    {selectedSharedCol && <Grid container spacing={1} justifyContent="left" style={{ padding: '15px 5px' }}>
      <Grid item xs={12}><LabelText label="Name" text={selectedSharedCol.name} /></Grid>
      <Grid item xs={12}><LabelText label="Type" text={selectedSharedCol.fieldType.name} /></Grid>
      <Grid item xs={12}><LabelText label="Description" text={selectedSharedCol.description} /></Grid>
      <Grid item xs={12}><ActionButton onClick={handleAddClick} label={"Add"} severity="secondary" size="small"></ActionButton></Grid>
    </Grid>}
  </>

}