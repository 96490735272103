import { useEffect, useState } from "react";
import DebugVariable from "./debugVariable";
import './css/verticalTabs.scss';
import React from "react";

export interface VerticalTab {
    title: string,
    children?: React.ReactNode,
    icon?:string
}
export interface VerticalTabProps {
    activeIndex?: number,
    tabs: VerticalTab[],
    contentSplit?: number
    onTabIndexChanged?: (tabIndex:number) => void,
}
export function VerticalTabs(props: VerticalTabProps) {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex ?? 0);
    const [activeTab, setActiveTab] = useState<VerticalTab>(props?.tabs[0]);
    const [tabs, setTabs] = useState(props.tabs);

    useEffect(() => {
        setActiveIndex(props.activeIndex ?? 0);
    }, [props.activeIndex]);

    useEffect(() => {
        if(props.onTabIndexChanged)
            props.onTabIndexChanged(activeIndex)
    }, [activeIndex]);

    useEffect(() => {
        setTabs(props.tabs)
        changeTab(props.activeIndex ?? 0);
    }, [props.tabs]);


    const changeTab = (index: number) => {
        if (!props.tabs || props.tabs.length < 1) return

        setActiveIndex(index);
        setActiveTab(props.tabs[index])
    }

    if (!tabs) {
        return <></>
    }

    if ((tabs?.length ?? 0) > 0) {

        return <>
            <DebugVariable hide={true} variable={activeTab.title}></DebugVariable>
            <div className="vertical-tabs">
                <div className="vertical-tabs-controls" style={{ width: ((props.contentSplit ?? 0) > 0 ? (100 - (props.contentSplit ?? 80)) + '%':undefined) }}>
                    <ul>
                        {React.Children.toArray(tabs?.map((tab, index) => {
                            if (tab === null || tab === undefined) {
                                return <></>
                            }
                            return <>
                                <li className={`vertical-tab-control-${activeIndex === index ? 'active' : ''}`} onClick={() => { changeTab(index); }} title={`Click to open '${tab.title}' tab` }>
                                    {tab.icon && <><i className={`pi pi-${tab.icon}`}></i> </> }
                                    {tab.title}
                                </li>
                            </>
                        }))}
                    </ul>
                </div>

                <div className="vertical-tab-content" style={{ width: ((props.contentSplit ?? 0) > 0 ? (props.contentSplit ?? 80) + '%' : undefined) }}>
                    {React.Children.toArray(tabs?.map((tab, index) => {
                        if (tab === null || tab === undefined) {
                            return <></>
                        }
                        return <>
                            <div hidden={activeIndex !== index} className={`vertical-tab vertical-tab-${activeIndex === index ? 'active' : ''} vertical-tab-fade-${activeIndex === index ? 'in' : 'out'}`}>
                                {tab.children}
                            </div>
                        </>
                    }))}
                </div >
            </div >
        </>
    }
    return <></>
}