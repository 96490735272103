import React, { useState, useEffect} from "react";
import {Grid} from '@mui/material';
import Home from '../home/home';
import GroupBox from '../core/groupBox';
import TextBox from '../core/textBox';
import Loader from '../core/loading';
import Error from '../core/error';
import ActionButton from '../core/actionButton';
import {resetPassword, verifyUserByToken} from '../../services/userService';
import {INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE, NO_VERIFICATION_TOKEN_ERROR_MESSAGE} from '../../containers/authenticationContainer'
import {UserLoginType} from '../../interfaces/user/iUser';
import {FormValidation, FormValidationField, ValidationType} from '../../extensions/formValidation';
import {INVALID_TOKEN_ERROR_MESSAGE} from '../../containers/authenticationContainer'
import useForm from "../../hooks/useForm"
import Feedback, { FeedbackType } from "../core/feedback";
 

export interface PropsType {
    token:string
    onError?: (errorMessage:string) => void
    onSuccess?: () => void
  }

const ResetPassword: React.FC<PropsType> = (props) => {
    const loggedIn = false;
     const  [loading, setLoading] = useState(false);
     const  [formValidation, setFormValidation] = useState(new FormValidation([]));
     const  [userLogin, setUserLogin] = useState(new UserLoginType('',''));
     const  [characterClassname, setCharacterClassname] = useState('root');
     const  [numberClassname, setNumberClassname] = useState('root');
     const  [upperCaseClassname, setUpperCaseClassname] = useState('root');
     const  [passwordValid, setPasswordValid] = useState(true);
     const  [passwordsMatch, setPasswordsMatch] = useState(true);
     const  [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
     const  [validToken, setValidToken] = useState(false);
     const  [submitted, setSubmitted] = useState(false);

     const {
        feedbackStatus, setFeedbackStatus, hasError, setHasError, errorMessage, setErrorMessage
      }
       = useForm({initialObjectValues:{}})

     useEffect(() => {
        validateToken()
      },[props.token]);


      const validateToken  = async () => {
        if(!props.token || props.token===''){
            loginFailure(NO_VERIFICATION_TOKEN_ERROR_MESSAGE)
            return
        }

        setLoading(true)
        const validToken = await verifyUserByToken(props.token)

        if(validToken.success)
            verificationSuccess()
        else
            loginFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)


        

      }
      const verificationSuccess = () =>{
        setValidToken(true)
        setTimeout(() => {
            setLoading(false)    
        }, 750);
        
     }
     const initialiseValidationFields = () => {
        
        const formFields = [new FormValidationField(userLogin.username, 'Please enter a valid email address')];
        
        formFields[0].validationType = ValidationType.Email;
        formValidation.validationFields = formFields;

        setFormValidation(formValidation);
        return formValidation;
     }

     const handleResetPassword = async () => {
        try {
            setSubmitted(true)
         var isValid = updateForm(true);

         if(isValid){
            setLoading(true);
            userLogin.token = props.token
            const response = await resetPassword(userLogin)
            
            if(response.success){
                loginSuccess()
            }
            else {
                loginFailure('')
            }
                
        }
         
    }catch (error) {loginFailure('')
         }
     }

     const loginSuccess = () =>{
        setLoading(false)
        setPasswordResetSuccess(true)
        if(props.onSuccess)
            props.onSuccess()
     }

     const loginFailure = (error:string) =>{
        setLoading(false);
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(error)
    }
    const updateForm = (overrideSubmitted?:boolean):boolean => {
        if(!submitted && !overrideSubmitted) return false;
        setUserLogin({...userLogin});
        
        const passwordValid = validatePassword({target:{value:userLogin.password}});
        const passwordsMatch = userLogin.password === userLogin.passwordConfirmation

        if(!passwordValid)
            setErrorMessage("Password does not meet requirements. A password must be at least 8 characters long and contain lowercase, uppercase, numeric and special characters.")

        if(passwordValid && !passwordsMatch )
            setErrorMessage("You must confirm your password.")

        setPasswordsMatch(passwordsMatch)
        setPasswordValid(passwordValid)

        initialiseValidationFields();
        const valid = passwordValid && passwordsMatch

        if(!valid)
            setFeedbackStatus(FeedbackType.Error)
        return valid
    }

    const validatePassword = (e:any) :boolean=> {

        if(!e || !e.target || e.target.type==='button')
            return true

        const password = e.target.value
        const validLength = password.length>7
        const validNumber = /\d\d/.test(password)
        const validUpperCase = /[A-Z]/.test(password)

        setCharacterClassname(validLength ? 'pass' : 'fail' )
        setNumberClassname (validNumber? 'pass':'fail')
        setUpperCaseClassname (validUpperCase? 'pass':'fail')


        return validLength && validNumber && validUpperCase
    }
     
    if(loading)
        return <Loader loading={loading}></Loader>

     if(loggedIn){
         return <Home></Home>
     }

        if(!validToken){
            return <Grid container spacing={3} justifyContent="center">
                    <Grid item lg={9} xs={12}>
                    <Error message={INVALID_TOKEN_ERROR_MESSAGE}></Error>
                    <br></br>
                    <ActionButton variant="link" href="/forgot-password" label="Reset your Password"></ActionButton>
                    </Grid>
            </Grid>
        }
        const cardActions= <div style={{padding:3,width:'100%'}}>
                <div style={{padding:3,width:'100%',textAlign:'center',marginBottom:15}}>
                    <ActionButton size="small" severity="success" onClick={handleResetPassword} label="Next"></ActionButton>&nbsp;
                </div>
                    <div style={{padding:3,width:'100%',textAlign:'center',marginBottom:5}}>
                    <ActionButton label="Back to Login" icon="pi pi-arrow-left" severity="secondary" href="/login"></ActionButton>
                </div>
         </div>
        
        if(!passwordResetSuccess)
         return  <React.Fragment>
             <br></br><br></br>
                 <Loader loading={loading}></Loader>
                 
                 <Grid container justifyContent = "center">
                    <Grid item xs={10} lg={6}>
                        <Feedback type={feedbackStatus} message={errorMessage}></Feedback>   
                    </Grid>
                    </Grid>
                    <Grid container justifyContent = "center">
                 <Grid item xs={10} lg={6}>
                 <GroupBox title="Reset your password" actions={cardActions} iconName={"lock"}>
                     <Grid container spacing={3} justifyContent="center">
                         <Grid item xs={12} lg={12}>
                             <div style={{textAlign:'center'}}>Please enter your new password below:</div>
                         </Grid>
                         <Grid item xs={3} lg={2}>
                             <div className={characterClassname} style={{textAlign:'center'}}>8+</div>
                         </Grid>
                         <Grid item xs={3} lg={2}>
                             <div className={upperCaseClassname} style={{textAlign:'center'}}>A</div>
                         </Grid>
                         <Grid item xs={4} lg={2}>
                             <div className={numberClassname} style={{textAlign:'center'}}>0-9</div>
                         </Grid>
                         </Grid>
                         
                         <Grid container spacing={3} justifyContent="center">

                         <Grid item xs={4} lg={2}>
                             <div style={{textAlign:'center'}}>8 characters</div>
                         </Grid>
                         <Grid item xs={4} lg={2}>
                             <div className={''}>1 uppercase</div>
                         </Grid>
                         <Grid item xs={4} lg={2}>
                             <div style={{textAlign:'center'}}>2+ number</div>
                         </Grid>
                         <Grid item xs={12} lg={7}><TextBox focus={true} id="outlined-password" label="Password" type={'password'} value={userLogin.password}  onChange={(e) =>  {userLogin.password = e.target.value; updateForm();}}  error={!passwordValid} helperText="Password does not meet requirements" onKeyPress={validatePassword}/></Grid>
                         <Grid item xs={12} lg={7}><TextBox id="outlined-password" label="Password Confirmation" type={'password'} value={userLogin.passwordConfirmation} onChange={(e) =>  {userLogin.passwordConfirmation = e.target.value; updateForm();}}  error={!passwordsMatch} helperText="Passwords don't match" /></Grid>
                     </Grid>
                 </GroupBox>
                 
                </Grid>
                </Grid>

                
            </React.Fragment>
        return  <Grid container justifyContent = "center">
                    
            <Grid item xs={10} lg={6}>
                {/* <GroupBox title="Check your email" actions={<ActionButton>Back to Login</ActionButton>} iconName={"send"}> */}
                <GroupBox title="You have successfully reset your password" iconName={"thumb_up_alt"} >
                    <Grid container spacing={3} justifyContent = "center">
                        <Grid item xs={12} lg={12}>
                            <div style={{textAlign:'center'}}> You can access EMS+ by clicking the button below.</div>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <div style={{textAlign:'center'}}> 
                                <ActionButton label="Login to EMS+" variant="link" href="/"></ActionButton>
                            </div>
                        </Grid>
                        
                    </Grid>
                    
                </GroupBox>
                
            </Grid>
              

                </Grid>
                
             
          
}


export default ResetPassword;
