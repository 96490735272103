import { useEffect, useState } from "react";
import { FormResultType } from "../../../interfaces/system/formType";
import DataTable from "../../core/dataTable";
import { fetchFormResultList } from "../../../services/form-service";

export interface FormResultListProps {
    itemsPerPage?: number,
    onRowClicked?: (item: FormResultType) => void,
    onError: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void,
    onNew?: () => void,
}
export function FormResultList(props: FormResultListProps) {
    const [listItems, setListItems] = useState<FormResultType[]>();
    const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ?? 25);
    const [loading, setLoading] = useState(true);
    // hooks
    useEffect(() => {
        console.clear();
        init();
    }, []);

    const init = async () => {
        handleLoading();
        const response = await fetchFormResultList();
        handleLoaded();
        if (response && response.success && response.result) {
            setListItems(response.result);
        } else {
            handleError(response.error ?? 'Unknown error');
        }
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleNewItemClick = () => {
        if (props.onNew) {
            props.onNew();
        }
    }

    const handleItemSelection = (item: FormResultType) => {
        if (props.onRowClicked) {
            props.onRowClicked(item);
        }
    }

    const handleLoading = () => {
        setLoading(true);
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        setLoading(false);
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    return <>
        <DataTable loading={loading}
            dataKey="id"
            paginationRowsPerPage={itemsPerPage}
            onSelect={(row) => handleItemSelection(row.value)}
            onEdit={(row) => handleItemSelection(row)}
            onNew={handleNewItemClick}
            editButton={true}
            paginator={(listItems?.length || 0) > itemsPerPage}
            filter={true}
            allowExport={true}
            value={listItems}
            columns={[
                { header: "Id", field: "id" },
                { header: "Name", field: "name" },
                { header: "Type", field: "formResultType.name" },
                { header: "Active", field: "deleted", formatType: "yesno-inverse" }
            ]} />
    </>
}