import { Box, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';

export interface IBasicTab {
    label: string
    component: React.ReactNode
    hidden?: boolean
    
  }

  export interface IBasicTabParams {
    tabs:IBasicTab[]
    onTabSelect?: (tabIndex: number, tabLabel?:string) => void
    key: string
    selectedTabIndex?:number
    selectedTabTitle?:string
    orientation?: 'horizontal' | 'vertical';
    children?: React.ReactNode
    hiddenTabIds?:number[]
  }

  interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  }

  const useStyles ={
    root: {
      flexGrow: 1,
      minHeight:'25px',
      
    },
    rootVertical: {
      flexGrow: 1,
      minHeight:'25px',
      display: "flex",
      textTransform:'none'
    },
    tabs: {
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        justifyContent: "flex-start"
      },
      minHeight:28,
      
     
      borderBottom:'#dee2e6 solid 1px'
    },  
    
    tab: {
      minHeight:'25px',
      overflow: 'visible',
      textTransform:'none',
      fontWeight:'bold'
    } 
  }

  function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
            <Box p={3} hidden={value !== index} style={{padding:'1px'}}>
              {children}
          </Box>
      </div>
    );
  }

const BasicTab:  React.FC<IBasicTabParams> = params => {
    const classes = useStyles
    const [selectedTabId, setSelectedTabId] = React.useState(params.selectedTabIndex??0);
    const [hiddenTabIds, setHiddenTabIds] = React.useState(params.hiddenTabIds||[])
    const [orientation, setOrientation] = React.useState(params.orientation || 'horizontal');
    const [tabs, setTabs] = React.useState(params.tabs)


    React.useEffect(() => {
      
      if(params.tabs){
        setTabs(params.tabs.filter(f=>!f.hidden))
        presentTabs()
      }
    },[params.tabs])
    React.useEffect(() => {
      presentTabs()
    },[params.hiddenTabIds])

    React.useEffect(() => {
      setSelectedTabId(params.selectedTabIndex??0)
      
    },[params.selectedTabIndex])
    React.useEffect(() => {
      if(!params.tabs || !params.selectedTabTitle) return
      
      
      setSelectedTabId(params.tabs.findIndex(f=>f.label=== params.selectedTabTitle))
      
    },[params.selectedTabTitle])

    const presentTabs = () => {
      if(params.hiddenTabIds && params.tabs){
        const t = params.tabs
        params.hiddenTabIds?.forEach(f=> t[f].hidden =true)
        setTabs([...t.filter(f=> !f.hidden)])
      }
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const tabTrigger = event.target as HTMLButtonElement;
        setSelectedTabId(newValue)
        if(params.onTabSelect)
          params.onTabSelect(newValue, tabTrigger?.innerText ?? undefined)
      };


    return  (
      
        <Paper classes={orientation === 'horizontal' ? classes.root : classes.rootVertical} style={{ width: '100%', borderBottom: '#dee2e6 solid 1px', display: (orientation === 'vertical' ? 'flex':'')}} key={params.key}>
          <Tabs classes={classes.tabs}
            value={selectedTabId}
            onChange={handleChange}
            
            indicatorColor="primary"
            textColor="primary"
            orientation={orientation}
            // centered
          >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} key={index} classes={classes.tab} style={{textTransform:'none',minHeight:'30',fontWeight:'bold', 
             
            display:hiddenTabIds?.indexOf(index)>-1?'none':''}} />
            
          ))}
          </Tabs>
          {tabs.map((tab,index) => (
            <TabPanel value={selectedTabId} index={index} key={index}>
              {tab.component}
            </TabPanel>    
          ))}
        </Paper>
      );

    }

export default BasicTab