import { CapacityGridColumnValidator } from "../../validators/capacity-grid-column-validator"
import { CapacityGridValidator } from "../../validators/capacity-grid-validator"
import { DomainEntityType, createNewDomainEntity } from "../domain/iDomainEntity"
import { UserType } from "../user/iUser"
import { ServiceType } from "./serviceType"

export interface CapacityGridSearchResultType {
    id: number
    name: string
    deleted: boolean
    sortOrder: number
    numberOfFields: number
    formType: DomainEntityType
}
export interface CapacityGridType {
    id: number,
    resourceType: DomainEntityType,
    name: string,
    description: string,
    sortOrder: number,
    deleted: boolean,
    columns: CapacityGridColumnType[],
    validator?: CapacityGridValidator
}
export interface CapacityGridKeyFieldSummaryType {
    serviceId: number,
    serviceName: string,
    capacityGridSubmissionId: number,
    capacityGridSubmittedByUserId: number,
    capacityGridSubmittedOn: Date,
    capacityGridKeyFieldLabel: string,
    capacityGridKeyFieldValue: string,
    capacityGridSubmittedByName: string,
}

export interface CapacityGridColumnType {
    id: number,
    isKeyField: boolean,
    fieldType: DomainEntityType,
    visibilityType: DomainEntityType,
    reportingFieldType: DomainEntityType,
    name: string,
    description: string,
    calculatedFieldFormula: string,
    sortOrder: number,
    deleted: boolean,
    textFieldOptions: CapacityGridFieldOptionText[],
    numericFieldOptions: CapacityGridFieldOptionNumericValue[],
    validator?: CapacityGridColumnValidator
}

export interface CapacityGridFieldOptionText {
    id: number,
    columnId: number,
    value: string,
    helpText: string,
    sortOrder: number,
    deleted: boolean,
}

export interface CapacityGridFieldOptionNumericValue {
    id: number,
    columnId: number,
    isTotal: boolean,
    from: number,
    to: number,
    colour: string,
    sortOrder: number,
    deleted: boolean,
}

export interface CapacityGridSubmissionDataType {
    id: number,
    capacityGridId: number,
    serviceId: number,
    keyFieldLabel: string,
    keyFieldValue: string,
    createdOn?: Date,
    createdBy: UserType,
    columns: CapacityGridSubmissionColumnType[]
}
export interface CapacityGridSubmissionColumnType {
    id: number,
    capacityGridSubmissionId: number,
    capacityGridColumnId: number,
    columnOptionId?: number,
    value?: string,
    isKeyField: boolean,
    fieldType: DomainEntityType,
}

export interface CapacityGridSubmissionOverviewType {
    service: ServiceType,
    submissionData: CapacityGridSubmissionDataType,
    parentService: DomainEntityType
}
export interface CapacityGridHistoricReportResultType {
    service: ServiceType,
    parentService: DomainEntityType,
    submissionData: CapacityGridSubmissionDataType[]
}

export interface CapacityGridSummaryReportResultType {
    month: number,
    year: number,
    daysInMonth: number,
    grids: CapacityGridSummaryReportResultCapacityGridType[]
    gridDefinitions: CapacityGridType[]
}
export interface CapacityGridSummaryReportResultCapacityGridType {
    capacityGrid: DomainEntityType,
    services: CapacityGridSummaryReportResultParentServiceType[]
}
export interface CapacityGridSummaryReportResultParentServiceType {
    parentService: DomainEntityType,
    divisions: CapacityGridSummaryReportDataItemType[]
}
export interface CapacityGridSummaryReportDataItemType {
    service: DomainEntityType,
    submissionData: CapacityGridSubmissionDataType[]
}

export interface CapacityGridDailyReportFilterOptions{
    areaId?: number[],
    serviceId?: number[],
    categoryId?: number[],
    capacityGridId?: number[],
    bedTypeId?: number[],
    deleted?: boolean
}
export interface CapacityGridHistoricReportFilterOptions{
    parentServiceId: number,
    dateFrom: Date,
    dateTo: Date
}
export interface CapacityGridSummaryReportFilterOptions{
    useSummaryFields:boolean,
    serviceIds: number[],
    month?:number,
    year?: number,
    bedTypeId?:number
}

export interface NecsExcludedServiceType {
    cqcId: string
    address:string
    deleted: boolean
}
export enum CapacityGridFieldTypes {
    Numeric = 1,
    Text = 2,
    Calculated = 3
}
export enum CapacityGridColumnVisibilityTypes {
    Shared = 1,
    CapacityGrid = 2
}
export enum CapacityGridReportingFieldTypes {
    Excluded = 1,
    Included = 2,
    Occupancy = 3
}

export const createEmptyCapacityGrid = (): CapacityGridType => {
    return {
        id: 0, resourceType: createNewDomainEntity(), name: '', description: '', sortOrder: 0, deleted: false, validator: new CapacityGridValidator(), columns: [],
    }
}
export const createEmptyCapacityGridColumn = (fieldTypeId: number = CapacityGridFieldTypes.Numeric, visibilityTypeId: number = CapacityGridColumnVisibilityTypes.CapacityGrid, reportingTypeId: number = 2, sortOrder: number = 999): CapacityGridColumnType => {
    let visibilityType = createNewDomainEntity();
    let reportingType = createNewDomainEntity();
    let fieldType = createNewDomainEntity();
    visibilityType.id = visibilityTypeId;
    reportingType.id = reportingTypeId;
    fieldType.id = fieldTypeId;
    let newItem = { id: -Date.now(), calculatedFieldFormula: '', deleted: false, description: '', fieldType: fieldType, isKeyField: false, name: '', numericFieldOptions: [], reportingFieldType: reportingType, textFieldOptions: [], sortOrder: sortOrder, visibilityType: visibilityType };
    return newItem;
}
export const createEmptyNecsExcludedServiceType = ():NecsExcludedServiceType=>{
    return {cqcId:'',deleted:false,address:''}
}