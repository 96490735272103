import { Grid } from "@mui/material"
import { Card } from 'primereact/card';
import { useEffect, useState } from "react";
import Feedback, { FeedbackType } from "./feedback";
import { Outlet } from 'react-router-dom'
import { BreadCrumb } from 'primereact/breadcrumb';

export interface PageBreadcrumbType {
    label:string
    url?:string
}

export interface pageProps {
    title: string
    children?: React.ReactNode
    feedbackStatus?: FeedbackType
    errorMessage?:string
    showBorder?:boolean
    breadCrumbs?:PageBreadcrumbType[]
  }
export default function Page(props:pageProps) {
    useEffect(() => {
        setFeedbackStatus(props.feedbackStatus|| FeedbackType.hide)
      }, [props.feedbackStatus])
      useEffect(() => {
        setErrorMessage(props.errorMessage)
      }, [props.errorMessage])

    const [ feedbackStatus, setFeedbackStatus ] = useState(props.feedbackStatus|| FeedbackType.hide)
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    // const items = [{ label: 'Summary',url:'../RXWAT' }, { label: 'Emergency' }];
    // const home = { icon: 'pi pi-home', url: '../' }


    return <>
        <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
        <Grid container justifyContent={"center"}>
            <Grid item xs={11}>
            <Grid container>
            <Grid item xs={12}>
            {props.breadCrumbs  && <BreadCrumb style={{marginTop:3}} model={props.breadCrumbs} 
            home={{label:props.breadCrumbs[0]?.label, icon:'pi pi-home', url:props?.breadCrumbs[0]?.url}} />}
                {!props.showBorder && <><h3 className="page--title">{props.title}</h3>

                {props.children}</>}
                {props.showBorder && <Card title={props.title} style={{marginTop:10}}> 
                    {props.children}
                </Card> 
                }
            </Grid>
            </Grid>
        </Grid>
        </Grid>
        <Outlet />

            </>
}