import React, {useEffect} from "react";
import Loader from '../core/loading';
import { ServiceStatusType } from "../../interfaces/system/serviceType";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormSubmissionSummary from "./form-submission-summary";
import DateLabelWithElapsedIndicator from "../core/date-label-with-elapsed-indicator";

export interface MapMarkerType {
    service:ServiceStatusType
  }

interface MapProps {
    serviceStatusList?:ServiceStatusType[]
    onServiceSelected:(service:ServiceStatusType)=> void
  }

export default function ServiceListview(props: MapProps) {
  const  [loading, setLoading] = React.useState(true);
  const [serviceStatusList, setServiceStatusList] = React.useState<ServiceStatusType[]>()

   useEffect(() => {
    setServiceStatusList(props.serviceStatusList?.slice(0,250))
    present()
  
   },[props.serviceStatusList])

    const present = ()=>{
        setLoading(false)
    }
    const headerTemplate = (data:ServiceStatusType) => {
        return (
            <React.Fragment>
                <a className="pi pi-fw pi-globe"></a>
                <span className="vertical-align-middle ml-2"><strong>{data?.area?.name}</strong></span>
            </React.Fragment>
        );
    }

    const serviceBodyTemplate = (rowData:ServiceStatusType) => {
        return (
            <React.Fragment>
                <img alt={rowData.category.name} src={`${process.env.REACT_APP_ASSETS_URL}/assets/icons/markers/orgtypes/org_10.png`}  width="30" />
                <div style={{cursor:'pointer'}} onClick={()=>props.onServiceSelected(rowData)}>{rowData.name}</div>
            </React.Fragment>
        );
    }
    const statusBodyTemplate = (rowData:ServiceStatusType) => {
        if(!rowData.formId && (rowData.capacityGridId??0)>0 )
            if(rowData.capacityGridOverview?.capacityGridKeyFieldLabel && rowData.capacityGridOverview?.capacityGridKeyFieldValue)
                return <>{rowData.capacityGridOverview?.capacityGridKeyFieldLabel}: {rowData.capacityGridOverview?.capacityGridKeyFieldValue}</>
            else
                return <></>
        return (<>
            <div>

                <div style={{ marginRight: 5 }}>
                    <FormSubmissionSummary showLastUpdated={false} serviceStatus={rowData} score={rowData.score} gaugeWidth="85px" displayType={'stacked'} className='list' />
                </div>
                
            </div>
            <div>
                <div style={{}}>
                    <FormSubmissionSummary showLastUpdated={false} serviceStatus={rowData} opelScore={rowData.opelScore} gaugeWidth="85px" displayType={'stacked'} className='list' />
                </div>
            </div>
        </>
        );
    }

    const lastupdatedBodyTemplate = (rowData:ServiceStatusType) => {
        
        return <div style={{display:'flex'}}>
                <DateLabelWithElapsedIndicator date={rowData.lastUpdatedOn ? rowData.lastUpdatedOn : rowData.capacityGridOverview?.capacityGridSubmittedOn} redMax ={8} greenMax={5} />
            </div>;
    }


    if(loading)
        return <div>
            <Loader loading={loading} useBackdrop={false}></Loader>
        </div>

    return <>
            <DataTable value={serviceStatusList} rowGroupMode="subheader" groupRowsBy="area.name"
            sortMode="single" sortField="area.name" sortOrder={1} scrollable scrollHeight="40vw"
            rowGroupHeaderTemplate={headerTemplate}  responsiveLayout="scroll">
            <Column field="name" header="Service" body={serviceBodyTemplate} style={{ minWidth: '35%',marginLeft:10 }}></Column>
            <Column field="formResultShortName" header="Status" body={statusBodyTemplate} style={{ minWidth: '45%' }}></Column>
            <Column field="lastUpdatedOn" header="Last Updated" body={lastupdatedBodyTemplate}  style={{ minWidth: '20%' }}></Column>
        </DataTable>
    </>
}

