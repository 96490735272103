import React, {useState, useEffect} from "react";
import ConfigEdit from "../../components/admin/system/config/config-edit";
import { FeedbackType } from '../../components/core/feedback'
import Page from "../../components/core/page";
import Panel from "../../components/core/panel";
import { AppToastContext } from "../../extensions/context/appHook";
import useForm from "../../hooks/useForm";
import { createNewAutomatedReportSchedule, SystemConfigType } from "../../interfaces/system/systemTypes";
import { fetchConfig, saveConfig } from "../../services/systemService";
// import AdminSettingsEdit from '../../components/system/adminSettingsEdit'

export interface ISettingsAdminContainerProps {
    mode?:'config'
    showMyProfile?:boolean
}

export function SettingsAdminContainer(props:ISettingsAdminContainerProps) {
    const toastHandler = React.useContext(AppToastContext)
    const [mode, setMode] = useState(props.mode)
    const [title, setTitle] = useState("System Configuration")
    const [config, setConfig] = useState<SystemConfigType>()
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage,
      }
       = useForm({initialObjectValues: createNewAutomatedReportSchedule()})
       useEffect(() => {
        setMode(props.mode)
      }
      ,[props.mode])
      useEffect(() => {
        present()
      }
      ,[mode])
       const present = async () =>{
        
         presentMapper['']()
      }
      const presentConfig = async () =>{
        const response = await fetchConfig();
        setFeedbackStatus(FeedbackType.hide)
        if(!response || response.success){
          setConfig(response.result)
          return
        }
        setErrorMessage(response.error||'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
    }

       const presentMapper: { [K: string]: Function } = {
        '': presentConfig,
         
        
     }

    
    const handleSaveConfig = async (config:SystemConfigType) =>{
        setConfig(config)
        setErrorMessage('')
        
        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveConfig(config)
         if(saveResponse.success){
           toastHandler.successMessage("Successfuly Saved System Config")  
           setFeedbackStatus(FeedbackType.hide)
           if(saveResponse.result){
             
            setConfig(saveResponse.result)
            
           }
           return
         }
        
        setErrorMessage(saveResponse.error||'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
  
      }
 

    return <>
                <Page title="" feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
                    <Panel title={title}>
                        <ConfigEdit  onSave={handleSaveConfig} config={config}/>
                    </Panel>
                </Page>
            </>
}

 