import { ValidatorType } from "./ValidatorType"
import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { DocumentUploadType } from "../interfaces/system/documentUploadTypes";

export class DocumentUploadValidator implements ValidatorType {

    validator = new FormValidation([])
    name = new FormValidationField('', '')
    description = new FormValidationField('', '')
    documentTypeId = new FormValidationField('', '', ValidationType.Numeric)

    initialiseValidationFields = (objectValues: DocumentUploadType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
        this.description = this.validator.createTextField(objectValues.description)
        this.documentTypeId = this.validator.createPostiveNumericField(objectValues.documentTypeId)
    }

    validate = (validatorObjectValues: DocumentUploadType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}