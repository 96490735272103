import { Grid } from "@mui/material"
import { useState } from "react";
import { FormComplianceReportType } from "../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../interfaces/system/areaTypes"
import { ExcelExportHeader, exportToExcel } from "../../services/reporting/excel-export-service";
import DataTable, { DataTableColumnProps } from "../core/dataTable";
import { SelectedServiceType } from "../core/service-selector";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "./basic-report-filter"

interface ServiceComplianceReportProps {
    areaHierarchy:AreaHierarchyType
    onFetchData: (serviceIds:number[])=> void
    data?:FormComplianceReportType[]
    criteria?:BasicReportFilterCriteria
    mode:'form'|'grid'
}
export default function ServiceComplianceReport(props:ServiceComplianceReportProps) {
    const  [selections, setSelections] = useState<SelectedServiceType[]>([]);


    let columns: DataTableColumnProps []
    
    if(props.mode === "form"){
        columns= [{header:"Service", field:"service.name"},
            {header:"Form", field:"form.name"},
            {header:"Category", field:"category.name"},
            {header:"Level", field:"reportingResultOption.name",formatType:"form-result"},
            {header:"Last Updated", field:"lastUpdatedOn",formatType:"datetime"},
            {header:"Days Since Last Update", field:"elapsedDaysSinceLastUpdated"}]
    }
    else{
        columns= [{header:"Organisation", field:"service.name"},
        {header:"Capacity Grid", field:"capacityGrid.name"},
        {header:"Last Updated", field:"lastUpdatedOn",formatType:"datetime"},
            { header: "Days Since Last Update", field: "elapsedDaysSinceLastUpdated", style: (rowData: any) => { let days = parseInt(rowData['elapsedDaysSinceLastUpdated']); return 'cap-grid-report-days-rag-' + (days !== 0 ? 'red':'green'); } }]
    }


    const handleApplyFilter = (criteria:BasicReportCriteria)=> {
        setSelections(criteria.selectedServiceIds)
        props.onFetchData(criteria.selectedServiceIds.map(m=>m.id))
    }
    const handleExport = () =>{

        const columns= [{header:"Service", field:"service.name"},
        {header:"Capacity Grid", field:"capacityGrid.name"},
        {header:"Level", field:"reportingResultOption.name",formatType:"form-result"},
        
        {header:"Last Updated", field:"lastUpdatedOn",formatType:"datetime"},
        {header:"Days Since Last Update", field:"elapsedDaysSinceLastUpdated"}]
        
        const headers:ExcelExportHeader[] = columns.map(m=> {return {title:m.header}})
        let title= 'Escalation Service Compliance Report'
        headers[0].width=35;headers[1].width=30;
        if(props.mode === "form"){
            headers[2].width=10;headers[3].width=25;headers[4].width=22;
        }
        else{
            headers[2].width=25;headers[2].width=22;
            title= 'Capacity Service Compliance Report'
        }
        exportToExcel({
        headers:headers,
            sheets: [{ title: title, name: title, elements:[{table:document.querySelector("table")}]}]
        })
    }

    
    return <Grid container spacing={2} >
        <Grid item xs={10}>
                <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                validateServiceSelections={true}
                onExport={handleExport}/>
            </Grid>
            <Grid item xs={11}>
            <DataTable dataKey="id" loading={false} columns={columns} value={props.data} paginator={false}
                filter={false} allowExport={true} showGridlines={true}
                />
            </Grid>
        </Grid>
}