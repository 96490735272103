import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect } from "react";
import { useState } from "react";
import { getCssVariableValue } from "../../services/utils";

export interface PresetColorPickerProps {
    value?: any;
    onChange?(color: string): void;
}

export function PresetColorPicker(props: PresetColorPickerProps) {
    const [value, setValue] = useState(props.value);
    const [presets, setPresets] = useState<string[]>();
    const presetVariableNames = ['rag-color-black', 'rag-color-red', 'rag-color-amber', 'rag-color-green'];

    useEffect(() => {
        //console.clear();
        let _presets: string[] = presetVariableNames.map(s => {
            return getCssVariableValue(s);
        });
        setPresets(_presets);
    }, []);

    

    const handleColorSelection = (e: any) => {
        setValue('' + e.target.value);
        handleUpdated(e.target.value);
    }
    const handleUpdated = (color: string) => {
        if (props.onChange) {
            props.onChange((color?.indexOf('#') <= -1 ? '#':'') + color);
        }
    }

    if (!presets || presets.length <= 0) {
        return <>kjkl</>;
    }

    return <>
        <ColorPicker value={value} onChange={handleColorSelection} inline />
        <p style={{ marginBottom: '1px' }}><small>Presets:</small></p>
        <div>
            {React.Children.toArray(presets?.map(color => {
                return <div style={{ width: '25%', float: 'left', display: 'inline-block', backgroundColor: '' + color, cursor: 'pointer' }} onClick={() => { setValue('' + color); handleUpdated(color); }}>
                    &nbsp;
                </div>
            }))}
            <div className="clearfix"></div>
        </div>
    </>
}