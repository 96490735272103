import { formatDateTime } from "../../extensions/formatter"
import { dateDiffFromNowInHours, getElapsedTextFromDateTime } from "../../services/utils"

export interface DateLabelWithElapsedIndicatorProps{
    date?:Date
    greenMax:number
    redMax:number
}
export default function DateLabelWithElapsedIndicator(props: DateLabelWithElapsedIndicatorProps) {

    const hoursSinceUpdate = dateDiffFromNowInHours(props?.date)
        const color = hoursSinceUpdate < props.greenMax ? 'green' : hoursSinceUpdate<props.redMax ? '#E6D25F' : 'red'
        const elapsedTime = !props?.date ? <></> : <div className={'color'} style={{display:'flex',marginLeft:10,border:`2px solid ${color}`, height:27, borderRadius:'50%',width:30,
                    padding:3,fontSize:'0.75rem',justifyContent:'center'}}>
                    {getElapsedTextFromDateTime(props?.date)}
        </div>

        return <><div>{formatDateTime(props.date)}</div>{elapsedTime}</>

}