import { useState } from "react";
import { createNewUser, UserType } from "../../interfaces/user/iUser"
import { searchUsers } from "../../services/userService";
import TypeAheadTextbox from "./typeAheadTextbox";
import { DomainEntityType } from "../../interfaces/domain/iDomainEntity";

export interface UserSearchProps {
    onUserSelection?: (user?: UserType) => void,
    onError?: (message: string) => void,
}
export function UserSearch(props: UserSearchProps) {
    const [userSearchResults, setUserSearchResults] = useState<DomainEntityType[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserType>();

    const handleSearchUsers = async (q: string) => {
        const response = await searchUsers(q, true);
        if (response && response.success && response.result) {
            setUserSearchResults(response.result.map(m=> ({name:`${m.fullName} (${m.email})`, id: m.id,displayOrder:0,children:[],active:true})))
        } else {
            handleError(response.error ?? 'Unknown error');
        }
    }

    const handleUserSearchSelected = async (e: any) => {

        
        const newUser = createNewUser()

        if(e.id<0)
        {
            setSelectedUser(undefined)
            if (props.onUserSelection) {
                props.onUserSelection(undefined);
            }
        }

        const selectedUser = userSearchResults.find(f=>f.id === e.id)
 
        if(!selectedUser) return

        newUser.id = selectedUser.id

        const name = selectedUser.name.split('(')

        newUser.fullName = name[0]
        newUser.email = name[1].replace(')','')

        setSelectedUser(newUser)
        if (props.onUserSelection) {
            props.onUserSelection(newUser);
        }
        // setUserSearchResults([]);
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }
    const handleUserSearchKeyPress = (query:string) =>{
        handleSearchUsers(query)
    }

    return <>
        <TypeAheadTextbox id="serviceId" options={userSearchResults} value={selectedUser?.id}  label="Search for user by name/email address" onChange={handleUserSearchSelected} onKeyPress={handleUserSearchKeyPress}/>
    </>
}