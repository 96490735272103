import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import Panel from "../../components/core/panel";
import { fetchCapacityGridSharedColumns } from "../../services/capacity-grid-service";
import { CapacityGridColumnType, CapacityGridColumnVisibilityTypes, CapacityGridFieldTypes, CapacityGridSearchResultType, CapacityGridType, createEmptyCapacityGrid, createEmptyCapacityGridColumn } from "../../interfaces/system/capacityGridType";
import CapacityGridList from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-list";
import CapacityGridEdit from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-editor";
import useForm from "../../hooks/useForm";
import { Grid } from "@mui/material";
import React from "react";
import { AppToastContext } from "../../extensions/context/appHook";
import { updatePageTitle } from "../../services/utils";
import { CapacityGridSharedColumnList } from "../../components/admin/capacity-grids/capacity-grid-shared-cols-editor/capacity-grid-shared-column-list";
import { CapacityGridSharedColumnEditor } from "../../components/admin/capacity-grids/capacity-grid-shared-cols-editor/capacity-grid-shared-column-editor";

interface CapacityGridSharedColBuilderProps {
  mode?: 'list' | 'edit'
}
export function CapacityGridSharedFieldsBuilderContainer(props: CapacityGridSharedColBuilderProps) {

  // component vars
  const defaultTitle: string = 'Capacity Grid Shared Column List';
  const toastHandler = React.useContext(AppToastContext);
  const [calculatedFieldTags, setCalculatedFieldTags] = useState<string[]>([]);

  // state vars
  const [mode, setMode] = useState(props.mode)
  const [title, setTitle] = useState(defaultTitle)
  const [selectedItem, setSelectedItem] = useState<CapacityGridColumnType>()

  const { feedbackStatus, setFeedbackStatus } = useForm({ initialObjectValues: {} })
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const navigate = useNavigate();



  // hooks
  useEffect(() => {
    //console.clear();
    init()
    buildCalculatedFieldTags();
  }, [mode])

  useEffect(() => {
    //console.clear();
    updatePageTitle(title)
  }, [title])


  // presentation handlers
  const init = async () => {
    updatePageTitle(title)
    if (mode !== 'list') return;
    const userIsBasic = false
    if (userIsBasic) {
      setFeedbackStatus(FeedbackType.NoAccess)
    }
  }

  const buildCalculatedFieldTags = async () => {
    const response = await fetchCapacityGridSharedColumns(false);
    if (response && response.success) {
      setCalculatedFieldTags(response.result?.filter(col => col.fieldType.id === CapacityGridFieldTypes.Numeric && !col.deleted).map(col => {
        return col.name
      }) ?? []);
    }
  }

  //#region list event handlers

  const handleListError = (errorMessage: string) => {
    setFeedbackStatus(FeedbackType.Error)
    setErrorMessage(errorMessage);
  }

  const handleListItemSelection = (item: CapacityGridColumnType) => {
    setFeedbackStatus(FeedbackType.hide)
    setErrorMessage(undefined);
    setSelectedItem(item);
    setMode('edit');
  }

  const handleListNewItem = () => {
    const newColumn = createEmptyCapacityGridColumn();
    newColumn.visibilityType.id = CapacityGridColumnVisibilityTypes.Shared
    handleListItemSelection(newColumn);
  }

  //#endregion

  //#region editor event handlers
  const handleEditorError = (errorMessage: string) => {
    setFeedbackStatus(FeedbackType.Error)
    setErrorMessage("Could not save column: " + errorMessage);
  }

  const handleEditorCancel = () => {
    setSelectedItem(undefined);
    setMode('list');
  }

  const handleEditorSaved = async (item: CapacityGridColumnType) => {
    setFeedbackStatus(FeedbackType.SaveSuccess)
    setErrorMessage(`Shared column '${item.name}' saved successfully`);
    handleEditorCancel();
  }

  //#endregion

  return <>
      <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
      <Grid item xs={12} lg={9}>
        <Panel title={title}>
          <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
          {mode === 'list' && <CapacityGridSharedColumnList onItemSelection={handleListItemSelection} onNew={handleListNewItem} onError={handleListError}></CapacityGridSharedColumnList>}
          {mode === 'edit' && selectedItem && <CapacityGridSharedColumnEditor calculatedFieldNames={calculatedFieldTags} onSaved={handleEditorSaved} item={selectedItem} onError={handleEditorError} onCancel={handleEditorCancel}></CapacityGridSharedColumnEditor>}
        </Panel>
      </Grid>
    </Grid>
  </>
}