import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Page from "../../components/core/page";
import { FeedbackType } from "../../components/core/feedback";
import { BroadcastList } from "../../components/admin/notification-broadcast-builder/broadcast-list";
import { fetchNotificationBroadcast, fetchNotificationBroadcastList, saveNotificationBroadcast } from "../../services/notificationBroadcastService";
import { NotificationBroadcastType, createEmptyNotificationBroadcast } from "../../interfaces/system/notificationBroadcastType";
import { useNavigate } from "react-router-dom";
import { BroadcastEditor } from "../../components/admin/notification-broadcast-builder/broadcast-edit";
import { clearDomainEntities } from "../../services/domainEntitiesService";

interface NotificationBroadcastContainerProps {
    mode: 'edit' | 'list'
}
export function NotificationBroadcastContainer(props: NotificationBroadcastContainerProps) {

    let { id } = useParams() || 0;
    let svcId = parseInt(id ?? '0');
    const navigate = useNavigate();

    const [title, setTitle] = useState('Capacity Service Compliance Report');
    const [feedbackStatus, setFeedbackStatus] = useState(FeedbackType.hide);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [broadcastList, setBroadcastList] = useState<NotificationBroadcastType[]>();
    const [selectedItemId, setSelectedItemId] = useState(svcId);
    const [selectedItem, setSelectedItem] = useState<NotificationBroadcastType>();
    const [mode, setMode] = useState(props.mode ?? 'list');


    useEffect(() => {
        present();
    }, [props.mode]);

    useEffect(() => {
        if (!selectedItemId || selectedItemId <= 0) return;
        presentEdit();
    }, [selectedItemId]);

    const present = async () => {
        setFeedbackStatus(FeedbackType.Loading);
        if (props.mode === 'edit') {
            presentEdit();
        } else {
            presentList();
        }
        setFeedbackStatus(FeedbackType.hide)
    }

    const presentList = async () => {
        setFeedbackStatus(FeedbackType.Loading);
        setTitle('Notification broadcasts');
        const response = await fetchNotificationBroadcastList();
        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setBroadcastList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const handleRowSelection = async (id: number) => {
        setSelectedItemId(id);
        setMode('edit');
        navigate(`/admin/notification-broadcasts/${id}`);
    }
    const handleCancel = async () => {
        setSelectedItemId(0);
        setMode('list');
        navigate(`/admin/notification-broadcasts`);
    }
    const handleNewItem = () => {
        setSelectedItemId(0);
        setMode('edit');
        setSelectedItem(undefined);
    }
    const handleSave = async (item: NotificationBroadcastType, valid: boolean) => {
        setSelectedItem(item);
        setErrorMessage('');
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveNotificationBroadcast(item)
        if (saveResponse.success) {
            //toastHandler.successMessage("Successfuly saved form - " + form.name)
            setFeedbackStatus(FeedbackType.hide)
            if (saveResponse.result) {
                const newForm = { ...(item as NotificationBroadcastType) }
                newForm.id = saveResponse.result?.id;
                setSelectedItem(newForm);
                setBroadcastList(undefined);
                clearDomainEntities();
                setErrorMessage('Item saved');
                setFeedbackStatus(FeedbackType.SaveSuccess);
            }
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error');
        setFeedbackStatus(FeedbackType.Error);

    }

    const presentEdit = async () => {
        setFeedbackStatus(FeedbackType.Loading);
        setTitle('Edit notification broadcast');
        if (selectedItemId > 0) {
            const response = await fetchNotificationBroadcast(selectedItemId);
            setFeedbackStatus(FeedbackType.hide)
            if (response && response.success) {
                setSelectedItem(response.result)
                return
            }
            setErrorMessage(response.error)
            setFeedbackStatus(FeedbackType.Error)
        }
    }

    return <Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
        {mode === 'list' && broadcastList &&
            <BroadcastList list={broadcastList} onSelection={handleRowSelection} onNew={handleNewItem} />
        }

        {mode === 'edit' &&
            <>
                <BroadcastEditor item={selectedItem ?? createEmptyNotificationBroadcast()} onSave={handleSave} onCancel={handleCancel} />
            </>
        }

    </Page>
}