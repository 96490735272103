import {useEffect,useState} from "react"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import Loader from "../../core/loading";
import {FormResultOptionType, FormResultTypeEnum, FormType } from "../../../interfaces/system/formType";
import Panel from "../../core/panel";
import FormValidator from "../../../validators/form-validator";
import { getCachedOptions, getFormResultOptionsFromResultId, getOptionsForFormResultId } from "../../../services/domainEntitiesService";

interface FormEditResultRangeProps {
    form?:FormType
    onUpdate: (formResultTypeId:FormResultTypeEnum,options:FormResultOptionType[]) => void 
  }
  export default function FormEditResultRange(props: FormEditResultRangeProps) {
    const [formResultOptions, setFormResultOptions] = useState<FormResultOptionType[]>([])
    const [opelFormResultOptions, setOpelFormResultOptions] = useState<FormResultOptionType[]>([])
    const [formResultId, setFormResultId] = useState(props?.form?.result?.id)
    const [opelFormResultId, setOpelFormResultId] = useState(props?.form?.opelResult?.id)
    const [validator] = useState(props.form?.validator)
    const {
      objectValues, setObjectValues,setValidator,
    }
    = useForm({initialObjectValues: props.form, validator: validator})

    const options = getCachedOptions()

  useEffect(() => {
    if(props.form?.id !== objectValues?.id){
      setObjectValues(props.form)
    }
    if(props.form && !props?.form?.validator){
      props.form.validator= new FormValidator()
    }
    setValidator(props?.form?.validator)

    if(formResultId!==props?.form?.result?.id)
      setFormResultId(props?.form?.result?.id)
    if(opelFormResultId!==props?.form?.opelResult?.id)
      setOpelFormResultId(props?.form?.opelResult?.id)


  },[props.form])
  useEffect(() => {
    getFormResultDetail()
   },[formResultId])
   useEffect(() => {
    getOpelFormResultDetail()
   },[opelFormResultId])
     

   const getFormResultDetail = () => {
    if(!formResultId) return
    
    if(objectValues?.result?.options?.length>0){
      setFormResultOptions(objectValues.result.options)
      return
    }
    let resultOptions = getFormResultOptionsFromResultId(options.formResultOptions, formResultId, objectValues.formId)

    
    if(!resultOptions || resultOptions.length<1){
      resultOptions = getOptionsForFormResultId(options.formResultOptions, formResultId)
    }
    setFormResultOptions(resultOptions)
    props.onUpdate(FormResultTypeEnum.Standard,resultOptions)
    
   }
   const getOpelFormResultDetail = () => {
    if(!opelFormResultId) return
    
    if(objectValues?.opelResult?.options?.length>0){
      setOpelFormResultOptions(objectValues.opelResult.options)
      return
    }

    let resultOptions = getFormResultOptionsFromResultId(options.formResultOptions, opelFormResultId, objectValues.formId)

    if(!resultOptions || resultOptions.length<1){
      resultOptions = getOptionsForFormResultId(options.formResultOptions, opelFormResultId)
    }
    setOpelFormResultOptions(resultOptions)
    props.onUpdate(FormResultTypeEnum.Opel, resultOptions)
   }
  
   const updateFormResultRange = (typeId:FormResultTypeEnum, formResultOptionId:number, from?:string, to?:string) =>{

      const options = typeId === FormResultTypeEnum.Standard ? [...formResultOptions] : [...opelFormResultOptions]
      const optionId = options.findIndex(f=> f.formResultOptionId == formResultOptionId)
      if(optionId<0) return

      if(from)
        options[optionId].weightingFrom = parseInt(from)

      if(to)
        options[optionId].weightingTo = parseInt(to)

        if(typeId === FormResultTypeEnum.Standard){
          setFormResultOptions(options)
        }
        else{
          setOpelFormResultOptions(options)
        }
        props.onUpdate(typeId,options)
   }
  
  if(!objectValues)
          return <Loader loading={true} />
    
           

    return <>
        <Panel title="EMS Escalation Weightings">
                <Grid item xs={12} >
                {formResultOptions?.map((r:FormResultOptionType) => (
                     <Grid container spacing={1}  style={{padding:'5px 5px'}}>
                     <Grid item xs={5}><span>{r.name}</span></Grid>
                     <Grid item xs={3}><TextBox id="weightingFrom"  label="Weighting From"  maxLength={6} type="number" value={r?.weightingFrom} onChange={(e)=>{updateFormResultRange(FormResultTypeEnum.Standard, r?.formResultOptionId,e.target.value)}}/></Grid>
                     <Grid item xs={3}><TextBox id="weightingTo"  label="Weighting To"  maxLength={6} type="number" value={r?.weightingTo}  onChange={(e)=>{updateFormResultRange(FormResultTypeEnum.Standard, r?.formResultOptionId,undefined,e.target.value)}}/></Grid>
                 </Grid>
                ))}
                </Grid>
        </Panel>

        {(opelFormResultId||0>0) && <Panel title="OPEL Weightings">
                <Grid item xs={12} >
                {opelFormResultOptions?.map((r:FormResultOptionType) => (
                     <Grid container spacing={1}  style={{padding:'5px 5px'}}>
                     <Grid item xs={5}><span>{r.name}</span></Grid>
                     <Grid item xs={3}><TextBox id="weightingFrom"  label="Weighting From"  maxLength={6} type="number" value={r?.weightingFrom}  onChange={(e)=>{updateFormResultRange(FormResultTypeEnum.Opel, r?.formResultOptionId,e.target.value)}}/></Grid>
                     <Grid item xs={3}><TextBox id="weightingTo"  label="Weighting To"  maxLength={6} type="number" value={r?.weightingTo}  onChange={(e)=>{updateFormResultRange(FormResultTypeEnum.Opel, r?.formResultOptionId,undefined,e.target.value)}}/></Grid>
                 </Grid>
                ))}
                </Grid>
        </Panel>
        }
    </>

}