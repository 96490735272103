import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { ActionCardEventType, ActionCardRecipientType, ActionCardType } from '../interfaces/system/actionCardType';
import { ValidatorType } from './ValidatorType'

export class ActionCardValidator implements ValidatorType {

    validator = new FormValidation([])
    serviceId = new FormValidationField('', '', ValidationType.Numeric)

    initialiseValidationFields = (objectValues: ActionCardType) => {
        this.validator = new FormValidation([])
        this.serviceId = this.validator.createPostiveNumericField(objectValues.serviceId)
    }

    validate = (validatorObjectValues: ActionCardType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
export class ActionCardRecipientValidator implements ValidatorType {

    validator = new FormValidation([])
    name = new FormValidationField('', 'Required field')
    description = new FormValidationField('', '')
    distributionListId = new FormValidationField('', '', ValidationType.Numeric)

    initialiseValidationFields = (objectValues: ActionCardRecipientType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
        this.description = this.validator.createTextField(objectValues.description)
        this.distributionListId = this.validator.createPostiveNumericField(objectValues.distributionListId)
        this.distributionListId.minValue = 1;
    }

    validate = (validatorObjectValues: ActionCardRecipientType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()
        return isValid
    }
}
export class ActionCardEventValidator implements ValidatorType {

    validator = new FormValidation([])
    // cant use this due to issue with HTML editor
    //    actionText = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: ActionCardEventType) => {
        this.validator = new FormValidation([])
        //this.actionText = this.validator.createTextField(objectValues.actionText)
    }

    validate = (validatorObjectValues: ActionCardEventType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = true;// this.validator.isValid()
        return isValid
    }
}