import { resetDateToEndOfDay, resetDateToStartOfDay } from "../../extensions/formatter"
import { CapacityGridHistoricReportFilterOptions, CapacityGridHistoricReportResultType, CapacityGridSubmissionOverviewType, CapacityGridSummaryReportFilterOptions, CapacityGridSummaryReportResultType, CapacityGridType } from "../../interfaces/system/capacityGridType"
import { HttpResponse, HttpPost, HttpGet } from "../http"

export const fetchDailyReport = async (serviceIds: number[], serviceCategoryIds?: number[]): Promise<HttpResponse<CapacityGridSubmissionOverviewType[]>> => {
    const response = await HttpPost<CapacityGridSubmissionOverviewType[]>(
        `reporting/capacity-grid/daily-report`, { serviceIds: serviceIds,serviceCategoryIds:serviceCategoryIds }
    )

    return response
}

export const fetchHistoricReport = async (options: CapacityGridHistoricReportFilterOptions): Promise<HttpResponse<CapacityGridHistoricReportResultType[]>> => {
    const _from = resetDateToStartOfDay(options.dateFrom);
    const _to = resetDateToEndOfDay(options.dateTo);
    const response = await HttpGet<CapacityGridHistoricReportResultType[]>(
        `reporting/capacity-grid/historic-report?parentServiceId=${options.parentServiceId}&dateFrom=${_from}&dateTo=${_to}`
    )

    return response
}

// loads all capacity grid submission definitions for current user
export const fetchCapacityGridSubmissionList = async (gridIds?:number[]): Promise<HttpResponse<CapacityGridType[]>> => {
    //const cacheKey = 'capacity-grid-submission-list';

    const response = await HttpPost<CapacityGridType[]>(
        `reporting/capacity-grid/submission-data`, { gridIds: gridIds ?? [] }
    )

    return response
}

export const fetchCapacityGridSummaryReport = async (options: CapacityGridSummaryReportFilterOptions): Promise<HttpResponse<CapacityGridSummaryReportResultType>> => {
    if (!options || !options.month || !options.year) {
        throw Error("A date range must be selected.");
    }
    const response = await HttpPost<CapacityGridSummaryReportResultType>(
        `reporting/capacity-grid/summary-report`, options)
    return response
}