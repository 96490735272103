import { Badge as B } from 'primereact/badge';
import React from 'react';

export interface BadgeProps{
    value:number
    supressWhenZero?:boolean
}
export default function Badge(props: BadgeProps) {
    
    const [value, setValue] = React.useState(props.value);
    React.useEffect(() => {
        setValue(props.value)
      }, [props.value])


      if(props.supressWhenZero && value <=0)
        return <></>

    return <B value={props.value}></B>
}