import { NecsImportAuditType, SystemAuditType, UserPermissionReportType } from "../../interfaces/reporting/system"
import { AutomatedReportDataType } from "../../interfaces/system/systemTypes"
import { UserSubscriptionReportType } from "../../interfaces/system/userSubscriptionReportType"
import { HttpResponse, HttpPost } from "../http"
export const fetchSystemAudit = async (fromDate?: Date, toDate?: Date, userId?: number, auditEventTypeId?: number[]): Promise<HttpResponse<SystemAuditType[]>> => {
    const response = await HttpPost<SystemAuditType[]>(
        `reporting/system/audit`,
        { userId: userId, fromDate: fromDate, toDate: toDate, auditEventTypeIds: auditEventTypeId }
    )

    return response
}

export const fetchAutomatedReportData = async (identifier: string, generatedOn: number): Promise<HttpResponse<AutomatedReportDataType>> => {
    const response = await HttpPost<AutomatedReportDataType>(
        `reporting/system/automated-report-data`,
        { identifier: identifier, generatedOnTicks: generatedOn }
    )
    return response
}
export const fetchNecsImportAuditLog = async (): Promise<HttpResponse<NecsImportAuditType[]>> => {
    const response = await HttpPost<NecsImportAuditType[]>(
        `reporting/system/necs-import-auditlog`,
        {}
    )

    return response
}
export const fetchUserSubscriptionReportData = async (serviceIds?: number[], userIds?: number[], levels?: number[]): Promise<HttpResponse<UserSubscriptionReportType[]>> => {
    const response = await HttpPost<UserSubscriptionReportType[]>(`reporting/user/get-user-subscription-report`, { serviceIds: serviceIds, userIds: userIds, levels: levels });
    return response;
}
export const fetchUsePermissionReportData = async (serviceIds?: number[], userIds?: number[], userRoleIds?: number[]): Promise<HttpResponse<UserPermissionReportType[]>> => {
    const response = await HttpPost<UserPermissionReportType[]>(`reporting/system/user-permissions`, { serviceIds: serviceIds, userIds: userIds, userRoleIds: userRoleIds });
    return response;
}
