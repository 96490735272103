import { UserRegistrationLoginType } from "../interfaces/user/iUser";
import { HttpResponse, HttpPost } from "./http";

export const generateCombinedEscalation = async () : Promise<HttpResponse<boolean>> => {
  
  const response = await HttpPost<boolean>(
    `task/calculate-combined-esclation`,{}
  )
 
  return response
}
export const getEncrpytedPassword = async (u:UserRegistrationLoginType) : Promise<HttpResponse<UserRegistrationLoginType>> => {
  
  const response = await HttpPost<UserRegistrationLoginType>(
    `task/e`,u
  )
 
  return response
}

export const saveAmbulanceMeanTA_TEMP = async (u?:string) : Promise<HttpResponse<string>> => {
  
  const response = await HttpPost<string>(
    `task/mean-ambulance-metric`,u
  )
 
  return response
}

