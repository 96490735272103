export interface iFormValidation {
    validationFields: iFormValidationField[];
    validationErrors: Array<boolean>;
}
export interface iFormValidationField {
    fieldValue: string;
    errorMessage: string;
    validationType: ValidationType;
    isValid: boolean | undefined;
    validate?(): any;
}

export enum ValidationType {
    Required = 1,
    Password,
    Date,
    Numeric,
    Text,
    Email,
    Postcode,
    Array,
    Formula,
    File
}


export class FormValidationField {
    allowNull: boolean
    fieldValue: string;
    isValid: boolean | undefined;
    errorMessage: string;
    validationType: ValidationType;
    minValue?: number;
    maxValue?: number
    constructor(fieldValue: any, errorMessage: string, validationType?: ValidationType) {
        this.fieldValue = fieldValue;
        this.errorMessage = errorMessage;
        this.isValid = undefined;
        this.allowNull = false
        this.validationType = validationType || ValidationType.Required;
    }

    validate = (): boolean => {
        const isValueNullOrEmpty = this.fieldValue === undefined || this.fieldValue === null || this.fieldValue === ''

        if (isValueNullOrEmpty && this.allowNull) {
            this.isValid = true

            return this.isValid
        }

        switch (this.validationType) {
            case ValidationType.Password:
                this.isValid = (this.fieldValue !== null && this.fieldValue !== '' && this.fieldValue.length > 6)
                break;
            case ValidationType.Date:

                this.isValid = !isValueNullOrEmpty || (isValueNullOrEmpty && this.allowNull)
                if (!isValueNullOrEmpty) {
                    const inputDate = new Date(this.fieldValue).getTime()

                    if (this.isValid && this.minValue) {
                        this.isValid = inputDate >= this.minValue
                    }
                    if (this.maxValue && this.fieldValue) {
                        this.isValid = inputDate <= this.maxValue
                    }
                }
                break;
            case ValidationType.Numeric:

                this.isValid = !isValueNullOrEmpty

                if (this.isValid && this.minValue && this.minValue < 0) {
                    this.isValid = parseFloat(this.fieldValue) <= this.minValue || this.allowNull
                    break;
                }


                if (this.isValid && this.minValue) {
                    this.isValid = parseInt(this.fieldValue) >= this.minValue || this.allowNull
                }
                break;
            case ValidationType.Array:
                this.isValid = this.fieldValue.length > 0
                break;
            case ValidationType.File:
                this.isValid = this.fieldValue.length > 0
                break;
            case ValidationType.Formula:
                this.isValid = !isValueNullOrEmpty
                if (this.isValid) {
                    let formula = this.fieldValue;
                    if (formula) {
                        let regexExtractFields = /\{(.*?)\}/g;
                        let formulaFieldRefs = Array.from(formula.matchAll(regexExtractFields), (m) => m[1]);
                        if (formulaFieldRefs) {
                            formulaFieldRefs.forEach(f => {
                                formula = formula?.replace(`{${f}}`, '1')
                            });
                        }
                        try {
                            eval(formula);
                            this.isValid = true;
                            break;
                        } catch (e:any) {
                            this.errorMessage = e.message + '';
                            //console.log(e);
                            this.isValid = false;
                        }
                    }
                    this.isValid = false;
                    break;
                }

                break;
            case ValidationType.Email:
                this.isValid = this.allowNull && (!this.fieldValue || this.fieldValue === '')
                if (this.isValid) break;

                this.isValid = (this.fieldValue !== null && this.fieldValue !== '');
                if (this.isValid) {
                    this.isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.fieldValue)
                }
                break;
            case ValidationType.Postcode:
                this.isValid = (this.fieldValue !== null && this.fieldValue !== '');
                if (this.isValid) {
                    this.isValid = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(this.fieldValue)
                }
                break;
            default:
                this.isValid = !isValueNullOrEmpty
                break;
        }
        return this.isValid;
    }
}


export class FormValidation implements iFormValidation {
    validationFields: FormValidationField[];
    hasValidated = false;
    validationErrors: Array<boolean>;
    lastUpdate: Date

    constructor(validationFields: FormValidationField[]) {
        this.validationFields = validationFields;
        this.validationErrors = new Array<boolean>();
        this.lastUpdate = new Date()
    }

    createTextField = (fieldValue: string): FormValidationField => {
        const formField = new FormValidationField(fieldValue, '')
        this.validationFields.push(formField)
        return formField
    };
    createFileField = (fieldValue: File): FormValidationField => {
        const formField = new FormValidationField(fieldValue, '')
        this.validationFields.push(formField)
        return formField
    };
    createTextFieldWithWarning = (fieldValue: string): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'required')
        this.validationFields.push(formField)
        return formField
    };

    createPostcodeField = (fieldValue: string): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'invalid postcode')
        formField.validationType = ValidationType.Postcode
        this.validationFields.push(formField)
        return formField
    };

    createDateField = (fieldValue: Date | undefined, errorMessage?: string): FormValidationField => {
        const formField = new FormValidationField(fieldValue, errorMessage || '')
        formField.validationType = ValidationType.Date
        this.validationFields.push(formField)
        return formField
    };
    createDateFieldMinDate18years = (fieldValue?: Date): FormValidationField => {
        const formField = this.createDateField(fieldValue, 'must be have 18 years or more')
        let minDate = new Date()
        minDate.setFullYear(minDate.getFullYear() - 18)
        formField.minValue = minDate.getTime()
        this.validationFields.push(formField)
        return formField
    }
    createDateFieldMustBeInFuture = (fieldValue?: Date, allowNull?: boolean): FormValidationField => {
        const formField = this.createDateField(fieldValue, 'must be in the future')
        formField.minValue = (new Date().setHours(0, 0, 0, 0))
        formField.allowNull = allowNull || false

        this.validationFields.push(formField)
        return formField
    }
    createDateFieldMustBeInPast = (fieldValue?: Date, allowNull?: boolean): FormValidationField => {
        const formField = this.createDateField(fieldValue, 'can\'t be in the future')
        formField.maxValue = (new Date().setHours(23, 59, 59, 59))
        formField.allowNull = allowNull || false
        this.validationFields.push(formField)
        return formField
    }
    createPostiveNumericField = (fieldValue?: number): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'must be greater than zero')
        formField.validationType = ValidationType.Numeric
        formField.minValue = 0

        this.validationFields.push(formField)
        return formField
    }
    createNegativeNumericField = (fieldValue?: number): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'must be less than zero')
        formField.validationType = ValidationType.Numeric
        formField.minValue = -0.000001
        formField.maxValue = -50

        this.validationFields.push(formField)
        return formField
    }
    createDomainTypeField = (fieldValue?: number): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'required')
        formField.validationType = ValidationType.Numeric
        formField.minValue = 1

        this.validationFields.push(formField)
        return formField
    }
    createEmailField = (fieldValue?: string): FormValidationField => {
        const formField = new FormValidationField(fieldValue, 'invalid email address')
        formField.validationType = ValidationType.Email

        this.validationFields.push(formField)
        return formField
    }
    createDropdownField = (fieldValue?: number): FormValidationField => {
        const formField = new FormValidationField(fieldValue, '')
        formField.validationType = ValidationType.Numeric
        formField.minValue = 1

        this.validationFields.push(formField)
        return formField
    };
    createMultiSelectField = (fieldValue?: number[], message?: string): FormValidationField => {
        // const validField = fieldValue && fieldValue.length >0 ? 1: 0
        const formField = new FormValidationField(fieldValue, message || 'You must select at least one value')
        formField.validationType = ValidationType.Array

        this.validationFields.push(formField)
        return formField
    };
    createFormulaField = (fieldValue?: string, message?: string): FormValidationField => {
        // const validField = fieldValue && fieldValue.length >0 ? 1: 0
        const formField = new FormValidationField(fieldValue, message || 'There is an error in your formula')
        formField.validationType = ValidationType.Formula

        this.validationFields.push(formField)
        return formField
    };
    isValid = () => {
        this.hasValidated = true;
        this.validationErrors = new Array<boolean>();

        for (let validationField of this.validationFields) {
            this.validationErrors.push(!validationField.validate());
        }

        this.lastUpdate = new Date()
        return this.validationFields.filter(e => !e.isValid).length === 0;
    }

}
export const validateFormFields = () => {

    return true;
}