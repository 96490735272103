import { NotificationBroadcastType } from "../interfaces/system/notificationBroadcastType"
import { HttpGet, HttpPost, HttpResponse } from "./http"

export const fetchNotificationBroadcastList = async (): Promise<HttpResponse<NotificationBroadcastType[]>> => {

    const response = await HttpGet<NotificationBroadcastType[]>(
        `NotificationBroadcast/list?deleted=true`
    )

    return response
}
export const fetchNotificationBroadcast = async (id:number): Promise<HttpResponse<NotificationBroadcastType>> => {

    const response = await HttpGet<NotificationBroadcastType>(
        `NotificationBroadcast/${id}`   
    )

    return response
}
export const saveNotificationBroadcast = async (data: NotificationBroadcastType): Promise<HttpResponse<NotificationBroadcastType>> => {
    let postData = { ...data }
    if ((postData.description?.length ?? 0) > 1024) {
        postData.description = postData.description.substring(0, 1024);
    }

    const response = await HttpPost<NotificationBroadcastType>(
        `NotificationBroadcast`, postData
    )

    return response
}