import { Grid } from "@mui/material";
import { Panel } from "primereact/panel";
import { useEffect, useState } from "react";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import { useNavigate, useParams } from "react-router-dom";
import { updatePageTitle } from "../../services/utils";
import { FormResultList } from "../../components/admin/form-result-builder/form-result-list";
import { createNewFormResult, FormResultType } from "../../interfaces/system/formType";
import { FormResultEditor } from "../../components/admin/form-result-builder/form-result-editor";
export interface FormResultBuilderContainerProps {
    mode?: 'list' | 'edit'
}
export function FormResultBuilderContainer(props: FormResultBuilderContainerProps) {
    // component vars
    let { id } = useParams();
    const defaultTitle: string = 'Form Result';

    // state vars
    const [mode, setMode] = useState(props.mode ?? 'list');
    const [selectedRecordId, setSelectedRecordId] = useState(parseInt(id ?? '0'))
    const [feedbackStatus, setFeedbackStatus] = useState<FeedbackType>(FeedbackType.hide)
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const navigate = useNavigate();

    // hooks
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const userIsBasic = false
        if (userIsBasic) {
            setFeedbackStatus(FeedbackType.NoAccess)
        }
        if (selectedRecordId > 0) {
            setMode('edit');
            setPageTitle('Edit');
        } else {
            setMode('list');
            setPageTitle(defaultTitle);
        }
    }

    const handleLoading = () => {
        setFeedbackStatus(FeedbackType.Loading);
    }
    const handleLoaded = () => {
        setFeedbackStatus(FeedbackType.hide);
    }
    const handleError = (message: string) => {
        setErrorMessage(message);
        setFeedbackStatus(FeedbackType.Error);
    }

    const setPageTitle = (title: string) => {
        setTimeout(() => { updatePageTitle(title); }, 100);
    }
    const handleRowClicked = (item: FormResultType) => {
        setSelectedRecordId(item.id);
        navigate(`/admin/form-reporting-category/${item.id}`);
        setMode('edit');
    }
    const handleRowSaved = (item: FormResultType) => {
        setErrorMessage(`${item.name} saved!`);
        setFeedbackStatus(FeedbackType.SaveSuccess);
    }
    const handleNewItemClicked = () => {
        handleRowClicked(createNewFormResult());
    }
    const handleEditorBackClicked = () => {
        setSelectedRecordId(0);
        navigate(`/admin/form-reporting-category`);
        setMode('list');
    }

    return <>
        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel header={defaultTitle}>
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    {mode === 'list' && <><FormResultList onRowClicked={handleRowClicked} onNew={handleNewItemClicked} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} /></>}
                    {mode === 'edit' && <><FormResultEditor selectedItemId={selectedRecordId} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} onSave={handleRowSaved} onBackClicked={handleEditorBackClicked} /></>}
                </Panel>
            </Grid>
        </Grid>
    </>
}