import { useEffect, useState } from "react"
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes"
import { FormDataCaptureReportType, FormSubmissionType } from "../../../interfaces/system/formType"
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter"
import { Grid } from "@mui/material"
import { PagedTab, PagedTabs } from "../../core/pagedTabs"
import React from "react"
import { ExcelExportSheet, cleanExcelSheetName, exportToExcel } from "../../../services/reporting/excel-export-service"
import { formatDateTime } from "../../../extensions/formatter"

export interface FormDataCaptureCriteriaProps {
    serviceIds: number[]
    fromDate: Date
    toDate: Date
}

interface FormDataCaptureReportProps {
    areaHierarchy: AreaHierarchyType
    onFetchData: (criteria: FormDataCaptureCriteriaProps) => void
    criteria?: BasicReportFilterCriteria
    data?: FormDataCaptureReportType[]
    defaultFilterCriteria?: BasicReportCriteria
}

export function FormDataCaptureReport(props: FormDataCaptureReportProps) {
    const [data, setData] = useState(props.data);
    const [searchTriggered, setSearchTriggered] = useState(false);

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        props.onFetchData({ serviceIds: criteria.selectedServiceIds.map(m => m.id), fromDate: criteria.fromDate ?? new Date(), toDate: criteria.toDate ?? new Date() })
        setData(undefined)
        setSearchTriggered(true);
    }

    const generateTable = (submissions: FormSubmissionType[], serviceName: string) => {
        const exportSheetName = cleanExcelSheetName(serviceName);

        return <table className={"table condensed table-highlight bordered table-striped"} data-export-sheet-name={`${exportSheetName}`} data-export-sheet-title={exportSheetName}>
            <thead>
                <tr>
                    <th className="bold text-left">Submitted</th>
                    <th className="bold text-left">Submitted By</th>
                    {React.Children.toArray(submissions[0].fields.map(field => {
                        return <th className="bold text-left">{field.formField?.name}</th>
                    }))}
                </tr>
            </thead>
            <tbody>
                {React.Children.toArray(submissions.map(submission => {
                    return <tr>
                        <td><small>{formatDateTime(submission.createdOn)}</small></td>
                        <td><small>{submission.createdBy?.fullName}</small></td>
                        {React.Children.toArray(submission.fields.map(field => {
                            return <td>{field.formFieldOption?.name ? field.formFieldOption?.name : field.value}</td>
                        }))}
                    </tr>
                }))}
            </tbody>
        </table>
    }

    const generateTabs = (): PagedTab[] => {
        let tabs: PagedTab[] = [];
        if (data) {
            data.forEach((submission, index) => {

                const parentName = submission.service.name;
                const tabContent = <div>
                    <h3 className="tab-content-title"><strong>{parentName}</strong></h3>
                    {generateTable(submission.submissions, parentName)}
                </div>
                let tab: PagedTab = { title: `${parentName} - ${index + 1} of ${data.length}`, children: tabContent };
                tabs.push(tab);
            })
        }
        return tabs;
    }

    const handleExport = () => {
        const title = 'Form Data Capture Report';
        const reportTables = document.querySelectorAll<HTMLTableElement>("table[data-export-sheet-name]");
        if ((reportTables?.length ?? 0) > 0) {
            let sheets: ExcelExportSheet[] = [];
            reportTables.forEach((table, index) => {
                sheets.push({
                    title: table.dataset.exportSheetTitle ?? `Form Data ${index}`,
                    name: table.dataset.exportSheetName ?? `Form Data ${index}`,
                    elements: [
                        { table: table },
                    ]
                });
            });
            exportToExcel({
                fileName: title,
                defaultHeaderWidth: 20,
                sheets: sheets
            });
        }
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}
                validateServiceSelections={true}
                onExport={handleExport} />

        </Grid>
        {data && <Grid item xs={12}>
            <PagedTabs tabs={generateTabs()} activeIndex={0} />
            {searchTriggered && (data?.length ?? 0) <= 0 &&
                <p className="alert alert-warning"> Search criteria returned no data, try expanding your search criteria.</p>
            }
        </Grid>
        }

    </Grid>
}
