import {FormValidation, FormValidationField} from '../extensions/formValidation';
import {ValidatorType} from './ValidatorType'

export default class DomainEntityFormValidator implements ValidatorType
{
    constructor (idCanBeNull?:boolean){
        if(idCanBeNull) this.idCanBeNull = true

    }
    formValidator: FormValidation= new FormValidation([])
    id: FormValidationField= new FormValidationField('','')
    name: FormValidationField= new FormValidationField('','')
    idCanBeNull: boolean = false


     initialiseValidationFields = (objectValues: any) => {
        this.formValidator = new FormValidation([])
        this.name = this.formValidator.createTextField(objectValues.name)
        
        if(!this.idCanBeNull){
            this.id = this.formValidator.createPostiveNumericField(objectValues.id)
            this.id.minValue=1 
        }
         
     }
    
 validate = (validatorObjectValues: any): boolean => {
    
     this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid()
    return isValid
 }
}

