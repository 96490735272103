import {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm";
import {Grid} from '@mui/material';
import Loader from "../../../core/loading";
import TickBox from "../../../core/tickBox";
import DomainEntityFormValidator from "../../../../validators/domain-entity-validator";
import { AutomatedReportScheduleType, AutomatedReportType, createNewAutomatedReportSchedule } from "../../../../interfaces/system/systemTypes"
import Panel from "../../../core/panel";
import DateTimePicker from "../../../core/dateTimePicker";
import ActionButton from "../../../core/actionButton";

interface AutomatedReportScheduleEditProps {
    automatedReport?:AutomatedReportType
     onUpdated: (schedules: AutomatedReportScheduleType[]) => void 
  }

export default function AutomatedReportScheduleEdit(props: AutomatedReportScheduleEditProps) {
  const [validator] = useState(new DomainEntityFormValidator())
  const {
    objectValues, setObjectValues
  }
   = useForm({initialObjectValues: props.automatedReport, validator: validator})
 

  useEffect(() => {
    
    const ar = objectValues
    if(!ar?.schedules || ar?.schedules.length===0)
      ar.schedules =[createNewAutomatedReportSchedule()]

    setObjectValues(ar)

},[props.automatedReport])

useEffect(() => {
  props.onUpdated(objectValues.schedules)
},[objectValues])

 

const handleAddSchedule = (index:number) =>{
  const schedule = [...objectValues.schedules]

  // schedule.splice(index, 0,createNewAutomatedReportSchedule())
  schedule.push(createNewAutomatedReportSchedule())
  setObjectValues({objectValues, schedules:[...schedule]})
}

const handleRemovechedule = (index:number) =>{
  const schedule = [...objectValues.schedules]

  schedule.splice(index, 1)
  setObjectValues({objectValues, schedules:[...schedule]})
}
const setScheduleValues = (e:any,i:number)=>{
  const schedule = [...objectValues.schedules]
  
  if(e.target?.type === 'checkbox')
    schedule[i][e.target.id]  = (e.target.checked as boolean)
  else
    schedule[i][e.target.id] = e.target.value

    setObjectValues({objectValues, schedules:[...schedule]})
}
 
   
if(!objectValues)
        return <Loader loading={true} />
        
  return <>
        {objectValues?.schedules?.map((f:AutomatedReportScheduleType,i:number) => ( 
      <Grid container justifyContent="center">    
        <Panel title={`Schedule ${i+1}`}>
          <Grid container spacing={2} >
              <Grid item xs={3}><TickBox id="monday" label="Monday" checked={f.monday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="tuesday" label="Tuesday" checked={f.tuesday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="wednesday" label="Wednesday" checked={f.wednesday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="thursday" label="Thursday" checked={f.thursday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="friday" label="Friday" checked={f.friday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="saturday" label="Saturday" checked={f.saturday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid item xs={3}><TickBox id="sunday" label="Sunday" checked={f.sunday} onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
              <Grid container spacing={2} style={{padding:'15px 5px'}}>
                  <Grid item xs={4}> <DateTimePicker id="nextOccurrsOn" label="Occurs From" showTimePicker={true} value={f.nextOccurrsOn }onChange={(e) => {setScheduleValues(e,i)}}/></Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={2}>
                    <ActionButton severity="danger" variant="icon" disabled={i<1} label="Remove" onClick={()=>{handleRemovechedule(i)}}/>
                  </Grid>
                  <Grid item xs={10}></Grid>
                  <Grid item xs={2}><ActionButton severity="success" variant="icon" label="Add" onClick={()=>{handleAddSchedule(i)}}/></Grid>
                </Grid>
            </Grid>
          </Panel>
        </Grid>
        ))}
        
      
    </>
}
