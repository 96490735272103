import React, {useEffect, useState}  from "react";
import { Message } from 'primereact/message';



interface ErrorTextParams {
    message?: string
    show: boolean
    severity : 'success' | 'info' | 'warn' | 'error';
    errorObject?:any
  }

  const FeedBackText:  React.FC<ErrorTextParams> = (params) => {

    useEffect(() => {
      const message = JSON.stringify(params.errorObject)
      setMessage(message?.replaceAll('"',''))
    },[params.errorObject])

     useEffect(() => {
       if(params.message)
        setMessage(params.message)
     },[params.message]);

    const  [message, setMessage] = useState(params.message);

    if(params.show && message)
      return  <Message style={{display:'block'}} severity={params.severity} text={message}  />
    else
        return <i></i>
}

export default FeedBackText;