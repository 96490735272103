import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../extensions/formatter";
import { CapacityGridKeyFieldSummaryType } from "../../interfaces/system/capacityGridType";
import { fetchCapacityGridKeyFieldSummaryForServiceId } from "../../services/capacity-grid-service";
import ActionButton from "../core/actionButton";

export interface CapacityGridKeyFieldSummaryByServiceIdProps {
    serviceId: number,
    onServiceSelected: (serviceId:number) => void
}
export function CapacityGridKeyFieldSummaryByServiceId(props: CapacityGridKeyFieldSummaryByServiceIdProps) {
    const [serviceId, setServiceId] = useState(props.serviceId);
    const [summaryData, setSummaryData] = useState<CapacityGridKeyFieldSummaryType[]>();

    useEffect(() => {
        if (props.serviceId) {
            setServiceId(props.serviceId);
            presentData();
        }
    }, [props.serviceId]);

    const presentData = async () => {
        const response = await fetchCapacityGridKeyFieldSummaryForServiceId(serviceId);
        if (response && response.success && response.result) {
            setSummaryData(response.result);
            return;
        }
        console.error(response.error);
    }

    if (!summaryData || !serviceId) {
        return <></>
    }

    return <>
        <h3>Current capacity for service divisions:</h3>
        <table className={`condensed table-highlight`}>
            <thead>
                <tr>
                    <th className="text-left">Service</th>
                    <th className="text-left">Current Status</th>
                    <th className="text-left">Last Updated</th>
                    <th className="text-left">Updated By</th>
                    <th style={{width:'40px'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {React.Children.toArray(summaryData.map(record => {
                    return <>
                        <tr>
                            <td>{record.serviceName}</td>
                            <td>{record.capacityGridKeyFieldLabel}: <strong>{record.capacityGridKeyFieldValue}</strong></td>
                            <td>{formatDateTime(record.capacityGridSubmittedOn)}</td>
                            <td>{record.capacityGridSubmittedByName}</td>
                            <td className="text-center">
                                <ActionButton label="View service" icon="pi pi-chevron-right" onClick={() => { props.onServiceSelected(record.serviceId) } } severity="secondary" size="small" variant="icon-only"></ActionButton>
                            </td>
                        </tr>
                    </>
                }))}

            </tbody>
        </table>
    </>
}