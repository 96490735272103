import React,{useState,useEffect} from "react";
import {Grid} from '@mui/material'
import {FormFooterSubmissionFieldType, FormSubmissionType, FormFooterFieldTypeEnum} from "../../interfaces/system/formType"
import TextBox from "../core/textBox";
interface FormSubmissionFooterProps {
    form?:FormSubmissionType
    onUpdate:(fields: FormFooterSubmissionFieldType[], valid:boolean) => void
    submitted:number
  }

  interface FormSubmissionFooterFieldType {
    field?:FormFooterSubmissionFieldType
    isIncomplete?:boolean
  }
  interface FormSubmissionFooterStandardFieldsType {
    comments:FormSubmissionFooterFieldType
    contactName:FormSubmissionFooterFieldType
    contactNumber:FormSubmissionFooterFieldType
    // aEAdmissions:FormSubmissionFooterFieldType
    // medAdmissions:FormSubmissionFooterFieldType
    // allAdmissions:FormSubmissionFooterFieldType
    // discharges:FormSubmissionFooterFieldType
  }

  
const FormSubmissionFooter:  React.FC<FormSubmissionFooterProps> = (props) => {
  const [form, setForm] = useState<FormSubmissionType>();
  const [basicFooter, setBasicFooter] = useState<FormSubmissionFooterStandardFieldsType>();
  // const [dailyActivityDisabled,setDailyActivityDisabled] =useState(true)
  const [submitted, setSubmitted] = useState(props.submitted)

  useEffect(() => {
    const footer = {
      comments:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.Comments),isIncomplete:false },
      contactName:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.ContactName),isIncomplete:false },
      contactNumber:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.ContactNumber),isIncomplete:false },
      // aEAdmissions:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.AEAdmissions),isIncomplete:false },
      // medAdmissions:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.MEDAdmissions),isIncomplete:false },
      // allAdmissions:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.AllAdmissions),isIncomplete:false },
      // discharges:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.Discharges),isIncomplete:false },
    }
    


    setBasicFooter(footer)
    setForm(props.form)
    // setDailyActivityDisabled((new Date().getHours() >=10) && (footer?.aEAdmissions?.field?.value !== undefined && footer?.aEAdmissions?.field?.value !== ''))
    if(props.submitted>0)
    validate()
  }
  ,[props.form])
  
  useEffect(() => {
    setSubmitted(props.submitted)
    validate()
  }
  ,[props.submitted])

  const validate = ()=>{
    if(!basicFooter) return true
    basicFooter.contactName.isIncomplete = basicFooter?.contactName.field?.value == undefined || basicFooter?.contactName.field?.value===''
    basicFooter.contactNumber.isIncomplete = basicFooter?.contactNumber.field?.value == undefined || basicFooter?.contactNumber.field?.value===''
    
    // basicFooter.aEAdmissions.isIncomplete = basicFooter?.aEAdmissions.field?.value == undefined || basicFooter?.aEAdmissions.field?.value===''
    // basicFooter.medAdmissions.isIncomplete = basicFooter?.medAdmissions.field?.value == undefined || basicFooter?.medAdmissions.field?.value===''
    // basicFooter.allAdmissions.isIncomplete = basicFooter?.allAdmissions.field?.value == undefined || basicFooter?.allAdmissions.field?.value===''
    // basicFooter.discharges.isIncomplete = basicFooter?.discharges.field?.value == undefined || basicFooter?.discharges.field?.value===''

    // const footerFields = [basicFooter.aEAdmissions, basicFooter.allAdmissions, basicFooter.comments, basicFooter.contactName, basicFooter.contactNumber,
    //   basicFooter.discharges, basicFooter.medAdmissions]

    const footerFields = [basicFooter.comments, basicFooter.contactName, basicFooter.contactNumber]


    const footerSubmissionFields: FormFooterSubmissionFieldType[]=[]

    footerFields.forEach(f=> {
      footerSubmissionFields.push({fieldId : f.field?.fieldId ||0, value : f.field?.value, fieldTypeId:f.field?.fieldType?.id})
    })

      setBasicFooter({...basicFooter})
      props.onUpdate(footerSubmissionFields,
        (basicFooter.contactName?.field?.isValid &&
        basicFooter.contactNumber?.field?.isValid
        //  &&
        // basicFooter.aEAdmissions?.field?.isValid &&
        // basicFooter.medAdmissions?.field?.isValid &&
        // basicFooter.allAdmissions?.field?.isValid &&
        // basicFooter.discharges?.field?.isValid
        )||false)
  }

  const handleFieldUpdated = (field?:FormSubmissionFooterFieldType, value?:string) => {
    if(!field?.field) return
    
    const b = basicFooter
    field.field.value = value;
    field.isIncomplete = value===undefined || value===''
    field.field.isValid =!field.isIncomplete;

    if(b)
      setBasicFooter({...b})

      validate()
  }
    return <>
    <Grid container spacing={1} >
      <Grid item xs={6}>
        <Grid container spacing={1} >
          <Grid item xs={12}>
              <TextBox value={basicFooter?.comments?.field?.value} label="Comments" rows={5} onChange={(e)=> {handleFieldUpdated(basicFooter?.comments, e.target.value)}}/>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid item xs={6}>
      <Grid container spacing={1} >
      <Grid item xs={11}>
              <TextBox value={basicFooter?.contactName?.field?.value} label="On Call Manager" error={(submitted>0 && basicFooter?.contactName?.isIncomplete)} onChange={(e)=> {handleFieldUpdated(basicFooter?.contactName, e.target.value)}}/>
          </Grid>
          <Grid item xs={11}>
          <TextBox value={basicFooter?.contactNumber?.field?.value} label="Contact Number" error={submitted>0&&basicFooter?.contactNumber?.isIncomplete} onChange={(e)=> {handleFieldUpdated(basicFooter?.contactNumber, e.target.value)}}/>
          </Grid>
              {/* <Grid item xs={12}><strong>Daily Activity (Yesterday)</strong></Grid>
                <Grid item xs={3}>
                  <TextBox disabled={dailyActivityDisabled} value={basicFooter?.aEAdmissions?.field?.value} label="A&E Admissions" type="number"  error={submitted>0&&basicFooter?.aEAdmissions?.isIncomplete}  onChange={(e)=> {handleFieldUpdated(basicFooter?.aEAdmissions, e.target.value)}}/>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                  <TextBox disabled={dailyActivityDisabled} value={basicFooter?.medAdmissions?.field?.value} label="MED Admissions" type="number"  error={submitted>0&&basicFooter?.medAdmissions?.isIncomplete}  onChange={(e)=> {handleFieldUpdated(basicFooter?.medAdmissions, e.target.value)}}/>
                </Grid>
                <Grid item xs={9}></Grid>
                
                <Grid item xs={3}>
                  <TextBox disabled={dailyActivityDisabled} value={basicFooter?.allAdmissions?.field?.value} label="All Admissions" type="number"  error={submitted>0&&basicFooter?.allAdmissions?.isIncomplete}  onChange={(e)=> {handleFieldUpdated(basicFooter?.allAdmissions, e.target.value)}}/>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                  <TextBox disabled={dailyActivityDisabled} value={basicFooter?.discharges?.field?.value} label="Discharges" type="number"  error={submitted>0&&basicFooter?.discharges?.isIncomplete}  onChange={(e)=> {handleFieldUpdated(basicFooter?.discharges, e.target.value)}}/>
                </Grid>
                <Grid item xs={9}></Grid> */}
              </Grid>
          </Grid>
      </Grid>
    </>;
}


export default FormSubmissionFooter;

