import React, {useState, useEffect} from 'react'
import { AreaHierarchyChild, AreaHierarchyType } from '../../interfaces/system/areaTypes';
import { Tree, TreeSelectionEvent } from 'primereact/tree';
import { ScrollPanel } from 'primereact/scrollpanel';
import regionIcon from '../../assets/images/icons/globe.svg'
import hospitalIcon from '../../assets/images/icons/hospital.svg'
import TextBox from './textBox';
import Dropdown from './dropdown';

export interface SelectedServiceType {
  id:number
}

interface ServiceCriteriaProps{
  showOnlyServicesWithFormAssociated?:boolean
  showOnlyServicesWithGridAssociated?:boolean
  showOnlyServiceCategoryId?:number
  hideCategoryFilter?:boolean
}
interface ServiceSelectorProps {
    selections?:SelectedServiceType[]
    onChange: (selected:SelectedServiceType[])=> void
    areaHierarchy?:AreaHierarchyType
    restrictAreaId?:number
    criteria?: ServiceCriteriaProps,
    singleSelection?: boolean
  }


  export default function ServiceSelector(props:ServiceSelectorProps) {
  const  [loading, setLoading] = useState(true);
      const [singleSelection] = useState(props.singleSelection ?? false);
  const [restrictAreaId, setRestrictAreaId] = useState(props.restrictAreaId)
  const  [nodes, setNodes] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>(null);
  const [filterText, setFilterText] = useState('');

   
  useEffect(() => {
    setRestrictAreaId(props.restrictAreaId)
  },[props.restrictAreaId])
  
  useEffect(() => {
    present()
  },[props.areaHierarchy])

  useEffect(() => {

    const selKey:any = {}
    props.selections?.forEach(f=> {selKey[-f.id] = {checked:true};
                            selectParentNode(-f.id,selKey)})
    




    setSelectedKeys(selKey)
    
  },[props.selections])
  useEffect(() => {
    if(props.areaHierarchy && !selectedKeys)
      present()
  },[selectedKeys])
  const selectParentNode = (id:number, keys:any) =>{
    const node = getNodeByKey(nodes, id.toString())
    
    const parentKey = node?.parentAreaId ?? node?.parentNode?.key
    
    if(!parentKey) return
    
    keys[parentKey] = {checked:false,partialChecked:true}

    selectParentNode(parentKey,keys)
  }
  const present = async () => {
    
    const areas= props.areaHierarchy
      
      if(areas)
        populateByRegion(areas)
      
      setLoading(false)
      if(props.criteria?.showOnlyServiceCategoryId)
        handleServiceCategoryFilter(props.criteria?.showOnlyServiceCategoryId.toString())
  }
 

  const populateByRegion = (areas:AreaHierarchyType) =>{
        let treeNodes:any = []
    
        areas.areas.forEach(area => {
          var key = `${area.id}`
          
          var node = {key:key, label:area.name, icon1:'pi pi-globe', iconAA:regionIcon, children:[],expanded:selectedKeys && selectedKeys[key]?.partialChecked,filterValue:`||${area.name}||`}
          populateChildNodes(area, node)
          if(node.children && node.children.length>0)
            treeNodes.push(node)
          
        })

        if(restrictAreaId){
          const a = getNodeByKey(treeNodes,restrictAreaId.toString())
          if(a){
            a.expanded=true
            treeNodes = []
            treeNodes.push(a)
          }
        }

        setNodes(treeNodes)
  }

 
  const populateChildNodes = (area:AreaHierarchyChild, parentNode:any)  =>{

    let services = area.services.filter(f=>f.hasPermission && !f.parentId)

    if(props.criteria){
      if(props.criteria.showOnlyServicesWithFormAssociated){
        services = services.filter(f=> f.formId)
      }
      if(props.criteria.showOnlyServicesWithGridAssociated){
        services = services.filter(f=> f.capacityGridId)
      }
    }

    services.forEach(c => {
      const key = `${-c.id}`
      
      var node = {key:key, label:c.name, icon1:'pi pi-box', iconAA:hospitalIcon,parentNode:parentNode,parentAreaId:area.id, canSelect:true,
      filterValue:`||${c.name}||${c.categoryId}||`}
      parentNode.children.push(node)
    });

    area.children.forEach(c => {
      var node = {key:`${c.id}`, label:c.name, icon1:'pi pi-globe', leaf:false, children:[], iconAA:regionIcon,parentNode:parentNode,canSelect:true,expanded:selectedKeys && selectedKeys[`${c.id}`]?.partialChecked,
      filterValue:`||${c.name}||`}
      
        populateChildNodes(c, node)
        if((node.children && node.children.length>0))
          parentNode.children.push(node)
    });
    return parentNode
  } 
  const getServicesFromArea = (area:AreaHierarchyChild) :[] =>{
    let treeNodes:any = []
    area.services.forEach(c => {
      var node = {key:`${-c.id}`, label:c.name, icon1:'pi pi-box', iconAA:hospitalIcon}
      treeNodes.push(node)
    });

    area.children.forEach(c => {
      treeNodes = treeNodes.concat(getServicesFromArea(c))
    });

    return treeNodes
  } 
  const getNodeByKey: any = (nodeList:any[],key:string) =>{
    if(!nodeList) {
      return undefined
    }
    let nodeFound = undefined
    nodeList.forEach(f=> {
      
      if((f.key as string) === (key as string)){
        nodeFound = f;
        return f
      }
      var childNode = getNodeByKey(f.children, key)
      if(childNode){
        nodeFound = childNode;
        return childNode
      }
    })
    return nodeFound
  }

  const setChildNodesPermission = (nodeList:any[],userRole:string) =>{
    if(!nodeList) {
      return undefined
    }
    nodeList.forEach(f=> {
      f.userRole = userRole
      setChildNodesPermission(f.children, userRole)
    })
  }

  const inputEl = React.useRef(null);

  const handleTextFilter = (s:string)=>{
    (inputEl as any).current.filter(s)
    setFilterText(s)
  }
  const handleServiceCategoryFilter = (s:string)=>{
    setSelectedKeys(undefined)
    if((inputEl as any).current){
      if(!s){
        (inputEl as any).current.filter(undefined)
      }
      else
        (inputEl as any).current.filter(s === '' ? s :`||${s}||`)
    }
  }
      const handleNodeSelect = (e: any) => {
          if (singleSelection) {
              const event = e as TreeSelectionEvent;
              const selected = { id: parseInt((event.value as string)?.replace('-', '')) }
              if (e.value && selected) {
                  //setSelectedKeys([selected])
                  setSelectedKeys(e.value)
                  props.onChange([selected])
              }
          } else {
              const a: any[] = e.value

              const keys: SelectedServiceType[] = []
              Object.keys(a).forEach(id => {
                let addNode = true
                  if (parseInt(id) < 0 && addNode)
                      keys.push({ id: parseInt(id.replace('-', '')) })
                      
              });
              setSelectedKeys(a)
              props.onChange(keys)
          }
    }

    if(!nodes || nodes?.length<1) return <><p>No services to select</p></>

    return <ScrollPanel style={{ width:  '100%', height: '35vh' }}>
        <Tree ref={inputEl} id="aa" value={nodes} loading={loading} selectionMode={ singleSelection ? "single":"checkbox" } selectionKeys={selectedKeys}
               onSelectionChange={handleNodeSelect}
               filterTemplate={<div>
                <div style={{width: props.criteria?.hideCategoryFilter? '90%': '45%',float:'left',paddingTop:3}}>
                  <TextBox onChange={(e:any)=>handleTextFilter(e.target.value)} label="Service or Area name filter"/>
                </div>
              {!props.criteria?.hideCategoryFilter && <div style={{width:'45%',float:'left',marginLeft:'5%',paddingTop:3}}>
                <Dropdown optionName="serviceCategories" label="Category filter" value={props.criteria?.showOnlyServiceCategoryId} includeBlankOption={true} onSelection={(e:any)=>handleServiceCategoryFilter(e.target.value)} />
              </div>
               }
              <div style={{clear:'both'}}></div>
              </div>}
              filter  filterPlaceholder="Filter by region or service" filterBy='filterValue' filterMode='lenient'/>
              &nbsp;
            </ScrollPanel>
      

}



