import React, { useEffect } from 'react';
import Loader from './loading'
import { Dialog as PDialog } from 'primereact/dialog';
import ActionButton from './actionButton'
import { Button } from 'primereact/button';


interface ISimpleDialogParams {
  show: boolean;
  onClose?: (value: string) => void
  onOk?: (value: string) => void
  title?: string
  subTitle?: string
  defaultValue?: string
  yesno?: boolean
  fullScreen?: boolean
  yesCaption?: string
  saveCaption?: string
  noCaption?: string
  children?: React.ReactNode
  loading?: boolean
  success?: boolean
  successMessage?: string
  successTitle?: string
  closeOnMaskClick?:boolean
}

const SimpleDialog: React.FC<ISimpleDialogParams> = params => {
  const [open, setOpen] = React.useState(params.show);
  const [title] = React.useState(params.title || 'Help');
  const [value, setValue] = React.useState(params.defaultValue);
  const [loading, setLoading] = React.useState(params.loading);
  const [showSuccessConfirmation, setShowSuccessConfirmation] = React.useState(false);
  const [yesCaption] = React.useState(params.yesCaption || 'Yes');
  const [noCaption] = React.useState(params.noCaption || 'No');
  const [closeOnMaskClick] = React.useState(params.closeOnMaskClick || true);

  useEffect(() => {
    setValue(params.defaultValue)
    setOpen(params.show)
    setShowSuccessConfirmation(false)
  }, [params.show])

  useEffect(() => {
    setLoading(params.loading)
  }, [params.loading])
  useEffect(() => {
    setShowSuccessConfirmation(params.success || false)
  }, [params.success])

  const handleConfirm = () => {

    if (params.onOk) {
      params.onOk('')
      return
    }
    setOpen(false)
    if (params.onClose) {
      params.onClose(params.yesno ? "true" : value || 'false')
    }

  }
  const handleCancel = () => {
    setOpen(false);
    if (params.onClose) {
      params.onClose(params.yesno ? "false" : value || 'false')
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    if (params.onClose)
      params.onClose('save')
  };

  const handleClose = () => {
    setOpen(false);
    if (params.onClose)
      params.onClose('')
  }
  const handleOk = () => {

    if (params.onOk)
      params.onOk('')
  };

  const renderFooter = () => {
    return (
      <div>
        {/* <PButton label="No" icon="pi pi-times" onClick={() => setOpen(false)} className="p-button-text" /> */}
        <ActionButton label="Close" icon="pi pi-check" onClick={handleClose} severity="success" />
      </div>
    );
  }
  const renderConfirmationFooter = () => {
    return (
      <div>
        {/* <PButton label="No" icon="pi pi-times" onClick={() => setOpen(false)} className="p-button-text" /> */}
        <ActionButton label={noCaption ?? "No"} variant='text' icon="pi pi-times" onClick={handleClose} severity="danger" />
        <ActionButton label={yesCaption ?? "Yes"} icon="pi pi-check" onClick={handleConfirm} severity="success" />

      </div>
    );
  }

  if (!params.show)
    return <></>

  //       if(params.fullScreen)
  //         return <div>
  // <Dialog
  //         fullScreen
  //         open={open}
  //         onClose={handleClose}
  //       >
  //         <AppBar>
  //           <Toolbar>
  //             <IconButton
  //               edge="start"
  //               color="inherit"
  //               onClick={handleClose}
  //               aria-label="close"
  //             >
  //               <CloseIcon />
  //             </IconButton>
  //             <Typography style={{ flex: 1 }} variant="h6" component="div">
  //               {params.title}
  //             </Typography>
  //             <Button autoFocus color="inherit" onClick={handleOk}>
  //               {saveCaption}
  //             </Button>
  //           </Toolbar>
  //         </AppBar>
  //         <List>
  //           <ListItem button>
  //             <ListItemText primary={params.title} secondary="Titania" />
  //           </ListItem>
  //           <Divider />
  //           {params.children}

  //         </List>

  //       </Dialog>

  //         </div>

  const buttons = <><Button onClick={handleConfirm} color="primary">
    {params.yesno ? yesCaption : 'ok'}
  </Button>
    <Button onClick={handleCancel} color="primary" >
      {params.yesno ? noCaption : 'Cancel'}
    </Button></>
  const loader = <Loader loading={loading || false} ></Loader>



  if (params.yesno && !showSuccessConfirmation)
    return (

        <PDialog header={title} visible={open} style={{ width: '30vw' }} footer={renderConfirmationFooter()} onHide={handleClose} dismissableMask={closeOnMaskClick}>
        {params.children}
      </PDialog>

      // <div>
      //   <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title" >
      //     <DialogTitle id="form-dialog-title">{params.title}</DialogTitle>
      //     <DialogContent>
      //       <DialogContentText>
      //           <div style={{width:'550px'}}>
      //         {params.subTitle}
      //         </div>
      //       </DialogContentText>
      //       {!params.yesno && <TextBox rows={5} onChange={(e)=>{setValue(e.target.value)}} focus={true}></TextBox>}
      //       {params.children}
      //     </DialogContent>
      //     <DialogActions>

      //     {loading ?  loader : buttons}

      //     </DialogActions>
      //   </Dialog>
      // </div>
    )



    return <PDialog header={title} visible={open} style={{ width: '50vw' }} footer={renderFooter()} onHide={handleClose} dismissableMask={closeOnMaskClick}>
    {params.children}
  </PDialog>

  // return (
  //   <Dialog open={true} onClose={handleCancel} aria-labelledby="form-dialog-title" >
  //       <DialogTitle id="form-dialog-title">{params.title}</DialogTitle>
  //       <DialogContent>
  //         <DialogContentText>
  //             <div style={{width:'500px'}}>
  //           {params.subTitle}
  //           </div>
  //         </DialogContentText>
  //             <Grid item xs={12} lg={12}>
  //               {/* <GroupBox title="Check your email" actions={<ActionButton>Back to Login</ActionButton>} iconName={"send"}> */}
  //               <GroupBox loading={loading} title={params.successTitle||''} iconName={"thumb_up_alt"}>
  //                   <Grid container spacing={3} justifyContent = "center">
  //                       <Grid item xs={12} lg={12}>
  //                           <div style={{textAlign:'center'}}> {params.successMessage||''}</div>
  //                       </Grid>

  //                   </Grid>

  //               </GroupBox>

  //           </Grid>
  //       </DialogContent>
  //       <DialogActions>

  //       <Button onClick={handleCancel} color="primary">
  //         Close
  //     </Button>

  //       </DialogActions>
  //     </Dialog>
  // )
}

export default SimpleDialog