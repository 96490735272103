import React from 'react';
import './index.scss';
import App from './App';
 import {theme} from './components/app/materialTheme'
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';

 

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>
  </React.Fragment>
);


 
 