import React, { useState, useEffect} from "react";
import {Grid} from '@mui/material';
import UserRegistrationSteps from './registrationSteps';
import { FormValidation, FormValidationField } from "../../extensions/formValidation";
import useForm from "../../hooks/useForm";
import { INVALID_LOGIN_MESSAGE, INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE, NO_VERIFICATION_TOKEN_ERROR_MESSAGE } from "../../containers/authenticationContainer";
import { login, verifyUserByToken } from "../../services/userService";
import { UserLoginType } from "../../interfaces/user/iUser";
import Feedback, { FeedbackType } from "../core/feedback";
import ActionButton from "../core/actionButton";
import GroupBox from "../core/groupBox";
import TextBox from "../core/textBox";
import Loader from "../core/loading";
import Error from "../core/error";

export interface PropsType {
    token:string
    onError?: (errorMessage:string) => void
    onSuccess?: () => void
  }

const VerifyUser: React.FC<PropsType> = (props) => {

     const  [loading, setLoading] = useState(false);
     const  [validToken, setValidToken] = useState(false);
     const  [email, setEmail] = useState('');
     const  [password, setPassword] = useState('');
     const  [formValidation, setFormValidation] = useState(new FormValidation([]));
     const [errorMessage, setErrorMessage] = useState<string>('')
     const {
        feedbackStatus, setFeedbackStatus
      }
       = useForm({initialObjectValues:{}})

     useEffect(() => {
        userVerification()
      },[props.token])

      const userVerification  = async () => {
        if(!props.token || props.token===''){
            loginFailure(NO_VERIFICATION_TOKEN_ERROR_MESSAGE)
            return
        }

        setLoading(true)
        const validToken = await verifyUserByToken(props.token)

        if(validToken.success)
            verificationSuccess()
        else
            loginFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)
      }
      const initialiseValidationFields = (e:string, p:string) => {
        
        const formFields = [new FormValidationField(e, 'Please enter a valid email address'),
                            new FormValidationField(p, 'Please enter a password')];

                            // formFields[0].validationType = ValidationType.Email;
                            
        formValidation.validationFields = formFields;

        setFormValidation(formValidation);
        return formValidation;
     }
      const handleLogin = async (e:string, p:string) => {
        
        try {
            
         initialiseValidationFields(e,p)
         var isValid = formValidation.isValid();
         
         if(isValid){
            setLoading(true);
        
            const token = await login(new UserLoginType(e,p, props.token))
            
            if(token.success){
                loginSuccess()
                return
            }
            
            loginFailure(INVALID_LOGIN_MESSAGE)  
        }
        else{
            loginFailure(INVALID_LOGIN_MESSAGE)
        }
            
    }catch (error) {loginFailure(INVALID_LOGIN_MESSAGE)
         }
     }
     
     
     const verificationSuccess = () =>{
        setValidToken(true)
        setLoading(false)
     }

     const loginSuccess = () =>{
        setValidToken(true)
        setFeedbackStatus(FeedbackType.hide)
        if(props.onSuccess)
            props.onSuccess()
     }

     const loginFailure = (error:string) =>{
        setLoading(false);
        setFeedbackStatus(FeedbackType.Error)
        setErrorMessage(error)
    }
     
    const cardActions= <Grid container spacing={3} justifyContent = "center">
    <Grid container spacing={1}  justifyContent="center"><ActionButton icon="pi pi-check-circle" severity="success"  onClick={()=> handleLogin(email, password)} label="Log in"></ActionButton></Grid>
    <Grid container spacing={0}  justifyContent="center">&nbsp;</Grid>
    <Grid container spacing={0}  justifyContent="center">
                   </Grid>
</Grid>

    if(loading)
        return <Loader loading={loading}></Loader>

     if(!validToken)
        return <Grid container spacing={3} justifyContent="center">
     <Grid item lg={9} xs={12}>
         <Error message={errorMessage}></Error>
                    </Grid>
        </Grid>
    
    
    return <>
        <Grid container justifyContent = "center">
            <Grid item xs={10} lg={6}>
            <Feedback type={feedbackStatus} message={errorMessage}></Feedback>   
            <UserRegistrationSteps step={1}/>
                <GroupBox title="You have now verified your account" iconName={"thumb_up_alt"} actions={cardActions}>
                    <Grid container spacing={3} justifyContent = "center">
                        <Grid item xs={12} lg={12}>
                            <p>To continue please enter your login details below</p>
                        </Grid>
                        <Grid container spacing={1} justifyContent = "center" >
                                <Grid item xs={8} ><TextBox id="outlined-username" focus={true} label="Email Address"   onChange={(e) => {setEmail(e.target.value||'')}} validationField={formValidation.validationFields[0]} onEnterKey={(e) => {setEmail(e); handleLogin(e,password)}} value={email}/></Grid>
                                <Grid item xs={8} ><TextBox id="outlined-password" label="Password" type={'password'} onChange={(e) =>  {setPassword(e.target.value||'')}}  validationField={formValidation.validationFields[1]} onEnterKey={(e) => {setPassword(e); handleLogin(email,e)}} /></Grid>
                            </Grid>
                    </Grid>
                </GroupBox>
            </Grid>
        </Grid>

    </>          
}


export default VerifyUser;
