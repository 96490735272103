import React, {useEffect} from 'react'
import hospitalIcon from '../../assets/images/icons/hospital.svg'
import { AutoComplete } from 'primereact/autocomplete';
import { FormValidationField } from '../../extensions/formValidation'
import { DomainEntityType } from '../../interfaces/domain/iDomainEntity';
import { getCachedOptions, getLocalOptions } from '../../services/domainEntitiesService';

export interface InputOptionType {
  inputValue?: string;
  title: string;
  id: number;
}

interface TypeAheadTextboxProps {
    label:string
    onChange?: (value:InputOptionType) => void
    onKeyPress?: (queryText:string) => void
    id?: string
    validationField?: FormValidationField
    error?:boolean
    options?:DomainEntityType[]
    optionName?: 'service-list'
    value?: number
    clearAfterSelection?:boolean
}


const TypeAheadTextbox:  React.FC<TypeAheadTextboxProps> = (props) => {

    const [options, setOptions]  = React.useState<InputOptionType[]>([])
    const [filteredOptions, setFilteredOptions]  = React.useState<InputOptionType[]>([])
    const [queryText,setQueryText] = React.useState<InputOptionType>()
    // const [loading,setLoading] = React.useState(false);
    const [initialised,setInitialised] = React.useState(false);
    const allOptions = getCachedOptions()

  useEffect(() => {
    const options: InputOptionType[] = []
    props.options?.forEach(f=>{
      options.push({inputValue:f.name, title:f.name , id:f.id})
    }) 
    setOptions(options)
},[props.options])

useEffect(() => {
  if(props.optionName){
    populateOptions()
  }
},[props.optionName])


useEffect(() => {
  if(!initialised) return
  if(!queryText){
    setQueryText(queryText)
    
    
    if(props.value &&  props.onChange)
      props.onChange({id:-1,title:''})
  }
  
},[queryText])


useEffect(() => {
   

  if(!props.value) {
    setQueryText(undefined)
    return
  }
  const title = options.find(f=>f.id=== props.value)
  
  
  if(!title) return
  
  setQueryText({id:props.value||0, title:title.title,inputValue:title.title})
},[props.value])

const populateOptions = async () => {
  // setLoading(true)
  const optionsLoadedFromContext = (allOptions && allOptions.serviceCategories && allOptions.serviceCategories.length>0)

  let domainEntities = (allOptions as any)[props.optionName||''] as DomainEntityType[]
  if(!optionsLoadedFromContext || !domainEntities){
    domainEntities = await getLocalOptions(props.optionName||'')
  }

  if(!domainEntities || domainEntities.length===0){
    // setLoading(false)
    return
  }

  let options:InputOptionType[] = []
  
    if(props.optionName === 'service-list')
        domainEntities.map(opt=> {
          options.push({id: opt.id, title:opt.name.split('|')[0]})
        })
    else
        domainEntities.map(opt=> {
          options.push({id: opt.id, title:opt.name})
        })

  const selectedText = options.find(f=>f.id === props.value)
  if(selectedText) setQueryText(selectedText)
  setOptions(options);
  // setLoading(false)
}

  const itemTemplate = (item: InputOptionType) => {
    var titleElems = item.title.split('||')
    return (
        <div>
            <div style={{width:'60%',float:'left'}}>
              <img alt={item.title} src={hospitalIcon} style={{width:24,height:24}} />
              <span  style={ {position:'relative', top:-5}}>{titleElems[0]}</span>
            </div>
            <div style={{width:'25%',float:'left',overflow:'hidden'}}>{titleElems[1]}</div>
            <div style={{width:'15%',float:'left'}}>{titleElems[2]}</div>
        </div>
    );
}
 const handleSelection =(e:any) =>{
    if(props.onChange){
      props.onChange(e.value)
      if(props.clearAfterSelection)
        setQueryText(undefined)
    }
 }
 const handleKeyCapture = (e:any) =>{
  if(props.onKeyPress )
    props.onKeyPress(e.target.value)
 }
  const searchCountry = (event: { query: string }) => {
    setTimeout(() => {
        let _filteredCountries;
        if (!event.query.trim().length) {
            _filteredCountries = [...options];
        }
        else {  
            _filteredCountries = options.filter((f) => {
                return f.title.toLowerCase().indexOf(event.query.toLowerCase())>-1;
            });
        }

        setFilteredOptions(_filteredCountries);
    }, 25);
}
 
  return   <><AutoComplete dropdown={true}  size={100} minLength={2} panelStyle={{height:250,maxHeight:'300px'}} virtualScrollerOptions={{ style:{height:250,maxHeight:'300px'},itemSize: 35, scrollHeight:"100%" }} 
  onSelect={handleSelection} value={queryText} suggestions={filteredOptions} completeMethod={searchCountry} field="title" forceSelection itemTemplate={itemTemplate} onChange={(e) => {setInitialised(true);setQueryText(e.value)}} aria-label={props.label} placeholder={props.label} 
  onKeyUpCapture={handleKeyCapture}/></>

}


  

export default TypeAheadTextbox



