
import {  Grid } from "@mui/material";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Badge from "../../components/core/badge";

import Page from "../../components/core/page";
import Panel from "../../components/core/panel";
import TextBox from "../../components/core/textBox";
import useForm from "../../hooks/useForm";
require('./reporting.scss')

interface ReportType {
    name:string
    description:string
    url:string
    category:string
}

export default function ReportingHomeContainer() {

    const navigate = useNavigate();
    
    useEffect(() => {
        present()
      }, [])

    const { feedbackStatus } = useForm({ initialObjectValues: {} })
    const [errorMessage] = useState<string | undefined>();
    const [reportList, setReportList] = useState<ReportType[]>([]);
    const [filteredRportList, setFilteredRportList] = useState(reportList);
    const [selectedReportCategory, setSelectedReportCategory] = useState("")

    const present = ()=>{
        const list:ReportType[] = [
                {name:"Service Compliance Report",category:"escalation",description:"Escalation Service Compliance Report. Details when a service last updated the Escalation level",url:"/reporting/form/compliance"},
                {name:"Escalation Triggers Report",category:"escalation",description:"Details the count of each Escalation Level per trigger within the selected date range per service",url:"/reporting/form/triggers"},
                {name:"Escalation Triggers Summary Report",category:"escalation",description:"Details the count of each Escalation Level per trigger within the selected date range per service",url:"/reporting/form/triggerssummary"},
                {name:"Escalation Trend Report",category:"escalation",description:"Escalation Trend Report",url:"/reporting/form/trend"},
                {name:"Service Status Report",category:"escalation",description:"The current escalation level by Service (refreshing every 5 minutes)",url:""},
                {name:"Escalation Level Summary Report",category:"escalation",description:"Tabular report containing all data form submissions for selected services",url:""},
                {name:"Escalation Level Report",category:"escalation",description:"Provides the detail of an Escalation Level for a selected Service",url:"/reporting/form/level"},
                {name:"Form Data Capture Report",category:"escalation",description:"Tabular report containing all data form submissions for selected services",url:""},
                {name:"Situation Report",category:"escalation",description:"Tabular report containing latest escalation levels for selected services. Editable by Admins",url:""},
                {name:"Opel Report",category:"opel",description:"Opel report.. Description to follow)",url:"/reporting/opel/submission"},
                // {name:"Capacity Tracker",category:"capacity",description:"Tracking of capacity data by CCG",url:""},
            { name: "Daily Capacity Grid Report", category: "capacity", description: "Latest Capacity data for services", url:"/reporting/capacity-grid"},
            { name: "Capacity Grid Historic Report", category: "capacity", description: "Latest Capacity data for services", url:"/reporting/capacity-grid"},
                {name:"Capacity Grid Summary Report",category:"capacity",description:"Capacity Grid Summary",url:""},
                {name:"Capacity Grid Compliance Report",category:"capacity",description:"Service compliance",url:"/reporting/capacity-grid/compliance"},
                // {name:"EMS Bed Availability Dataset",category:"capacity",description:"Tabular report containing all form submissions for acute services for the previous day. This data is generated in csv format and emailed to a distribution list at 11am each day",url:""},
                {name:"Dataset Metrics Report",category:"demand",description:"Tabular report containing dataset metrics for services",url:""},
                {name:"System Audit Report",category:"system",description:"Tabular report containing the audit of system actions by specific users, date range and event",url:"/reporting/system/audit"},
                {name:"Exception Report",category:"system",description:"View exception logs",url:""},
                {name:"Service Access Report",category:"system",description:"View the roles assigned to Users and Services. Filter Area or Service as well as specific user or role(s)",url:""},
                {name:"Subscription Audit Report",category:"system",description:" View user subscriptions for Services.",url:"/reporting/system/user-subscription-report"},
        ]

        setReportList(list)
        setFilteredRportList(list)
    }
    const filterReportListByCategory = (category:string)=>{

        if(category=== selectedReportCategory)
        {
            setSelectedReportCategory("")
            setFilteredRportList(reportList)
            return
        }


        const list = reportList.filter(f=> f.category === category)
        setFilteredRportList(list)
        setSelectedReportCategory(category)
    }

    const handleReportSearch = (e:any ) =>{
        const searchText = e.target.value
        if(searchText.length<1){
            setFilteredRportList(reportList)
            return
        }

        const list = reportList.filter(f=> f.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase())>-1 || 
                                            f.description.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase())>-1)
        setFilteredRportList(list)

    }
    const handleReportClick = (r:ReportType) =>{
        navigate(r.url,{replace:false,state:Date.now()})
    }
    return <>
        <Page title="Reporting" feedbackStatus={feedbackStatus} errorMessage={errorMessage} showBorder={true}>
        <Grid container >
            <Grid item xs={3} />
                <Grid item xs={9}>
                    <Grid container>
                        
                        <Grid item xs={9} className="search-box"  >
                            <TextBox label="Search" icon="pi pi-search" onKeyPress={handleReportSearch}/>
                        </Grid>
                    </Grid>
                </Grid>        
        </Grid>
            <Grid container >
            <Grid item xs={3}>
                <Panel title="Category">
                    <div className="side-menu">
                        <div className={`item ${selectedReportCategory === "escalation" ? "selected" : ""}`} onClick={()=>filterReportListByCategory("escalation")}><i className="pi pi-fw pi-chart-bar"></i>Escalation &nbsp; <Badge supressWhenZero={true} value={filteredRportList.filter(f=>f.category==="escalation").length}></Badge></div>
                        <div className={`item ${selectedReportCategory === "opel" ? "selected" : ""}`} onClick={()=>filterReportListByCategory("opel")}><i className="pi pi-fw pi-circle"></i>Opel &nbsp; <Badge supressWhenZero={true} value={filteredRportList.filter(f=>f.category==="opel").length}></Badge></div>
                        <div className={`item ${selectedReportCategory === "capacity" ? "selected" : ""}`} onClick={()=>filterReportListByCategory("capacity")}><i className="pi pi-fw pi-table"></i>Capacity Grids &nbsp; <Badge supressWhenZero={true} value={filteredRportList.filter(f=>f.category==="capacity").length}></Badge></div>
                        <div className={`item ${selectedReportCategory === "demand" ? "selected" : ""}`} onClick={()=>filterReportListByCategory("demand")}><i className="pi pi-fw pi-arrows-v"></i>Live Data &nbsp; <Badge supressWhenZero={true} value={filteredRportList.filter(f=>f.category==="demand").length}></Badge></div>
                        <div className={`item ${selectedReportCategory === "system" ? "selected" : ""}`} onClick={()=>filterReportListByCategory("system")}><i className="pi pi-fw pi-cog"></i>System &nbsp; <Badge supressWhenZero={true} value={filteredRportList.filter(f=>f.category==="system").length}></Badge></div>
                    </div>
                </Panel>
            </Grid>

            <Grid item xs={9} className="report-container">
                    <Grid item xs={9} className="report-container">
                    <Panel title="Report List">
                        {filteredRportList?.map((f) => (
                            <div className="report-menu" onClick={()=>handleReportClick(f)}>
                                <div className="item"><i className="pi pi-fw pi-book"></i><span className="header">{f.name}</span>
                                <div className="report-description">{f.description}</div>
                                </div>
                            </div>
                        ))}
                    </Panel>
                    
                    </Grid>
                </Grid>
                    
            </Grid>
        </Page>
    </>
}