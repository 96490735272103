import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import GroupBox from '../core/groupBox';
import TickBox from '../core/tickBox';
import TextBox from '../core/textBox';
import Loader from '../core/loading';
import ActionButton from '../core/actionButton';
import { registerUser } from '../../services/userService';
import { UserLoginType } from '../../interfaces/user/iUser';
import { FormValidation, FormValidationField, ValidationType } from '../../extensions/formValidation';
import UserRegistrationSteps from './registrationSteps';
import FeedBackText from '../core/feedbackText';
import ReCAPTCHA from "react-google-recaptcha";
require('./user.scss')

export interface PropsType {
    onError?: (errorMessage: string) => void
    onSuccess?: () => void
}

const UserRegistration: React.FC<PropsType> = (props) => {

    const [loading, setLoading] = useState(false);
    const [formValidation, setFormValidation] = useState(new FormValidation([]));
    const [userLogin, setUserLogin] = useState(new UserLoginType('', ''));
    const [characterClassname, setCharacterClassname] = useState('root');
    const [numberClassname, setNumberClassname] = useState('root');
    const [upperCaseClassname, setUpperCaseClassname] = useState('root');
    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState<any>();
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [successfullyRegistered, setSuccessfullyRegistered] = useState(false)
    const [acceptedTermsClassname, setAcceptedTermsClassname] = useState('')
    const [captchaToken, setCaptchaToken] = useState('');


    useEffect(() => {
        if (acceptedTerms)
            updateForm()
    }, [acceptedTerms])

    useEffect(() => {
        updateForm()
    }, [submitted])


    const initialiseValidationFields = () => {

        const formFields = [new FormValidationField(email, 'Please enter a valid email address')]

        formFields[0].validationType = ValidationType.Email;
        formValidation.validationFields = formFields;

        setFormValidation(formValidation);
        return formValidation;
    }

    const handleRegistration = async () => {
        setSubmitted(true)

        var isValid = updateForm(true)

        if (isValid) {
            setLoading(true);
            const response = await registerUser(userLogin)

            if (response.success) {
                loginSuccess()
            }
            else {
                loginFailure(response.error)
                if (props.onError)
                    props.onError(response.error || 'general')
            }
        }
        else
            loginFailure()
    }

    const loginSuccess = () => {
        setLoading(false)
        setError(false)
        setSuccessfullyRegistered(true)
    }

    const loginFailure = (error?: any) => {

        if (error)
            setErrorObject(error);

        setError(true)
        setLoading(false);
    }
    const updateForm = (overrideSubmitted?: boolean): boolean => {

        setErrorMessage('')
        if (!submitted && !overrideSubmitted) return false;

        setUserLogin({ ...userLogin });

        var formValidation = initialiseValidationFields();
        const passwordValid = validatePassword({ target: { value: userLogin.password } });
        const passwordsMatch = userLogin.password === userLogin.passwordConfirmation;

        setPasswordsMatch(passwordsMatch)
        setPasswordValid(passwordValid)

        var formValid = formValidation.isValid()

        setAcceptedTermsClassname(acceptedTerms ? '' : 'error-box')
        const valid = passwordValid && passwordsMatch && formValid && acceptedTerms

        if ((passwordValid && passwordsMatch) && !acceptedTerms)
            setErrorMessage("You must read and agree to the privacy statement.")
        if ((!captchaToken) || captchaToken === undefined || captchaToken === '') {
            setErrorMessage("You must check the 'I'm not a robot' field.");
            return false;
        }
        if (passwordValid && !passwordsMatch)
            setErrorMessage("You must confirm your password.")
        if (!passwordValid)
            setErrorMessage("Password does not meet requirements. A password must be at least 8 characters long and contain lowercase, uppercase, numeric and special characters.")
        if (!formValid)
            setErrorMessage('formValid')

        return valid
    }

    const validatePassword = (e: any): boolean => {

        if (!e || !e.target || e.target.type === 'button')
            return true

        const password = e.target.value

        const validLength = password.length > 7
        const validNumber = /\d\d/.test(password)
        const validUpperCase = /[A-Z]/.test(password)

        setCharacterClassname(validLength ? 'pass' : 'fail')
        setNumberClassname(validNumber ? 'pass' : 'fail')
        setUpperCaseClassname(validUpperCase ? 'pass' : 'fail')


        return validLength && validNumber && validUpperCase
    }

    if (loading)
        return <Loader loading={loading}></Loader>

    const cardActions = <Grid container spacing={3} justifyContent="center">
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={7}>
            <ActionButton label="Back to Login" severity="secondary" icon="pi pi-arrow-left" href="/login"></ActionButton>
        </Grid>
        <Grid item xs={3}>
            <ActionButton size="small" severity="success" onClick={handleRegistration} label="Next"></ActionButton>&nbsp;
        </Grid>

    </Grid>




    if (successfullyRegistered)
        return <>
            <Grid container justifyContent="center">

                <Grid item xs={10} lg={6}>
                    <GroupBox title="Register for EMS+"   >
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <UserRegistrationSteps step={1} />
                            </Grid>
                            <p>A verification email has been sent to <strong>{email}</strong> containing details on how to verify your user account.</p>
                            <p>If you haven't received the verification email please check your Junk Email folder.</p>
                            <p>The link in this email will expire after 24 hours.</p>
                        </Grid>
                    </GroupBox>
                </Grid>
            </Grid>
        </>
    else
        return <React.Fragment>
            <br></br><br></br>
            <Loader loading={loading}></Loader>
            <Grid container justifyContent="center">
                <Grid item xs={10} lg={6}>
                    <FeedBackText severity="error" show={error} errorObject={errorObject} message={errorMessage}></FeedBackText>
                    <GroupBox title="Register for EMS+" actions={cardActions} help={<p>There are 5 steps to complete the registration process. The first step is email verification. Please enter you email address and a strong password in order to verify your email address and complete the registration process.</p>}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <UserRegistrationSteps />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Please enter an email address and a password below to register:</p>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <TextBox id="outlined-username" focus={true} label="Email Address" value={email} onChange={(e) => { userLogin.username = e.target.value; setEmail(e.target.value) }} validationField={formValidation.validationFields[0]} />
                            </Grid>
                            <Grid item xs={12} lg={7}><TextBox id="outlined-password" label="Password" type={'password'} value={userLogin.password} onChange={(e) => { userLogin.password = e.target.value; setUserLogin({ ...userLogin, password: e.target.value }); updateForm(); }} error={submitted && !passwordValid} helperText="Password does not meet requirements" onKeyPress={validatePassword} /></Grid>
                            <Grid item xs={12} lg={7}><TextBox id="outlined-password" label="Password Confirmation" type={'password'} value={userLogin.passwordConfirmation} onChange={(e) => { userLogin.passwordConfirmation = e.target.value; setUserLogin({ ...userLogin, passwordConfirmation: e.target.value }); updateForm(); }} error={submitted && !passwordsMatch} helperText="Passwords don't match" /></Grid>
                            <Grid container spacing={0} justifyContent="center">
                                <Grid item xs={12} lg={12}>
                                    <div style={{ textAlign: 'center' }}>Password requirements</div>
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <div className={characterClassname} style={{ textAlign: 'center' }}>8+</div>
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <div className={upperCaseClassname} style={{ textAlign: 'center' }}>A</div>
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <div className={numberClassname} style={{ textAlign: 'center' }}>0-9</div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={4} lg={2}>
                                    <div style={{ textAlign: 'center' }}>8 characters</div>
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <div style={{ textAlign: 'center' }}>1 uppercase</div>
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <div style={{ textAlign: 'center' }}>2+ number</div>
                                </Grid>
                                <Grid item xs={8}><hr></hr></Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justifyContent="center">
                            <br></br><br></br>
                        </Grid>
                        <Grid container spacing={3} justifyContent="center">
                            <TickBox label="" checked={acceptedTerms} labelElement={<div className={acceptedTermsClassname}>I have read and understand the <a href="">privacy statement</a></div>} onChange={() => { setAcceptedTerms(!acceptedTerms); }} />
                        </Grid>
                        <div style={{ clear: 'both' }}><br></br></div>
                        <Grid container spacing={3} justifyContent="center">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY ?? ''}
                                onChange={(value) => { userLogin.captchaToken = value ?? ''; setCaptchaToken(value ?? ''); }}
                            />
                        </Grid>
                        <div style={{ clear: 'both' }}><br></br></div>
                        <Grid container spacing={3} justifyContent="center">
                            <p>This site is protected by reCAPTCHA and the Google <a href="https://www.google.com/intl/en-GB/policies/privacy/" target="_blank">Privacy Policy</a> and <a _ngcontent-kgp-c377="" href="https://www.google.com/intl/en-GB/policies/terms/" target="_blank">Terms of Service</a> apply.</p>
                        </Grid>
                    </GroupBox>
                    <div style={{ clear: 'both' }}><br></br></div>

                    <Grid container spacing={0} justifyContent="center">


                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>



}
export default UserRegistration;
