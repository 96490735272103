import {FormValidation, FormValidationField} from '../extensions/formValidation';
import {AddressType} from '../interfaces/system/iAddress'
import {ValidatorType} from './ValidatorType'

export class AddressDetailsFormValidator implements ValidatorType
{
    formValidator: FormValidation= new FormValidation([])
    addressLine1: FormValidationField= new FormValidationField('','')
    town: FormValidationField= new FormValidationField('','')
    postcode: FormValidationField= new FormValidationField('','')
    email: FormValidationField = new FormValidationField('','')
    latitude: FormValidationField = new FormValidationField('','')
    longitude: FormValidationField = new FormValidationField('','')

     initialiseValidationFields = (objectValues: AddressType) => {
        this.formValidator = new FormValidation([])
        this.addressLine1 = this.formValidator.createTextField(objectValues.addressLine1)
        this.town = this.formValidator.createTextField(objectValues.town)
        this.postcode = this.formValidator.createPostcodeField(objectValues.postcode)
        this.email = this.formValidator.createEmailField(objectValues.email||'')
        this.email.allowNull=true
        this.longitude = this.formValidator.createTextField(objectValues.longitude.toString())
        
        this.latitude = this.formValidator.createTextField(objectValues.latitude.toString())
        this.latitude.minValue=1
     }
    
 validate = (validatorObjectValues: AddressType): boolean => {
     this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid()

    return isValid
 }
}

export default AddressDetailsFormValidator;