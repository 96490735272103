import React from "react";
import {Grid} from '@mui/material';
import UserRegistrationSteps from './registrationSteps';
import GroupBox from '../core/groupBox';

 
const UserRegistrationComplete: React.FC = () => {

    
    return <>
   <Grid container justifyContent="center">

<Grid item xs={10} lg={6}>
        <GroupBox title="Registration complete"   >
             <Grid container spacing={2} justifyContent="center">
             <Grid item xs={12}>
                <UserRegistrationSteps step={4}/>
             </Grid>
                 <p>Thank you for registering for EMS+. You access requirements are now with the RCMT awaiting approval.</p>
                 <p>You will receive and email informing you when you will be able to access your selected service(s).</p>
             </Grid>
         </GroupBox>
         </Grid>
         </Grid>

        </>          
}


export default UserRegistrationComplete;
