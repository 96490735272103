import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { CapacityGridColumnType, CapacityGridFieldTypes } from '../interfaces/system/capacityGridType';
import { ValidatorType } from './ValidatorType'

export class CapacityGridColumnValidator implements ValidatorType {

    colValidator = new FormValidation([])
    name = new FormValidationField('', '')
    columnType = new FormValidationField('', '', ValidationType.Numeric)
    reportingType = new FormValidationField('', '', ValidationType.Numeric)
    description = new FormValidationField('', '')
    calulatedFormula = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: CapacityGridColumnType) => {
        this.colValidator = new FormValidation([]);
        this.name = this.colValidator.createTextField(objectValues.name || '');
        this.columnType = this.colValidator.createDropdownField(objectValues.fieldType.id);
        this.reportingType = this.colValidator.createDropdownField(objectValues.reportingFieldType.id);
        this.description = this.colValidator.createTextField(objectValues.description);

        if (objectValues.fieldType.id === CapacityGridFieldTypes.Calculated) {
            this.calulatedFormula = this.colValidator.createFormulaField(objectValues.calculatedFieldFormula);
        }

    }

    validate = (validatorObjectValues: CapacityGridColumnType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.colValidator.isValid()
        return isValid
    }
}