import { useEffect, useState } from "react";
import { NotificationBroadcastType } from "../../../interfaces/system/notificationBroadcastType"
import DataTable from "../../core/dataTable";

interface BroadcastListProps {
    list: NotificationBroadcastType[],
    onSelection: (id: number) => void,
    onNew: () => void
}
export function BroadcastList(props: BroadcastListProps) {
    const itemsPerPage = 25;
    const [list, setList] = useState(props.list);

    useEffect(() => {
        setList(props.list)
    }, [props.list]);



    return <>
        <DataTable dataKey="id" loading={false} columns={[{ header: "Id", field: "id" },
        { header: "Name", field: "name" },
        { header: "Active From", field: "activeFrom", formatType: 'datetime' },
        { header: "Active To", field: "activeTo", formatType: 'datetime' },
            { header: "Active", field: "deleted", formatType: "yesno-inverse" }]} paginationRowsPerPage={itemsPerPage} value={list} onEdit={(row) => props.onSelection(row['id'])} onSelect={(row) => props.onSelection(row.value.id)}
            editButton={true} paginator={list.length > itemsPerPage} filter={true} allowExport={true} onNew={props.onNew}
        />
    </>
}