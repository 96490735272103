import React, { CSSProperties, useState } from "react";
// import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/core/Icon'
// import IconButton from '@material-ui/core/IconButton'
// import Link from '@material-ui/core/Link';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { PropTypes } from "@mui/material";
import './css/actionButton.scss';

interface ActionButtonProps {
    id?: string;
    size?: "small" | "medium" | "large" | undefined;
    children?: React.ReactNode;
    icon?:string;
    label?:string;
    href?:string;
    onClick?:any;
    title?:string;
    visible?:boolean;
    severity?:'success'|'secondary'|'help'|'info'|'danger';
    style?:CSSProperties | undefined
    colour?:PropTypes.Color
    variant?: 'text' | 'outlined' | 'contained' | 'icon' | 'link' | 'icon-only'
    faded?:boolean | false
    loading?:boolean
    disabled?:boolean
  }

const ActionButton:  React.FC<ActionButtonProps> = props => {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(props.disabled ?? false);
     
    React.useEffect(() => {
      setDisabled(props.disabled??false)

   }, [props.disabled])

      // const preventDefault = (event: React.SyntheticEvent) => {
      //   event.preventDefault();
      //   if(props.href)
      //     window.location.href = props.href||''
      //   else{
      //     if(props.onClick)
      //       props.onClick()
      //   }
      // }
      const handleClick = () =>{
        if(props.onClick)
          props.onClick()

        if(props.href) navigate(props.href)
        
      }

      let icon =  props.icon
      if(!icon) {
        switch(props.label||'' ){
        case 'Next':case'Continue': icon = 'pi pi-caret-right'; break;
        case 'Save': icon = 'pi pi-save'; break;
        case 'Back': icon = 'pi pi-caret-left'; break;
        case 'New':case 'Add': icon = 'p-button-icon p-c p-button-icon-left pi pi-plus'; break;
        case 'Remove': case 'Delete': icon = 'p-button-icon p-c p-button-icon-left pi pi-times'; break;
        case 'Create': icon = 'p-button-icon p-c p-button-icon-left pi pi-plus'; break;
        case 'Export': icon = 'p-button-icon p-c p-button-icon-left pi pi-upload'; break;
        }
      }

      // const iconButtonStyles = {
      //   circle: {fontSize:'8px', padding:'2px',color: classes.lightColour, borderRadius:'25px', borderColor:classes.lightColour, borderStyle:'solid', borderWidth:'2px'},
      //   standard: {padding:'2px',color: classes.lightColour}
      // };
      let className = props.variant === 'link' ? "":props.severity? `p-button-${props.severity} `:''
        className += props.size ? `p-button-${props.size} ` : '';
      let label = props.label
      if(props.variant === "icon-only"){
        className = className +' p-button_-rounded p-button-text'
        label = ''
      }
      if(props.variant === "icon")
        className = className +' p-button_-rounded'
      if(props.variant === "outlined")
        className = className +' p-button-outlined'
      if(props.variant === "text")
        className = className +' p-button-text'

    return <Button loading={props.loading} icon={icon} disabled={ disabled } 
    label={label} title={props.label} className={className} visible={props.visible} onClick={handleClick}
    link={props.variant==="link"}
    style={props.style}
    />
      
 
    // if(props.visible !== undefined && !props.visible)
    //   return <></>
    // if(props.icon==="" && (props.variant || props.variant !== 'icon'))
    //     return <Button  style={{color: classes.lightColour}} className={classes.root} size={props.size||"medium"} type="submit" color={props.colour||"secondary"} variant={'outlined'} onClick={props.onClick} >{props.children}</Button>;
    // else
    // if((props.variant && props.variant === 'link'))
    //     return <Link href={props.href || ''}  onClick={preventDefault}>{props.label}</Link>
    //     //return <Link href="/login" onClick={preventDefault}>{props.label}___</Link>
    // else
    // if (props.variant && props.variant !== 'icon' )
    //   return <IconButton aria-label={props.label||'edit'} title={props.title} color={props.colour||"secondary"} onClick={props.onClick} style={props.variant!=="contained" ? iconButtonStyles.standard : iconButtonStyles.circle} >
    //   {/* <Icon fontSize={props.size==='large' ? 'large' :'inherit' }>{props.icon}</Icon> */}
    //   <Icon>{props.icon}</Icon>
    //   {props.children}
    // </IconButton>
    // return <Button style={{opacity: props.faded ? .8 : 1}} className={classes.root} size={props.size||"medium"}   type="submit" color={props.colour||"secondary"} variant={'outlined'}  onClick={props.onClick}
    //   startIcon={<Icon>{props.icon}</Icon>}>{props.children}</Button>;
}

export default ActionButton;