import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { FormType } from '../interfaces/system/formType';
import {ValidatorType} from './ValidatorType'


export class FormFieldValidator implements ValidatorType
{

   formValidator = new FormValidation([])
    name= new FormValidationField('','')
   //  reportingCategory= new FormValidationField('','')

     initialiseValidationFields = (objectValues: FormType) => {
        this.formValidator = new FormValidation([])

        
        this.name= this.formValidator.createTextField(objectValues.name)
      //   this.reportingCategory= this.formValidator.createDomainTypeField(objectValues.reportingCategoryId)
    }
    
 validate =  (validatorObjectValues: FormType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid
 }
}

export default FormFieldValidator

