import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { formatDateTime } from "../../extensions/formatter";
import { ServiceStatusType } from "../../interfaces/system/serviceType"
import DebugVariable from "../core/debugVariable"

export interface CapacityGridSummaryProps {
    serviceStatus?: ServiceStatusType
}
export function CapacityGridSummary(props: CapacityGridSummaryProps) {
    const [service, setService] = useState(props.serviceStatus);

    React.useEffect(() => {
        setService(props.serviceStatus)
    }, [props.serviceStatus])

    if (!service || (service.capacityGridId ?? 0) <= 0) {
        return <></>
    }

    return <>
        <Grid container spacing={0}>
            <Grid item xs={6}>
                {(service.capacityGridOverview?.capacityGridKeyFieldLabel ?? '') !== '' &&
                    <p style={{ fontSize: '1.5rem' }}>{service.capacityGridOverview?.capacityGridKeyFieldLabel}: {service.capacityGridOverview?.capacityGridKeyFieldValue}</p>
                }
                
                {service.capacityGridOverview?.capacityGridSubmittedOn &&
                    <p>
                        Last updated: <strong>{formatDateTime(service.capacityGridOverview?.capacityGridSubmittedOn)}</strong>
                        {service.capacityGridOverview?.capacityGridSubmittedByName &&
                            <> By: <strong>{service.capacityGridOverview?.capacityGridSubmittedByName}</strong></>
                        }
                    </p>
                }

                <DebugVariable hide={true} variable={service.capacityGridOverview}></DebugVariable>
            </Grid>
        </Grid>

    </>
}