import {FormValidation, FormValidationField} from '../extensions/formValidation';
import { AutomatedReportType } from '../interfaces/system/systemTypes';
import {ValidatorType} from './ValidatorType'


export class AutomatedReportValidator implements ValidatorType
{

   formValidator = new FormValidation([])
    name= new FormValidationField('','')
    subject= new FormValidationField('','')
    reportType= new FormValidationField('','')
    distributionList= new FormValidationField('','')
    tabIndex = 0

     initialiseValidationFields = (objectValues: AutomatedReportType) => {
        this.formValidator = new FormValidation([])

        this.name= this.formValidator.createTextField(objectValues.name)
        this.subject= this.formValidator.createTextField(objectValues.subject)

        if(!objectValues.reportTypeId && objectValues.reportType) objectValues.reportTypeId = objectValues.reportType.id
        this.reportType= this.formValidator.createDomainTypeField(objectValues.reportTypeId)
        this.distributionList= this.formValidator.createDomainTypeField(objectValues.distributionListId)
    }
    
 validate =  (validatorObjectValues: AutomatedReportType): boolean => {
    this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid() 

    return isValid
 }
}

