import { useEffect, useState } from "react";
import { CapacityGridColumnType } from "../../../../interfaces/system/capacityGridType";
import { fetchCapacityGridSharedColumns } from "../../../../services/capacity-grid-service";
import DataTable from "../../../core/dataTable";
import DebugVariable from "../../../core/debugVariable";
import Loading from "../../../core/loading";

export interface CapacityGridSharedColumnListProps {
  onError: (errorMessage: string) => void,
  onItemSelection: (item: CapacityGridColumnType) => void,
  onNew: () => void,
  itemsPerPage?: number
}

export function CapacityGridSharedColumnList(props: CapacityGridSharedColumnListProps) {

  const [loading, setLoading] = useState(true);
  const [resultList, setResultList] = useState<CapacityGridColumnType[]>()
  const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ?? 25);

  // hooks
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    setItemsPerPage(props.itemsPerPage ?? 25);
  }, [props.itemsPerPage])


  const init = async () => {
    const response = await fetchCapacityGridSharedColumns(false);
    if (response && response.success) {
      setResultList(response.result)
      setLoading(false);
      return
    }
    setLoading(false);
    props.onError(response.error ?? 'Unknown error');
  }

  const handleItemSelection = (item: CapacityGridColumnType) => {
    props.onItemSelection(item);
  }

  const handleNewItem = () => {
    props.onNew();
  }

  if (loading) {
    return <>
      <Loading loading={loading}></Loading>
    </>
  }

  return <>
    <DataTable dataKey="id" loading={false} columns={[{ header: "Id", field: "id" },
    { header: "Name", field: "name" },
    { header: "Type", field: "fieldType.name" },
    { header: "Reporting Type", field: "reportingFieldType.name" },
    { header: "Active", field: "deleted", formatType: "yesno-inverse" }]} paginationRowsPerPage={itemsPerPage} onSelect={(row) => handleItemSelection(row.value)} onEdit={(row) => handleItemSelection(row.value)}
      editButton={true} paginator={(resultList?.length || 0) > itemsPerPage} filter={true} allowExport={true} onNew={handleNewItem}
      value={resultList}
    />
  </>

}