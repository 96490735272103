import React, {useEffect} from "react";
import { PendingUserRegistrationType } from "../../../interfaces/user/iUser";
import DataTable from "../../core/dataTable";

 
interface IProps {
    request:PendingUserRegistrationType|undefined
    loading: boolean
    onSendVerificationMail:(userId:string) => void
  }

const PendingRegistrations:  React.FC<IProps> = (props) => {

    const [loading] = React.useState(props.loading);
    const [pendingAccessRequest, setPendingAccessRequest] = React.useState(props.request)

    useEffect(() => {

   },[])
   useEffect(() => {
    setPendingAccessRequest(props.request)
   },[props.request])
    
   const handleEdit = (rowData:any) => {
        props.onSendVerificationMail(rowData["userId"])
   }
    return <>
    <DataTable loading={loading} value={pendingAccessRequest?.requests} 
    paginationRowsPerPage={50} columns={
        [
            {header:'Email', field:'email'},
            {header:'Registered On', field:'registeredOn', formatType:'datetime' },
             {header:'Verified', field:'verified', formatType:'yesno'},
             {header:'Verified On', field:'verifiedOn', formatType:'datetime'},
             {header:'Status', field:'registrationStatusText', },
             
            // {header:'Verified On', field:'VerifiedOn', formatType:'datetime'}
        ]} dataKey="id" editButton={true}  onEdit={handleEdit} editButtonLabel="Resend Verification Email" editButtonToggleField="verified" editButtonToggleFieldInverse={true}
        editButtonIcon="pi pi-envelope" paginator={true}/>
    </>;
}

export default PendingRegistrations;

