import { Grid } from "@mui/material";
import { useState } from "react";
import ActionButton from "../../core/actionButton";
import { UserType } from "../../../interfaces/user/iUser";
import { forcePasswordReset, userForgotPassword } from "../../../services/userService";
import Panel from "../../core/panel";
import TextBox from "../../core/textBox";

export interface ResetUserPasswordProps {
    User: UserType,
    OnError?: (errorMessage: string) => void,
    OnSaved?: (user: UserType, message?: string) => void,
    OnLoading?: () => void,
    OnLoaded?: () => void,
}
export function ResetUserPassword(props: ResetUserPasswordProps) {
    const [userToEdit] = useState(props.User);
    const [newPassword, setNewPassword] = useState('');


    const handleLoading = () => {
        if (props.OnLoading) {
            props.OnLoading();
        }
    }

    const handleLoaded = () => {
        if (props.OnLoaded) {
            props.OnLoaded();
        }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }

    const handlePasswordLink = async () => {
        handleLoading();
        const response = await userForgotPassword(userToEdit.email);
        handleLoaded();
        if (!response || !response.success || !response.result) {
            handleError(response?.error ?? 'An unknown error was encountered');
            return;
        }
        if (props.OnSaved) {
            props.OnSaved(props.User, "User password reset sent");
        }
    }
    const handleResetPassword = async () => {
        handleLoading();
        const response = await forcePasswordReset(userToEdit.id, newPassword);
        handleLoaded();
        if (!response || !response.success || !response.result) {
            handleError(response?.error ?? 'An unknown error was encountered');
            return;
        }
        if (props.OnSaved) {
            props.OnSaved(props.User, "User password was reset");
        }
    }

    return <>
        <Panel title="Generate Password Reset Email">
            <p>To send this user a password reset email click the button below:</p>
            <Grid container spacing={2} justifyContent="left">
                <Grid item xs={12}>
                    <ActionButton onClick={handlePasswordLink} label="Reset User Password" severity={'success'}></ActionButton>
                </Grid>

            </Grid>
        </Panel>
        <Panel title="Reset User Password">
            <p>Reset a user password</p>
            <Grid container spacing={2} justifyContent="left">
                <Grid item xs={6}>
                    <TextBox label="Password" onChange={(e)=> setNewPassword(e.target.value)}></TextBox>
                </Grid>
                <Grid item xs={12}>
                    <ActionButton onClick={handleResetPassword} label="Reset" disabled={newPassword==='' || newPassword.length<6} severity={'success'}></ActionButton>
                </Grid>

            </Grid>
        </Panel>

    </>

}