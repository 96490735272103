import { useEffect, useState } from "react";
import { ComponentPropsBase } from "../../../../interfaces/system/componentPropsBase";
import DataTable from "../../../core/dataTable";
import { fetchSystemMessageList } from "../../../../services/system-message-service";
import { SystemMessageType } from "../../../../interfaces/system/systemMessageType";

export interface SystemMessageListProps extends ComponentPropsBase {
    itemsPerPage?: number,
    onRowClicked?: (item: SystemMessageType) => void,
    onNew?: () => void,
}

export function SystemMessageList(props: SystemMessageListProps) {
    const [listItems, setListItems] = useState<SystemMessageType[]>();
    const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ?? 25);
    const [loading, setLoading] = useState(true);

    // hooks
    useEffect(() => {
        //console.clear();
        init();
    }, []);

    const init = async () => {
        handleLoading();
        const response = await fetchSystemMessageList();
        handleLoaded();
        if (response && response.success && response.result) {
            setListItems(response.result);
        } else {
            handleError(response.error ?? 'Unknown error');
        }
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleNewItemClick = () => {
        if (props.onNew) {
            props.onNew();
        }
    }

    const handleItemSelection = (item: SystemMessageType) => {
        if (props.onRowClicked) {
            props.onRowClicked(item);
        }
    }

    const handleLoading = () => {
        setLoading(true);
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        setLoading(false);
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    return <>
        <DataTable loading={loading}
            dataKey="id"
            paginationRowsPerPage={itemsPerPage}
            onSelect={(row) => handleItemSelection(row.value)}
            onEdit={(row) => handleItemSelection(row)}
            onNew={handleNewItemClick}
            editButton={true}
            paginator={(listItems?.length || 0) > itemsPerPage}
            filter={true}
            allowExport={true}
            value={listItems}
            columns={[
                { header: "Id", field: "id" },
                { header: "Subject", field: "subject" },
                { header: "Delivery Type", field: "deliveryType.name" },
                { header: "Active From", field: "activeFrom", formatType: "date" },
                { header: "Active To", field: "activeTo", formatType: "date" },
                { header: "Active", field: "deleted", formatType: "yesno-inverse" }
            ]} />
    </>

}