import React, { useState, useEffect } from "react";
import { CapacityGridFieldOptionNumericValue, CapacityGridSearchResultType } from "../../../../interfaces/system/capacityGridType";
import ActionButton from "../../../core/actionButton";
import CapacityGridEditorRangeEdit from "./capacity-grid-editor-range-edit";

interface CapacityGridEditorRangeListProps {
    columnId: number,
    resultList?: CapacityGridFieldOptionNumericValue[],
    onUpdate: (data: CapacityGridFieldOptionNumericValue[]) => void
}

export default function CapacityGridEditorRangeList(props: CapacityGridEditorRangeListProps) {

    // state vars
    const [resultList, setResultList] = useState(props.resultList)
    const [selectedItem, setSelectedItem] = useState<CapacityGridFieldOptionNumericValue>()

    // hook setups
    useEffect(() => {
        init();
    }, [props.resultList])


    // component events
    const init = async () => {
        setResultList(props.resultList)
    }

    const handleItemSelection = (item: CapacityGridFieldOptionNumericValue) => {
        setSelectedItem({ ...item });
    }

    const handleNewItemButtonClick = () => {
        setSelectedItem({ id: 0, colour: '', from: 0, to: 0, deleted: false, isTotal: false, sortOrder: 99, columnId: props.columnId });
    }

    const handleItemUpdated = (item: CapacityGridFieldOptionNumericValue) => {
        if (resultList) {
            if (item.id === 0) {
                item.id = 0 - (resultList.length) - 1;
                resultList?.push(item);
                if (resultList) {
                    setResultList([...resultList]);
                }
                setSelectedItem(undefined);
                if (resultList) {
                    props.onUpdate(resultList);
                }
                return;
            }
            let items = resultList?.map(range => {
                if (range.id === item.id) {
                    return item;
                }
                return range;
            });
            setResultList(items);
            if (items) {
                props.onUpdate(items);
            }
        }
    }


    return <>
        <table className="condensed">
            <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th style={{ width: "100px" }}>Colour</th>
                    <th style={{ width: "100px" }}>Active</th>
                    <th style={{ width: "100px" }}>Is Total</th>
                    <th style={{ width: "100px" }}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {resultList?.map((range, index) => {
                    if (range.deleted) {
                        return <></>
                    }
                    return <tr key={`range_list_${index}`}>
                        <td>{range.from}</td>
                        <td>{range.to}</td>
                        <td style={{ backgroundColor: range.colour }}>&nbsp;</td>
                        <td>{!range.deleted ? "Yes" : "No"}</td>
                        <td>{range.isTotal ? "Yes" : "No"}</td>
                        <td className="button-fw">
                            <ActionButton onClick={() => { handleItemSelection(range) }} label={"Edit"} severity="secondary" size="small"></ActionButton>

                        </td>
                    </tr>
                })}
                <tr>
                    <td style={{ textAlign: 'right' }} colSpan={10}>
                        <ActionButton onClick={handleNewItemButtonClick} label={"New"} severity="secondary" size="small"></ActionButton>
                    </td>
                </tr>
            </tbody>
        </table>

        {selectedItem && <CapacityGridEditorRangeEdit selectedItem={selectedItem} onUpdate={handleItemUpdated}></CapacityGridEditorRangeEdit>}

    </>
}