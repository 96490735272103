import { Grid } from "@mui/material";
import React from "react";
import { EmergencyMetric } from "../../../interfaces/reporting/demandAndCapacity";
import Page from "../../core/page";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import DemandAndCapacityCurrentWaits from "./demand-and-capacity-current-waits";
import DemandAndCapacityDataQuality from "./demand-and-capacity-data-quality";
import DemandAndCapacityGauge from "./demand-and-capacity-gauge";
import DemandAndCapacityPanel from "./demand-and-capacity-panel";
import DemandAndCapacityPatientArrivalsChart from "./demand-and-capacity-patient-arrivals-chart";

export interface DemandAndCapacityEmergencyDashboardProps {
    emergencyMetrics: EmergencyMetric[]
    siteCode:string
}
interface EmergDashProps{
    emergencyMetric?: EmergencyMetric
}
export default function DemandAndCapacityEmergencyDashboard(props: DemandAndCapacityEmergencyDashboardProps) {

    const [emergencyMetrics, setEmergencyMetrics] = React.useState<EmergencyMetric[]>();
    const [siteCodes, setSiteCodes] = React.useState<string[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);

        React.useEffect(() => {
            setEmergencyMetrics(props.emergencyMetrics)
      }
      ,[props.emergencyMetrics])

      React.useEffect(() => {

            const sc = [props.siteCode].concat(props.emergencyMetrics.map(m => m.siteCode)).filter((x, i, a) => a.indexOf(x) === i)
            setSiteCodes(sc)      
      },[emergencyMetrics])
      React.useEffect(() => {
        setDataLoaded(true)
    },[siteCodes])

        
      if(!dataLoaded) return <>no data</>
    
      if(dataLoaded){
        const tabs: PagedTab[] = [];
        siteCodes.forEach(sc => {
            const emergencyData = emergencyMetrics ? emergencyMetrics.find(f => f.siteCode === sc) : undefined
    
            tabs.push({
                title: emergencyData?.service.name ?? '',
                children:<EmergDash emergencyMetric={emergencyData} />
            })
        });
        

        return <Page title="Live Emergency Data Dashboard"
        breadCrumbs={[{ label: 'Summary', url: `../${props.siteCode}` }, { label: 'Emergency' }]}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PagedTabs tabs={tabs}></PagedTabs>
                </Grid>

            </Grid>
        </Page>
      }

    function EmergDash (p:EmergDashProps) {
        const { emergencyMetric} = p;
        if(!emergencyMetric) return <></>
        return (
             <Grid container>
                                <Grid item xs={12} md={4} >
                                        <Grid item xs={12} >
                                            <DemandAndCapacityCurrentWaits emergencyMetric={emergencyMetric} key={`emerg1${emergencyMetric.siteCode}`} />
                                        </Grid>
                                        <Grid item md={9} xs={12} >
                                            <DemandAndCapacityGauge id={`emergd${emergencyMetric.siteCode}`} title="% of Patients in Dept <4 Hrs" MaxValue={100}
                                                minValue={emergencyMetric.percentageOfPatientsInDepartmentLt4Hours > 2 ? emergencyMetric.percentageOfPatientsInDepartmentLt4Hours - 2 : 0}
                                                value={emergencyMetric.percentageOfPatientsInDepartmentLt4Hours}
                                                steps={[35, 45, 20]}
                                                help="Percentage of patients seen within 4 hours. This figure includes patients who are waiting"
                                                format="percentage"
                                                inverted={true}
                                            />
                                        </Grid>
                                </Grid>
                                
                                <Grid item xs={12} md={4}>
                                    <Grid container justifyContent={"center"} spacing={1}>
                                        <Grid item md={9} xs={12} >
                                            <DemandAndCapacityGauge id={`emerg${emergencyMetric.siteCode}`} title="Patients Waiting" MaxValue={60} minValue={0}
                                                steps={[15,10,75]}
                                                value={emergencyMetric.patientsWaiting}
                                                help="No. Patients currently waiting in emergency department"
                                            />
                                        </Grid>
                                        <Grid item md={9} xs={12} >
                                            <DemandAndCapacityPanel id={`emerg_avg${emergencyMetric.siteCode}`}
                                                title="Average time In Department"
                                                value={emergencyMetric.currentAverageTimeInDepartment}
                                                format="time"
                                                help="Average time In Department"
                                                targetValue={0}
                                                range={{ red: { min: 240, max: 1000 }, amber: { min: 121, max: 239 }, green: { min: 0, max: 120 } }}
                                                secondaryPanel={{
                                                    value: emergencyMetric.totalAverageTimeInDepartment, targetValue: 240,
                                                    supportingText: `${emergencyMetric.attendeesSinceMidnight} Attendees since midnight`
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={9} xs={12} >
                                            <p >Today's Admitted Patients</p>
                                            <DemandAndCapacityPanel id={`emerg_conv${emergencyMetric.siteCode}`}
                                                title="Conversion Rate"
                                                value={emergencyMetric.conversionRate}
                                                format="percentage"
                                                targetValue={0}
                                                supportingText={`${emergencyMetric.patientsAdmitted} patients admitted`}
                                                range={{ red: { min: 70, max: 1000 }, amber: { min: 26, max: 70 }, green: { min: 0, max: 25 } }}
                                                help="Conversion Rate" />
                                        </Grid>
                                        <Grid item md={9} xs={12} >
                                            <DemandAndCapacityPanel id={`emerg_dta${emergencyMetric.siteCode}`}
                                                title="Time from Arrival to DTA"
                                                value={emergencyMetric.timeFromArrivalToAdmitDecision}
                                                format="time"
                                                targetValue={0}
                                                range={{ red: { min: 240, max: 1000 }, amber: { min: 200, max: 240 }, green: { min: 0, max: 240 } }}
                                                help="For all patients that have been admitted since midnight, this is their average time from arrival into the department to them having a decision to admit (DTA). The indicator will turn amber if the average exceeds 90 minutes, and then red if the average is greater than 150 minutes. This time is indicative of pressure in the ED itself, be it as a result of high volumes of patients or high acuity levels." />
                                        </Grid>
                                        <Grid item md={9} xs={12} >
                                            <DemandAndCapacityPanel id={`emerg_dta2${emergencyMetric.siteCode}`}
                                                title="Time from DTA to Admission"
                                                value={emergencyMetric.timeFromAdmitDecisionToAdmission}
                                                format="time"
                                                targetValue={0}
                                                range={{ red: { min: 240, max: 1000 }, amber: { min: 200, max: 240 }, green: { min: 0, max: 240 } }}
                                                help="For all patients that have been admitted since midnight, this is their average time from them receiving a decision to admit (DTA) to be admitted onto a ward. The indicator will turn amber if the average exceeds 90 minutes, and then red if the average is greater than 120 minutes. This time is indicative of issues arising in the ED as a result of bed availability on the wards." />
                                        </Grid>
                                        
                                    </Grid>
                                    
                                </Grid>
                                <Grid item md={4} xs={11}>
                                    <DemandAndCapacityPatientArrivalsChart emergencyMetric={emergencyMetric} />
                                </Grid>

                                <Grid item md={9} xs={12}>
                                </Grid>
                                <Grid item md={3}>
                                    <DemandAndCapacityDataQuality emergencyMetric={emergencyMetric} />
                                </Grid>
                            </Grid>
            )
                                    }
}