import { Grid } from "@mui/material"
import React, {useEffect,useState} from "react"
import useForm from "../../../../hooks/useForm"
import { APIUserPermissionType, APIUserType } from "../../../../interfaces/system/systemTypes"
import DomainEntityFormValidator from "../../../../validators/domain-entity-validator"
import TextBox from "../../../core/textBox"
import TickBox from "../../../core/tickBox"
import Loader from "../../../core/loading"
import OKCancelButtons from "../../../core/OKCancelButtons"
import BasicTab from "../../../core/basicTab"
import ActionButton from "../../../core/actionButton"

interface ApiUserEditProps {
    user?:APIUserType
    onSave: (user: APIUserType,valid:boolean) => void 
    onCancel: () => void 
  }

export default function ApiUserEdit(props: ApiUserEditProps) {
  
  const [selectedTabTitle, setSelectedTabTitle] = useState<string>()
  const [permissions, setPermissions] = useState<APIUserPermissionType[]>()
  const [validator] = useState(new DomainEntityFormValidator())
  const {
    validateForm, objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.user, validator: validator})
 

   useEffect(() => {
      setObjectValues(props.user)
      setPermissions(props.user?.permissions)
  },[props.user])
  const updatePermission = (e: any,index: number) => {
    
    if(!permissions) return
    
    const mapping = permissions[index] as any
    
    mapping[e.target.id] = e.target.value
    permissions[index] = mapping

    setPermissions([...permissions])
    
 }
const handleSave = async () =>{
  let isValid = validateForm(objectValues)
  
  props.onSave(objectValues, isValid)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
   

      const handleRemovePermission = (index:number) =>{
        if(!permissions) return
        const schedule = [...permissions]
      
        schedule.splice(index, 1)
        setObjectValues({...objectValues, permissions:[...schedule]})
        setPermissions(schedule)
      }

      const handleAddSchedule = () =>{
        if(!permissions) return
        const schedule = [...permissions]
      
        // schedule.splice(index, 0,{deleted:false,siteCode:''})
        schedule.push({deleted:false,siteCode:''})
        setObjectValues({...objectValues, permissions:[...schedule]})
        setPermissions(schedule)
      }

if(!objectValues)
        return <Loader loading={true} />
  
  const details= <>
          <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          <Grid item xs={2}><TextBox id="id"  label="Id" disabled={true} value={objectValues.id} validationField={validator?.id}  onChange={(e) => {setValues(e)}}/></Grid>
          <Grid item xs={2}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
          <Grid item xs={4}><TextBox id="key"  label="Key" disabled={true} value={objectValues.key} validationField={validator?.id}  onChange={(e) => {setValues(e)}}/></Grid>
          
          
          <Grid item xs={8}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={12} style={{marginTop:-15}}></Grid>
          <Grid item xs={8}><TextBox id="userName" focus={true} label="Username" value={objectValues.userName} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={8}><TextBox id="privateKey" focus={true} label="Password" value={objectValues.privateKey} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={8}><TextBox id="contactName" focus={true} label="Contact Name" value={objectValues.contactName} onChange={(e) => {setValues(e)}} /></Grid>
          <Grid item xs={8}><TextBox id="contactEmail" focus={true} label="Contact Email" type="email" value={objectValues.contactEmail} onChange={(e) => {setValues(e)}} /></Grid>
          
          <Grid item xs={8}><TextBox id="description" rows={5} label="Description" value={objectValues.description} onChange={(e) => {setValues(e)}} /></Grid>
      
          
        </Grid>
        
        </>

        const permissionsElem = <><br /> <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
        {React.Children.toArray(permissions?.map((item: APIUserPermissionType, index: number) => {
          return <>
            <Grid spacing={2} container  style={{padding:'5px 15px'}}>
              <Grid item xs={1}><TickBox id="deleted" label="" checked={item.deleted} onChange={(e)=>{updatePermission(e,index)}}/></Grid>
              <Grid item xs={3}><TextBox id="siteCode"  label="Site Code"  maxLength={16}   value={item.siteCode} onChange={(e)=>{updatePermission(e,index)}} /></Grid>
              <Grid item xs={2}>
                    <ActionButton severity="danger" variant="icon" disabled={index<1} label="Remove" onClick={()=>{handleRemovePermission(index)}}/>
                  </Grid>
                  
            </Grid>
          </>
        }))}
        <Grid spacing={2} container  style={{padding:'45px 25px'}}>
        <Grid item xs={2}><ActionButton severity="success" variant="icon" label="Add" onClick={handleAddSchedule}/></Grid>
        </Grid>
      </Grid>
      </>



return <>
<Grid container justifyContent="center">
    <BasicTab key='serv_' selectedTabTitle={selectedTabTitle} tabs={[{label:'User Details', component: <>{details}</> },
                  {label:'Permissions', component: <>{permissionsElem}</>},
                ]}
                  >
    </BasicTab>
      <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
        <Grid item xs={8}>
          <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />
        </Grid>     
      </Grid>     
              
</Grid>
</>
}
