import { useEffect, useState } from "react";
import { CapacityGridColumnType, CapacityGridColumnVisibilityTypes, CapacityGridType, createEmptyCapacityGridColumn } from "../../../../interfaces/system/capacityGridType";
import 'primeicons/primeicons.css';
import { Grid } from "@mui/material";
import CapacityGridColumnEditor from "./capacity-grid-column-editor";
import ActionButton from "../../../core/actionButton";
import { createNewDomainEntity } from "../../../../interfaces/domain/iDomainEntity";
import SortableList from "../../../core/sortableList";
import DebugVariable from "../../../core/debugVariable";
import { CapacityGridEditorAddSharedColumn } from "./capacity-grid-editor-add-shared-col";
import GroupBox from "../../../core/groupBox";

interface CapacityGridColumnListProps {
  grid: CapacityGridType,
  resultList?: CapacityGridColumnType[],
  onSelection?: (id: number) => void,
  onUpdate: (items: CapacityGridColumnType[]) => void,
  onNew: () => void,
  itemsPerPage?: number
}

export default function CapacityGridColumnList(props: CapacityGridColumnListProps) {
  // state vars
  const [resultList, setResultList] = useState<CapacityGridColumnType[]>()
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedGrid, setSelectedGrid] = useState<CapacityGridType>(props.grid);
  const [selectedColumn, setSelectedColumn] = useState<CapacityGridColumnType>();
  const [showSharedCols, setShowSharedCols] = useState(false);

  // hook setups
  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setResultList(props.resultList);
  }, [props.resultList])

  useEffect(() => {
    setSelectedGrid(props.grid);
  }, [props.grid])

  // component events
  const init = async () => {
    setResultList(props.resultList);
    setDataLoading(false);
    setShowSharedCols(false);
  }

  // event handlers
  const handleRowSelection = (item: CapacityGridColumnType) => {
    if (props.onSelection) {
      props.onSelection(item.id)
    }
    setSelectedColumn(item);
  }

  const handleColumnUpdated = (item: CapacityGridColumnType) => {
    if (resultList) {
      let updatedCols = resultList.map(col => {
        if (col.id === item.id) {
          return item;
        }
        return col;
      })
      setResultList(updatedCols);
      setSelectedColumn(undefined);
      props.onUpdate(updatedCols);
    }
  }

  const handleAddNewColumnClick = () => {
    if (resultList) {
      let visibilityType = createNewDomainEntity();
      let reportingType = createNewDomainEntity();
      let fieldType = createNewDomainEntity();
      visibilityType.id = CapacityGridColumnVisibilityTypes.CapacityGrid;
      reportingType.id = 2;
      fieldType.id = 1;
      let newItem = createEmptyCapacityGridColumn(undefined, undefined, undefined, (resultList?.length ?? 0) + 1);
      setResultList([...resultList, newItem]);
      setSelectedColumn(newItem);
      setShowSharedCols(false);
    }
  }

  const handleAddSharedColumnClick = async () => {
    setSelectedColumn(undefined);

    setShowSharedCols(true);
  }

  const handleAddSharedColumn = async (item: CapacityGridColumnType) => {
    setShowSharedCols(false);
    if (!resultList) {
      setResultList([]);
    }
    if (resultList) {
      item.sortOrder = resultList.length + 1;
      setResultList([...resultList, item]);
      setSelectedColumn(item);
      props.onUpdate([...resultList, item]);
    }
  }

  const handleReOrder = (items: CapacityGridColumnType[]) => {
    setResultList(items)
    props.onUpdate(items);
  }

  const handleItemRemoved = (itemRemoved: CapacityGridColumnType, newList: CapacityGridColumnType[]) => {
    if (resultList) {
      let newData = [...newList, { ...itemRemoved, deleted: true }];
      handleReOrder(newData);
      setSelectedColumn(undefined);
    }
  }

  return <>
    <Grid container spacing={4} justifyContent="left" style={{ padding: '15px 5px' }}>
      <Grid item xs={4} style={{ paddingTop: '3rem' }}>
        <DebugVariable hide={true} variable={selectedGrid}></DebugVariable>
        {(selectedGrid?.id ?? 0) <= 0 && <p>You will be able to add columns once the grid has been saved</p>}

        {(selectedGrid?.id ?? 0) > 0 && <SortableList data={resultList || undefined} onItemSelection={handleRowSelection} onRemove={handleItemRemoved} onReOrder={handleReOrder} showRemove={true}></SortableList>}

        {(selectedGrid?.id ?? 0) > 0 && <>
          <Grid container spacing={1} >
            <Grid item xs={6}>
              <ActionButton onClick={handleAddSharedColumnClick} label={"Add Shared Column"} severity="secondary" size="small"></ActionButton>

            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <ActionButton onClick={handleAddNewColumnClick} label={"Add New Column"} severity="secondary" size="small"></ActionButton>
            </Grid>
          </Grid>
        </>
        }
      </Grid>
      <Grid item xs={8}>
        <GroupBox title=" ">
          {selectedColumn && <CapacityGridColumnEditor column={selectedColumn} calculatedFieldNames={(selectedGrid?.columns?.map(c => { return c.name }) ?? [])} onUpdate={handleColumnUpdated}></CapacityGridColumnEditor>}

          {showSharedCols && <CapacityGridEditorAddSharedColumn onAdd={handleAddSharedColumn} currentItems={resultList ?? []}></CapacityGridEditorAddSharedColumn>}
        </GroupBox>
      </Grid>
    </Grid>

  </>
}