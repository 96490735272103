import { useEffect, useState } from "react";
import { DocumentUploadType, DocumentUploadTypes } from "../../../interfaces/system/documentUploadTypes";
import DebugVariable from "../../core/debugVariable";
import { Tree, TreeSelectionEvent } from "primereact/tree";
import { TreeNode } from "primereact/treenode";

export interface DocumentLibraryListProps {
    list: DocumentUploadType[],
    expandType?:number,
    onSelection?(itemId: number, event: TreeSelectionEvent): void
}

export function DocumentLibraryList(props: DocumentLibraryListProps) {
    const [uploadList, setUploadList] = useState(props.list);
    const [treeValues, setTreeValues] = useState<TreeNode[]>();

    useEffect(() => {
        buildTree();
    }, [props.list]);

    const buildTree = () => {
        if (!uploadList) return;
        const docTypes: DocumentUploadTypes[] = [DocumentUploadTypes.Help, DocumentUploadTypes.Service];
        const options: TreeNode[] = [];
        docTypes.forEach(t => {
            const children: TreeNode[] = [];
            uploadList.forEach(u => {
                if (u.documentTypeId === t) {
                    children.push({
                        key: `${u.id}`,
                        label: u.name,
                        icon: 'pi pi-file'
                    });
                }
            });
            const root: TreeNode = {
                key: `-${t}`,
                expanded:props.expandType === t,
                label: DocumentUploadTypes[t],
                icon: 'pi pi-folder',
                children: children
            };
            options.push(root);
        });
        setTreeValues(options);
    }

    const handleSelection = (event: TreeSelectionEvent) => {
        const itemId = parseInt(event.value?.toString() ?? '-1');
        if (itemId > 0 && props.onSelection) {
            props.onSelection(itemId, event);
        }
    }

    return <>
        <DebugVariable hide={true} variable={uploadList} />
        <p><strong>Document Library</strong> select an upload type below to view uploaded files:</p>
        <Tree selectionMode="single" value={treeValues} onSelectionChange={handleSelection} />
    </>
}