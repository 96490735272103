import React, { useEffect}  from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
 

interface LoadingProps {
    loading: boolean
    useBackdrop?:boolean
  }

const Loading:  React.FC<LoadingProps> = params => {
    const [open, setOpen] = React.useState(params.loading);
    
    useEffect(() => {setOpen(params.loading)},[params.loading]);

    // const handleClose = () => {
    //   setOpen(false);
    // };
 
    // if(open){
    //     return <React.Fragment></React.Fragment>
    // }
    //return <Backdrop className={classes.backdrop} open={open} onClick={handleClose}></Backdrop>
    if(params.useBackdrop===undefined || params.useBackdrop)
      return <Backdrop  open={open}  style={{zIndex:99999,color:'#2b59d6'}}>
          <CircularProgress color="inherit" size={80} thickness={4}/>
        </Backdrop>
    else
      return <>{open && <CircularProgress color="secondary" />}</>
}

export default Loading;