import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BasicTab from "../../core/basicTab";
import TextBox from "../../core/textBox";
import TickBox from "../../core/tickBox";
import OKCancelButtons from "../../core/OKCancelButtons";
import ActionButton from "../../core/actionButton";
import { ActionCardRecipientType, ActionCardServiceIdCheckResponseType, ActionCardType } from "../../../interfaces/system/actionCardType";
import Dropdown from "../../core/dropdown";
import { ActionCardRecipientEditor } from "./action-card-recipient-editor";
import { ActionCardValidator } from "../../../validators/action-card-validator";
import { FormType } from "../../../interfaces/system/formType";
import { ActionCardEventEdit } from "./action-card-event-edit";
import DebugVariable from "../../core/debugVariable";

interface ActionCardEditorProps {
    item: ActionCardType,
    form?: FormType,
    onSave?: (item: ActionCardType, valid: boolean) => void,
    checkForDuplicateServiceId: (serviceId: number) => Promise<ActionCardServiceIdCheckResponseType>,
    onCancel?: () => void
}
export function ActionCardEditor(props: ActionCardEditorProps) {
    const [selectedItem, setSelectedItem] = useState<ActionCardType>(props.item);
    const [form, setForm] = useState(props.form);
    const [modelValidator, setModelValidator] = useState(new ActionCardValidator());
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [serviceIdInUseById, setServiceIdInUseById] = useState<number>(0);

    useEffect(() => {
        setSelectedItem(props.item);
        setIsValid(modelValidator.validate(props.item));
    }, [props.item]);


    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem));
    }

    const handleServiceIdUpdated = async (e: any) => {
        if (!selectedItem) return;

        const response = await props.checkForDuplicateServiceId(e.target.value);
        let serviceInUse = false;
        if (response && response.exists) {
            serviceInUse = true;
            setIsValid(false);
            setServiceIdInUseById(response.actionCardId);
        }

        if (!serviceInUse) {
            let updatedItem: ActionCardType = { ...selectedItem, serviceId: e.target.value };
            setSelectedItem(updatedItem);
            setDirty(true);
            setIsValid(modelValidator.validate(selectedItem));
            setServiceIdInUseById(0);
        }
    }

    const handleCancelClicked = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }
    const handleSave = async () => {
        if (props.onSave) {
            props.onSave(selectedItem, isValid);
        }
    }

    const handleRecipientsUpdated = (recipients: ActionCardRecipientType[], valid: boolean) => {
        //console.clear(); console.log(recipients, "Updated recipient");
        if (!selectedItem || !valid) return;
        let updatedItem = { ...selectedItem, recipients: recipients };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem) && valid);
    }

    const handleEventsUpdated = (recipient: ActionCardRecipientType, valid: boolean) => {
        //console.clear(); console.log(recipient.events, "Updated recipient events");
        if (!selectedItem || !valid) return;

        let newList: ActionCardRecipientType[] = [];
        selectedItem.recipients?.forEach(r => {
            if (r.id === recipient.id) {
                newList.push(recipient);
            } else {
                newList.push(r);
            }
        });
        if ((selectedItem.recipients?.length ?? 0) <= 0) {
            newList.push(recipient);
        }

        let updatedItem: ActionCardType = { ...selectedItem, recipients: newList };
        setSelectedItem(updatedItem);
        setDirty(true);
        setIsValid(modelValidator.validate(selectedItem) && valid);
    }

    const EditElement = <>
        <Grid container spacing={2} justifyContent="left" style={{ padding: "1rem 0.5rem" }}>
            <Grid item xs={12}>
                <p><strong>Edit Action Card:</strong></p>
            </Grid>
            <Grid item xs={2}>
                <TextBox id="id" label="Id" value={selectedItem.id} disabled={true} />
            </Grid>
            <Grid item xs={2}>
                <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} disabled={selectedItem.id <= 0} />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={6} md={4}>
                <Dropdown label="Service" id="serviceId" value={selectedItem.serviceId} onChange={handleServiceIdUpdated} validationField={modelValidator.serviceId} includeBlankOption={true} optionName="service-list" />
                {serviceIdInUseById > 0 &&
                    <p className="error-text">This service ID already has action card set, <a href={`/admin/action-cards/${serviceIdInUseById}`}> use existing item</a></p>}
            </Grid>
        </Grid>
        <p>&nbsp;</p>
    </>

    const tabs = [
        { label: 'Action Card Details', component: <>{EditElement}</> }
    ];

    // add tabs
    if (selectedItem.id > 0) {
        tabs.push({ label: 'Recipient Groups', component: <ActionCardRecipientEditor item={selectedItem} form={form} onUpdate={handleRecipientsUpdated} /> });
    }
    if ((selectedItem?.recipients?.length ?? 0) > 0) {
        tabs.push({ label: 'Events', component: <ActionCardEventEdit item={selectedItem} form={form} onUpdate={handleEventsUpdated} /> });
    }

    return <>
        <Grid container>
            <BasicTab key='tabs' tabs={tabs}/>

            {dirty && <OKCancelButtons onOK={handleSave} onCancel={handleCancelClicked} />}

            {(!dirty) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                <div style={{ float: 'left', marginRight: '5px' }}>
                    <ActionButton onClick={handleCancelClicked} label="Back" severity="secondary"></ActionButton>
                </div>
            </div>}
        </Grid>
    </>
}