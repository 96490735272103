import { FormValidation, FormValidationField, ValidationType } from '../extensions/formValidation';
import { DistributionListMemberType, DistributionListType } from '../interfaces/system/distributionListTypes';
import { ValidatorType } from './ValidatorType'

export class DistributionListValidator implements ValidatorType {

    validator = new FormValidation([])
    name = new FormValidationField('', '')

    initialiseValidationFields = (objectValues: DistributionListType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.name)
    }

    validate = (validatorObjectValues: DistributionListType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}
export class DistributionListMemberValidator implements ValidatorType {

    validator = new FormValidation([])
    name = new FormValidationField('', '', ValidationType.Required);
    emailAddress = new FormValidationField('', '', ValidationType.Email);

    initialiseValidationFields = (objectValues: DistributionListMemberType) => {
        this.validator = new FormValidation([])
        this.name = this.validator.createTextField(objectValues.userDisplayName)
        this.emailAddress = this.validator.createEmailField(objectValues.userEmailAddress)
    }

    validate = (validatorObjectValues: DistributionListMemberType): boolean => {
        this.initialiseValidationFields(validatorObjectValues)
        const isValid = this.validator.isValid()

        return isValid
    }
}