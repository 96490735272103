import { Grid } from "@mui/material";
import { Panel } from "primereact/panel";
import SortableList, { SortableItem } from "../../components/core/sortableList";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function AdminContainer() {
    const navigate = useNavigate();

    const navItems: SortableItem[] = [
        { id: 0, sortOrder: 1, displayName: "Users", icon: "users" },
        { id: 1, sortOrder: 2, displayName: "Services", icon: "building" },
        { id: 2, sortOrder: 3, displayName: "Forms", icon: "book" },
        { id: 3, sortOrder: 4, displayName: "Capacity Grids", icon: "th-large" },
        { id: 4, sortOrder: 5, displayName: "System", icon: "sliders-h" },
    ];

    const [selectedItem, setSelectedItem] = useState<SortableItem>(navItems[0]);

    const handleListSelection = (selectedItem: SortableItem) => {
        setSelectedItem(selectedItem);
    }

    const createTile = (title: string, description: string, url: string) => {
        return <>
            <Grid item xs={6} sm={3} lg={4}>
                <div className="link-tile" onClick={() => { navigate(url); }}>
                    <p className="link-tile--title">{title}</p>
                    <p>{description}</p>
                </div>
            </Grid>
        </>
    }

    return <>
        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel header="Admin Options:">
                    <Grid container columnSpacing={2} style={{ paddingTop: "1rem" }}>
                        <Grid item xs={12} md={3}>
                            <SortableList data={navItems} onItemSelection={handleListSelection} onReOrder={() => { }} disableSort={true}></SortableList>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container columnSpacing={2}>
                                {selectedItem.id === 0 && <>
                                    {createTile("Edit Users", "View/Edit users of the system including their access rights and password.", "/admin/user")}
                                    {createTile("Pending Access Requests", "View/Action any pending access requests requested by users.", "/admin/user-pending-access")}
                                    {createTile("Pending User Registrations", "View/Action any new user requests.", "/admin/user-pending-registration")}
                                    {createTile("User Roles", "View/Edit system user roles.", "/admin/user-pending-registration")}

                                </>}

                                {selectedItem.id === 1 && <>
                                    {createTile("Services", "View/Edit all services within the system.", "/admin/service")}
                                    {createTile("Service Categories", "View/Edit all service categories within the system.", "/admin/service-categories")}
                                    {createTile("Bed Types", "View/Edit all bed types within the system.", "/admin/service")}
                                    {createTile("Areas", "View/Edit all areas within the system.", "/admin/areas")}
                                    {createTile("Features", "View/Edit service features.", "/admin/service-features")}
                                    {createTile("Trusts", "View/Edit service trusts.", "/admin/service-trusts")}
                                    {createTile("Bed Type", "View/Edit Bed Types.", "/admin/bed-types")}
                                    {createTile("Funding Type", "View/Edit Funding Types.", "/admin/funding-types")}

                                </>}

                                {selectedItem.id === 2 && <>
                                    {createTile("Forms", "View/Edit all forms within the system.", "/admin/form")}
                                    {createTile("Form Results", "View/Edit available form result options.", "/admin/form-reporting-category")}

                                </>}

                                {selectedItem.id === 3 && <>
                                    {createTile("Capacity Grids", "View/Edit all forms within the system.", "/admin/capacity-grid")}
                                    {createTile("Shared Columns", "View/Edit capacity grid shared columns/fields.", "/admin/capacity-grid-shared-fields")}

                                </>}

                                {selectedItem.id === 4 && <>
                                    {createTile("Distribution Lists", "View/Edit email distribution lists.", "/admin/distribution-lists")}
                                    {createTile("Distribution List Members", "Edit members of distribution lists.", "/admin/distribution-list-members")}
                                    {createTile("System Messages", "Edit members of distribution lists.", "/admin/system-messages")}
                                    {createTile("Automated Reports", "View/Edit automated reports.", "/admin/automated-reports")}

                                </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>
}