import { useEffect, useState } from "react";
import { CapacityGridSubmissionOverviewType, CapacityGridType } from "../../interfaces/system/capacityGridType";
import { ServiceType } from "../../interfaces/system/serviceType";
import { fetchCapacityGrid } from "../../services/capacity-grid-service";
import { fetchDailyReport } from "../../services/reporting/capacity-grid-service";
import { fetchService } from "../../services/systemService";
import { CapacityGridDailyReportTable } from "../reports/capacity-grid/capacity-grid-daily-report-table";

export interface CapacityGridReportForDivisionProps {
    divisionId: number
    categoryIds:number[]
}
export function CapacityGridReportForDivision(props: CapacityGridReportForDivisionProps) {
    const [divisionId, setDivisionId] = useState(props.divisionId);
    // state vars
    const [capacityGrids, setCapacityGrids] = useState<CapacityGridType[]>();
    const [services, setServices] = useState<ServiceType[]>();
    const [submissionData, setSubmissionData] = useState<CapacityGridSubmissionOverviewType[]>();
    const [categoryIds, setCategoryIds] = useState(props.categoryIds);

    useEffect(() => {
        if (props.divisionId > 0) {
            setDivisionId(props.divisionId);
            loadServiceDetails();
        }
    }, [props.divisionId]);

    useEffect(() => {
        setCategoryIds(props.categoryIds)
    }, [props.categoryIds]);

    useEffect(() => {
        if (services) {
            loadCapacityGrid();
        }
    }, [services]);

    useEffect(() => {
        if (capacityGrids) {
            loadCapacityGridSubmissionData();
        }
    }, [capacityGrids]);

    const loadServiceDetails = async () => {
        const response = await fetchService(divisionId);
        if (response && response.success && response.result) {
            setServices([response.result]);
        }
    }

    const loadCapacityGrid = async () => {
        if (services && (services[0].capacityGridId ?? 0) > 0) {
            const response = await fetchCapacityGrid((services[0].capacityGridId ?? 0), false);
            if (response && response.success && response.result) {
                setCapacityGrids([response.result]);
            }
        }
    }

    const loadCapacityGridSubmissionData = async () => {
        if (capacityGrids && services) {
            const response = await fetchDailyReport([services[0].id],categoryIds);
            if (response && response.success && response.result) {
                setSubmissionData(response.result);
            }
        }
    }

    //
    if (!capacityGrids || !services || !submissionData) {
        return <></>
    }
    return <>
        <div style={{ padding: '1rem' }}>
            <p>Latest capacity grid data (<em>{capacityGrids[0].name}</em>):</p>
            <CapacityGridDailyReportTable CapacityGrids={capacityGrids} Data={submissionData} Services={services} DisplayVertical={true} />
        </div>
    </>
}