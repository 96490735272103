import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

export interface PasswordComplexityFeedbackProps {
    password: string,
    passwordConfirm: string,
    OnValidate: (result: boolean) => void
}
export function PasswordComplexityFeedback(props: PasswordComplexityFeedbackProps) {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isValid, setIsValid] = useState(false);
    const classNameFail = "fail";
    const classNamePass = "pass";
    const classNameNeutral = "root";
    const minPasswordLength = 8;

    useEffect(() => {
        setPassword(props.password);
    }, [props.password]);

    useEffect(() => {
        setPasswordConfirm(props.passwordConfirm);
    }, [props.passwordConfirm]);

    useEffect(() => {
        validate();
    }, [password, passwordConfirm]);


    const validate = () => {
        const valid = isPasswordValid();
        setIsValid(valid);
        props.OnValidate(valid);
    }

    const isPasswordValid = (): boolean => {
        return isValidLength() && isValidNumeric() && isValidUpperCase() && passwordsMatch();
    }
    const passwordsMatch = (): boolean => {
        return password === passwordConfirm;
    }
    const isValidLength = () => {
        return password.length >= minPasswordLength;
    }
    const isValidUpperCase = () => {
        const validUpper = /[A-Z]/.test(password ?? '');
        return validUpper;
    }
    const isValidNumeric = () => {
        const validNumber = /\d{2,}/.test(password ?? '');
        return validNumber;
    }

    const getValidationResultLength = () => {
        if (!password) {
            return classNameNeutral;
        }
        return isValidLength() ? classNamePass : classNameFail;
    }

    const getValidationResultNumeric = () => {
        if (!password) {
            return classNameNeutral;
        }
        return isValidNumeric() ? classNamePass : classNameFail;
    }

    const getValidationResultUppercase = () => {
        if (!password) {
            return classNameNeutral;
        }
        return isValidUpperCase() ? classNamePass : classNameFail;
    }

    return <>
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
                <div style={{ textAlign: 'center' }}>Password requirements</div>
            </Grid>
            <Grid item xs={4}>
                <div className={getValidationResultLength()} style={{ textAlign: 'center' }}>8+</div>
            </Grid>
            <Grid item xs={4}>
                <div className={getValidationResultUppercase()} style={{ textAlign: 'center' }}>A</div>
            </Grid>
            <Grid item xs={4}>
                <div className={getValidationResultNumeric()} style={{ textAlign: 'center' }}>0-9</div>
            </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={4}>
                <div style={{ textAlign: 'center' }}>{minPasswordLength} characters</div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ textAlign: 'center' }}>1 uppercase</div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ textAlign: 'center' }}>2+ number</div>
            </Grid>
            <Grid item xs={12}><hr></hr></Grid>
        </Grid>
    </>
}