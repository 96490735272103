import { Grid } from "@mui/material";
import { Panel } from "primereact/panel";
import ActionButton from "../../components/core/actionButton";
import Feedback, { FeedbackType } from "../../components/core/feedback";

export function AccessDeniedContainer() {

    const handleLogin =()=>{
        window.location.href="/login"
    }

    return <>

        <Grid container justifyContent="center" spacing={1} style={{ paddingTop: "2rem" }}>
            <Grid item xs={12} lg={9}>
                <Panel header="Access Denied">
                    <Grid container columnSpacing={2} style={{ paddingTop: "1rem" }}>
                        <Grid item xs={12}>
                            <Feedback type={FeedbackType.NoAccess} message={"You are not authorised to access this page. If you require access please contact RCMT."}></Feedback>
                            <p></p>
                        </Grid>
                        <Grid item xs={12}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}><ActionButton style={{width:'100%'}} severity="success"  onClick={handleLogin} label="Log in"/></Grid>
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>
}