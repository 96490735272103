import { useEffect, useState } from "react";
import { DistributionListMembersDetailsResultType, UpdateDistributionListMemberType } from "../../../../interfaces/system/distributionListTypes"
import { fetchDistributionListMemberDetails, fetchDistributionListMembers, updateDistributionListMember } from "../../../../services/distribution-list-service";
import { Grid } from "@mui/material";
import OKCancelButtons from "../../../core/OKCancelButtons";
import ActionButton from "../../../core/actionButton";
import TextBox from "../../../core/textBox";
import TickBox from "../../../core/tickBox";
import { DomainEntityType } from "../../../../interfaces/domain/iDomainEntity";
import { DistributionListMemberAddToList } from "./distribution-list-member-add-to-list";
import DataTable from "../../../core/dataTable";
import FeedBackText from "../../../core/feedbackText";

export interface DistributionListMemberEditorProps {
    selectedId?: number,
    selectedEmailAddress?: string,
    onSaved: (item: DistributionListMembersDetailsResultType) => void,
    onBackClicked: () => void,
    onError: (message: string) => void,
    onLoading?: () => void,
    onLoaded?: () => void
}
export function DistributionListMemberEditor(props: DistributionListMemberEditorProps) {
    const [selectedItemId, setSelectedItemId] = useState(props.selectedId);
    const [selectedItemEmailAddress, setSelectedItemEmailAddress] = useState(props.selectedEmailAddress);
    const [selectedItem, setSelectedItem] = useState<DistributionListMembersDetailsResultType>();
    const [isValid, setIsValid] = useState(true);
    const [dirty, setDirty] = useState(false);
    const [listsToRemoveFrom, setListsToRemoveFrom] = useState<number[]>();
    const [listsToAddTo, setListsToAddTo] = useState<DomainEntityType[]>();

    // hooks
    useEffect(() => {
        console.clear();
        init();
        window.addEventListener('popstate', function () {
            props.onBackClicked()
        }, false);
    }, []);

    const init = async () => {
        if ((selectedItemId ?? 0) > 0 || selectedItemEmailAddress !== '') {
            handleLoading();
            const response = await fetchDistributionListMemberDetails(selectedItemId, selectedItemEmailAddress, false, false);
            handleLoaded();
            if (response && response.success && response.result) {
                setSelectedItem(response.result);
            } else {
                handleError(response.error ?? 'Unknown error');
            }
        }
    }


    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedGrid = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedGrid);
        setDirty(true);
        //setIsValid(modelValidator.validate(selectedItem));
    }
    const handleFieldUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedGrid = { ...selectedItem, [e.target.id]: e.target.value === '' ? undefined : e.target.value };
        setSelectedItem(updatedGrid);
        setDirty(true);
        //setIsValid(modelValidator.validate(selectedItem));
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleBackClicked = () => {
        if (props.onBackClicked) {
            props.onBackClicked();
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleCancel = async () => {
        if (props.onBackClicked) {
            props.onBackClicked()
        }
    }
    const handleSave = async () => {
        if (selectedItem) {
            handleLoading();
            const saveData: UpdateDistributionListMemberType = { originalEmailAddress: props.selectedEmailAddress, userId: selectedItemId, displayName: selectedItem.userDisplayName, emailAddress: selectedItem.userEmailAddress, addToListIds: listsToAddTo?.map(x => x.id) ?? undefined, removeFromListIds: listsToRemoveFrom, deleted:selectedItem.deleted };
            const response = await updateDistributionListMember(saveData);
            if (response && response.success && response.result && response.result.success) {
                if (selectedItem.deleted) {
                    selectedItem.distributionLists = [];
                }
                const response = await fetchDistributionListMemberDetails(selectedItemId, selectedItemEmailAddress, undefined, false);
                const response2 = await fetchDistributionListMembers(false);
                handleLoaded();
                props.onSaved(selectedItem);
                return;
            }
            handleLoaded();
            handleError(response?.error ?? "Unknown error");
        }
    }

    
    const handleRemoveUserFromList = (listId: number) => {
        let ids = [...listsToRemoveFrom ?? []];
        if (!ids.includes(listId)) {
            ids.push(listId);
            setDirty(true);
        }
        setListsToRemoveFrom(ids);
    }
    const handleAddUserToList = (item: DomainEntityType) => {
        let ids = [...listsToAddTo ?? []];
        if (!ids.includes(item)) {
            ids.push(item);
            setDirty(true);
        }
        setListsToAddTo(ids);
    }

    if(selectedItem && !selectedItem.distributionLists)
        return <>
        
        <FeedBackText show={true} severity="error" message="The selected user is not a member of any distribution lists" />
        <OKCancelButtons okHidden={true} onOK={handleSave} onCancel={handleCancel} />
        <br></br>
        </>


    if (selectedItem) {
        return <>

            <Grid container>

                <Grid container spacing={2} justifyContent="left" style={{ padding: '15px 5px' }}>
                    <Grid item xs={2}>
                        <TickBox id="deleted" label="Deleted" checked={selectedItem.deleted} onChange={handleDeletedUpdated} />
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={12}>
                        <TextBox id="userDisplayName" disabled={(selectedItemId ?? 0) > 0} label="Display Name" value={selectedItem.userDisplayName} onChange={handleFieldUpdated} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox id="userEmailAddress" disabled={(selectedItemId ?? 0) > 0} label="Email Address" value={selectedItem.userEmailAddress} onChange={handleFieldUpdated} />
                    </Grid>

                    <Grid item xs={12}>
                        <hr style={{ margin: '2rem 0' }} />
                        <h3 className="page--title"><span className="pi pi-plus"></span> Add to Distribuiton List</h3>
                    </Grid>

                    <DistributionListMemberAddToList existingDistListIds={(selectedItem?.distributionLists?.length ?? 0) > 0 ? selectedItem?.distributionLists.map(dl => { return dl.id; }) : undefined} onAddToList={handleAddUserToList} onError={handleError} onLoaded={handleLoaded} onLoading={handleLoading} />


                    <Grid item xs={12}>
                        <hr style={{ margin: '2rem 0' }} />
                        <h3 className="page--title"><span className="pi pi-envelope"></span> Current Distribuiton Lists</h3>


                        <DataTable  loading={false} dataKey="id" columns={[
                        { header: "Name", field: "name" },
                        { header: "Description", field: "description", }]} paginationRowsPerPage={25}
                        editButton={false} paginator={(selectedItem?.distributionLists?.length || 0) > 25} filter={true} allowExport={false} deleteButton={true} onDelete={(member:any) => { handleRemoveUserFromList(member.id) } }
                        value={selectedItem?.distributionLists.filter(f=> !listsToRemoveFrom?.includes(f.id))}
                        />

                    </Grid>


                </Grid>

                {dirty && isValid && <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />}

                {(!dirty || !isValid) && <div style={{ margin: '10px 10px 10px 0', width: '100%' }}>
                    <div style={{ float: 'left', marginRight: '5px' }}>
                        <ActionButton onClick={handleBackClicked} label="Back" severity="secondary"></ActionButton>
                    </div>
                </div>}
            </Grid>
        </>
    }
    return <></>
}