import { Grid } from "@mui/material";
import SortableList from "../../core/sortableList";
import { ActionCardEventType, ActionCardEventTypes, ActionCardRecipientType, ActionCardType, createEmptyActionCardEvent } from "../../../interfaces/system/actionCardType";
import { FormFieldOptionType, FormFieldType, FormType } from "../../../interfaces/system/formType";
import { useEffect, useState } from "react";
import BasicTab from "../../core/basicTab";
import TickBox from "../../core/tickBox";
import Dropdown from "../../core/dropdown";
import DebugVariable from "../../core/debugVariable";
import { ActionCardEventValidator } from "../../../validators/action-card-validator";
import ActionButton from "../../core/actionButton";
import { EditorTextChangeEvent } from "primereact/editor";
import { TagBuilder } from "../../core/tagBuilder";

export interface ActionCardEventEditProps {
    item: ActionCardType,
    form?: FormType,
    onUpdate: (recipient: ActionCardRecipientType, valid: boolean) => void
}

export function ActionCardEventEdit(props: ActionCardEventEditProps) {
    const [selectedActionCard, setSelectedActionCard] = useState(props.item);
    const [selectedRecipient, setSelectedRecipient] = useState<ActionCardRecipientType>();
    const [selectedFormField, setSelectedFormField] = useState<FormFieldType>();
    const [selectedItem, setSelectedItem] = useState<ActionCardEventType>();
    const [form, setForm] = useState(props.form);
    const [modelValidator, setModelValidator] = useState(new ActionCardEventValidator());
    const [isValid, setIsValid] = useState(false);
    // for the HTML editor to work we cannot directly access the current item as it creates a loop, instead store the html value and set in 'triggerUpdate' method
    const [htmlActionTextValue, setHtmlActionTextValue] = useState('');

    useEffect(() => {
        setSelectedActionCard(props.item);
    }, [props.item]);

    useEffect(() => {
        if (selectedItem) {
            setIsValid(modelValidator.validate(selectedItem));
            // store item's action text in HTML editor
            setHtmlActionTextValue(selectedItem.actionText);
        }
    }, [selectedItem]);

    const handleHtmlEditorUpdated = (e: EditorTextChangeEvent) => {
        setHtmlActionTextValue(e.htmlValue ?? '');
    }

    const handleDeletedUpdated = (e: any) => {
        if (!selectedItem) return;
        let updatedItem = { ...selectedItem, deleted: e.target.checked ? true : false };
        setSelectedItem(updatedItem);
    }

    const triggerUpdate = () => {
        if (!selectedRecipient || !selectedItem) return;
        selectedItem.actionText = htmlActionTextValue;
        let newList: ActionCardEventType[] = [];
        selectedRecipient.events?.forEach(r => {
            if (r.id === selectedItem.id) {
                newList.push(selectedItem);
            } else {
                newList.push(r);
            }
        });

        let updatedItem = { ...selectedRecipient, events: newList };
        props.onUpdate(updatedItem, true);
        setSelectedRecipient(updatedItem);
    }

    const handleRecipientSelected = async (e: any) => {
        const id = parseInt(e.value);
        setSelectedItem(undefined);
        setSelectedRecipient(undefined);
        setSelectedFormField(undefined);
        if (selectedActionCard.recipients) {
            const r = selectedActionCard.recipients.find(r => r.id === id);
            if (r) {
                setSelectedRecipient(r);
            }
        }
    }

    const handleSelectEscalationEvent = (reportingId: number) => {
        let esclationEvent = selectedRecipient?.events?.find(e => e.resultTypeId === ActionCardEventTypes.Escalation && e.reportingLevelOptionId === reportingId)
        if (!esclationEvent) {
            esclationEvent = createEmptyActionCardEvent(selectedRecipient?.id ?? 0, ActionCardEventTypes.Escalation);
            esclationEvent.id = 0 - ((selectedRecipient?.events?.length ?? 1) + 1);
            selectedRecipient?.events?.push(esclationEvent);
        }
        esclationEvent.reportingLevelOptionId = reportingId;
        if (esclationEvent) {
            setSelectedItem(esclationEvent);
        }
    }

    const handleSelectFormFieldEvent = (option: FormFieldOptionType) => {
        let esclationEvent: ActionCardEventType | undefined = selectedRecipient?.events?.find(e => e.resultTypeId === ActionCardEventTypes.FormField && e.formFieldId === (selectedFormField?.id ?? -99) && e.formResultId === option.id);
        if (!esclationEvent) {
            esclationEvent = createEmptyActionCardEvent(selectedRecipient?.id ?? 0, ActionCardEventTypes.FormField);
            esclationEvent.id = 0 - ((selectedRecipient?.events?.length ?? 1) + 1);
            esclationEvent.formFieldId = selectedFormField?.id ?? 0;
            esclationEvent.formResultId = option.id;
            selectedRecipient?.events?.push(esclationEvent);
        }

        if (esclationEvent) {
            setSelectedItem(esclationEvent);
        }
    }

    const handleUpdateEventClick = () => {
        if (isValid) {
            triggerUpdate();
        }
    }

    const EditElement = () => {
        return <>
            <Grid container spacing={1} justifyContent="left">
                <Grid item xs={8}>
                    <h3 style={{ marginTop: '0', fontWeight: 'normal' }}>
                        {(selectedItem?.id ?? 0) <= 0 ? 'Create new' : 'Edit'} <strong>{selectedItem?.resultTypeId === ActionCardEventTypes.Escalation ? 'Escalation' : 'Form Value'}</strong> event for <strong>{selectedRecipient?.name}</strong> recipient group.
                    </h3>
                </Grid>
                {/*<Grid item xs={2}>*/}
                {/*    <TextBox id="id" label="Id" value={selectedItem?.id} disabled={true} />*/}
                {/*</Grid>*/}
                <Grid item xs={4} >
                    <div style={{ float: 'right', display: 'inline-block' }}>
                        <TickBox id="deleted" label="Deleted" checked={selectedItem?.deleted} onChange={handleDeletedUpdated} disabled={(selectedItem?.id ?? 0) <= 0} />
                    </div>
                </Grid>
                <Grid item xs={8}></Grid>

                <Grid item xs={12}>
                    <TagBuilder editor={true} id="actionText" value={selectedItem?.actionText} onChange={handleHtmlEditorUpdated} label="Action Text" tags={[]} />
                </Grid>

                <Grid item xs={12}>
                    <div style={{ float: 'right' }}>
                        <ActionButton label={(selectedItem?.id ?? 0) > 0 ? 'Update' : 'Add'} severity="info" size="small" onClick={handleUpdateEventClick} disabled={htmlActionTextValue === ''} />
                    </div>
                    <DebugVariable variable={selectedItem} title="Selected event" hide={true} />

                </Grid>
            </Grid>
        </>;
    }

    const ListEscalationEventsElement = <>
        {selectedRecipient &&
            <>
                <Grid container spacing={4} justifyContent="left" style={{ padding: "2rem 0.5rem" }}>
                    <Grid item xs={6} sm={4} lg={3}>
                        <SortableList data={[1, 2, 3, 4].map(id => { return { id: id, sortOrder: id, deleted: false, name: `Level: ${id}` } })} onItemSelection={(e) => { handleSelectEscalationEvent(e.id); }} disableSort={true} onReOrder={() => { }} />
                    </Grid>
                    <Grid item xs={6} sm={8} lg={9}>
                        {selectedItem && EditElement()}
                    </Grid>
                </Grid>
            </>
        }
    </>

    const ListFormValues = <>
        {selectedFormField &&
            <>
                <Grid container spacing={4} justifyContent="left">
                    <Grid item xs={5}>
                        <SortableList data={selectedFormField.options ?? []} onItemSelection={(e) => { handleSelectFormFieldEvent(e as FormFieldOptionType); }} disableSort={true} onReOrder={() => { }} />
                    </Grid>
                    <Grid item xs={7}>
                        {selectedItem && EditElement()}
                    </Grid>
                </Grid>
            </>
        }
    </>

    const ListFormFieldsElement = <>
        {selectedRecipient &&
            <>
                <Grid container spacing={1} justifyContent="left" style={{ padding: "2rem 0.5rem" }}>
                    <Grid item xs={3}>
                        <SortableList data={form?.fields ?? []} onItemSelection={(e) => { setSelectedFormField(e as FormFieldType); setSelectedItem(undefined); }} disableSort={true} onReOrder={() => { }} />
                        {(form?.fields?.length ?? 0) <= 0 && <p className="error-text">Service form contains no fields.</p>}
                    </Grid>
                    <Grid item xs={9}>
                        {ListFormValues}
                    </Grid>

                </Grid>

            </>
        }
    </>

    if (!form) {
        return <>Awaiting service selection</>
    }

    return <>
        <DebugVariable hide={true} variable={selectedRecipient} title="Selected recipient" />
        <Grid container spacing={2} justifyContent="left" style={{ padding: "1rem 0.5rem" }}>

            <Grid item xs={6} lg={4}>
                <p><strong>Select Recipient Group:</strong></p>
                <Dropdown label="Recipient" id="recipientId" value={selectedRecipient?.id} includeBlankOption={true} options={selectedActionCard.recipients?.map(r => { return { id: r.id, code: r.id + '', name: r.name } }) ?? []} onSelection={handleRecipientSelected} />
            </Grid>
            <Grid item xs={6} lg={8}>
                <p>&nbsp;</p>
                {selectedRecipient && <><p>Selected Recipient: <strong>{selectedRecipient?.name}</strong><br />{selectedRecipient.description }</p></>}
            </Grid>


            {selectedRecipient &&
                <>
                    <Grid item xs={12}>
                        <BasicTab key='tabs' tabs={[
                            { label: 'Escalation Level Events', component: <>{ListEscalationEventsElement}</> },
                            { label: 'Form Field Events', component: <>{ListFormFieldsElement}</> }
                        ]}>

                        </BasicTab>
                    </Grid>
                </>
            }
            {!selectedRecipient &&
                <Grid item xs={12}>
                    <p>Select a recipient to edit events for that recipient.</p>
                </Grid>
            }
        </Grid>
    </>
}