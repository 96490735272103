import { Checkbox, FormControlLabel } from '@mui/material'
import React, {useState, useEffect, ReactNode} from 'react'

interface ITickBoxParams {
    id?:string
    label:string
    labelElement?:ReactNode
    checked?: boolean
    onChange: (e: React.ChangeEvent<{ value: unknown }>) => void
    disabled?:boolean | false
  }


const TickBox:  React.FC<ITickBoxParams> = (params) => {
    const  [checked, setChecked] = useState(params.checked||false);
    useEffect(() => {
      setChecked(params.checked||false)
    },[params.checked])

    const handleChange = (event: any) => {
            setChecked(event.target.checked)
            event.target.type = "checkbox"
            params.onChange(event)
    }

    return <FormControlLabel
    control={<Checkbox color="primary" 
    id={params.id}
        checked={checked}
        disabled={params.disabled}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />}
    label={params.labelElement || params.label}
  />
      

}

export default TickBox