import React,{useEffect} from 'react'
import { Panel as PP,PanelHeaderTemplateOptions } from 'primereact/panel';
import Loader from '../core/loading'
import { Ripple } from 'primereact/ripple';
import OKCancelButtons from "../core/OKCancelButtons";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';

export interface MultiPanel{
  title?:string
  children?: React.ReactNode
}
interface PropsType {
    title?:string
    loading?:boolean
    children?: React.ReactNode
    toggleable?:boolean
    showOkCancel?:boolean
    onOK?: ()=> void
    onCancel?: ()=> void
    onClose?: ()=> void
    scrollHeight?:string
    hidePanel?:boolean
    dismissable?:boolean
    panels?:MultiPanel[]
    mode?: 'card' | 'panel'
    noContentPadding?: boolean
    className?:string
  }


const Panel:  React.FC<PropsType> = (props) => {

  useEffect(() => {
  },[props.loading]);

  const handleOk =() =>{if(props.onOK) props.onOK()}
  const handleCancel =() =>{if(props.onCancel) props.onCancel()}
  const loader=<Loader useBackdrop={false} loading={props.loading||false}></Loader>


  if(props.mode=== 'card'){
    return <Card subTitle={props.title} >
       {props.children}
    </Card>
  }


  if(!props.toggleable && !props.dismissable && !props.panels){

    let childContent = <>{props.loading === undefined || !props.loading ? props.children : loader}</>

    if(props.scrollHeight){
      const height = props.scrollHeight.indexOf('%')===-1 ? `${props.scrollHeight}px`:
      `${(parseInt(props.scrollHeight.replace('%','')) /100) * window.screen.availHeight}px`
        childContent = <ScrollPanel style={{ width: '100%', height: height }} className={props.className}>
    {childContent}
            </ScrollPanel>
    }
    if(props.hidePanel) return <div>{props.children}</div>
    return <>
        <PP header={props.title} toggleable={props.toggleable || false} style={{ marginTop: 5 }} className={props.noContentPadding ?? false ? 'p-panel-content-no-padding':'' }>
        {childContent}
    </PP>
    {props.showOkCancel && <OKCancelButtons onOK={handleOk} onCancel={handleCancel} />}
    </>;

    
  }

  const template = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;

    return (
        <div className={className} style={{display:'block'}}>
            <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
            </button>
            <span style={{marginLeft:5}} className={titleClassName}>
            {props.title}
            </span>
        </div>
    )
}
 
  if(props.toggleable)
    return <><PP header={props.title} toggleable={true} headerTemplate={template}>
          {props.loading === undefined || !props.loading ? props.children : loader}
      </PP>
      
      </>

    if(props.panels ){
      return         <div className="card">
      <Accordion multiple activeIndex={[0]}>
        {props.panels?.map((f:MultiPanel) => (

          <AccordionTab  header={f.title} >
            {f.children}
            
          </AccordionTab>
            ))}      
        </Accordion>
  </div>
    }
    

    const templateWithClose = (options: PanelHeaderTemplateOptions) => {
      const toggleIcon = 'pi pi-times-circle'
      const className = `${options.className} justify-content-start`;
      const titleClassName = `${options.titleClassName} pl-1`;
  
      return (
          <div className={className} style={{display:'block'}}>
              <button className={options.togglerClassName} onClick={props.onClose} style={{float:'right'}}>
                  <span className={toggleIcon} style={{fontSize:25}}></span>
                  <Ripple />
              </button>
              <span style={{marginLeft:5}} className={titleClassName}>
              {props.title}
              </span>
          </div>
      )
    }
      return <><PP header={props.title} headerTemplate={templateWithClose}>
                  {props.loading === undefined || !props.loading ? props.children : loader}
              </PP>
      </>
}

export default Panel