import React, { useEffect, useCallback } from 'react';
import { removeJwt, resetDomainEntities, userHasApprovedPermissions, userRoleIsAdmin } from '../../services/storageService';
import logo from '../../assets/images/logo_alt.png';
import { useNavigate } from "react-router-dom";
import { isUserAuthenticated } from "../../services/storageService";
import { Menubar } from 'primereact/menubar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import TextBox from '../core/textBox';
import { isPublicPage } from '../../extensions/utils';
import FeedBackText from '../core/feedbackText';


export interface AppMenuProps {
    notifications?: string[]
    authenticated: boolean
    impersonating:boolean|string
}


const AppMenu: React.FC<AppMenuProps> = (props) => {

    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [impersonating, setImpersonating] = React.useState(props.impersonating)
    const [isAuthenticated, setIsAuthenticated] = React.useState(props.authenticated)
    const [showNotRegisteredMessage] = React.useState(isPublicPage)
    const [isAdmin, setIsAdmin] = React.useState(false)
    const [timoutCountdownSeconds, setTimoutCountdownSeconds] = React.useState(900)
    const [showNotificationBell, setShowNotificationBell] = React.useState(false)
    const [notifications, setNotifications] = React.useState(props.notifications)
    const op = React.useRef(null);

    let idleTimeout: any;
    let idleTimeoutCountdown: any;


    useEffect(() => {
        // setNumberOfNotification(props.numberOfNotifications)

        if (props.notifications && props.notifications.length > 0) {
            setShowNotificationBell(!showNotificationBell)
        }
        setNotifications(props.notifications)

    }, [props.notifications])
    useEffect(() => {
        setIsAuthenticated(props.authenticated && userHasApprovedPermissions())
        setIsAdmin(userRoleIsAdmin())
    }, [props.authenticated])


    useEffect(() => {
        setImpersonating(props.impersonating)
    }, [props.impersonating])


    useEffect(() => {
        if (timoutCountdownSeconds < 1)
            idleLogoutAction()
    }, [timoutCountdownSeconds])


    const resetIdleTimer = useCallback(() => {
        if (!isAuthenticated)
            return
        clearTimeout(idleTimeout)
        clearInterval(idleTimeoutCountdown)
        setTimoutCountdownSeconds(31)
        //270000
        idleTimeout = setTimeout(idleLogout, 2700000)

    }, [])

    const idleLogout = () => {
        clearTimeout(idleTimeout);
        idleTimeoutCountdown = setInterval(() => {
            setTimoutCountdownSeconds(timoutCountdownSeconds => (timoutCountdownSeconds - 1))
        }, 1000)
    }

    const idleLogoutAction = () => {
        handleLogout()
    }
    document.onmousemove = resetIdleTimer;
    document.onkeypress = resetIdleTimer;

    useEffect(() => {
        clearTimeout(idleTimeout)
        clearInterval(idleTimeoutCountdown)
        if (isAuthenticated)
            resetIdleTimer()

        // setCurrentUser(getUser())
        setIsAuthenticated(isUserAuthenticated())
    }, [])



    const handleLogout = () => {
        removeJwt()
        resetDomainEntities()
        // window.location.reload(false)
        window.location.href = "/login"

    }

    const handleNotificationClicked = (e: any) => {
        if (!showNotificationBell) return
        if (op && op?.current && notifications && notifications?.length > 0)
            (op?.current as any).toggle(e)
    }

    const handleShowNotification = () => {
        setNotifications(undefined)
        setShowNotificationBell(false)

    }

    const navigate = useNavigate();


    const items: MenuItem[] = [{
        label: 'Home',
        icon: 'pi pi-fw pi-home',
        command: () => navigate('/home', { replace: false, state: Date.now() })
    },
    {
        label: 'Admin',
        visible: isAdmin,
        icon: 'pi pi-fw pi-wrench',
        items: [
            {
                label: 'Home',
                icon: 'pi pi-fw pi-bookmark',
                command: () => navigate('/admin', { replace: true })
            },
            {
                label: 'Users',
                command: () => navigate('/admin/user', { replace: true }),
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Pending Access Requests',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => navigate('/admin/user-pending-access', { replace: true })
                    },
                    {
                        label: 'Pending User Registrations',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => navigate('/admin/user-pending-registration', { replace: true })
                    },
                    {
                        label: 'Users',
                        icon: 'pi pi-fw pi-users',
                        command: () => navigate('/admin/user', { replace: true })
                    },
                    {
                        label: 'User Roles',
                        icon: 'pi pi-fw pi-lock',
                        command: () => navigate('/admin/user-role', { replace: true })
                    }
                ]
            },
            {
                label: 'Services',
                icon: 'pi pi-fw pi-building',
                command: () => navigate('/admin/service', { replace: true }),
                items: [
                    {
                        label: 'Services',
                        icon: 'pi pi-fw pi-building',
                        command: () => navigate('/admin/service', { replace: true })
                    },
                    {
                        label: 'Categories',
                        icon: 'pi pi-fw pi-box',
                        command: () => navigate('/admin/service-categories', { replace: true })
                    },
                    {
                        label: 'Bed Types',
                        icon: 'pi pi-fw pi-share-alt',
                        command: () => navigate('/admin/bed-types', { replace: true })
                    },
                    {
                        label: 'Areas',
                        icon: 'pi pi-fw pi-globe',
                        command: () => navigate('/admin/areas', { replace: true })
                    },
                    {
                        label: 'Features',
                        icon: 'pi pi-fw pi-objects-column',
                        command: () => navigate('/admin/service-features', { replace: true })
                    },
                    {
                        label: 'Funding Types',
                        icon: 'pi pi-fw pi-pound',
                        command: () => navigate('/admin/funding-types', { replace: true })
                    },
                    {
                        label: 'Trusts',
                        icon: 'pi pi-fw pi-eye',
                        command: () => navigate('/admin/service-trusts', { replace: true })
                    }
                ]
            },
            {
                label: 'Forms',
                icon: 'pi pi-fw pi-book',
                command: () => navigate('/admin/form', { replace: true }),
                items: [
                    {
                        label: 'Forms',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/admin/form', { replace: true })
                    },
                    {
                        label: 'Form Result',
                        icon: 'pi pi-fw pi-clone',
                        command: () => navigate('/admin/form-reporting-category', { replace: true })
                    }
                ]
            },
            {
                label: 'Capacity Grids',
                icon: 'pi pi-fw pi-th-large',
                command: () => navigate('/admin/capacity-grid', { replace: true }),
                items: [
                    {
                        label: 'Capacity Grids',
                        icon: 'pi pi-fw pi-table',
                        command: () => navigate('/admin/capacity-grid', { replace: true })
                    },
                    {
                        label: 'Shared Columns',
                        icon: 'pi pi-fw pi-calculator',
                        command: () => navigate('/admin/capacity-grid-shared-fields', { replace: true })
                    },
                    {
                        label: 'Necs Excluded Services',
                        icon: 'pi pi-fw pi-building',
                        command: () => navigate('/admin/necs-excluded-services', { replace: true })
                    }
                ]
            },
            {
                label: 'System',
                icon: 'pi pi-fw pi-sliders-h',
                command: () => navigate('/admin/', { replace: true }),
                items: [{
                    label: 'Document Library',
                    icon: 'pi pi-book',
                    command: () => navigate('/admin/document-library', { replace: true })

                }, {
                    label: 'Action Cards',
                    icon: 'pi pi-bolt',
                    command: () => navigate('/admin/action-cards', { replace: true })

                }, {
                    label: 'Distribution Lists',
                    icon: 'pi pi-envelope pi-envelope',
                    command: () => navigate('/admin/distribution-lists', { replace: true }),
                    items: [
                        {
                            label: 'Distribution Lists',
                            icon: 'pi pi-fw pi-envelope',
                            command: () => navigate('/admin/distribution-lists', { replace: true })
                        },
                        {
                            label: 'Distribution List Members',
                            icon: 'pi pi-fw pi-user',
                            command: () => navigate('/admin/distribution-list-members', { replace: true })
                        }
                    ]
                }, {
                    label: 'System Messages',

                    icon: 'pi pi-exclamation-triangle',
                    command: () => navigate('/admin/system-messages', { replace: true }),
                }, {
                    label: 'Automated Reports',
                    icon: 'pi pi-fw pi-bookmark',
                    command: () => navigate('/admin/automated-reports', { replace: true }),
                }, {
                    label: 'Notification Broadcasts',
                    icon: 'pi pi-megaphone',
                    command: () => navigate('/admin/notification-broadcasts', { replace: true })

                }, {
                    label: 'Configuration',
                    icon: 'pi pi-fw pi-cog',
                    command: () => navigate('/admin/config', { replace: true }),
                }, {
                    label: 'API Users',
                    icon: 'pi pi-fw pi-users',
                    command: () => navigate('/admin/api-users', { replace: true }),
                }]
            },
            {
                separator: true
            },
            {
                label: 'Tasks',
                icon: 'pi pi-fw pi-external-link',
                command: () => navigate('/admin/tasks', { replace: true })
            }
        ]
    },
    {
        label: 'Reporting',
        icon: 'pi pi-fw pi-book',
        items: [
            {
                label: 'Home',
                icon: 'pi pi-fw pi-bookmark',
                command: () => navigate('/reporting', { replace: true })
            },
            {
                label: 'Escalation',
                icon: 'pi pi-chart-bar',
                items: [
                    {
                        label: 'Service Compliance',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/compliance', { replace: true })
                    },
                    {
                        label: 'Escalation Triggers',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/triggers', { replace: true })
                    },
                    {
                        label: 'Escalation Triggers Summary',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/triggerssummary', { replace: true })
                    },
                    {
                        label: 'Escalation Trend',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/trend', { replace: true })
                    },
                    {
                        label: 'Escalation Level',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/level', { replace: true })
                    },
                    {
                        label: 'Service Status',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/service-status', { replace: true })
                    },
                    {
                        label: 'Form Data Capture',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/data-capture', { replace: true })
                    },
                    {
                        label: 'Situation Report',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/form/sitrep', { replace: true })
                    }
                ]
            }, {
                label: 'Opel',
                icon: 'pi pi-circle',
                items: [
                    {
                        label: 'Opel',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/opel/submission', { replace: true })
                    },
                ]
            },
            {
                label: 'Capacity Grids',
                icon: 'pi pi-fw pi-table',
                items: [
                    {
                        label: 'Service Compliance',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/capacity-grid/compliance', { replace: true })
                    },
                    // {
                    //     label: 'Capacity Tracker',
                    //     icon: 'pi pi-fw pi-book',
                    //     command: () => navigate('/admin/capacity-grid', { replace: true })
                    // },
                    {
                        label: 'Daily Report',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/capacity-grid', { replace: true })
                    },
                    {
                        label: 'Summary Report',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/reporting/capacity-grid/summary', { replace: true })
                    }
                ]
            },
            {
                label: 'Live Data',
                icon: 'pi pi-fw pi-arrows-v',
                items: [
                    {
                        label: 'Dataset Metrics',
                        icon: 'pi pi-fw pi-book',
                        command: () => navigate('/admin/capacity-grid', { replace: true })
                    }
                ]
            },
            {
                label: 'System',
                visible: isAdmin,
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        label: 'System Audit',
                        icon: 'pi pi-fw pi-cog',
                        command: () => navigate('/reporting/system/audit', { replace: true })
                    }, {
                        label: 'Email Audit',
                        icon: 'pi pi-fw pi-send',
                        command: () => navigate('/reporting/system/email', { replace: true })
                    }, {
                        label: 'Necs Data Import Audit',
                        icon: 'pi pi-fw pi-send',
                        command: () => navigate('/reporting/system/capacity-grid-import-audit', { replace: true })
                    }, {
                        label: 'User Subscription Audit',
                        icon: 'pi pi-fw pi-user',
                        command: () => navigate('/reporting/system/user-subscription-report', { replace: true })
                    }, {
                        label: 'User Permission Report',
                        icon: 'pi pi-fw pi-user',
                        command: () => navigate('/reporting/system/user-role-report', { replace: true })
                    }
                ]

            },

        ]
    },
    // {
    //     label: 'Mobile App',
    //     icon: 'pi pi-fw pi-mobile',
    //     items: [
    //         {
    //             label: 'Download',
    //             icon: 'pi pi-cloud-download',
    //         }
    //     ]
    // },
    {
        label: 'My Account',
        icon: 'pi pi-fw pi-user',
        items: [
            {
                label: 'Options',
                icon: 'pi pi-fw pi-cog',
                command: () => navigate('/account', { replace: true })
            },
            {
                label: 'Log off',
                icon: 'pi pi-fw pi-power-off',
                command: handleLogout
            }
        ]
    },
    {
        label: 'Help',
        icon: 'pi pi-fw pi-info-circle',
        command: () => navigate('/help/list', { replace: true })
    }
    ];

    const start = <><div style={{ width: '43px', float: 'left', cursor: 'pointer' }} onClick={() => navigate("/")}>
        <img src={logo} className="App-logo" alt="logo" style={{ height: 30, width: 35, margin: 5 }} />
    </div>
        <div className='hide-when-small' style={{ marginTop: -12, float: 'left', cursor: 'pointer', marginRight: 15, }} onClick={() => navigate("/")}>
            <h3>EMS+</h3>

        </div>
        {showNotRegisteredMessage && <div style={{ marginTop: 8, float: 'left' }}>
            <span>You are not registered for EMS+. For a full user experience please register by clicking <a href="/register">here</a></span>
        </div>}
    </>

    
    const end = <>
        <div className='hide-when-small' style={{ float: 'right' }}><TextBox icon="pi-search" iconPosition='left' placeholder='search'></TextBox></div>
        <div style={{ float: 'right', marginTop: 4 }}><Button rounded outlined={!showNotificationBell} icon="pi pi-bell" severity="warning" aria-label="Notification"
            onClick={handleNotificationClicked} />&nbsp;</div>
        <OverlayPanel style={{ marginLeft: -70, padding: 0 }} ref={op} onHide={handleShowNotification}>
            <ul style={{ padding: 2 }}>
                {notifications?.map(m => {
                    return <li>{m}</li>
                }

                )}
            </ul>
        </OverlayPanel>
    </>;

    if (isAuthenticated)
        return (
            <div style={{ zIndex: 999, position: 'relative' }}>
                <div className="card">
                    <Menubar model={items} start={start} end={end} />
                </div>
                {impersonating && <>
                    <FeedBackText show={true} message={`You are impersonating ${impersonating}`} severity='error' />
                </>}

            </div>
        );
    else
        return (
            <div>
                <div className="card">
                    <Menubar start={start} model={props.authenticated ? [{
                        label: 'Log Off',
                        icon: 'pi pi-fw pi-power-off',
                        command: () => handleLogout()
                    }] : []} />
                </div>
            </div>
        );
}

export default AppMenu