import { DocumentUploadType, DocumentUploadTypes } from "../interfaces/system/documentUploadTypes"
import { HttpGet, HttpPost, HttpResponse } from "./http"

export const fetchDocumentLibraryList = async (docType?: DocumentUploadTypes): Promise<HttpResponse<DocumentUploadType[]>> => {

    const response = await HttpGet<DocumentUploadType[]>(
        `storage/list` + (docType ? "?docType=" + (docType as number).toString() : "")
    )

    return response
}
export const fetchDocumentsByService = async (id:number): Promise<HttpResponse<DocumentUploadType[]>> => {

    const response = await HttpGet<DocumentUploadType[]>(
        `storage/service?serviceId=${id}`
    )

    return response
}
export const fetchDocumentUpload = async (id:number): Promise<HttpResponse<DocumentUploadType>> => {

    const response = await HttpGet<DocumentUploadType>(
        `storage/${id}`
    )

    return response
}
export const saveDocumentUpload = async (data: DocumentUploadType, file?: File): Promise<HttpResponse<DocumentUploadType>> => {
    let postData = { ...data }
    let serviceIds = '';
    if ((postData.services?.length ?? 0) > 0) {
        postData.services?.forEach((i) => {
            serviceIds += `&services=${i.serviceId}`;
        });
    }

    const response = await HttpPost<DocumentUploadType>(
        `storage/SaveDocument?id=${data.id}&name=${data.name}&description=${data.description}&documentTypeId=${data.documentTypeId}&deleted=${data.deleted}${serviceIds}`, {}, file
    )

    return response
}