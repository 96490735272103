import React, {useEffect,useState} from "react"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import OKCancelButtons from "../../core/OKCancelButtons";
import Loader from "../../core/loading";
import SelectList from "../../core/selectList";
import TickBox from "../../core/tickBox";
import { UserRoleType } from "../../../interfaces/user/iUser";

interface UserRoleEditProps {
    userRole?:UserRoleType
    onSave: (service: UserRoleType,valid:boolean) => void 
    onCancel: () => void 
  }

const ServiceEdit:  React.FC<UserRoleEditProps> = (props) => {
  const [validator] = useState(props.userRole?.validator)
  const [permissionIds,setPermissionIds] = useState<number[]>([])
  const [disablePublicName,setDisablePublicName] = useState(true)
  const {
    validateForm, objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.userRole, validator: validator})

   useEffect(() => {

        window.addEventListener('popstate', function() {
            props.onCancel()
        }, false);
   },[])
   
useEffect(() => {
  
  if(props.userRole?.id != objectValues?.id){
    
    setObjectValues(props.userRole)
    
  }
  const selectedPermissions = props.userRole?.permissions.map((m)=> m.permissionTypeId)
    setPermissionIds(selectedPermissions||[])
},[props.userRole])

   
const handleSave = async () =>{
  let isValid = validateForm(objectValues)
  setObjectValues({
    ...objectValues,
    validator: validator
 })


  props.onSave(objectValues, isValid)
      
}  
      const handleCancel =() =>{if(props.onCancel) props.onCancel()}
   
  const handleFeaturesSelected = (selected:number[]) =>{
    setObjectValues({
      ...objectValues,
      permissionTypeIds: selected,
      permissions : selected.map((m)=> {return {id: 0,deleted:false, permissionTypeId:m}})
   })
  }
if(!objectValues)
        return <Loader loading={true} />
  

  return <>
  <Grid container justifyContent="center">
  <Grid   container spacing={0} justifyContent = "center" style={{padding:'15px 5px'}}>
  <Grid item xs={9}>
  <Grid container spacing={2}>
                <Grid item xs={2}><TextBox id="id"  label="Id" value={objectValues.id} disabled={true}/></Grid>
                
                <Grid item xs={2} ><TextBox id="sortOrder" type="number" label="Display Order" value={objectValues.sortOrder} onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={2}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
                <Grid item xs={6} ></Grid>
                <Grid item xs={6}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={6} ></Grid>
                <Grid item xs={6}><TextBox id="publicName"  label="Public Name" disabled={disablePublicName}  value={objectValues.publicName} onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={2}><TickBox id="public"  label="Public"  checked={objectValues.public} onChange={(e) => {setValues(e);setDisablePublicName(!disablePublicName)}}/></Grid>
                <Grid item xs={8}><TextBox rows={4} id="description"  label="Description"  value={objectValues.description} onChange={(e) => {setValues(e)}} /></Grid>
                
                </Grid>
                <Grid item xs={8}>
      <SelectList title="Permissions" optionName="permission-list" onChange={handleFeaturesSelected} fullRow={true} scrollHeight={120} selectedIds={permissionIds} validationField={validator?.permissions} />
    </Grid>
    <Grid item xs={8}>
        <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />
        </Grid>     
</Grid> 
</Grid>
</Grid>     
                
  </>

}

export default ServiceEdit;