import React, { useState} from "react";
import ActionButton from "../../components/core/actionButton";
import {Grid} from '@mui/material'
import { generateCombinedEscalation, getEncrpytedPassword, saveAmbulanceMeanTA_TEMP } from "../../services/taskService";
import TextBox from "../../components/core/textBox";
import Panel from "../../components/core/panel";
import { createNewUserLogin, UserRegistrationLoginType } from "../../interfaces/user/iUser";
  

const TaskContainer:  React.FC = () => {

    const [loading, setLoading]= useState(false)
    const [user, setUser]= useState<UserRegistrationLoginType>(createNewUserLogin())
    const [meanTAData, setMeanTAData]= useState<string>()
    const [meanTASuccess, setMeanTASuccess]= useState(0)
    const [e, sete] = useState<string>()

    const handleGenerateCombined = async ()=>{
        setLoading(true)
        await generateCombinedEscalation()
        setLoading(false)
    }
    const handleGetEncrpytedPwd = async ()=>{
        setLoading(true)
        const r = await getEncrpytedPassword(user)
        if(r.success) {
            sete(r.result?.token)
        }
        setLoading(false)
    }
    
    const handleSaveMeanTA_THISISTEMP = async ()=>{
        setLoading(true)
        const r = await saveAmbulanceMeanTA_TEMP(meanTAData)
        if(r.success) {
            setMeanTASuccess(1)
        }
        else
            setMeanTASuccess(2)
        setLoading(false)
    }
    return <>
    <br />
    <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} lg={9}>
            <ActionButton label="Generate Combined Services" loading={loading} variant="icon" icon="pi pi-fw pi-building" severity="help" onClick={handleGenerateCombined}/>
        </Grid>

        <Grid item xs={9} lg={6}>
            <Panel title="Password" >
                <h4>Put this in admin to create an API User </h4>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={9} lg={4}>
                        <TextBox label="Password" onChange={(e)=>{ setUser({...user, userName:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={9} lg={4}>
                        <TextBox label="Salt"  onChange={(e)=>{ setUser({...user, password:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <ActionButton label="Get Encrpyted Password" loading={loading} variant="icon" icon="pi pi-fw pi-lock" severity="help" onClick={handleGetEncrpytedPwd}/>
                    </Grid>
                    {e &&
                        <p>Password is {JSON.stringify(e)}</p>
                    }       
                </Grid>
            </Panel>
        </Grid>
        
        <Grid item xs={9} lg={7}>
            <Panel title="Ambulance Data" >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={9}>
                        <TextBox label="Bulk input {sitecode}<tab or space>{minutes}" rows={10} onChange={(e)=>setMeanTAData(e.target.value)}/>
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <ActionButton label="Submit" loading={loading} variant="icon" icon="pi pi-fw pi-tick" severity="help" onClick={handleSaveMeanTA_THISISTEMP}/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        {meanTASuccess===1 && <p>
                            Successfully updated Mean Turnaround times
                        </p>}
                        {meanTASuccess===2 && <p>
                            There was an error updating Mean Turnaround times
                        </p>}
                    </Grid>
                </Grid>
            </Panel>
        </Grid>
    </Grid>
    </>

//     const [taskList, setTaskList] = useState(new Array<ITaskSearchResult>())
//     const [searchCriteria, setSearchCriteria] = useState<ITaskSearchCriteria>({})
//     const [taskNotes, setTaskNotes]= useState<INote[]>([])
//     const [taskReminders, setTaskReminders]= useState<ITaskReminderLite[]>()
//     const [taskNotifications, setTaskNotifications]= useState<ITaskNotificationLite[]>()
//     const {
//         hasError, setHasError,
//         handleChange, 
//         loading, setLoading,setSaveSuccess, saveSuccess, objectValues, saveFailure, setSaveFailure, setObjectValues
//       }
//        = useForm({initialObjectValues: createNewTask() })
//        const history = useHistory()
       
//        const reducer = (state :any, action: ITaskReducerAction) => {
//         switch (action.type) {
//           case 'settask':
//             setObjectValues({...action.payload})
//             return objectValues
//         case 'setreminders':
//             setObjectValues({...objectValues,reminders:action.payload.reminders})
//             return objectValues
//           case 'updateform':
//               handleChange(action.e)
//               return objectValues
//           }
//           // should this be here?
//         return state
//     }
//       const [state, dispatch] = useReducer(reducer, objectValues)
//       const params = useParams<IRouteParams>();
//       const [selectedTaskId] = useState(params.selectedTaskId);

//     const handleSearch = async (searchCriteria: ITaskSearchCriteria) => {
//         setHasError(false)
//         try{
//         const searchResults = await taskSearch(searchCriteria)  
//         setTaskList(searchResults)
//         setSearchCriteria(searchCriteria)
        
//         loadTaskReminders()
//         loadTaskNotifications()
        
//         }
//         catch{
//             setHasError(true)
//         }
//         finally{
//             setLoading(false)
//         }
//     }
//     const loadTaskReminders = async () => {
//         const reminders = await fetchTaskRemindersForCurrentUser()
//         setTaskReminders(reminders)
//     }
//     const loadTaskNotifications = async () => {
//         const notifications = await fetchTaskNotificationsForCurrentUser()
//         setTaskNotifications(notifications)
//     }
//     useEffect(() => {
//         setLoading(true)
//         if(!params.selectedTaskId){
//             handleSearch(searchCriteria)
//         }
//     }
//     ,[])
 
    

//     useEffect(() => {
//         if(selectedTaskId) {
//             getTaskById(selectedTaskId)
//         }
//    },[selectedTaskId])

//     const getTaskById = async (taskId:string) => {
//         history.push(`/task/${taskId}`)
//         try{
//         let result = createNewTask()
//         if(taskId !== '-1'){
//             result = await fetchTaskById(taskId)
//             const notes = await fetchTaskNotes(taskId)
            
//             setTaskNotes(notes)
            
//         }
//         else{
//             result.id=-1
//         }
//         dispatch({type:'settask', payload:result})
//     }
//     finally{
//         setLoading(false)
//     }
            
        
//     }
//     const handleNewTask = () =>{
//         getTaskById('-1')
//     }
//     const handleClose = () => {
//         history.push(`/task`)
//         handleSearch(searchCriteria)
//         setObjectValues({...objectValues, id:0})
//     }
//     const handleSave = async (task: ITask) => {
//         try 
//         {
//             setLoading(true)
//             const success = await saveTask(task)
//             setSaveSuccess(success)
//             setSaveFailure(!success)
//             history.push(`/task`)
//             if(success){
//                 handleSearch(searchCriteria)
//                 setObjectValues({...objectValues, id:0})
//                 setTimeout(() => setSaveSuccess(false), 30000)
//             }
//         } 
//         catch (error) {
//             setSaveFailure(true)
//             handleSaveFailure()
//         }
//         finally{
//             setLoading(false);
//         }
//     }
//     const handleSaveFailure = () =>{
//         setSaveFailure(true)
//     }
//     const handleNotificationSelected= (taskId: string) =>{
//         getTaskById(taskId)
//         clearNotificationsForTaskId(taskId)
//         loadTaskNotifications()
//     }
//     const handleTaskSelected = (id:string) => {
//         getTaskById(id)
//     }
//     return <>
//         <TaskReducerContext.Provider value={{state, dispatch}}>
//             <Loader loading={loading && !hasError }></Loader>
//             <FeedBackText show={saveSuccess} type="success"></FeedBackText>
//             <FeedBackText show={saveFailure} type="error"></FeedBackText>
//             {objectValues.id === 0 && <Grid container spacing={1}>
//                 <Grid item xs={12} lg={8}>
//                     <TaskSearch handleSearch={handleSearch} searchCriteria={searchCriteria}></TaskSearch>
//                 </Grid>
//                 <Grid item xs={12} lg={8}>
//                     <FeedBackText show={hasError} message="Unable to perform search" type="error"></FeedBackText>
//                 </Grid>
//                 <Grid item xs={12}>
//                 <TaskList taskList={taskList} onTaskSelected={handleTaskSelected}></TaskList>
//                 </Grid>
//                 <Grid item xs={12} lg={5}>
//                     <TaskReminderList reminders={taskReminders} taskSelected={(taskId)=> getTaskById(taskId)}></TaskReminderList>
//                 </Grid>
//                 <Grid item xs={12} lg={5}>
//                     <TaskNotificationList notifications={taskNotifications} taskSelected={(taskId)=> handleNotificationSelected(taskId)}></TaskNotificationList>
//                 </Grid>
                
//                 <FloatingButton icon='Add' onClick={handleNewTask}></FloatingButton>
//             </Grid>
//             }
//              {
//             objectValues.id !==0 && <TaskEdit task={objectValues} notes={taskNotes} readOnlyMode={objectValues.id!=='-1'} onClose={handleClose} onSave={handleSave}></TaskEdit>
//         }
//         </TaskReducerContext.Provider>
//     </>
}

export default TaskContainer