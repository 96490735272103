import { useEffect, useState } from "react";
import { DocumentUploadType, getUploadFileName } from "../../interfaces/system/documentUploadTypes"
import { Grid } from "@mui/material";
import React from "react";
import ActionButton from "../core/actionButton";
import { DownloadDocumentLibraryFile } from "../../services/http";
import { formatDate, formatDateTime, formatDateTimeWithDay } from "../../extensions/formatter";

export interface ServiceUploadListProps {
    serviceUploads?: DocumentUploadType[]
}

export function ServiceUploadList(props: ServiceUploadListProps) {
    const [serviceUploads, setServiceUploads] = useState(props.serviceUploads);

    useEffect(() => {
        setServiceUploads(props.serviceUploads)
    }, [props.serviceUploads])

    const handleDownloadFile = async (upload: DocumentUploadType) => {
        await DownloadDocumentLibraryFile(upload.uploadKey + '', upload.documentTypeId, getUploadFileName(upload));
    }

    if (!serviceUploads || serviceUploads.length <= 0) {
        return <>No uploads</>
    }

    return <>
        <p>&nbsp;</p>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8}>
                <p>Uploads associated with this service:</p>
                <table className="condensed bordered table-striped">
                    <thead>
                        <tr>
                            <th className="text-left bold">Document Name</th>
                            <th className="text-left bold">Filename</th>
                            <th className="text-left bold">Uploaded On</th>
                            <th className="text-left bold">File Size</th>
                            <th style={{ width: '200px' }}>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(serviceUploads.map(upload => {
                            return <tr>
                                <td>{upload.name}</td>
                                <td>{getUploadFileName(upload)}</td>
                                <td>{formatDateTime(upload.uploadedOn)}</td>
                                <td>{Math.ceil((upload.fileSize) / 1024) + " kb"}</td>
                                <td>
                                    <ActionButton onClick={(e: any) => { handleDownloadFile(upload); }} label="Download file" severity="info" size="small" icon="pi pi-paperclip"></ActionButton>
                                </td>
                            </tr>
                        }))}
                    </tbody>
                </table>
            </Grid>
        </Grid>
        <p>&nbsp;</p>
    </>
}